import React from 'react'
import {
  Text,
  Modal,
  HStack,
  VStack,
  View,
  Input,
  Avatar,
  Button,
  Box,
  Center,
  Image,
  Stack,
  Tooltip,
} from 'native-base'
import explanation from '../assets/question_icon.png'
import AppBarHeader from '../components/AppBar'
import { FlatList } from 'react-native-gesture-handler'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_PILOTS, GET_OPERATOR } from '../graphql/queries'
import { auth, storage } from '../utils/nhost'
import {
  CREATE_PILOT,
  UPDATE_PILOT,
  DELETE_PILOT,
  CREATE_PILOT_IMAGE,
} from '../graphql/mutations'
import { getToken } from '../Token'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import ImageUploading from 'react-images-uploading'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import ModalError from '../components/ModalError'
import Actions from '../components/Pilot/Actions'
import { HASURA } from '@env'
import phoneLanguage from '../PhoneLanguage'

export const Pilot = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUserId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [createPilot] = useMutation(CREATE_PILOT)
  const [images, setImages] = React.useState([])
  const maxNumber = 1
  const [createPilotImage] = useMutation(CREATE_PILOT_IMAGE)
  const [operatorId, setOperatorId] = React.useState('')
  const [pilotsArray, setPilotsArray] = React.useState([])
  const [getPilots] = useLazyQuery(GET_PILOTS)
  const [getOperatorId] = useLazyQuery(GET_OPERATOR)
  const { t } = useTranslation()
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [buttonCreateLoading, setButtonCreateLoading] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [errorNumber, setErrorNumber] = React.useState('')
  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificateNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      selectedCallingCode: '',
    },
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)

    const operator = await getOperatorId({
      variables: { userId: userId },
    })
    setOperatorId(operator.data.operators[0].id)
    await getPilotsArray(userId)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
    setLoading(false)
  }, [])

  const getPilotsArray = async (userId) => {
    const pilots = await getPilots({
      variables: { userId: userId },
      fetchPolicy: 'network-only',
    })
    const pilotsInformation = await getPilotsWithImage(pilots.data.pilots)
    setPilotsArray(pilotsInformation)
  }

  const getPilotsWithImage = async (pilots) => {
    const newArrayPilots = await Promise.all(
      pilots.map(async (pilot) => {
        if (pilot.pilot_image_id && pilot.pilot_image.file_id) {
          const fileId = pilot.pilot_image.file_id
          const getUrl = await storage.getPresignedUrl({ fileId })
          const pilotInformation = {
            ...pilot,
            urlImage: getUrl.presignedUrl.url,
          }
          return pilotInformation
        } else {
          return pilot
        }
      })
    )
    return newArrayPilots
  }

  //Upload ImageUpload

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    try {
      let res = await fetch(`${HASURA}/v1/storage/files/`, {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      let responseJson = await res.json()
      const imageId = responseJson.processedFiles[0].id
      return imageId
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (pilot) => {
    setButtonCreateLoading(true)
    try {
      if (phoneValid === true) {
        if (images.length > 0) {
          await createPilotWithImage(pilot)
        } else {
          await createNewPilot(pilot)
        }
        setButtonCreateLoading(false)
        closeModal()
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonCreateLoading(false)
      }
    } catch (error) {
      setButtonCreateLoading(false)
      setError(t('pilots.errors.create'))
      openError()
    }
  }

  const createPilotWithImage = async (pilot) => {
    const imageId = await uploadImage()
    const pilotImage = await createPilotImage({
      variables: {
        file_id: imageId,
      },
    })
    const createdPilot = await createPilot({
      variables: {
        certificate_number: pilot.certificateNumber,
        email: pilot.email,
        first_name: pilot.firstName,
        last_name: pilot.lastName,
        phone_number: pilot.selectedCallingCode,
        operator_id: operatorId,
        pilot_image_id: pilotImage.data.insert_pilot_image_one.id,
      },
      refetchQueries: [
        {
          query: GET_PILOTS,
          variables: {
            userId: user,
          },
        },
      ],
    })
    const refetchFleet = await getPilots({
      variables: { userId: user },
      fetchPolicy: 'network-only',
    })
    const newPilotsInformation = [
      ...refetchFleet.data.pilots,
      createdPilot.data.insert_pilots_one,
    ]
    const newPilotsArray = await getPilotsWithImage(newPilotsInformation)
    setPilotsArray(newPilotsArray)
    reset()
    setImages([])
  }

  const createNewPilot = async (pilot) => {
    const createdPilot = await createPilot({
      variables: {
        certificate_number: pilot.certificateNumber,
        email: pilot.email,
        first_name: pilot.firstName,
        last_name: pilot.lastName,
        phone_number: pilot.selectedCallingCode,
        operator_id: operatorId,
      },
      refetchQueries: [
        {
          query: GET_PILOTS,
          variables: {
            userId: user,
          },
        },
      ],
    })
    const refetchFleet = await getPilots({
      variables: { userId: user },
      fetchPolicy: 'network-only',
    })
    const newPilotsInformation = [
      ...refetchFleet.data.pilots,
      createdPilot.data.insert_pilots_one,
    ]

    setPilotsArray(newPilotsInformation)
    reset()
  }

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const onDelete = (id) => {
    const newArrayPilotDelete = pilotsArray.filter((item) => {
      return item.id !== id
    })
    setPilotsArray(newArrayPilotDelete)
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const updateArrayPilots = async (updatedPilot, index) => {
    let newArr = [...pilotsArray]
    newArr[index] = updatedPilot
    const newPilotsArray = await getPilotsWithImage(newArr)
    setPilotsArray(newPilotsArray)
  }

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }
  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  if (loading) return <Loading />
  return (
    <View>
      <>
        {pilotsArray.length === 0 ? (
          <View>
            <AppBarHeader />
            <Center>
              <Text bold p="2">
                {t('pilots.noPilots')}
              </Text>
              <Button
                onPress={() => {
                  openModal()
                }}
                colorScheme="yellow"
              >
                {t('pilots.addPilotButton')}
              </Button>
            </Center>
          </View>
        ) : (
          <View>
            <AppBarHeader />
            <View pl="10%" pr="10%">
              <Box
                p={[4, 4, 12, 12]}
                rounded="xl"
                bgColor="muted.100"
                shadow="9"
              >
                <Text fontSize="2xl" bold>
                  {t('pilots.pilotsTitle')}
                </Text>
                <br />
                <FlatList
                  data={pilotsArray}
                  keyExtractor={(item) => item.id.toString()}
                  ItemSeparatorComponent={() => <br />}
                  renderItem={({ item, index }) => (
                    <Box
                      p={[4, 4, 9, 9]}
                      rounded="xl"
                      bgColor="white"
                      key={item.id.toString()}
                    >
                      <Stack
                        space={3}
                        direction={['column', 'column', 'column', 'row']}
                      >
                        <View width="50%">
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {item.first_name} {item.last_name}
                          </Text>
                          <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                            {item.certificate_number}
                          </Text>
                          <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                            {item.email}
                          </Text>
                          <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                            {formatPhoneNumber(item.phone_number.toString())}
                          </Text>
                        </View>
                        <View width="20%" pl={['30%', '30%', '40%', '0%']}>
                          <Avatar
                            bg="yellow.400"
                            size="xl"
                            source={{
                              uri: item.urlImage
                                ? item.urlImage
                                : `https://cdn.pixabay.com/photo/2019/08/11/18/59/icon-4399701_1280.png`,
                            }}
                          ></Avatar>
                        </View>
                        <View pt="2%" pl={['25%', '25%', '37%', '0%']}>
                          <Actions
                            index={index}
                            pilot={item}
                            user={user}
                            onAction={onDelete}
                            onEdit={updateArrayPilots}
                          />
                        </View>
                      </Stack>
                    </Box>
                  )}
                />
                <br />
                <Button
                  onPress={() => {
                    openModal()
                  }}
                  colorScheme="yellow"
                  size="md"
                >
                  {t('pilots.addPilotButton')}
                </Button>
              </Box>
            </View>
            <br />
          </View>
        )}
      </>
      <Modal isOpen={show} onClose={() => closeModal()} flex={1}>
        <Modal.Content backgroundColor="white">
          <Modal.CloseButton />
          <Modal.Header>{t('pilots.createPilot')}</Modal.Header>
          <Modal.Body>
            <View pt="30">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <View className="upload__image-wrapper">
                    {imageList.map((image, index) => (
                      <Center key={index}>
                        <Image
                          source={{
                            uri: `${image['data_url']}`,
                          }}
                          alt="Alternate Text"
                          size={'xl'}
                          resizeMode="cover"
                          borderRadius={100}
                        />
                        <HStack space={3} p="4">
                          <Box shadow={2} maxWidth="40">
                            <Text pt="2" isTruncated="true">
                              {image.file.name}
                            </Text>
                          </Box>
                          <Button
                            colorScheme="yellow"
                            onPress={() => onImageUpdate(index)}
                          >
                            {t('pilots.updateButton')}
                          </Button>
                          <Button
                            colorScheme="red"
                            onPress={() => onImageRemove(index)}
                          >
                            {t('pilots.removeButton')}
                          </Button>
                        </HStack>
                      </Center>
                    ))}
                    <Center>
                      <Button
                        width="40%"
                        colorScheme="yellow"
                        size="md"
                        style={isDragging ? { color: 'red' } : undefined}
                        onPress={onImageUpload}
                        {...dragProps}
                      >
                        {t('pilots.uploadImage')}
                      </Button>
                    </Center>
                  </View>
                )}
              </ImageUploading>
            </View>

            <Text bold fontSize="md" p="2">
              {t('pilots.pilotInformation')}
            </Text>
            <Text bold fontSize="s" p="2">
              {t('pilots.certificateNumber')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.certificateNumber')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., BRA 2345"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="certificateNumber"
            />
            {errors.certificateNumber && (
              <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
                {t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.firstName')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                  message: t('register.onlyLetters'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.firstName')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., John"
                    >
                      <Image
                        bg="transparent"
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="firstName"
            />
            {errors.firstName && (
              <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
                {errors.firstName.message
                  ? errors.firstName.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.lastName')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                  message: t('register.onlyLetters'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.lastName')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., Smith"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="lastName"
            />
            {errors.lastName && (
              <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
                {errors.lastName.message
                  ? errors.lastName.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.email')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.email')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., john@altaport.com"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="email"
            />
            {errors.email && (
              <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
                {errors.email.message
                  ? errors.email.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.phone')}
            </Text>
            <VStack space={1}>
              <HStack space={1}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      inputStyle={{
                        width: '100%',
                        backgroundColor: 'white',
                        borderColor: '#E3E3E3',
                      }}
                      buttonStyle={{ borderColor: '#D4D4D4' }}
                      country={selectedLanguage}
                      masks={{ br: '.. .........' }}
                      placeholder={placeholderPhone()}
                      value={value}
                      onChange={onChange}
                      isValid={(value, country) => {
                        if (
                          country.name === 'United States' &&
                          value.length > 1 &&
                          value.length < 11
                        ) {
                          setPhoneValid(false)
                        } else if (
                          value.length > 2 &&
                          country.name === 'Brazil' &&
                          value.length < 12
                        ) {
                          setPhoneValid(false)
                        } else {
                          setPhoneValid(true)
                          return true
                        }
                      }}
                    />
                  )}
                  name="selectedCallingCode"
                />
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., +55 15 99677-1785"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    mt={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              </HStack>
              {errors.selectedCallingCode && (
                <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
                  {t('pilots.required')}
                </Text>
              )}
              {phoneValid === true ? (
                <View />
              ) : (
                <View zIndex="-2">
                  <Text fontSize="xs" color="#eb0100">
                    {errorNumber}
                  </Text>
                </View>
              )}
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal()
                }}
              >
                {t('pilots.cancelButton')}
              </Button>
              <Button
                colorScheme="yellow"
                onPress={handleSubmit(onSubmit)}
                isLoading={buttonCreateLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('pilots.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showError}>
        <ModalError error={error} onClose={closeError} />
      </Modal>
    </View>
  )
}

export default Pilot
