import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Text,
  Modal,
  Input,
  Center,
  View,
  Button,
  Heading,
  Image,
} from 'native-base'
import { useQuery, useMutation } from '@apollo/client'
import AppBarHeader from '../components/AppBar'
import { GET_USER_PROFILE } from '../graphql/queries'
import { UPDATE_PROFILE } from '../graphql/mutations'
import { getToken } from '../Token'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { auth, storage } from '../utils/nhost'
import Loading from '../components/Loading'
import ChangePasswordOperatorandManager from '../components/ChangePasswordOperatorandManager'
import phoneLanguage from '../PhoneLanguage'
import errorImage from '../assets/error.png'

export const UserProfile = (props) => {
  const [user, setUserId] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [selectedCallingCode, setSelectedCallingCode] = React.useState('')
  const [updateProfile] = useMutation(UPDATE_PROFILE)
  const { t } = useTranslation()
  const [showChangePassword, setShowChangePassword] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
  }, [])

  const onCloseModal = () => {
    setShowChangePassword(false)
  }

  const showModal = () => {
    setShow(true)
  }
  const logout = async () => {
    await auth.signOut()
    props.history.push('/')
  }

  const { loading, error, data } = useQuery(GET_USER_PROFILE, {
    variables: { _eq: user },
  })

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }

  const update = () => {
    try {
      updateProfile({
        variables: {
          id: data.profile[0].id,
          phone_number: selectedCallingCode
            ? selectedCallingCode
            : data.profile[0].phone_number,
          last_name: lastName ? lastName : data.profile[0].last_name,
          first_name: firstName ? firstName : data.profile[0].first_name,
        },
      })
      setShow(false)
      setFirstName('')
      setLastName('')
      setSelectedCallingCode('')
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`

  return (
    <View>
      <AppBarHeader />
      <Center p="2">
        {data && data.profile && data.profile.length > 0 ? (
          <View backgroundColor="light.100" rounded="2xl" shadow="9" p="4">
            <View>
              <Heading size="lg" p="2">
                {t('userProfile.title')}
              </Heading>

              <Text pb="2" color="muted.600">
                {t('userProfile.firstNameLabel')}
              </Text>
              <Text p="2">{data.profile[0].first_name}</Text>
              <Text pb="2" color="muted.600">
                {t('userProfile.lastNameLabel')}
              </Text>
              <Text p="2">{data.profile[0].last_name}</Text>
              <Text pb="2" color="muted.600">
                {t('userProfile.phoneNumberLabel')}
              </Text>
              <Text p="2">
                {formatPhoneNumber(data.profile[0].phone_number.toString())}
              </Text>
              <Button
                rounded="2xl"
                bgColor="yellow.300"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => {
                  showModal()
                }}
              >
                {t('userProfile.editButton')}
              </Button>
              <View pt="2">
                <Button
                  bgColor="yellow.300"
                  _text={{
                    color: 'coolgray.800',
                  }}
                  onPress={() => {
                    setShowChangePassword(true)
                  }}
                  rounded="2xl"
                >
                  {t('userProfile.changePassword')}
                </Button>
              </View>
              <View pt="2">
                <Button
                  rounded="2xl"
                  colorScheme="red"
                  onPress={() => {
                    logout()
                  }}
                >
                  {t('userProfile.logOut')}
                </Button>
              </View>
            </View>
          </View>
        ) : (
          <View>
            <Center>
              <Image
                source={{ uri: errorImage }}
                alt="Alternate Text"
                style={{ width: 200, height: 200 }}
              />
              <Heading size="xs" mb="5">
                <Text>{t('errors.userProfile')}</Text>
              </Heading>
            </Center>
          </View>
        )}
      </Center>
      <Modal isOpen={show} onClose={() => setShow(false)}>
        <Center>
          <View
            backgroundColor="light.100"
            shadow="9"
            rounded="2xl"
            p="4"
            width="300"
          >
            <Heading size="lg">{t('userProfile.modalTitle')}</Heading>
            <Text>{t('userProfile.firstNameLabel')}</Text>
            <Input
              bgColor="white"
              placeholder={t('userProfile.firstNameModalLabel')}
              defaultValue={data.profile[0].first_name}
              onChangeText={(firstName) => setFirstName(firstName)}
            />
            <Text>{t('userProfile.lastNameLabel')}</Text>
            <Input
              bgColor="white"
              placeholder={t('userProfile.lastNameModalLabel')}
              defaultValue={data.profile[0].last_name}
              onChangeText={(lastName) => setLastName(lastName)}
            />
            <Text>{t('userProfile.phoneNumberLabel')}</Text>
            <PhoneInput
              inputStyle={{ width: '100%' }}
              country={selectedLanguage}
              masks={{ br: '.. .........' }}
              placeholder={placeholderPhone()}
              value={selectedCallingCode}
              onChange={(phone) => setSelectedCallingCode(phone)}
            />
            <View p="3">
              <Button
                rounded="2xl"
                bgColor="yellow.300"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => update()}
              >
                {t('userProfile.editButtonSave')}
              </Button>
            </View>
          </View>
        </Center>
      </Modal>
      <Center>
        <Modal
          isOpen={showChangePassword}
          onClose={() => setShowChangePassword(false)}
        >
          <ChangePasswordOperatorandManager onAction={onCloseModal} />
        </Modal>
      </Center>
    </View>
  )
}
export default UserProfile
