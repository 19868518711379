import React from 'react'
import { Text, Modal, Input, Center, View, Button, Heading } from 'native-base'
import { useMutation, useLazyQuery } from '@apollo/client'
import AppBarHeader from '../components/AppBar'
import { GET_COMPANY } from '../graphql/queries'
import { UPDATE_COMPANY_PROFILE } from '../graphql/mutations'
import { getToken } from '../Token'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loading from '../components/Loading'
import phoneLanguage from '../PhoneLanguage'
import { useForm, Controller } from 'react-hook-form'

export const CompanyProfile = () => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUserId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [updateCompanyProfile] = useMutation(UPDATE_COMPANY_PROFILE)
  const { t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [getCompany] = useLazyQuery(GET_COMPANY)
  const [companyProfile, setCompanyProfile] = React.useState('')
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const company = await getCompany({
      variables: { userId: userId },
    })
    setCompanyProfile(company.data.users_companies[0].company.company_profile)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
    setLoading(false)
  }, [])

  const showModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const update = async (data) => {
    setButtonLoading(true)
    try {
      if (phoneValid === true) {
        const newCompany = await updateCompanyProfile({
          variables: {
            id: companyProfile.id,
            phone: data.selectedCallingCode
              ? data.selectedCallingCode
              : companyProfile.phone,
            company_name: data.companyName
              ? data.companyName
              : companyProfile.company_name,
            email: data.email ? data.email : companyProfile.email,
          },
        })
        setCompanyProfile(newCompany.data.update_company_profile_by_pk)
        closeModal()
        setButtonLoading(false)
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonLoading(false)
      }
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: companyProfile.company_name,
      email: companyProfile.email,
      selectedCallingCode: companyProfile.phone,
    },
  })
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  if (loading) return <Loading />

  return (
    <View
      backgroundColor="white"
      style={{
        flexDirection: 'column',
      }}
    >
      <AppBarHeader
        style={{
          flex: 1,
        }}
      />
      <Center>
        <View backgroundColor="light.100" rounded="2xl" shadow="9" p="4">
          <View>
            <Heading size="lg" p="2">
              {t('companyProfile.title')}
            </Heading>

            <Text color="muted.600">{t('companyProfile.name')}</Text>
            <Text pt="2" pb="2">
              {companyProfile.company_name}
            </Text>
            <Text color="muted.600">{t('companyProfile.emailLabel')}</Text>
            <Text pt="2" pb="2">
              {companyProfile.email}
            </Text>
            <Text color="muted.600">
              {t('companyProfile.phoneNumberLabel')}
            </Text>
            <Text pt="2" pb="2">
              {formatPhoneNumber(companyProfile.phone.toString())}
            </Text>
            <Button
              rounded="2xl"
              colorScheme="yellow"
              onPress={() => showModal()}
            >
              {t('companyProfile.editButton')}
            </Button>
          </View>
        </View>
      </Center>
      <Modal isOpen={show} onClose={() => closeModal()}>
        <Modal.Content maxWidth="350">
          <Modal.CloseButton />
          <Modal.Header>{t('companyProfile.modalTitle')}</Modal.Header>
          <Modal.Body>
            <Text pt="1" pb="2">
              {t('companyProfile.companyNameModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
                  message: t('operatorAccount.lettersAndNumbers'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  borderRadius="2xl"
                  onChangeText={onChange}
                  value={value}
                  onBlur={onBlur}
                  placeholder={t('companyProfile.companyNameModalLabel')}
                  bgColor="white"
                />
              )}
              defaultValue={companyProfile.company_name}
              name="companyName"
            />
            {errors.companyName && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.companyName.message
                  ? errors.companyName.message
                  : t('operatorAccount.required')}
              </Text>
            )}
            <Text pt="1" pb="2">
              {t('companyProfile.emailModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  borderRadius="2xl"
                  onChangeText={onChange}
                  value={value}
                  onBlur={onBlur}
                  placeholder={t('operatorAccount.emailModalPlaceholder')}
                  bgColor="white"
                />
              )}
              defaultValue={companyProfile.email}
              name="email"
            />
            {errors.email && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.email.message
                  ? errors.email.message
                  : t('operatorAccount.required')}
              </Text>
            )}
            <Text pt="1" pb="2">
              {t('companyProfile.phoneNumberModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    borderRadius: 15,
                    backgroundColor: 'white',
                    borderColor: '#E3E3E3',
                  }}
                  buttonStyle={{ borderColor: '#D4D4D4' }}
                  masks={{ br: '.. .........' }}
                  placeholder={placeholderPhone()}
                  value={value}
                  onChange={onChange}
                  isValid={(value, country) => {
                    if (
                      value.length > 1 &&
                      country.name === 'United States' &&
                      value.length < 11
                    ) {
                      setPhoneValid(false)
                    } else if (
                      value.length > 2 &&
                      country.name === 'Brazil' &&
                      value.length < 12
                    ) {
                      setPhoneValid(false)
                    } else {
                      setPhoneValid(true)
                      return true
                    }
                  }}
                />
              )}
              defaultValue={companyProfile.phone.toString()}
              name="selectedCallingCode"
            />
            {errors.selectedCallingCode && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {t('operatorAccount.required')}
              </Text>
            )}
            {phoneValid === true ? (
              <View />
            ) : (
              <View zIndex="-2">
                <Text fontSize="xs" color="#eb0100">
                  {errorNumber}
                </Text>
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal()
                }}
              >
                {t('calendarView.cancel')}
              </Button>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
                onPress={handleSubmit(update)}
              >
                {t('companyProfile.editButtonSave')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  )
}
export default CompanyProfile
