import React from 'react'
import {
  HStack,
  View,
  Box,
  Alert,
  Heading,
  Divider,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'

export const ErrorPayment = (props) => {
  const { t } = useTranslation()
  return (
    <View>
      <AppBarHeader />
      <View pr="28%" pl="28%">
        <Center>
          <VStack space={5}>
            <Alert w="90%" maxW="400" status="error">
              <VStack space={1} flexShrink={1} w="100%">
                <HStack
                  flexShrink={1}
                  space={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HStack flexShrink={1} space={2} alignItems="center">
                    <Alert.Icon />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('try_again.text')}
                    </Text>
                  </HStack>
                </HStack>
                <Box
                  pl="6"
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  Your booking could not be processed at this time.
                </Box>
              </VStack>
            </Alert>
          </VStack>
        </Center>
      </View>
    </View>
  )
}
export default ErrorPayment
