import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment-timezone'
import 'moment/locale/pt'
import 'moment/locale/es'

const Locale = async () => {
  const selectedLocale = await AsyncStorage.getItem('flag')

  if (selectedLocale) {
    if (selectedLocale === 'es') {
      moment.locale('es')
    }
    if (selectedLocale === 'en') {
      moment.locale('en')
    }
    if (selectedLocale === 'pt') {
      moment.locale('pt')
    }
  } else {
    moment.locale('en')
  }
}

export default Locale
