import React from 'react'
import {
  Text,
  Modal,
  HStack,
  VStack,
  View,
  Input,
  Button,
  Box,
  Center,
  Image,
  Tooltip,
} from 'native-base'
import explanation from '../assets/question_icon.png'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_PILOTS, GET_OPERATOR } from '../graphql/queries'
import { auth } from '../utils/nhost'
import { CREATE_PILOT, CREATE_PILOT_IMAGE } from '../graphql/mutations'
import { getToken } from '../Token'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import ImageUploading from 'react-images-uploading'
import { HASURA } from '@env'
import phoneLanguage from '../PhoneLanguage'
import { useTranslation } from 'react-i18next'

export const newPilotBooking = ({ onAction, onClose }) => {
  const [user, setUser] = React.useState('')
  const [images, setImages] = React.useState([])
  const [getPilots] = useLazyQuery(GET_PILOTS)
  const maxNumber = 69
  const [createPilot] = useMutation(CREATE_PILOT)
  const [createPilotImage] = useMutation(CREATE_PILOT_IMAGE)
  const [operatorId, setOperatorId] = React.useState('')
  const [operator] = useLazyQuery(GET_OPERATOR)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [buttonCreateLoading, setButtonCreateLoading] = React.useState(false)
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')
  const [error, setError] = React.useState('')
  const { t } = useTranslation()

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']

    const getOperator = await operator({
      variables: { userId: userId },
    })
    setUser(userId)
    const getOperatorId = getOperator.data.operators[0].id
    setOperatorId(getOperatorId)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
  }, [])

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    try {
      let res = await fetch(`${HASURA}/v1/storage/files/`, {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      let responseJson = await res.json()
      const imageId = responseJson.processedFiles[0].id
      return imageId
    } catch (error) {
      console.log(error)
    }
  }

  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }

  const onSubmit = async (pilot) => {
    setButtonCreateLoading(true)
    try {
      if (phoneValid === true) {
        if (images.length > 0) {
          await createPilotWithImage(pilot)
        } else {
          await createNewPilot(pilot)
        }
        setButtonCreateLoading(false)
        onAction()
        onClose()
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonCreateLoading(false)
      }
    } catch (error) {
      setButtonCreateLoading(false)
      setError(t('pilots.errors.create'))
    }
  }

  const createPilotWithImage = async (pilot) => {
    const imageId = await uploadImage()
    const pilotImage = await createPilotImage({
      variables: {
        file_id: imageId,
      },
    })
    await createPilot({
      variables: {
        certificate_number: pilot.certificateNumber,
        email: pilot.email,
        first_name: pilot.firstName,
        last_name: pilot.lastName,
        phone_number: pilot.selectedCallingCode,
        operator_id: operatorId,
        pilot_image_id: pilotImage.data.insert_pilot_image_one.id,
      },
    })
    reset()
    setImages([])
  }

  const createNewPilot = async (pilot) => {
    await createPilot({
      variables: {
        certificate_number: pilot.certificateNumber,
        email: pilot.email,
        first_name: pilot.firstName,
        last_name: pilot.lastName,
        phone_number: pilot.selectedCallingCode,
        operator_id: operatorId,
      },
    })
    reset()
  }

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const {
    control,
    reset,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({})

  if (error)
    return (
      <Text p="2" color="#eb0100">
        {error}
      </Text>
    )

  return (
    <Modal.Content>
      <Modal.CloseButton />
      <Modal.Header>{t('pilots.createPilot')}</Modal.Header>
      <Modal.Body>
        <View pt="30">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <View className="upload__image-wrapper">
                {imageList.map((image, index) => (
                  <Center key={index}>
                    <Image
                      source={{
                        uri: `${image['data_url']}`,
                      }}
                      alt="Alternate Text"
                      size={'xl'}
                      resizeMode="cover"
                      borderRadius={100}
                    />
                    <HStack space={3} p="4">
                      <Box shadow={2} maxWidth="40">
                        <Text pt="2" isTruncated="true">
                          {image.file.name}
                        </Text>
                      </Box>
                      <Button
                        colorScheme="yellow"
                        onPress={() => onImageUpdate(index)}
                      >
                        {t('pilots.updateButton')}
                      </Button>
                      <Button
                        colorScheme="red"
                        onPress={() => onImageRemove(index)}
                      >
                        {t('pilots.removeButton')}
                      </Button>
                    </HStack>
                  </Center>
                ))}
                <Center>
                  <Button
                    width="40%"
                    colorScheme="yellow"
                    size="md"
                    style={isDragging ? { color: 'red' } : undefined}
                    onPress={onImageUpload}
                    {...dragProps}
                  >
                    {t('pilots.uploadImage')}
                  </Button>
                </Center>
              </View>
            )}
          </ImageUploading>
        </View>
        <Text bold fontSize="md" p="2">
          {t('pilots.pilotInformation')}
        </Text>
        <Text bold fontSize="s" p="2">
          {t('pilots.certificateNumber')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('pilots.certificateNumber')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., BRA 2345"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="certificateNumber"
        />
        {errors.certificateNumber && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {t('pilots.required')}
          </Text>
        )}
        <Text bold fontSize="s" p="2">
          {t('pilots.firstName')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
              message: t('register.onlyLetters'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('pilots.firstName')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., John"
                >
                  <Image
                    bg="transparent"
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="firstName"
        />
        {errors.firstName && (
          <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
            {errors.firstName.message
              ? errors.firstName.message
              : t('pilots.required')}
          </Text>
        )}
        <Text bold fontSize="s" p="2">
          {t('pilots.lastName')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
              message: t('register.onlyLetters'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('pilots.lastName')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., Smith"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="lastName"
        />
        {errors.lastName && (
          <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
            {errors.lastName.message
              ? errors.lastName.message
              : t('pilots.required')}
          </Text>
        )}

        <Text bold fontSize="s" p="2">
          {t('pilots.phone')}
        </Text>
        <VStack space={1}>
          <HStack space={1}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  country={selectedLanguage}
                  inputStyle={{ width: '100%' }}
                  masks={{ br: '.. .........' }}
                  placeholder={placeholderPhone()}
                  value={value}
                  onChange={onChange}
                  isValid={(value, country) => {
                    if (
                      country.name === 'United States' &&
                      value.length > 1 &&
                      value.length < 11
                    ) {
                      setPhoneValid(false)
                    } else if (
                      value.length > 2 &&
                      country.name === 'Brazil' &&
                      value.length < 12
                    ) {
                      setPhoneValid(false)
                    } else {
                      setPhoneValid(true)
                      return true
                    }
                  }}
                />
              )}
              name="selectedCallingCode"
            />
            <Tooltip
              bg="yellow.100"
              _text={{
                color: 'black',
              }}
              placement="left"
              label="e.g., +55 15 99677-1785"
            >
              <Image
                source={{
                  uri: explanation,
                }}
                alt="Alternate Text"
                mr={2}
                mt={2}
                style={{ width: 20, height: 20 }}
              />
            </Tooltip>
          </HStack>
          {errors.selectedCallingCode && (
            <Text p="2" fontSize="xs" color="#eb0100">
              {t('pilots.required')}
            </Text>
          )}
          {phoneValid === true ? (
            <View />
          ) : (
            <View zIndex="-2">
              <Text fontSize="xs" color="#eb0100">
                {errorNumber}
              </Text>
            </View>
          )}
        </VStack>
        <Text bold fontSize="s" p="2">
          {t('pilots.email')}
        </Text>

        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('pilots.email')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., john@altaport.com"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="email"
        />
        {errors.email && (
          <Text pb="1" pl="1" fontSize="xs" color="#eb0100">
            {errors.email.message ? errors.email.message : t('pilots.required')}
          </Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button.Group space={2}>
          <Button
            variant="ghost"
            colorScheme="blueGray"
            onPress={() => {
              onClose()
            }}
          >
            {t('pilots.cancelButton')}
          </Button>
          <Button
            colorScheme="yellow"
            onPress={handleSubmit(onSubmit)}
            isLoading={buttonCreateLoading}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
          >
            {t('pilots.saveButton')}
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  )
}
export default newPilotBooking
