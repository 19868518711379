import React from 'react'

import {
  View,
  Button,
  Center,
  HStack,
  NativeBaseProvider,
  Stack,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'

export const OperatorAccountMenu = (props) => {
  const { t } = useTranslation()

  const goToOperatorInfo = () => {
    props.history.push(`/operatorAccount`)
  }
  const goToOperatorPayments = () => {
    props.history.push(`/operatorPayments`)
  }

  return (
    <View>
      <AppBarHeader />
      <NativeBaseProvider>
        <Center>
          <Stack space={3} direction={['column', 'column', 'column', 'row']}>
            <Button
              p="20"
              onPress={() => {
                goToOperatorInfo()
              }}
              bgColor="muted.200"
              _text={{
                color: '#1F2937',
                fontSize: 'md',
                bold: true,
              }}
              rounded="xl"
              shadow="8"
            >
              {t('operatorAccountMenu.info')}
            </Button>
            <Button
              p="20"
              onPress={() => {
                goToOperatorPayments()
              }}
              bgColor="muted.200"
              _text={{
                color: '#1F2937',
                fontSize: 'md',
                bold: true,
              }}
              rounded="xl"
              shadow="8"
            >
              {t('operatorAccountMenu.billing')}
            </Button>
          </Stack>
        </Center>
      </NativeBaseProvider>
    </View>
  )
}
export default OperatorAccountMenu
