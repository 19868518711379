import React from 'react'
import { tw } from 'react-native-tailwindcss'
import {
  Center,
  View,
  HStack,
  Container,
  VStack,
  Box,
  Heading,
  Text,
  Button,
  Modal,
  Image,
  Stack,
  CircleIcon,
  Link,
} from 'native-base'
import { StyleSheet } from 'react-native'
import AppBarHeader from '../components/AppBar'
import Helipad from '../components/heliport/Helipad'
import EditHeliportForm from '../components/heliport/EditHeliportForm'
import { GET_HELIPORT_MANAGER, GET_HELIPORT_IMAGE } from '../graphql/queries'
import { UPDATE_HELIPORT_PROFILE, IMAGE_HELIPORT } from '../graphql/mutations'
import { useLazyQuery, useMutation } from '@apollo/client'
import { getToken } from '../Token'
import ImageUploading from 'react-images-uploading'
import { auth, storage } from '../utils/nhost'
import { Decimal2DMS } from 'dms-to-decimal'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import { HASURA } from '@env'
import HeliportDaysHours from '../components/HeliportDaysHours'

export const ManageHeliport = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [show, setShow] = React.useState(false)
  const [visible, setIsVisible] = React.useState(false)
  const [images, setImages] = React.useState([])
  const [heliport, setHeliport] = React.useState({})
  const [url, setUrl] = React.useState('')
  const [user, setUser] = React.useState('')
  const [updateHeliportProfile] = useMutation(UPDATE_HELIPORT_PROFILE)
  const [createHeliportImage] = useMutation(IMAGE_HELIPORT)
  const maxNumber = 69
  const [getHeliportImage] = useLazyQuery(GET_HELIPORT_IMAGE)
  const [getHeliport] = useLazyQuery(GET_HELIPORT_MANAGER)
  const [notReservation, setNotReservation] = React.useState(false)
  const [timezone, setTimezone] = React.useState('')
  const { t } = useTranslation()
  const [showAviability, setShowAviability] = React.useState(false)

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUser(userId)

    const heliport = await getHeliport({ variables: { userId: userId } })

    setHeliport(heliport.data.heliports[0])
    if (heliport.data.heliports[0].heliport_profile) {
      if (heliport.data.heliports[0].heliport_profile.heliport_image_id) {
        const heliportImage = await getHeliportImage({
          variables: {
            id: heliport.data.heliports[0].heliport_profile.heliport_image_id,
          },
        })
        const fileId = heliportImage.data.heliport_images_by_pk.file_id
        const url = await storage.getPresignedUrl({ fileId })
        if (url.presignedUrl) {
          setUrl(url.presignedUrl.url)
        }
      }
    }

    setTimezone(heliport.data.heliports[0].heliport_profile.timezone)
    setLoading(false)
  }, [])

  const handleAction = (heliport) => {
    closeModalEdit()
    setHeliport({
      company: {
        id: heliport.heliport.company_id,
        company_profile: {
          company_name: heliport.heliport.company.company_profile.company_name,
          phone: heliport.heliport.company.company_profile.phone,
          email: heliport.heliport.company.company_profile.email,
        },
      },
      heliport_profile: heliport,
    })
  }

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    try {
      let res = await fetch(`${HASURA}/v1/storage/files/`, {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      let responseJson = await res.json()
      const imageId = responseJson.processedFiles[0].id
      return imageId
    } catch (error) {
      console.log(error)
    }
  }

  const editImage = async () => {
    try {
      const imageId = await uploadImage()
      const heliportImage = await createHeliportImage({
        variables: {
          file_id: imageId,
        },
      })
      await updateHeliportProfile({
        variables: {
          id: heliport.heliport_profile.id,
          heliport_description: heliport.heliport_profile.heliport_description,
          helipads_number: heliport.heliport_profile.helipads_number,
          fuel_available: heliport.heliport_profile.fuel_available,
          address: heliport.heliport_profile.address,
          cancellation_policy: heliport.heliport_profile.cancellation_policy,
          city: heliport.heliport_profile.city,
          hourly_rate: heliport.heliport_profile.hourly_rate,
          icao_code: heliport.heliport_profile.icao_code,
          landing_slot_length: heliport.heliport_profile.landing_slot_length,
          latitude: heliport.heliport_profile.latitude,
          longitude: heliport.heliport_profile.longitude,
          manager_name: heliport.heliport_profile.manager_name,
          measure_rotor_diameter:
            heliport.heliport_profile.measure_rotor_diameter,
          name: heliport.heliport_profile.name,
          other_restrictions: heliport.heliport_profile.other_restrictions,
          overnight_rate: heliport.heliport_profile.overnight_rate,
          phone: heliport.heliport_profile.phone,
          reservations: 'instant',
          size_limit_weigth: heliport.heliport_profile.size_limit_weigth,
          measure_limit_weigth: heliport.heliport_profile.measure_limit_weigth,
          state: heliport.heliport_profile.state,
          timezone: heliport.heliport_profile.timezone,
          country: heliport.heliport_profile.country,
          limit_late_booking: heliport.heliport_profile.limit_late_booking
            ? heliport.heliport_profile.limit_late_booking
            : undefined,
          surface_type: heliport.heliport_profile.surface_type
            ? heliport.heliport_profile.surface_type
            : undefined,
          heliport_currency: heliport.heliport_profile.heliport_currency,
          heliport_image_id: heliportImage
            ? heliportImage.data.insert_heliport_images_one.id
            : heliport.heliport_profile.heliport_image_id,
          email:
            heliport &&
            heliport.heliport_profile &&
            heliport.heliport_profile.email
              ? heliport.heliport_profile.email
              : undefined,
          parking_available:
            heliport &&
            heliport.heliport_profile &&
            heliport.heliport_profile.parking_available
              ? heliport.heliport_profile.parking_available
              : false,
        },
      })
      setShow(false)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const coordinateHeliportLatitude = () => {
    const typeLatitude = 'latitude'
    const latitude = Decimal2DMS(
      heliport.heliport_profile.latitude,
      typeLatitude
    )
    return latitude
  }

  const coordinateHeliportLongitude = () => {
    const typeLongitude = 'longitude'
    const longitude = Decimal2DMS(
      heliport.heliport_profile.longitude,
      typeLongitude
    )
    return longitude
  }
  // Validation of missing fields in register
  const validateReservation = () => {
    if (
      heliport.heliport_profile.landing_slot_length &&
      heliport.heliport_profile.helipads_number !== 0 &&
      heliport.heliport_profile.size_limit_weigth !== 0 &&
      heliport.heliport_profile.measure_limit_weigth &&
      heliport.heliport_profile.measure_rotor_diameter &&
      heliport.heliport_profile.cancellation_policy &&
      heliport.heliport_profile.latitude !== 0 &&
      heliport.heliport_profile.longitude !== 0 &&
      heliport.heliport_profile.heliport_currency &&
      heliport.heliport_profile.limit_late_booking &&
      heliport.heliport_profile.surface_type
    ) {
      props.history.push(
        `/scheduleHeliport?heliport=${heliport.heliport_profile.timekit_project_id}&company=${heliport.company.id}`
      )
    } else {
      setNotReservation(true)
    }
  }

  const openModalEdit = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModalEdit = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }
  const openModalAviabilities = () => {
    document
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowAviability(true)
  }

  const closeModalAviabilities = () => {
    document.body.style.overflow = 'scroll'
    setShowAviability(false)
  }

  const getHeliportDimensions = (dimensions) => {
    if (dimensions) {
      const dimensionsLimit = JSON.parse(dimensions)
      const dimensionOne = dimensionsLimit.dimension_one
        ? dimensionsLimit.dimension_one
        : ''
      const dimensionTwo = dimensionsLimit.dimension_two
        ? dimensionsLimit.dimension_two
        : ''
      return `${dimensionOne} x ${dimensionTwo}`
    } else {
      return ''
    }
  }

  const onSaveEditAviabilities = () => {
    closeModalAviabilities()
  }

  const dictionaryTime = {
    days: t('time.days'),
    minutes: t('time.minutes'),
    hours: t('time.hours'),
  }

  const cancellationWindow = (time) => {
    if (time) {
      const number = time.split(' ')[0]
      const heliportTime = time.split(' ')[1]
      return `${number} ${dictionaryTime[heliportTime]}`
    } else {
      return ''
    }
  }

  if (loading) {
    return <Loading />
  }
  return (
    <View>
      <AppBarHeader
        style={{
          flex: 1,
        }}
      />
      <View style={styles.parent}>
        <Heading size="3xl" style={[tw.fontMono]} mb="5">
          {heliport.heliport_profile.name}
        </Heading>
        <Stack
          direction={['column', 'column', 'column', 'row']}
          space={3}
          mb="5"
        >
          <Text fontSize={['md', 'md', 'md', 'xl']}>
            {heliport.heliport_profile.city},{' '}
            {heliport.heliport_profile.country === 'Brazil'
              ? t('manageHeliport.country.brazil')
              : heliport.heliport_profile.country === 'USA'
              ? t('manageHeliport.country.usa')
              : t('manageHeliport.country.mexico')}{' '}
            <CircleIcon size={['1', '1', '2', '2']} mb={1} ml={3} /> {''}
          </Text>
          <Text fontSize={['md', 'md', 'md', 'xl']}>
            <Text fontWeight="bold">{t('manageHeliport.latitude')}</Text>
            {coordinateHeliportLatitude()}
            <CircleIcon size={['1', '1', '2', '2']} mb={1} ml={3} /> {''}{' '}
            <Text fontWeight="bold"> {t('manageHeliport.longitude')}</Text>
            {coordinateHeliportLongitude()}
            <CircleIcon size={['1', '1', '2', '2']} mb={1} ml={3} />
          </Text>
          <Text fontSize={['md', 'md', 'md', 'xl']}>
            {heliport.heliport_profile.icao_code}
          </Text>
        </Stack>
        <VStack space={3}>
          <Box p="5" rounded="xl" bgColor="gray.200">
            <Stack space={5} direction={['column', 'column', 'column', 'row']}>
              <Box
                p="5"
                rounded="xl"
                bgColor="light.100"
                width={['100%', '100%', '100%', '53%']}
              >
                <Stack direction={['column', 'column', 'row', 'row']}>
                  <Container width={['100%', '100%', '50%', '50%']}>
                    <VStack>
                      <Box>
                        <Text
                          fontSize={['xs', 'sm', 'md', 'md']}
                          fontWeight="bold"
                        >
                          {t('manageHeliport.helipad')}
                        </Text>
                        <Box>
                          <HStack space={1}>
                            <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('manageHeliport.maxWeight')}
                            </Text>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {heliport.heliport_profile.size_limit_weigth}
                            </Text>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {heliport.heliport_profile.measure_limit_weigth
                                ? heliport.heliport_profile.measure_limit_weigth
                                : ''}
                            </Text>
                          </HStack>
                          <Stack
                            space={1}
                            direction={['column', 'column', 'column', 'column']}
                          >
                            <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('manageHeliport.helipadDimensions')}
                            </Text>
                            <HStack space={1}>
                              <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                                {getHeliportDimensions(
                                  heliport.heliport_profile.dimensions_limit
                                )}
                              </Text>
                              <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                                {heliport.heliport_profile
                                  .measure_rotor_diameter
                                  ? heliport.heliport_profile
                                      .measure_rotor_diameter
                                  : ''}
                              </Text>
                            </HStack>
                          </Stack>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']} bold>
                              {t('manageHeliport.cancellation')}
                            </Text>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {cancellationWindow(
                                heliport.heliport_profile.cancellation_policy
                              )}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']} bold>
                              {t('manageHeliport.state')}
                            </Text>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {heliport.heliport_profile.state}
                            </Text>
                          </HStack>
                        </Box>
                      </Box>
                    </VStack>
                  </Container>
                  <Container width={['100%', '100%', '50%', '50%']}>
                    <Text fontSize={['xs', 'sm', 'md', 'md']} fontWeight="bold">
                      {t('manageHeliport.manager')}
                    </Text>
                    <Box>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'sm']}
                        fontWeight="bold"
                      >
                        {t('manageHeliport.address')}
                      </Text>
                      <Link
                        style={{ color: '#4D4D4D' }}
                        isExternal
                        rel="Link to Address"
                        href={`https://www.google.com/maps/place/${heliport.heliport_profile.address}`}
                      >
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {heliport.heliport_profile.address}
                        </Text>
                      </Link>

                      <HStack space={1}>
                        <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('manageHeliport.name')}
                        </Text>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {heliport.heliport_profile.manager_name}
                        </Text>
                      </HStack>
                      <HStack space={1}>
                        <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('manageHeliport.phone')}
                        </Text>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {formatPhoneNumber(
                            heliport.heliport_profile.phone.toString()
                          )}
                        </Text>
                      </HStack>
                    </Box>
                    <Text
                      pt="2"
                      fontSize={['xs', 'sm', 'md', 'md']}
                      fontWeight="bold"
                    >
                      {t('manageHeliport.companyInformation')}
                    </Text>
                    <Box>
                      <HStack space={1}>
                        <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('manageHeliport.companyName')}
                        </Text>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {heliport.company.company_profile.company_name}
                        </Text>
                      </HStack>
                      <HStack space={1}>
                        <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('manageHeliport.companyPhone')}
                        </Text>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {formatPhoneNumber(
                            heliport.company.company_profile.phone.toString()
                          )}
                        </Text>
                      </HStack>
                      <HStack space={1}>
                        <Text bold fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('manageHeliport.email')}
                        </Text>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {heliport.company.company_profile.email}
                        </Text>
                      </HStack>
                    </Box>
                  </Container>
                </Stack>
                <br />
                <View width={['100%', '100%', '100%', '100%']}>
                  <Text fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
                    {t('manageHeliport.description')}
                  </Text>
                  <Box>
                    <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                      {heliport.heliport_profile.heliport_description}
                    </Text>
                  </Box>
                </View>
                <br />
                <Stack
                  space={[2, 2, 3, 5]}
                  direction={['column', 'column', 'row', 'row']}
                >
                  <Center>
                    <Button
                      _text={{
                        fontSize: ['xs', 'sm', 'sm', 'sm'],
                      }}
                      width="100%"
                      onPress={() => {
                        openModalEdit()
                      }}
                      colorScheme="yellow"
                    >
                      {t('manageHeliport.editHeliport')}
                    </Button>
                  </Center>
                  <Center>
                    <Button
                      _text={{
                        fontSize: ['xs', 'sm', 'sm', 'sm'],
                      }}
                      width="100%"
                      onPress={() => {
                        openModalAviabilities()
                      }}
                      colorScheme="yellow"
                    >
                      {t('manageHeliport.editAviabilities')}
                    </Button>
                  </Center>
                </Stack>
              </Box>
              <Box width={['100%', '100%', '100%', '45%']}>
                <View>
                  <Image
                    source={{
                      uri: url
                        ? url
                        : `https://cdn.pixabay.com/photo/2017/03/04/14/19/helicopter-2116170_1280.jpg`,
                    }}
                    rounded="xl"
                    alt="Alternate Text Image Heliport"
                    style={{ width: '100%', height: 250 }}
                  />
                  <Button
                    position="absolute"
                    bottom="1%"
                    right="1%"
                    onPress={() => {
                      setIsVisible(true)
                    }}
                    colorScheme="yellow"
                  >
                    <Image
                      source={{
                        uri: 'https://cdn-icons-png.flaticon.com/512/1159/1159633.png',
                      }}
                      alt="Alternate Text"
                      style={{ width: 20, height: 20 }}
                    />
                  </Button>
                </View>
                <Center p="2">
                  <Button
                    width="100%"
                    onPress={() => {
                      window.scrollTo(0, 0), validateReservation()
                    }}
                    colorScheme="yellow"
                  >
                    {t('manageHeliport.addReservationsManually')}
                  </Button>
                </Center>
              </Box>
            </Stack>
          </Box>
          <Box p={[9, 9, 12, 12]} rounded="xl" bgColor="gray.200">
            <Helipad heliportTimezone={timezone} />
          </Box>
        </VStack>

        <p />
      </View>
      <Modal isOpen={show} onClose={() => closeModalEdit()} flex={1}>
        <Modal.Content maxWidth="100%">
          <Modal.CloseButton />
          <Modal.Header>{t('manageHeliport.editHeliport')}</Modal.Header>
          <Modal.Body>
            <EditHeliportForm
              heliportInformation={heliport.heliport_profile}
              onAction={handleAction}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={showAviability}
        onClose={() => closeModalAviabilities()}
        flex={1}
        size="xl"
      >
        <Modal.Content maxWidth="100%">
          <Modal.CloseButton />
          <Modal.Header>{t('manageHeliport.editHeliport')}</Modal.Header>
          <Modal.Body>
            <HeliportDaysHours
              onSaveAction={onSaveEditAviabilities}
              edit={true}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Modal isOpen={visible} onClose={() => setIsVisible(false)} flex={1}>
        <Modal.Content maxWidth="550">
          <Modal.CloseButton />
          <Modal.Header>{t('manageHeliport.editImage')}</Modal.Header>
          <Modal.Body>
            <Text bold fontSize={['xs', 'sm', 'sm', 'md']} p="2">
              {t('manageHeliport.currentImage')}
            </Text>
            <Center>
              <Image
                source={{
                  uri: url,
                }}
                rounded="xl"
                alt="Alternate Text Image Heliport"
                style={{ width: '100%', height: 250 }}
              />
            </Center>
            <br />
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <View className="upload__image-wrapper">
                  {imageList.map((image, index) => (
                    <Center key={index}>
                      <HStack space={3} p="4">
                        <Box shadow={2} maxWidth="40">
                          <Text pt="2" isTruncated="true">
                            {image.file.name}
                          </Text>
                        </Box>
                        <Button
                          colorScheme="yellow"
                          onPress={() => onImageUpdate(index)}
                        >
                          {t('manageHeliport.updateButton')}
                        </Button>
                        <Button
                          colorScheme="red"
                          onPress={() => onImageRemove(index)}
                        >
                          {t('manageHeliport.removeButton')}
                        </Button>
                      </HStack>
                    </Center>
                  ))}
                  <Center>
                    <Button
                      width="40%"
                      colorScheme="yellow"
                      size="md"
                      style={isDragging ? { color: 'red' } : undefined}
                      onPress={onImageUpload}
                      {...dragProps}
                    >
                      {t('manageHeliport.uploadImage')}
                    </Button>
                  </Center>
                </View>
              )}
            </ImageUploading>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setIsVisible(false)
                }}
              >
                {t('manageHeliport.cancel')}
              </Button>
              <Button
                colorScheme="yellow"
                onPress={() => {
                  editImage()
                }}
              >
                {t('manageHeliport.save')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={notReservation}
        onClose={() => setNotReservation(false)}
        flex={1}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('manageHeliport.reminder')}</Modal.Header>
          <Modal.Body>
            <Text p={3}>{t('manageHeliport.reminderMessage')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={3}>
              <Button
                bgColor="coolGray.300"
                size="md"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => setNotReservation(false)}
              >
                {t('manageHeliport.reminderAccept')}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  )
}
export default ManageHeliport
const styles = StyleSheet.create({
  parent: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
})
