import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Heading,
  Text,
  Modal,
  Divider,
  Center,
  Button,
  View,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLazyQuery } from '@apollo/client'
import { GET_OPERATOR } from '../graphql/queries'
import { getToken } from '../Token'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import { auth } from '../utils/nhost'
import Loading from '../components/Loading'
import ChangePasswordOperatorandManager from '../components/ChangePasswordOperatorandManager'
import Actions from '../components/Operator/Actions'

export const OperatorAccount = (props) => {
  const [loading, setIsLoading] = React.useState(true)
  const [user, setUser] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [showChangePassword, setShowChangePassword] = React.useState(false)
  const { t } = useTranslation()
  const [operator] = useLazyQuery(GET_OPERATOR)
  const [operatorInformation, setOperatorInformation] = React.useState({})

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUser(userId)
    const operator = await getOperatorInformation(userId)
    setOperatorInformation(operator)
    setIsLoading(false)
  }, [])

  const showModal = () => {
    setShow(true)
  }

  const logout = async () => {
    await auth.signOut()
    props.history.push('/')
  }

  const getOperatorInformation = async (user) => {
    const getOperator = await operator({ variables: { userId: user } })
    return getOperator.data.operators[0]
  }

  const refreshOperatorInformation = async (newOperator) => {
    setOperatorInformation(newOperator)
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const onCloseModal = () => {
    setShowChangePassword(false)
  }

  const onCloseEdit = () => {
    setShow(false)
  }

  if (loading) return <Loading />

  return (
    <View>
      <AppBarHeader />
      <Center>
        <View backgroundColor="light.100" rounded="xl" p="4" width="300">
          <Heading size="lg" p="2" color="black">
            {t('operatorAccount.title')}
          </Heading>
          <Divider bgColor="yellow.200" thickness="2" />
          <Heading size="sm" pt="1" pb="2" color="black">
            {t('operatorAccount.information')}
          </Heading>
          <Text bold color="muted.600">
            {t('operatorAccount.companyName')}
          </Text>
          <Text p="2">{operatorInformation.operator_company_name}</Text>
          <Divider bgColor="yellow.200" thickness="2" />
          <Heading size="sm" pt="1" pb="2" color="black">
            {t('operatorAccount.contactInformation')}
          </Heading>
          <Text bold color="muted.600">
            {t('operatorAccount.nameLabel')}
          </Text>
          <Text p="2">{operatorInformation.operator_name}</Text>
          <Text bold color="muted.600">
            {t('operatorAccount.contactEmail')}
          </Text>
          <Text p="2">{operatorInformation.operators_contact.email}</Text>
          <Text bold color="muted.600">
            {t('operatorAccount.contactPhone')}
          </Text>
          <Text p="2">
            {formatPhoneNumber(
              operatorInformation.operators_contact.phone.toString()
            )}
          </Text>
          <Button
            colorScheme="yellow"
            onPress={() => {
              showModal()
            }}
            rounded="2xl"
          >
            {t('operatorAccount.editButton')}
          </Button>
          <View pt="2">
            <Button
              colorScheme="yellow"
              onPress={() => {
                setShowChangePassword(true)
              }}
              rounded="2xl"
            >
              {t('operatorAccount.changePassword')}
            </Button>
          </View>
          <View pt="2">
            <Button
              colorScheme="red"
              onPress={() => {
                logout()
              }}
              rounded="2xl"
            >
              {t('operatorAccount.logOut')}
            </Button>
          </View>
        </View>
        <p />
      </Center>

      <Modal isOpen={show} onClose={() => setShow(false)}>
        <Actions
          onClose={onCloseEdit}
          editOperator={operatorInformation}
          onEdit={refreshOperatorInformation}
        />
      </Modal>
      <Modal
        isOpen={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      >
        <ChangePasswordOperatorandManager onAction={onCloseModal} />
      </Modal>
    </View>
  )
}
export default OperatorAccount
const style = StyleSheet.create({})
