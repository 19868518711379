const defaultState = {
  timeslot: null,
  booking: null,
}

const TimeslotReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TIMESLOT_SAVE': {
      return {
        ...state,
        ...{
          timeslot: action.payload.timeslot ? action.payload.timeslot : null,
        },
      }
    }
    case 'BOOKING_SAVE': {
      return {
        ...state,
        ...{
          booking: action.payload.booking ? action.payload.booking : null,
        },
      }
    }
    default:
      return defaultState
  }
}

export default TimeslotReducer
