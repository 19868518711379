import React from 'react'
import {
  HStack,
  View,
  Box,
  Alert,
  Heading,
  Divider,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLocation } from '../routing'
import BookingDetailPaymentInvoice from '../components/BookingDetailPaymentInvoice'
import { SERVER_SENGRID } from '@env'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'

export const PaymentSummaryManualReservation = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [paymentIntentInformation, setPaymentIntentInformation] =
    React.useState({})
  const [amount, setAmount] = React.useState(null)
  const [booking, setBooking] = React.useState({})
  const [heliportCurrency, setHeliportCurrency] = React.useState({})

  const { t } = useTranslation()

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const getSessionId = query.get('session_id')

    const bookingId = query.get('booking')

    const response = await fetch(`${SERVER_SENGRID}/booking/success`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        session_id: getSessionId,
      }),
    })

    const { session } = await response.json()

    setPaymentIntentInformation(session)

    const amount = calculateAmount(session.amount_total)

    const bookingInformation = await axiosInstance.post(
      `/bookings/bookingById`,
      {
        id: bookingId,
      }
    )
    bookingInformation.data.booking.meta.payment_intent_id =
      session.payment_intent

    setBooking(bookingInformation.data.booking)

    setHeliportCurrency(bookingInformation.data.booking.meta.currency)

    setAmount(amount)
    setLoading(false)
  }, [])

  const calculateAmount = (amount) => {
    const newAmount = amount / 100
    return newAmount
  }

  return (
    <View>
      <AppBarHeader />
      {loading ? (
        <Loading />
      ) : (
        <View pr="28%" pl="28%">
          <Center>
            <VStack space={5}>
              <Alert w="100%" status="success">
                <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                  <Alert.Icon size="md" />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{
                      color: 'coolGray.800',
                    }}
                  >
                    {t('paymentSummary.bookingReceived.title')}
                  </Text>

                  <Box
                    _text={{
                      textAlign: 'center',
                    }}
                    _dark={{
                      _text: {
                        color: 'coolGray.600',
                      },
                    }}
                  >
                    {t('paymentSummary.bookingReceived.yourBooking')}
                  </Box>
                </VStack>
              </Alert>
              <Box bgColor="#e0e0e0" rounded="xl" shadow="9">
                <Heading p="2" size="md">
                  {t('paymentSummary.bookingReceived.paymentsDetails')}
                </Heading>
                <View p="2">
                  <Box
                    bgColor="#ffffff"
                    rounded="xl"
                    shadow="9"
                    width="35%"
                    p="2"
                  >
                    <Text bold fontSize="s" p="2">
                      {t('paymentSummary.bookingReceived.amount')}
                    </Text>
                    <Text bold fontSize="2xl" p="2">
                      {heliportCurrency === 'R$' ? 'R$' : '$'}
                      {amount}
                    </Text>
                  </Box>
                </View>
                <View p="2">
                  <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                    <Text bold fontSize="s" p="2">
                      {t('paymentSummary.bookingReceived.billingDetails')}
                    </Text>
                    <HStack space={3}>
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.email')}
                      </Text>
                      <Text p="2">
                        {paymentIntentInformation.customer_details.email}
                      </Text>
                    </HStack>
                    <HStack space={3}>
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.phone')}
                      </Text>
                      <Text p="2">
                        {paymentIntentInformation.customer_details.phone
                          ? paymentIntentInformation.customer_details.phone
                          : ''}
                      </Text>
                    </HStack>
                    <Divider bg="yellow.400" />
                    <Text bold fontSize="s" p="2">
                      {t('paymentSummary.bookingReceived.paymentMethod')}
                    </Text>
                    <HStack space={3}>
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.type')}
                      </Text>
                      <Text p="2">
                        {paymentIntentInformation.payment_method_types[0]}
                      </Text>
                    </HStack>
                  </Box>
                </View>
                <View p="2">
                  <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                    <BookingDetailPaymentInvoice booking={booking} />
                  </Box>
                </View>
              </Box>
              <View p="2">
                <Button
                  colorScheme="yellow"
                  onPress={() => {
                    props.history.push('/manageReservationOperator')
                  }}
                >
                  {t('paymentSummary.bookingReceived.viewReservations')}
                </Button>
              </View>
            </VStack>
          </Center>
        </View>
      )}
    </View>
  )
}
export default PaymentSummaryManualReservation
