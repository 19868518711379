import React from 'react'
import {
  View,
  Box,
  Alert,
  Heading,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import BookingDetailPaymentInvoice from '../components/BookingDetailPaymentInvoice'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Loading from '../components/Loading'
import axiosInstance from '../AxiosInstance'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_BY_COMPANY } from '../graphql/queries'

export const BookingInoviceConfirmation = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [amount, setAmount] = React.useState(null)
  const [booking, setBooking] = React.useState({})
  const [error, setError] = React.useState(false)
  const [getHeliport] = useLazyQuery(GET_HELIPORT_BY_COMPANY)
  const [heliportCurrency, setHeliportCurrency] = React.useState({})
  const { t } = useTranslation()

  React.useEffect(async () => {
    const getBooking = await AsyncStorage.getItem('booking')

    const booking = JSON.parse(getBooking)

    const companyId = booking.meta.company_id

    const heliport = await getHeliport({
      variables: { id: companyId },
    })

    setHeliportCurrency(
      heliport.data.company_by_pk.heliports[0].heliport_profile
        .heliport_currency
    )

    const getAmount = await AsyncStorage.getItem('bookingAmount')

    const amountInformation = JSON.parse(getAmount)

    await createBookingInvoice(booking)

    setAmount(parseFloat(amountInformation.amount) / 100)

    setLoading(false)
  }, [])

  const createBookingInvoice = async (booking) => {
    try {
      if (booking.id) {
        const getBookingById = await axiosInstance.post(
          `/bookings/bookingById`,
          {
            id: booking.id,
          }
        )
        setBooking(getBookingById.data.booking)
      } else {
        booking.meta = {
          ...booking.meta,
          stripe: 'unpaid',
        }
        const getBooking = await axiosInstance.post(
          `/bookings/createBookingInvoice`,
          {
            booking: booking,
          }
        )

        const bookingDetails = {
          id: getBooking.data.booking.id,
          graph: getBooking.data.booking.graph,
          customer: getBooking.data.booking.attributes.customer,
          resource_id: getBooking.data.booking.resource.id,
          project_id: getBooking.data.booking.project.id,
          meta: getBooking.data.booking.meta,
          what: getBooking.data.booking.what
            ? getBooking.data.booking.what
            : getBooking.data.booking.attributes.event.what,
          where: getBooking.data.booking.where
            ? getBooking.data.booking.where
            : getBooking.data.booking.attributes.event.where,
          description: getBooking.data.booking.description
            ? getBooking.data.booking.description
            : getBooking.data.booking.attributes.event.description,
          start: getBooking.data.booking.start
            ? getBooking.data.booking.start
            : getBooking.data.booking.attributes.event.start,
          end: getBooking.data.booking.end
            ? getBooking.data.booking.end
            : getBooking.data.booking.attributes.event.end,
          event_notifications:
            getBooking.data.booking.attributes.event_notifications,
        }

        const jsonValue = JSON.stringify(bookingDetails)
        await AsyncStorage.setItem('booking', jsonValue)
        setBooking(getBooking.data.booking)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <View>
      <AppBarHeader />
      {loading ? (
        <Loading />
      ) : (
        <View pr="28%" pl="28%">
          {error ? (
            <Alert w="100%" status="error">
              <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                <Alert.Icon size="md" />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentSummary.errorBooking.title')}
                </Text>

                <Box
                  _text={{
                    textAlign: 'center',
                  }}
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  {t('paymentSummary.errorBooking.message')}
                </Box>
              </VStack>
            </Alert>
          ) : (
            <Center>
              <VStack space={5}>
                <Alert w="100%" status="success">
                  <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                    <Alert.Icon size="md" />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('paymentSummary.bookingReceived.title')}
                    </Text>

                    <Box
                      _text={{
                        textAlign: 'center',
                      }}
                      _dark={{
                        _text: {
                          color: 'coolGray.600',
                        },
                      }}
                    >
                      {t('paymentSummary.bookingReceived.yourBooking')}
                    </Box>
                  </VStack>
                </Alert>
                <Box bgColor="#e0e0e0" rounded="xl" shadow="9">
                  <Heading p="2" size="md">
                    {t('paymentSummary.bookingReceived.paymentsDetails')}
                  </Heading>
                  <View p="2">
                    <Box
                      bgColor="#ffffff"
                      rounded="xl"
                      shadow="9"
                      width="35%"
                      p="2"
                    >
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.amount')}
                      </Text>
                      <Text bold fontSize="2xl" p="2">
                        {heliportCurrency === 'R$' ? 'R$' : '$'}
                        {amount}
                      </Text>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <BookingDetailPaymentInvoice booking={booking} />
                    </Box>
                  </View>
                </Box>
                <View p="2">
                  <Button
                    colorScheme="yellow"
                    onPress={() => {
                      props.history.push('/manageReservationOperator')
                    }}
                  >
                    {t('paymentSummary.bookingReceived.viewReservations')}
                  </Button>
                </View>
              </VStack>
            </Center>
          )}
        </View>
      )}
    </View>
  )
}
export default BookingInoviceConfirmation
