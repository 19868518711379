import React from 'react'
import { tw } from 'react-native-tailwindcss'
import {
  View,
  Center,
  Stack,
  Box,
  Heading,
  Input,
  Text,
  Divider,
  Radio,
  Select,
  CheckIcon,
  TextArea,
  Checkbox,
  Button,
  HStack,
  VStack,
  Image,
  NativeBaseProvider,
  Tooltip,
  Modal,
} from 'native-base'
import explanation from '../assets/question_icon.png'
import PhoneInput from 'react-phone-input-2'
import TimezoneSelect from 'react-timezone-select'
import 'react-phone-input-2/lib/style.css'
import { GET_COMPANY } from '../graphql/queries'
import { useQuery, useMutation } from '@apollo/client'
import { getToken } from '../Token'
import { IMAGE_HELIPORT } from '../graphql/mutations'
import ImageUploading from 'react-images-uploading'
import { auth } from '../utils/nhost'
import { useForm, Controller } from 'react-hook-form'
var dms2dec = require('dms2dec')
import Autocomplete from 'react-google-autocomplete'
import { API_KEY_MAP } from '@env'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import { Link } from '../routing'
import En from '../assets/Altaport_Services_Agreement_EN.pdf'
import Pt from '../assets/Altaport_Services_Agreement_PT.pdf'
import { HASURA } from '@env'
import PhoneLanguage from '../PhoneLanguage.js'
import AsyncStorage from '@react-native-async-storage/async-storage'
import axiosInstance from '../AxiosInstance'
import ModalError from '../components/ModalError'

export const HeliportForm = (props) => {
  const [checkedFuel, setCheckedFuel] = React.useState(false)
  const [user, setUserId] = React.useState('')
  const [createHeliportImage] = useMutation(IMAGE_HELIPORT)
  const [images, setImages] = React.useState([])
  const maxNumber = 69
  const [address, setAddress] = React.useState('')
  const [addressError, setAddressError] = React.useState('')
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const { t } = useTranslation()
  const [checkedParking, setCheckedParking] = React.useState('')
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [selectedCurrentLanguage, setSelectedCurrentLanguage] =
    React.useState('')
  const [errorCreateHeliport, setErrorCreateHeliport] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const selectedLanguage = await PhoneLanguage()
    setSelectedLanguage(selectedLanguage)
    const selectedCurrentLanguage = await AsyncStorage.getItem('flag')
    setSelectedCurrentLanguage(selectedCurrentLanguage)
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      heliportName: '',
      heliportCity: '',
      heliportState: '',
      heliportIciaoCode: '',
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      time: '',
      sizeWeigthLimit: '',
      weightMeasureType: '',
      maximumRotorDiameterMeasureType: '',
      otherRestrictions: '',
      cancellationTime: '',
      hourlyRate: '',
      overnightRate: '',
      heliportDescription: '',
      latitudeCoordinate: 'S',
      longitudeCoordinate: 'W',
      latitudeGrades: '',
      latitudeMinutes: '',
      latitudeSeconds: '',
      longitudeGrades: '',
      longitudeMinutes: '',
      longitudeSeconds: '',
      landingSlotLength: '',
      helipadsNumber: '',
      sizeWeigthLimit: '',
      cancellationWindow: '',
      heliportCurrency: '',
      timezone: '',
      limitTimeLateBooking: '',
      limitLateBooking: '',
      surfaceType: '',
      selectedCallingCode: '',
      hourlyCurrency: '',
      overnightCurrency: '',
      checked: '',
      dimension_one: '',
      dimension_two: '',
    },
  })

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { userId: user },
  })

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    let res = await fetch(`${HASURA}/v1/storage/files/`, {
      method: 'post',
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let responseJson = await res.json()
    const imageId = responseJson.processedFiles[0].id
    return imageId
  }

  const createCustomerFields = () => {
    //This fields are required whan created customer_fields
    const requiredFields = {
      name: {
        title: 'Name',
        prefilled: '',
        readonly: false,
      },
      email: {
        title: 'E-mail',
        prefilled: '',
        readonly: false,
      },
    }
    const createCustomerFieldsObject = {
      ...requiredFields,
    }
    return createCustomerFieldsObject
  }

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }
  const coordinates = (heliportInformation) => {
    const coordinatesDecimal = dms2dec(
      [
        parseFloat(heliportInformation.latitudeGrades),
        parseFloat(heliportInformation.latitudeMinutes),
        parseFloat(heliportInformation.latitudeSeconds),
      ],
      heliportInformation.latitudeCoordinate,
      [
        parseFloat(heliportInformation.longitudeGrades),
        parseFloat(heliportInformation.longitudeMinutes),
        parseFloat(heliportInformation.longitudeSeconds),
      ],
      heliportInformation.longitudeCoordinate
    )

    return coordinatesDecimal
  }

  const onSubmit = async (heliportInformation) => {
    setButtonLoading(true)
    if (phoneValid === true && address) {
      try {
        const heliportCoordinates = coordinates(heliportInformation)
        const latitude = heliportCoordinates[0]
        const longitude = heliportCoordinates[heliportCoordinates.length - 1]
        const token = auth.getJWTToken()
        const customerFields = createCustomerFields()
        const dimensions = {
          dimension_one: heliportInformation.dimension_one,
          dimension_two: heliportInformation.dimension_two,
        }

        if (images.length === 0) {
          await axiosInstance.post(`/heliport/createHeliport`, {
            token: token,
            companyId: data.users_companies[0].company.id,
            heliportInformation: heliportInformation,
            address: address,
            customerFields: customerFields,
            latitude: latitude,
            longitude: longitude,
            email: data.users_companies[0].company.company_profile.email,
            checkedFuel: checkedFuel,
            checkedParking: checkedParking,
            dimensions_limit: JSON.stringify(dimensions),
          })
          setButtonLoading(false)
          await props.history.push('/heliportDaysandHours')
        } else {
          const imageId = await uploadImage()
          const heliportImage = await createHeliportImage({
            variables: {
              file_id: imageId,
            },
          })
          await axiosInstance.post(`/heliport/createHeliport`, {
            token: token,
            companyId: data.users_companies[0].company.id,
            heliportInformation: heliportInformation,
            address: address,
            customerFields: customerFields,
            latitude: latitude,
            longitude: longitude,
            email: data.users_companies[0].company.company_profile.email,
            checkedFuel: checkedFuel,
            checkedParking: checkedParking,
            heliportImageId: heliportImage.data.insert_heliport_images_one
              ? heliportImage.data.insert_heliport_images_one.id
              : undefined,
            dimensions_limit: JSON.stringify(dimensions),
          })
          setButtonLoading(false)
          await props.history.push('/heliportDaysandHours')
        }
      } catch (error) {
        setButtonLoading(false)
        setErrorCreateHeliport(t('errors.createHeliport'))
        openError()
      }
    } else {
      setButtonLoading(false)
      setErrorNumber(t('errorPhoneNumber'))
      setAddressError(t('heliportForm.addressField'))
    }
  }

  //Google Autocomplete options
  const options = {
    fields: ['formatted_address', 'geometry.location', 'address_components'],
    strictBounds: false,
    types: ['geocode'],
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  return (
    <View
      backgroundColor="white"
      style={{
        flexDirection: 'column',
      }}
    >
      <NativeBaseProvider>
        <Center>
          <p />
          <Box
            shadow="9"
            rounded="lg"
            borderColor="coolGray.200"
            borderWidth="1"
            w={['90%', '90%', '500', '500']}
            _light={{ bg: 'lightText' }}
            _dark={{ bg: 'lightText' }}
          >
            <Stack space="2" p="8">
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.identifier')}
              </Heading>
              <Text>{t('heliportForm.name')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder={t('heliportForm.name')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., Brasilia Air"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="heliportName"
              />
              {errors.heliportName && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.code')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder='e.g., "SDJD"'
                    maxLength={'4'}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., SDJD"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="heliportIciaoCode"
              />
              {errors.heliportIciaoCode && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Divider />
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.contactInformation')}
              </Heading>
              <Text>{t('heliportForm.managerName')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                    message: t('register.onlyLetters'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder={t('heliportForm.firstName')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., Rob"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="firstName"
              />
              {errors.firstName && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.firstName.message
                    ? errors.firstName.message
                    : t('register.required')}
                </Text>
              )}
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                    message: t('register.onlyLetters'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder={t('heliportForm.lastName')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., Smith"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="lastName"
              />
              {errors.lastName && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.lastName.message
                    ? errors.lastName.message
                    : t('register.required')}
                </Text>
              )}
              <Text>{t('heliportForm.phone')}</Text>
              <HStack space={1}>
                <View w={['90%', '90%', '93%', '93%']}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <PhoneInput
                        country={selectedLanguage}
                        inputStyle={{
                          width: '100%',
                          backgroundColor: 'white',
                          borderColor: '#E3E3E3',
                        }}
                        buttonStyle={{ borderColor: '#D4D4D4' }}
                        masks={{ br: '.. .........' }}
                        placeholder={placeholderPhone()}
                        value={value}
                        onChange={onChange}
                        isValid={(value, country) => {
                          if (
                            value.length > 1 &&
                            country.name === 'United States' &&
                            value.length < 11
                          ) {
                            setPhoneValid(false)
                          } else if (
                            value.length > 2 &&
                            country.name === 'Brazil' &&
                            value.length < 12
                          ) {
                            setPhoneValid(false)
                          } else {
                            setPhoneValid(true)
                            return true
                          }
                        }}
                      />
                    )}
                    name="selectedCallingCode"
                  />
                </View>
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., +55 15 99677-1785"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    mt={2}
                    alt="Alternate Text"
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              </HStack>
              {errors.selectedCallingCode && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('register.required')}
                </Text>
              )}
              {phoneValid === true ? (
                <View />
              ) : (
                <View zIndex="-2">
                  <Text fontSize="xs" color="#eb0100">
                    {errorNumber}
                  </Text>
                </View>
              )}
              <Divider />
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.location')}
              </Heading>
              <Text>{t('heliportForm.city')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder={t('heliportForm.city')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    zIndex="-2"
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., Brasilia"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="heliportCity"
              />
              {errors.heliportCity && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.state')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    maxLength={'2'}
                    placeholder="e.g. SP"
                    onBlur={onBlur}
                    zIndex="-2"
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        multiple={true}
                        label={t('heliportForm.field')}
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="heliportState"
              />
              {errors.heliportState && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.address')}</Text>
              <HStack space={1}>
                <View w={['90%', '90%', '93%', '93%']}>
                  <Autocomplete
                    options={options}
                    apiKey={API_KEY_MAP}
                    style={{
                      height: 32,
                      paddingLeft: 9,
                      fontSize: 12,
                      border: '0.5px solid',
                      borderRadius: '5px',
                    }}
                    onPlaceSelected={(place) => {
                      setAddress(place.formatted_address)
                    }}
                  />
                  {!address ? (
                    <View>
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {t('heliportForm.required')}
                      </Text>
                    </View>
                  ) : (
                    <View></View>
                  )}
                </View>
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., Parça Portugal"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    mt={2}
                    alt="Alternate Text"
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              </HStack>
              <Text>{t('heliportForm.latitude')}</Text>
              <HStack space={4}>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          placeholder="00"
                          textAlign="center"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="latitudeGrades"
                    />
                    {errors.latitudeGrades && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.latitudeGrades.message
                          ? errors.latitudeGrades.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>º</Text>
                </VStack>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          placeholder="00"
                          textAlign="center"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="latitudeMinutes"
                    />
                    {errors.latitudeMinutes && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.latitudeMinutes.message
                          ? errors.latitudeMinutes.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>’</Text>
                </VStack>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          placeholder="00"
                          textAlign="center"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="latitudeSeconds"
                    />
                    {errors.latitudeSeconds && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.latitudeSeconds.message
                          ? errors.latitudeSeconds.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>”</Text>
                </VStack>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        onBlur={onBlur}
                        selectedValue={value}
                        maxWidth="85"
                        width={['50', '50', '127', '127']}
                        placeholder="S / N"
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item label="S" value="S" />
                        <Select.Item label="N" value="N" />
                      </Select>
                    )}
                    name="latitudeCoordinate"
                  />
                  {errors.latitudeCoordinate && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Tooltip
                    bg="yellow.100"
                    _text={{
                      color: 'black',
                    }}
                    placement="left"
                    label="e.g., 45º 30' 12” S"
                  >
                    <Image
                      source={{
                        uri: explanation,
                      }}
                      mt={2}
                      alt="Alternate Text"
                      style={{ width: 20, height: 20 }}
                    />
                  </Tooltip>
                </VStack>
              </HStack>
              <Text>{t('heliportForm.longitude')}</Text>
              <HStack space={4}>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          placeholder="00"
                          textAlign="center"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="longitudeGrades"
                    />
                    {errors.longitudeGrades && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.longitudeGrades.message
                          ? errors.longitudeGrades.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>º</Text>
                </VStack>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          placeholder="00"
                          textAlign="center"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="longitudeMinutes"
                    />
                    {errors.longitudeMinutes && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.longitudeMinutes.message
                          ? errors.longitudeMinutes.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>’</Text>
                </VStack>
                <View width="15%">
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          h="10"
                          w="12"
                          textAlign="center"
                          placeholder="00"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                        />
                      )}
                      name="longitudeSeconds"
                    />
                    {errors.longitudeSeconds && (
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {errors.longitudeSeconds.message
                          ? errors.longitudeSeconds.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <VStack>
                  <Text>”</Text>
                </VStack>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      onBlur={onBlur}
                      selectedValue={value}
                      maxWidth="85"
                      width={['50', '50', '127', '127']}
                      placeholder={t('west') + ' / E'}
                      _selectedItem={{
                        bg: 'teal.600',
                        endIcon: <CheckIcon size="5" />,
                      }}
                      onValueChange={onChange}
                    >
                      <Select.Item label={t('west')} value="W" />
                      <Select.Item label="E" value="E" />
                    </Select>
                  )}
                  name="longitudeCoordinate"
                />
                {errors.longitudeCoordinate && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {t('heliportForm.required')}
                  </Text>
                )}
                <VStack>
                  <Tooltip
                    bg="yellow.100"
                    _text={{
                      color: 'black',
                    }}
                    placement="left"
                    label="e.g., 12º 11' 50” W"
                  >
                    <Image
                      source={{
                        uri: explanation,
                      }}
                      mt={2}
                      alt="Alternate Text"
                      style={{ width: 20, height: 20 }}
                    />
                  </Tooltip>
                </VStack>
              </HStack>
              <Text>{t('heliportForm.timezone')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TimezoneSelect
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
                name="timezone"
              />
              {errors.timezone && (
                <Text p="2" color="#eb0100">
                  {errors.timezone.message
                    ? errors.timezone.message
                    : t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.country')}</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onBlur={onBlur}
                    selectedValue={value}
                    minWidth="200"
                    accessibilityLabel="Choose Service"
                    placeholder={t('heliportForm.country')}
                    _selectedItem={{
                      bg: 'teal.600',
                      endIcon: <CheckIcon size="5" />,
                    }}
                    mt={1}
                    onValueChange={onChange}
                  >
                    <Select.Item
                      label={t('appBar.language.brazil')}
                      value="Brazil"
                    />
                    <Select.Item
                      label={t('appBar.language.mexico')}
                      value="Mexico"
                    />
                    <Select.Item label={t('appBar.language.usa')} value="USA" />
                  </Select>
                )}
                name="country"
              />
              {errors.country && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Divider />
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.availability')}
              </Heading>
              <Text>{t('heliportForm.timeslot')}*</Text>
              <HStack space={3}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        placeholder="20"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        variant="unstyled"
                        borderWidth={1}
                        InputRightElement={
                          <Tooltip
                            bg="yellow.100"
                            _text={{
                              color: 'black',
                            }}
                            placement="left"
                            label="e.g., 20"
                          >
                            <Image
                              source={{
                                uri: explanation,
                              }}
                              alt="Alternate Text"
                              mr={2}
                              style={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                    name="landingSlotLength"
                  />
                  {errors.landingSlotLength && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {errors.landingSlotLength.message
                        ? errors.landingSlotLength.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        selectedValue={value}
                        onBlur={onBlur}
                        width={['140', '140', '170', '170']}
                        accessibilityLabel="time"
                        placeholder={
                          t('heliportForm.hours') +
                          '/' +
                          t('heliportForm.minutes')
                        }
                        _selectedItem={{
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item
                          label={t('heliportForm.hours')}
                          value="hours"
                        />
                        <Select.Item
                          label={t('heliportForm.minutes')}
                          value="minutes"
                        />
                      </Select>
                    )}
                    name="time"
                  />
                  {errors.time && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
              </HStack>
              <Text>{t('heliportForm.numberHelipads')}</Text>
              <Controller
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t('heliportForm.numbers'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    placeholder={t('heliportForm.numberHelipadsLabel')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., 1"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="helipadsNumber"
              />
              {errors.helipadsNumber && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {errors.helipadsNumber.message
                    ? errors.helipadsNumber.message
                    : t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.fuel')}</Text>
              <Radio.Group
                name="myRadioGroup"
                value={checkedFuel}
                accessibilityLabel="select a role"
                onChange={(nextValue) => {
                  setCheckedFuel(nextValue)
                }}
              >
                <Radio value={true} my={1} accessibilityLabel="fuel yes">
                  {t('heliportForm.yes')}
                </Radio>
                <Radio value={false} my={1} accessibilityLabel="fuel no">
                  {t('heliportForm.no')}
                </Radio>
              </Radio.Group>
              <Divider />
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.restrictions')}
              </Heading>
              <Text>{t('heliportForm.size')}</Text>
              <Text>{t('heliportForm.weight')}</Text>
              <HStack space={3}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        placeholder={t('heliportForm.weight')}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        variant="unstyled"
                        borderWidth={1}
                        InputRightElement={
                          <Tooltip
                            bg="yellow.100"
                            _text={{
                              color: 'black',
                            }}
                            placement="left"
                            label="e.g., 200"
                          >
                            <Image
                              source={{
                                uri: explanation,
                              }}
                              alt="Alternate Text"
                              mr={2}
                              style={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                    name="sizeWeigthLimit"
                  />
                  {errors.sizeWeigthLimit && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {errors.sizeWeigthLimit.message
                        ? errors.sizeWeigthLimit.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        selectedValue={value}
                        onBlur={onBlur}
                        width={['140', '140', '200', '200']}
                        accessibilityLabel="Choose Service"
                        placeholder="kg/lbs"
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item label="kg" value="kg" />
                        <Select.Item label="lbs" value="lbs" />
                        <Select.Item label="t" value="t" />
                      </Select>
                    )}
                    name="weightMeasureType"
                  />
                  {errors.weightMeasureType && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
              </HStack>
              <Text>{t('heliportForm.maximumRotor')}</Text>
              <Stack space={2} direction={['row', 'row', 'row', 'row']}>
                <View width={['17%', '17%', '20%', '20%']}>
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          placeholder="20"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          variant="unstyled"
                          borderWidth={1}
                          InputRightElement={
                            <Tooltip
                              bg="yellow.100"
                              _text={{
                                color: 'black',
                              }}
                              placement="left"
                              label="e.g., 30"
                            >
                              <Image
                                source={{
                                  uri: explanation,
                                }}
                                alt="Alternate Text"
                                mr={2}
                                style={{ width: 20, height: 20 }}
                              />
                            </Tooltip>
                          }
                        />
                      )}
                      name="dimension_one"
                    />
                    {errors.dimension_one && (
                      <Text style={[tw.textRed600, tw.p2]}>
                        {errors.dimension_one.message
                          ? errors.dimension_one.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <Center>
                  <Text> x </Text>
                </Center>
                <View width={['17%', '17%', '20%', '20%']}>
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: t('heliportForm.numbers'),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          borderRadius="2xl"
                          placeholder="25"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          variant="unstyled"
                          borderWidth={1}
                          InputRightElement={
                            <Tooltip
                              bg="yellow.100"
                              _text={{
                                color: 'black',
                              }}
                              placement="left"
                              label="e.g., 30"
                            >
                              <Image
                                source={{
                                  uri: explanation,
                                }}
                                alt="Alternate Text"
                                mr={2}
                                style={{ width: 20, height: 20 }}
                              />
                            </Tooltip>
                          }
                        />
                      )}
                      name="dimension_two"
                    />
                    {errors.dimension_two && (
                      <Text style={[tw.textRed600, tw.p2]}>
                        {errors.dimension_two.message
                          ? errors.dimension_two.message
                          : t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
                <View width={['50%', '50%', '50%', '50%']}>
                  <VStack>
                    <Controller
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          selectedValue={value}
                          onBlur={onBlur}
                          accessibilityLabel="Choose Service"
                          placeholder="m/ft"
                          _selectedItem={{
                            bg: 'teal.600',
                            endIcon: <CheckIcon size="5" />,
                          }}
                          onValueChange={onChange}
                        >
                          <Select.Item label="m" value="m" />
                          <Select.Item label="ft" value="ft" />
                        </Select>
                      )}
                      name="maximumRotorDiameterMeasureType"
                    />
                    {errors.maximumRotorDiameterMeasureType && (
                      <Text style={[tw.textRed600, tw.p2]}>
                        {t('heliportForm.required')}
                      </Text>
                    )}
                  </VStack>
                </View>
              </Stack>
              <Text>{t('heliportForm.surface')}</Text>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    onBlur={onBlur}
                    placeholder={t('heliportForm.aspalthLabel')}
                    value={value}
                    onChangeText={onChange}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label={t('heliportForm.aspalth')}
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="surfaceType"
              />
              {errors.surfaceType && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.otherRestrictions')}</Text>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextArea
                    placeholder={t('heliportForm.otherRestrictions')}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label={'e.g.,' + t('heliportForm.noAvailables')}
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="otherRestrictions"
              />
              {errors.otherRestrictions && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Text>{t('heliportForm.limitLate')}</Text>
              <HStack space={3}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        width={['110', '110', '180', '180']}
                        placeholder="e.g., 2"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        variant="unstyled"
                        borderWidth={1}
                        InputRightElement={
                          <Tooltip
                            bg="yellow.100"
                            _text={{
                              color: 'black',
                            }}
                            placement="left"
                            label="e.g., 2"
                          >
                            <Image
                              source={{
                                uri: explanation,
                              }}
                              alt="Alternate Text"
                              mr={2}
                              style={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                    name="limitLateBooking"
                  />
                  {errors.limitLateBooking && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {errors.limitLateBooking.message
                        ? errors.limitLateBooking.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        selectedValue={value}
                        onBlur={onBlur}
                        accessibilityLabel="time"
                        width={['120', '120', '190', '190']}
                        placeholder={
                          t('heliportForm.hours') + '/' + t('heliportForm.days')
                        }
                        _selectedItem={{
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item
                          label={t('heliportForm.minutes')}
                          value="minutes"
                        />
                        <Select.Item
                          label={t('heliportForm.hours')}
                          value="hours"
                        />
                      </Select>
                    )}
                    name="limitTimeLateBooking"
                  />
                  {errors.limitTimeLateBooking && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
              </HStack>
              <Text>{t('heliportForm.cancellation')}</Text>
              <HStack space={3}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        width={['110', '110', '180', '180']}
                        placeholder="e.g., 2"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        variant="unstyled"
                        borderWidth={1}
                        InputRightElement={
                          <Tooltip
                            bg="yellow.100"
                            _text={{
                              color: 'black',
                            }}
                            placement="left"
                            label="e.g., 2"
                          >
                            <Image
                              source={{
                                uri: explanation,
                              }}
                              alt="Alternate Text"
                              mr={2}
                              style={{ width: 20, height: 20 }}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                    name="cancellationWindow"
                  />
                  {errors.cancellationWindow && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {errors.cancellationWindow.message
                        ? errors.cancellationWindow.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        selectedValue={value}
                        onBlur={onBlur}
                        accessibilityLabel="time"
                        width={['120', '120', '190', '190']}
                        placeholder={
                          t('heliportForm.hours') + '/' + t('heliportForm.days')
                        }
                        _selectedItem={{
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item
                          label={t('heliportForm.minutes')}
                          value="minutes"
                        />
                        <Select.Item
                          label={t('heliportForm.hours')}
                          value="hours"
                        />
                        <Select.Item
                          label={t('heliportForm.days')}
                          value="days"
                        />
                      </Select>
                    )}
                    name="cancellationTime"
                  />
                  {errors.cancellationTime && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <VStack>
                  <Text>{t('heliportForm.before')}</Text>
                  <Text>{t('heliportForm.flight')}</Text>
                </VStack>
              </HStack>
              <Divider />
              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.fees')}
              </Heading>

              <HStack pb="2" space={3}>
                <Text pt="2">{t('heliportForm.heliportCurrency')}</Text>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        selectedValue={value}
                        onBlur={onBlur}
                        accessibilityLabel="time"
                        width={['120', '120', '200', '200']}
                        placeholder={t('heliportForm.currency')}
                        _selectedItem={{
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={onChange}
                      >
                        <Select.Item
                          label={t('heliportForm.real')}
                          value="R$"
                        />
                        <Select.Item label={t('heliportForm.usd')} value="$" />
                        <Select.Item
                          label={t('heliportForm.mexican')}
                          value="MX$"
                        />
                      </Select>
                    )}
                    name="heliportCurrency"
                  />
                  {errors.heliportCurrency && (
                    <Text style={[tw.textRed600, tw.p2]}>
                      {t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
              </HStack>
              <HStack space={8}>
                <Text>{t('heliportForm.parkingAvailable')}</Text>
                <Checkbox
                  value={checkedParking}
                  onChange={(nextValue) => {
                    setCheckedParking(nextValue)
                  }}
                  accessibilityLabel="choose parking"
                  colorScheme="yellow"
                />
              </HStack>
              {checkedParking ? (
                <View>
                  <Stack
                    pt="2"
                    pb="2"
                    space={3}
                    direction={['column', 'column', 'row', 'row']}
                  >
                    <VStack space={2}>
                      <Text>{t('heliportForm.parkingHour')}</Text>
                      <Controller
                        control={control}
                        rules={{
                          required: false,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: t('heliportForm.numbers'),
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            borderRadius="2xl"
                            onBlur={onBlur}
                            placeholder={'$150/' + t('heliportForm.hour')}
                            value={value}
                            onChangeText={onChange}
                            variant="unstyled"
                            borderWidth={1}
                            InputRightElement={
                              <Tooltip
                                bg="yellow.100"
                                _text={{
                                  color: 'black',
                                }}
                                placement="left"
                                label="e.g. 150"
                              >
                                <Image
                                  source={{
                                    uri: explanation,
                                  }}
                                  alt="Alternate Text"
                                  mr={2}
                                  style={{ width: 20, height: 20 }}
                                />
                              </Tooltip>
                            }
                          />
                        )}
                        name="hourlyRate"
                      />
                      {errors.hourlyRate && (
                        <Text style={[tw.textRed600, tw.p2]}>
                          {errors.hourlyRate.message
                            ? errors.hourlyRate.message
                            : t('heliportForm.required')}
                        </Text>
                      )}
                    </VStack>
                    <VStack space={2}>
                      <Text>{t('heliportForm.parkingNight')}</Text>
                      <VStack space={2}>
                        <Controller
                          control={control}
                          rules={{
                            required: false,
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: t('heliportForm.numbers'),
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              borderRadius="2xl"
                              onBlur={onBlur}
                              placeholder={'$500/' + t('heliportForm.night')}
                              value={value}
                              onChangeText={onChange}
                              variant="unstyled"
                              borderWidth={1}
                              InputRightElement={
                                <Tooltip
                                  bg="yellow.100"
                                  _text={{
                                    color: 'black',
                                  }}
                                  placement="left"
                                  label="e.g. 500"
                                >
                                  <Image
                                    source={{
                                      uri: explanation,
                                    }}
                                    alt="Alternate Text"
                                    mr={2}
                                    style={{ width: 20, height: 20 }}
                                  />
                                </Tooltip>
                              }
                            />
                          )}
                          name="overnightRate"
                        />
                        {errors.overnightRate && (
                          <Text style={[tw.textRed600, tw.p2]}>
                            {errors.overnightRate.message
                              ? errors.overnightRate.message
                              : t('heliportForm.required')}
                          </Text>
                        )}
                      </VStack>
                    </VStack>
                  </Stack>
                </View>
              ) : (
                <View></View>
              )}

              <Heading size={['md', 'lg', 'md']} fontWeight="large">
                {t('heliportForm.description')}
              </Heading>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextArea
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label={t('heliportForm.descriptionLabel')}
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="heliportDescription"
              />
              {errors.heliportDescription && (
                <Text style={[tw.textRed600, tw.p2]}>
                  {t('heliportForm.required')}
                </Text>
              )}
              <Divider />
              <View pt="30">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <View className="upload__image-wrapper">
                      {imageList.map((image, index) => (
                        <Center key={index}>
                          <Image
                            source={{
                              uri: `${image['data_url']}`,
                            }}
                            alt="Alternate Text"
                            size={'xl'}
                            resizeMode="cover"
                            rounded="xl"
                          />
                          <HStack space={3} p="4">
                            <Box shadow={2} maxWidth="40">
                              <Text pt="2" isTruncated="true">
                                {image.file.name}
                              </Text>
                            </Box>
                            <Button
                              colorScheme="yellow"
                              onPress={() => onImageUpdate(index)}
                            >
                              {t('heliportForm.update')}
                            </Button>
                            <Button
                              colorScheme="red"
                              onPress={() => onImageRemove(index)}
                            >
                              {t('heliportForm.remove')}
                            </Button>
                          </HStack>
                        </Center>
                      ))}
                      <Button
                        rounded="2xl"
                        colorScheme="yellow"
                        style={isDragging ? { color: 'red' } : undefined}
                        onPress={onImageUpload}
                        {...dragProps}
                      >
                        {t('heliportForm.uploadImage')}
                      </Button>
                    </View>
                  )}
                </ImageUploading>
              </View>
              <Divider />
              <br />
              <HStack space={3}>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('register.tosMessage'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      accessibilityLabel="choose numbers"
                      colorScheme="yellow"
                    />
                  )}
                  name="checked"
                />
                <HStack space={1}>
                  <Text>{t('heliportForm.accept')}</Text>
                  {selectedCurrentLanguage ? (
                    language === 'pt' ? (
                      <Link
                        style={{ color: '#4D4D4D' }}
                        target="_blank"
                        rel="Portuguese Services Agreement"
                        to={Pt}
                      >
                        <Text>{t('heliportForm.servicesAgreement')}</Text>
                      </Link>
                    ) : (
                      <Link
                        style={{ color: '#4D4D4D' }}
                        target="_blank"
                        rel="English Service Agreement"
                        to={En}
                      >
                        <Text>{t('heliportForm.servicesAgreement')}</Text>
                      </Link>
                    )
                  ) : (
                    <Link
                      style={{ color: '#4D4D4D' }}
                      target="_blank"
                      rel="English Service Agreement"
                      to={En}
                    >
                      <Text>{t('heliportForm.servicesAgreement')}</Text>
                    </Link>
                  )}
                </HStack>
              </HStack>
              {errors.checked && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.checked.message
                    ? errors.checked.message
                    : t('register.required')}
                </Text>
              )}
              <Text p="2" fontSize="xs" color="#eb0100">
                {addressError}
              </Text>
              {phoneValid === true ? (
                <View />
              ) : (
                <View>
                  <Text pb="2" pl="2" fontSize="xs" color="#eb0100">
                    {errorNumber}
                  </Text>
                </View>
              )}
              <Button
                onPress={handleSubmit(onSubmit)}
                colorScheme="yellow"
                rounded="2xl"
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('heliportForm.createHeliport')}
              </Button>
            </Stack>
          </Box>
        </Center>
        <Modal isOpen={showError}>
          <ModalError error={errorCreateHeliport} onClose={closeError} />
        </Modal>
      </NativeBaseProvider>
    </View>
  )
}
export default HeliportForm
