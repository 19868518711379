import React from 'react'
import {
  Alert,
  VStack,
  HStack,
  IconButton,
  CloseIcon,
  Box,
  Text,
  Modal,
  Radio,
  Checkbox,
  Button,
  View,
  Input,
  Image,
  Tooltip,
  Center,
} from 'native-base'
import { auth } from '../utils/nhost'
import { useMutation } from '@apollo/client'
import {
  CREATE_PROFILE,
  CREATE_COMPANY,
  CREATE_OPERATOR,
  CREATE_OPERATOR_CONTACT,
  CREATE_FLEET,
} from '../graphql/mutations'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Link } from '../routing'
import explanation from '../assets/question_icon.png'
import login from '../assets/login.png'
import imgSrc from '../assets/logo_yellow.png'
import { StyleSheet } from 'react-native'
import Tos from '../assets/Terms_of_Services_PT.pdf'
import phoneLanguage from '../PhoneLanguage'
import PasswordChecklist from 'react-password-checklist'

export const Register = (props) => {
  const [errorRegister, setErrorRegister] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [createProfile] = useMutation(CREATE_PROFILE)
  const [createCompany] = useMutation(CREATE_COMPANY)
  const [createOperator] = useMutation(CREATE_OPERATOR)
  const [createOperatorContact] = useMutation(CREATE_OPERATOR_CONTACT)
  const [createFleet] = useMutation(CREATE_FLEET)
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = React.useState(false)
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [errorNumber, setErrorNumber] = React.useState('')
  const [passwordAgain, setPasswordAgain] = React.useState('')
  const [passwordValue, setPasswordValue] = React.useState('')
  const [passwordCheck, setPasswordCheck] = React.useState('')

  React.useEffect(async () => {
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      identifier: '',
      password: '',
      companyName: '',
      operatorCompanyName: '',
      firstName: '',
      lastName: '',
      checked: false,
      checkedRole: '',
      selectedCallingCode: '',
    },
  })

  const handleClick = () => setShowPassword(!showPassword)

  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '+55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '+1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '+52 123 456 1789'
    } else {
      return '+1 (704) 123-4567'
    }
  }

  const onSubmit = async (data) => {
    if (!passwordValue) {
      setPasswordAgain(t('register.required'))
    } else if (passwordValue && passwordCheck === true) {
      if (phoneValid === true) {
        if (data.checkedRole === 'manager') {
          try {
            const { error } = await auth.signUp({
              email: data.identifier,
              password: passwordValue,
              options: {
                allowedRoles: ['user', 'me', 'manager'],
                defaultRole: 'user',
              },
            })
            if (error) {
              setErrorRegister(error.message)
              setShow(true)
            } else {
              await createProfile({
                variables: {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  phone_number: data.selectedCallingCode,
                },
              })
              await createCompany({
                variables: {
                  email: data.identifier,
                  phone: data.selectedCallingCode,
                  company_name: data.companyName,
                },
              })
              await props.history.push('/heliportForm')
            }
          } catch (error) {
            console.log(error)
          }
        } else if (data.checkedRole === 'operator') {
          try {
            const { error } = await auth.signUp({
              email: data.identifier,
              password: passwordValue,
              options: {
                allowedRoles: ['user', 'me', 'operator'],
                defaultRole: 'user',
              },
            })
            if (error) {
              setErrorRegister(error.message)
              setShow(true)
            } else {
              const responseProfile = await createProfile({
                variables: {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  phone_number: data.selectedCallingCode,
                },
              })
              const operator = await createOperator({
                variables: {
                  user_id:
                    responseProfile.data.insert_profile.returning[0].user_id,
                  operator_company_name: data.companyName,
                  operator_name: `${data.firstName} ${data.lastName}`,
                },
              })
              await createOperatorContact({
                variables: {
                  email: data.identifier,
                  name: `${data.firstName} ${data.lastName}`,
                  phone: data.selectedCallingCode,
                  operator_id: operator.data.insert_operators_one.id,
                },
              })
              await createFleet({
                variables: {
                  operator_id: operator.data.insert_operators_one.id,
                },
              })
              await props.history.push('/')
            }
          } catch (error) {
            console.log(error)
          }
        }
      } else {
        setErrorNumber(t('errorPhoneNumber'))
      }
    }
  }

  return (
    <View
      bgColor="white"
      style={{
        flexDirection: 'column',
      }}
    >
      <Center p={['15%', '15%', '10%', '2%']}>
        <Image
          source={{ uri: imgSrc }}
          alt="Alternate Text"
          style={styles.stretch}
        />
      </Center>
      <Center>
        <br />
        <Box
          w={['90%', '90%', '420', '420']}
          _light={{ bg: 'lightText' }}
          _dark={{ bg: 'lightText' }}
        >
          <View bgColor="white" h="100%">
            <Text bold fontSize="36" color="#4D4D4D">
              {t('register.title')}
            </Text>
            <Text fontSize="16" pb="4" color="#B3B3B3">
              {t('register.subtitle')}
            </Text>
            <Text fontWeight="medium" color="#4D4D4D">
              {t('register.userType')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('register.type'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Radio.Group
                  onBlur={onBlur}
                  name="myRadioGroup"
                  value={value}
                  accessibilityLabel="select a role"
                  onChange={onChange}
                >
                  <Radio
                    borderColor="#F9D342"
                    colorScheme="yellow"
                    value="manager"
                    my={1}
                    accessibilityLabel="role manager"
                  >
                    <Text pl={2} fontWeight="medium" color="#4D4D4D">
                      {t('register.helpedManager')}
                    </Text>
                  </Radio>

                  <Radio
                    borderColor="#F9D342"
                    colorScheme="yellow"
                    value="operator"
                    my={1}
                    accessibilityLabel="role operator"
                  >
                    <Text pl={2} fontWeight="medium" color="#4D4D4D">
                      {t('register.helicopterOperator')}
                    </Text>
                  </Radio>
                </Radio.Group>
              )}
              name="checkedRole"
            />
            {errors.checkedRole && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.checkedRole.message
                  ? errors.checkedRole.message
                  : t('register.required')}
              </Text>
            )}
            <View>
              <Text pl={2} pb={1} fontWeight="medium" color="#4D4D4D">
                {t('register.companyNameLabel')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
                    message: t('register.lettersAndNumbers'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    label={t('register.companyNameLabel')}
                    placeholder={t('register.companyNamePlaceHolder')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    variant="unstyled"
                    borderWidth={1}
                    InputRightElement={
                      <Tooltip
                        bg="yellow.100"
                        _text={{
                          color: 'black',
                        }}
                        placement="left"
                        label="e.g., Brasilia Air"
                      >
                        <Image
                          source={{
                            uri: explanation,
                          }}
                          alt="Alternate Text"
                          mr={2}
                          style={{ width: 20, height: 20 }}
                        />
                      </Tooltip>
                    }
                  />
                )}
                name="companyName"
              />
              {errors.companyName && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.companyName.message
                    ? errors.companyName.message
                    : t('register.required')}
                </Text>
              )}
            </View>
            <br />
            <HStack width="98%" space={2}>
              <VStack width="50%">
                <Text pl={2} pb={1} fontWeight="medium" color="#4D4D4D">
                  {t('register.firstNameLabel')}
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                      message: t('register.onlyLetters'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      borderRadius="2xl"
                      label={t('register.firstNameLabel')}
                      placeholder={t('register.firstNamePlaceHolder')}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      variant="unstyled"
                      borderWidth={1}
                      InputRightElement={
                        <Tooltip
                          bg="yellow.100"
                          _text={{
                            color: 'black',
                          }}
                          placement="left"
                          label="e.g., Gustavo"
                        >
                          <Image
                            source={{
                              uri: explanation,
                            }}
                            alt="Alternate Text"
                            mr={2}
                            style={{ width: 20, height: 20 }}
                          />
                        </Tooltip>
                      }
                    />
                  )}
                  name="firstName"
                />
                {errors.firstName && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {errors.firstName.message
                      ? errors.firstName.message
                      : t('register.required')}
                  </Text>
                )}
              </VStack>
              <VStack width="50%">
                <Text pl={2} pb={1} fontWeight="medium" color="#4D4D4D">
                  {t('register.lastNameLabel')}
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                      message: t('register.onlyLetters'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      borderRadius="2xl"
                      label={t('register.lastNameLabel')}
                      placeholder={t('register.lastNamePlaceHolder')}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      variant="unstyled"
                      borderWidth={1}
                      InputRightElement={
                        <Tooltip
                          bg="yellow.100"
                          _text={{
                            color: 'black',
                          }}
                          placement="left"
                          label="e.g., Macedo"
                        >
                          <Image
                            source={{
                              uri: explanation,
                            }}
                            alt="Alternate Text"
                            mr={2}
                            style={{ width: 20, height: 20 }}
                          />
                        </Tooltip>
                      }
                    />
                  )}
                  name="lastName"
                />
                {errors.lastName && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {errors.lastName.message
                      ? errors.lastName.message
                      : t('register.required')}
                  </Text>
                )}
              </VStack>
            </HStack>
            <Text pl={2} pt={2} pb={1} fontWeight="medium" color="#4D4D4D">
              {t('register.phoneNumberLabel')}
            </Text>
            <HStack pl={1} space={1}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PhoneInput
                    inputStyle={{
                      width: '100%',
                      borderRadius: '15px',
                      backgroundColor: 'white',
                      borderColor: '#E4E4E4',
                    }}
                    buttonStyle={{ borderColor: '#D4D4D4' }}
                    country={selectedLanguage}
                    masks={{ br: '.. .........' }}
                    placeholder={placeholderPhone()}
                    value={value}
                    onChange={onChange}
                    isValid={(value, country) => {
                      if (
                        value.length > 1 &&
                        country.name === 'United States' &&
                        value.length < 11
                      ) {
                        setPhoneValid(false)
                      } else if (
                        value.length > 2 &&
                        country.name === 'Brazil' &&
                        value.length < 12
                      ) {
                        setPhoneValid(false)
                      } else {
                        setPhoneValid(true)
                        return true
                      }
                    }}
                  />
                )}
                name="selectedCallingCode"
              />
              <Tooltip
                bg="yellow.100"
                _text={{
                  color: 'black',
                }}
                placement="left"
                label="e.g., +1 435 5435-345"
              >
                <Image
                  source={{
                    uri: explanation,
                  }}
                  alt="Alternate Text"
                  mt={2}
                  mr={2}
                  style={{ width: 20, height: 20 }}
                />
              </Tooltip>
            </HStack>
            {errors.selectedCallingCode && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {t('register.required')}
              </Text>
            )}
            {phoneValid === true ? (
              <View />
            ) : (
              <View zIndex="-2">
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errorNumber}
                </Text>
              </View>
            )}
            <Text pl={2} pt={2} pb={1} fontWeight="medium" color="#4D4D4D">
              {t('register.userNameLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  zIndex="-2"
                  borderRadius="2xl"
                  label={t('register.userNameLabel')}
                  placeholder={t('register.userNamePlaceHolder')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., gustavo@altaport.com"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="identifier"
            />
            {errors.identifier && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.identifier.message
                  ? errors.identifier.message
                  : t('register.required')}
              </Text>
            )}
            <Text pl={2} pt={2} pb={1} fontWeight="medium" color="#4D4D4D">
              {t('register.passwordLabel')}
            </Text>
            <Input
              zIndex="-2"
              borderRadius="2xl"
              type={showPassword ? 'text' : 'password'}
              py="0"
              height={9}
              variant="unstyled"
              placeholder={t('register.passwordLabel')}
              onChangeText={(value) => setPasswordValue(value)}
              borderWidth={1}
              InputRightElement={
                <Button
                  size="xs"
                  variant="link"
                  rounded="none"
                  w="1/6"
                  h="full"
                  colorScheme="yellow"
                  onPress={handleClick}
                >
                  {showPassword ? t('register.hide') : t('register.show')}
                </Button>
              }
            />
            {passwordValue ? (
              <PasswordChecklist
                rules={[
                  'minLength',
                  'specialChar',
                  'number',
                  'capital',
                  'lowercase',
                ]}
                minLength={8}
                value={passwordValue}
                onChange={(valid) => setPasswordCheck(valid)}
                messages={{
                  number: t('errorsPassword.number'),
                  capital: t('errorsPassword.capital'),
                  lowercase: t('errorsPassword.lowercase'),
                  specialChar: t('errorsPassword.specialChar'),
                  minLength: t('errorsPassword.minLength'),
                }}
              />
            ) : (
              <View>
                {passwordAgain ? (
                  <View>
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {t('register.required')}
                    </Text>
                  </View>
                ) : (
                  <View />
                )}
              </View>
            )}
            <br />
            <View zIndex="-2" pl={2}>
              <HStack>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('register.tosMessage'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      onBlur={onBlur}
                      colorScheme="yellow"
                      value={value}
                      mt={2}
                      onChange={onChange}
                      accessibilityLabel="choose numbers"
                    />
                  )}
                  name="checked"
                />

                <Text p={2} color="#4D4D4D">
                  {t('register.acceptTerms')}{' '}
                  <Link
                    style={{ color: '#4D4D4D', fontWeight: 'medium' }}
                    target="_blank"
                    rel="Terms of Service"
                    to={Tos}
                  >
                    {t('register.tos')}
                  </Link>
                </Text>
              </HStack>
            </View>
            {errors.checked && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.checked.message
                  ? errors.checked.message
                  : t('register.required')}
              </Text>
            )}
            <Button
              zIndex="-2"
              rounded="3xl"
              colorScheme="yellow"
              onPress={handleSubmit(onSubmit)}
            >
              <HStack
                style={{ alignItems: 'center', justifyContent: 'center' }}
                space={3}
              >
                <Text fontSize="16" fontWeight="medium">
                  {t('register.registerButtonText')}
                </Text>
                <Image
                  source={{
                    uri: login,
                  }}
                  alt="Alternate Text"
                  style={{ width: 20, height: 20 }}
                />
              </HStack>
            </Button>
            <Center>
              <HStack space={1} p="2">
                <Text fontSize="xs" color="muted.600">
                  {t('register.member')}
                </Text>
                <View>
                  <Link style={{ textDecoration: 'none' }} to="/auth/login">
                    <Text fontWeight="medium" fontSize="xs" color="primary.700">
                      {t('register.signIn')}
                    </Text>
                  </Link>
                </View>
              </HStack>
            </Center>
          </View>
        </Box>
      </Center>

      <Modal isOpen={show}>
        <Alert status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('try_again.text')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => setShow(false)}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              {errorRegister}
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default Register
const styles = StyleSheet.create({
  stretch: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
  },
})
