import React from 'react'
import {
  HStack,
  View,
  Box,
  Alert,
  Heading,
  Divider,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLocation } from '../routing'
import BookingDetailPayment from '../components/BookingDetailPayment'
import axiosInstance from '../AxiosInstance'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_BY_COMPANY } from '../graphql/queries'

export const PaymentSummary = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [amount, setAmount] = React.useState(null)
  const [booking, setBooking] = React.useState({})
  const [error, setError] = React.useState(false)
  const [getHeliport] = useLazyQuery(GET_HELIPORT_BY_COMPANY)
  const [heliportCurrency, setHeliportCurrency] = React.useState({})
  const { t } = useTranslation()

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const bookingId = query.get('booking')
    const amount = 0

    const booking = await getBooking(bookingId)
    const companyId = booking.meta.company_id

    const heliport = await getHeliport({
      variables: { id: companyId },
    })

    setHeliportCurrency(
      heliport.data.company_by_pk.heliports[0].heliport_profile
        .heliport_currency
    )
    setBooking(booking.attributes.customer)
    setAmount(amount)
    setLoading(false)
  }, [])

  const getBooking = async (id) => {
    try {
      const booking = await axiosInstance.post(`/bookings/bookingById`, {
        id: id,
      })
      return booking.data.booking
    } catch (error) {
      setError(true)
    }
  }

  return (
    <View>
      <AppBarHeader />
      {loading ? (
        <Loading />
      ) : (
        <View pr="28%" pl="28%">
          {error ? (
            <Alert w="100%" status="error">
              <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                <Alert.Icon size="md" />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentSummary.errorBooking.title')}
                </Text>

                <Box
                  _text={{
                    textAlign: 'center',
                  }}
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  {t('paymentSummary.errorBooking.message')}
                </Box>
              </VStack>
            </Alert>
          ) : (
            <Center>
              <VStack space={5}>
                <Alert w="100%" status="success">
                  <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                    <Alert.Icon size="md" />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('paymentSummary.bookingReceived.title')}
                    </Text>

                    <Box
                      _text={{
                        textAlign: 'center',
                      }}
                      _dark={{
                        _text: {
                          color: 'coolGray.600',
                        },
                      }}
                    >
                      {t('paymentSummary.bookingReceived.yourBooking')}
                    </Box>
                  </VStack>
                </Alert>
                <Box bgColor="#e0e0e0" rounded="xl" shadow="9">
                  <Heading p="2" size="md">
                    {t('paymentSummary.bookingReceived.paymentsDetails')}
                  </Heading>
                  <View p="2">
                    <Box
                      bgColor="#ffffff"
                      rounded="xl"
                      shadow="9"
                      width="35%"
                      p="2"
                    >
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.amount')}
                      </Text>
                      <Text bold fontSize="2xl" p="2">
                        {heliportCurrency === 'R$' ? 'R$' : '$'}
                        {amount}
                      </Text>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.billingDetails')}
                      </Text>
                      <Divider bg="yellow.400" />
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.paymentMethod')}
                      </Text>
                      <HStack space={3}>
                        <Text bold fontSize="s" p="2">
                          {t('paymentSummary.bookingReceived.type')}
                        </Text>
                        <Text p="2">
                          {t('paymentSummary.bookingReceived.promotionalCode')}
                        </Text>
                      </HStack>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <BookingDetailPayment booking={booking} />
                    </Box>
                  </View>
                </Box>
                <View p="2">
                  <Button
                    colorScheme="yellow"
                    onPress={() => {
                      props.history.push('/manageReservationOperator')
                    }}
                  >
                    {t('paymentSummary.bookingReceived.viewReservations')}
                  </Button>
                </View>
              </VStack>
            </Center>
          )}
        </View>
      )}
    </View>
  )
}
export default PaymentSummary
