import React from 'react'
import {
  View,
  Text,
  Box,
  Heading,
  Divider,
  HStack,
  VStack,
  Container,
  Stack,
  NativeBaseProvider,
} from 'native-base'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import { useHistory } from '../../routing'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import { GET_OPERATOR } from '../../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import axiosInstance from '../../AxiosInstance'

const BookingsIsCancelled = ({ booking }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const goToBookingDetail = (id) => {
    history.push(`/bookingOperatorDetail/${id}`)
  }

  const getDate = (bookingDate, timezone) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate, timezone) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate, timezone) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const bookingStatus = {
    cancelled_by_customer: t(
      'manageReservationOperator.pastBookings.status.cancelledCustomer'
    ),
    cancelled: t('manageReservationOperator.pastBookings.status.cancelled'),
    declined: t('manageReservationOperator.pastBookings.status.declined'),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize="md" fontWeight="bold">
              {t('manageReservationOperator.pastBookings.title')}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <View>
                        <Text
                          fontSize={['xs', 'sm', 'sm', 'md']}
                          fontWeight="bold"
                        >
                          {t(
                            'manageReservationOperator.pastBookings.reservation.title'
                          )}
                        </Text>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.pastBookings.reservation.date'
                            )}
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {getDate(
                              item.attributes.event.start,
                              item.meta.timezone
                                ? item.meta.timezone
                                : undefined
                            )}
                          </Text>
                        </HStack>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.pastBookings.reservation.start'
                            )}
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {getStartBooking(
                              item.attributes.event.start,
                              item.meta.timezone
                                ? item.meta.timezone
                                : undefined
                            )}
                          </Text>
                        </HStack>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.pastBookings.reservation.end'
                            )}
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {getEndBooking(
                              item.attributes.event.end,
                              item.meta.timezone
                                ? item.meta.timezone
                                : undefined
                            )}
                          </Text>
                        </HStack>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            Helipad:
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {item.attributes.event.what}
                          </Text>
                        </HStack>
                      </View>

                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'manageReservationOperator.pastBookings.customers.title'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading size="xs">
                              {t(
                                'manageReservationOperator.pastBookings.customers.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'manageReservationOperator.pastBookings.customers.aircraftType'
                              )}
                            </Heading>
                            <Text></Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'manageReservationOperator.pastBookings.customers.tailNumbers'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.tail_number
                                ? item.meta.tail_number
                                : item.attributes.customer &&
                                  item.attributes.customer.tail_number
                                ? item.attributes.customer.tail_number
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '20%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'manageReservationOperator.pastBookings.status.title'
                        )}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.status.booking'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.status.payment'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      {item.meta.reason ? (
                        <View>
                          <HStack space={2}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Note:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta.reason}
                            </Text>
                          </HStack>
                        </View>
                      ) : (
                        <Text></Text>
                      )}
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width="20%" height="100%">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'manageReservationOperator.pastBookings.actions.title'
                        )}
                      </Text>
                      <View>
                        <Text
                          bold
                          fontSize={['xs', 'sm', 'sm', 'sm']}
                          underline
                          onPress={() => {
                            goToBookingDetail(item.id)
                          }}
                        >
                          {t(
                            'manageReservationOperator.pastBookings.actions.viewDetails'
                          )}
                        </Text>
                      </View>
                    </Container>
                  </Stack>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

const BookingsIsCompleted = ({ booking }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const goToBookingDetail = (id) => {
    history.push(`/bookingOperatorDetail/${id}`)
  }

  const getDate = (bookingDate, timezone) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate, timezone) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate, timezone) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const bookingStatus = {
    completed: t('manageReservationOperator.pastBookings.completed'),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  if (booking.length > 0) {
    return (
      <Box p={['4', '4', '10', '10']} rounded="xl" backgroundColor="light.100">
        <Text p="1%" fontSize="md" fontWeight="bold">
          {t('manageReservationOperator.pastBookings.completedTitle')}
        </Text>
        <FlatList
          data={booking}
          keyExtractor={(item) => item.id.toString()}
          ItemSeparatorComponent={() => <br />}
          renderItem={({ item }) => (
            <Box
              p={['4', '4', '12', '12']}
              rounded="xl"
              backgroundColor="white"
              key={item.id.toString()}
            >
              <Stack
                space={3}
                direction={['column', 'column', 'column', 'row']}
              >
                <Container width={['100%', '100%', '100%', '25%']}>
                  <VStack space={3}>
                    <View>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'sm']}
                        fontWeight="bold"
                      >
                        {t(
                          'manageReservationOperator.pastBookings.reservation.title'
                        )}
                      </Text>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.reservation.date'
                          )}
                        </Heading>
                        <Text>
                          {getDate(
                            item.attributes.event.start,
                            item.meta.timezone ? item.meta.timezone : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.reservation.start'
                          )}
                        </Heading>
                        <Text>
                          {getStartBooking(
                            item.attributes.event.start,
                            item.meta.timezone ? item.meta.timezone : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.reservation.end'
                          )}
                        </Heading>
                        <Text>
                          {getEndBooking(
                            item.attributes.event.end,
                            item.meta.timezone ? item.meta.timezone : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          Helipad:
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.attributes.event.what}
                        </Text>
                      </HStack>
                    </View>
                  </VStack>
                  <Divider bg="yellow.400" />
                </Container>
                <Container width={['100%', '100%', '100%', '25%']}>
                  <VStack space={3}>
                    <View>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'sm']}
                        fontWeight="bold"
                      >
                        {t(
                          'manageReservationOperator.pastBookings.customers.title'
                        )}
                      </Text>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.customers.operator'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta && item.meta.name
                            ? item.meta.name
                            : item.attributes.customer &&
                              item.attributes.customer.name
                            ? item.attributes.customer.name
                            : ''}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.customers.aircraftType'
                          )}
                        </Heading>
                        <Text></Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.pastBookings.customers.tailNumbers'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta && item.meta.tail_number
                            ? item.meta.tail_number
                            : item.attributes.customer &&
                              item.attributes.customer.tail_number
                            ? item.attributes.customer.tail_number
                            : ''}
                        </Text>
                      </HStack>
                    </View>
                  </VStack>
                  <Divider bg="yellow.400" />
                </Container>
                <Container width={['100%', '100%', '100%', '20%']}>
                  <Text fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
                    {t('manageReservationOperator.pastBookings.status.title')}
                  </Text>
                  <HStack space={2}>
                    <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                      {t(
                        'manageReservationOperator.pastBookings.status.booking'
                      )}
                    </Heading>
                    <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                      {bookingStatus[item.state]}
                    </Text>
                  </HStack>
                  <HStack space={2}>
                    <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                      {t(
                        'manageReservationOperator.pastBookings.status.payment'
                      )}
                    </Heading>
                    <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                      {item.meta.paymentStatus
                        ? getPaymentStatusStripe(item)
                        : getPaymentStatus(item)}
                    </Text>
                  </HStack>
                  <Divider bg="yellow.400" />
                </Container>

                <Container width="20%" height="100%">
                  <Text fontSize="md" fontWeight="bold">
                    {t('manageReservationOperator.pastBookings.actions.title')}
                  </Text>
                  <View>
                    <HStack space={3}>
                      <Text
                        bold
                        underline
                        p="3"
                        onPress={() => {
                          goToBookingDetail(item.id)
                        }}
                      >
                        {t(
                          'manageReservationOperator.pastBookings.actions.viewDetails'
                        )}
                      </Text>
                    </HStack>
                  </View>
                </Container>
              </Stack>
            </Box>
          )}
        />
      </Box>
    )
  } else {
    return <Text></Text>
  }
}

export const PastBookingsOperator = () => {
  const [user, setUserId] = React.useState('')
  const [bookingsCompleted, setBookingsCompleted] = React.useState([])
  const [bookingsCancelled, setBookingsCancelled] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(true)
  const { t } = useTranslation()

  const [operator] = useLazyQuery(GET_OPERATOR, {
    variables: { userId: user },
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const operatorInformation = await operator()
    await getBookingsOperator(operatorInformation)
    setIsLoading(false)
  }, [])

  const getBookingsOperator = async (operatorInformation) => {
    try {
      const getHeliportBookingsOperator = await axiosInstance.post(
        `/bookings/getBookingsOperator`,
        {
          operatorId: operatorInformation.data.operators[0].id,
        }
      )

      const heliportBokkingsCompleted = getHeliportBookingsCompleted(
        getHeliportBookingsOperator.data.bookings
      )
      setBookingsCompleted(heliportBokkingsCompleted)

      const heliportBookingsCancelled = getHeliportBookingsCancelled(
        getHeliportBookingsOperator.data.bookings
      )
      setBookingsCancelled(heliportBookingsCancelled)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.bookings'))
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      } else {
        setError(t('errors.bookings'))
      }
    }
  }

  const getHeliportBookingsCompleted = (heliportBookings) => {
    const heliportBookingsCompleted = heliportBookings.filter((booking) => {
      return booking.state === 'completed'
    })
    return heliportBookingsCompleted
  }

  const getHeliportBookingsCancelled = (heliportBookings) => {
    const heliportBookingsCancelled = heliportBookings.filter((booking) => {
      return (
        booking.state === 'cancelled' ||
        booking.state === 'cancelled_by_customer' ||
        booking.state === 'declined'
      )
    })
    const heliportCanceledReschedule = heliportBookingsCancelled.filter(
      (booking) => {
        return !booking.meta.cancled_id && !booking.meta.reschedule_id
      }
    )
    return heliportCanceledReschedule
  }

  return (
    <View>
      {isLoading ? (
        <Loading />
      ) : bookingsCompleted.length === 0 && bookingsCancelled.length === 0 ? (
        <View>
          <Heading p="1%">{t('manageReservationOperator.title')}</Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <Text>{t('manageReservationOperator.noBooking')}</Text>
          </Box>
        </View>
      ) : (
        <View>
          <Heading p="1%">{t('manageReservationOperator.title')}</Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <BookingsIsCancelled booking={bookingsCancelled} />
            <p />
            <BookingsIsCompleted booking={bookingsCompleted} />
          </Box>
          <p />
        </View>
      )}
    </View>
  )
}
export default PastBookingsOperator
