import React from 'react'
import {
  View,
  HStack,
  Box,
  Checkbox,
  Button,
  Text,
  VStack,
  Center,
  Divider,
  NativeBaseProvider,
  Stack,
  Progress,
  Heading,
  ArrowForwardIcon,
  Modal,
} from 'native-base'
import { useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_MANAGER } from '../graphql/queries'
import { getToken } from '../Token'
import SundayHours from '../components/HeliportDays/SundayHours'
import MondayHours from '../components/HeliportDays/MondayHours'
import TuesdayHours from '../components/HeliportDays/TuesdayHours'
import WednesdayHours from '../components/HeliportDays/WednesdayHours'
import ThursdayHours from '../components/HeliportDays/ThursdayHours'
import FridayHours from '../components/HeliportDays/FridayHours'
import SaturdayHours from '../components/HeliportDays/SaturdayHours'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ModalError from '../components/ModalError'
import axiosInstance from '../AxiosInstance'
import { auth } from '../utils/nhost'
import { useHistory } from '../routing'

export const HeliportDaysandHours = (props) => {
  const [checkedSaturday, setCheckedSaturday] = React.useState('')
  const [checkedSunday, setCheckedSunday] = React.useState('')
  const [checkedMonday, setCheckedMonday] = React.useState('')
  const [checkedTuesday, setCheckedTuesday] = React.useState('')
  const [checkedWednesday, setCheckedWednesday] = React.useState('')
  const [checkedThursday, setCheckedThursday] = React.useState('')
  const [checkedFriday, setCheckedFriday] = React.useState('')
  const [days, setDays] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [heliportProfileId, setHeliportProfileId] = React.useState('')
  const [timekitProjectId, setTimekitProjectId] = React.useState('')
  const [currency, setCurrency] = React.useState('')
  const [heliportAviabilities, setHeliportAviabilities] = React.useState([])
  const [aviabilitiesGroup, setHeliportAviabilitiesGroup] = React.useState({})
  const emptyDays = []
  const { t } = useTranslation()
  const [buttonEditLoading, setButtonEditLoading] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [user, setUser] = React.useState('')
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  let history = useHistory()

  const [heliport] = useLazyQuery(GET_HELIPORT_MANAGER, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUser(userId)
    const getManager = await getManagerQuery(userId)

    setHeliportProfileId(getManager.heliports[0].heliport_profile.id)
    setTimekitProjectId(
      getManager.heliports[0].heliport_profile.timekit_project_id
    )
    const getCurrency = getCurrencyHeliport(
      getManager.heliports[0].heliport_profile.heliport_currency
    )
    const getAviabilitiesByDay = heliportAviabilitiesGroupByDay(
      getManager.heliports[0].heliport_profile.heliport_aviabilities
    )
    setHeliportAviabilitiesGroup(getAviabilitiesByDay)
    setHeliportAviabilities(
      getManager.heliports[0].heliport_profile.heliport_aviabilities
    )
    setCurrency(getCurrency)
    setLoading(false)
  }, [])

  const getManagerQuery = async (userId) => {
    const managerHeliport = await heliport({
      variables: { userId: userId },
    })
    return managerHeliport.data
  }

  const heliportAviabilitiesGroupByDay = (aviabilities) => {
    const newArrayAviabilities = aviabilities.map((aviability) => {
      return {
        booking_graph: aviability.booking_graph,
        endHour: moment(aviability.end_hour, 'HH:mm'),
        startHour: moment(aviability.start_hour, 'HH:mm'),
        currency: aviability.currency,
        price: aviability.price,
        day: aviability.day,
        id: aviability.day,
      }
    })
    let aviabilityGroup = _.groupBy(newArrayAviabilities, 'day')
    return aviabilityGroup
  }

  const getCurrencyHeliport = (currency) => {
    if (currency === '$') {
      return 'usd'
    } else if (currency == 'R$') {
      return 'brl'
    } else if (currency === 'MX$') {
      return 'mxn'
    }
  }

  const onSaveDay = (day) => {
    if (days.length === 0) {
      const availabilityDays = [...days, day]
      setDays(availabilityDays)
    } else {
      const weekendDay = Object.keys(day)
      const existsDayInArray = days.some((value) =>
        Object.keys(value).some((key) => key === weekendDay[0])
      )
      if (existsDayInArray === false) {
        const availabilityDays = [...days, day]
        setDays(availabilityDays)
      } else {
        const lastDayIndex = days.findIndex((day) => {
          return Object.keys(day).some((key) => key === weekendDay[0])
        })
        if (lastDayIndex !== -1) {
          days[lastDayIndex] = day
          setDays(days)
        }
      }
    }
  }

  const createAviability = async () => {
    setButtonLoading(true)
    try {
      const token = auth.getJWTToken()
      await axiosInstance.post(`/heliportDays/createDaysandHours`, {
        days: days,
        heliportProfileId: heliportProfileId,
        currency: currency,
        timekitProjectId: timekitProjectId,
        token: token,
      })
      setButtonLoading(false)
      if (props.edit === true) {
        props.onSaveAction()
      } else {
        history.push('/bankInformationManager')
      }
    } catch (error) {
      setButtonLoading(false)
      setError(t('editAvailability.errorSave'))
      openError()
    }
  }

  const editAviability = async () => {
    setButtonEditLoading(true)
    try {
      const token = auth.getJWTToken()
      await axiosInstance.put(`/heliportDays/updateDaysandHours`, {
        days: days,
        heliportProfileId: heliportProfileId,
        currency: currency,
        timekitProjectId: timekitProjectId,
        token: token,
      })

      setButtonEditLoading(false)
      props.onSaveAction()
    } catch (error) {
      setButtonEditLoading(false)
      setError(t('editAvailability.errorEdit'))
      openError()
    }
  }

  const editAviabilitySignUp = async () => {
    setButtonEditLoading(true)
    try {
      const token = auth.getJWTToken()
      await axiosInstance.put(`/heliportDays/updateDaysandHours`, {
        days: days,
        heliportProfileId: heliportProfileId,
        currency: currency,
        timekitProjectId: timekitProjectId,
        token: token,
      })

      setButtonEditLoading(false)
      history.push('/bankInformationManager')
    } catch (error) {
      setButtonEditLoading(false)
      setError(t('editAvailability.errorEdit'))
      openError()
    }
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  if (loading) return <Loading />
  return (
    <View>
      {heliportAviabilities.length > 0 ? (
        <View pl="10%" pr="10%" pb="10%" pt="5%">
          <NativeBaseProvider>
            {props.edit === false ? (
              <View>
                <Center>
                  <Box w={['90%', '90%', '500', '500']}>
                    <Progress
                      bg="yellow.100"
                      _filledTrack={{
                        bg: 'yellow.400',
                      }}
                      size="md"
                      value={33}
                    />
                  </Box>
                  <br />
                  <Heading size="xs">{t('heliportForm.step')} 2 / 3</Heading>
                </Center>
                <View style={{ alignItems: 'self-end' }}>
                  <Button
                    isDisabled={days.length === 0 ? true : false}
                    colorScheme="yellow"
                    rounded="2xl"
                    width="10"
                    leftIcon={<ArrowForwardIcon size="4" />}
                    onPress={() => history.push('/bankInformationManager')}
                  />
                </View>
              </View>
            ) : (
              <View></View>
            )}
            <Box rounded="xl" bgColor="#f2f2f2" p="9" shadow="9">
              <Center>
                <VStack space={5}>
                  <Stack
                    space={[2, 2, 2, 40]}
                    p="3%"
                    pl="8%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Text bold>{t('editAvailability.day')}</Text>
                    <Text bold>{t('editAvailability.hour')}</Text>
                    <Text bold>{t('editAvailability.price')}</Text>
                    <Text bold>{t('editAvailability.approval')}</Text>
                  </Stack>
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.sunday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedSunday}
                            onChange={(nextValue) => {
                              setCheckedSunday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.sunday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <SundayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.sunday
                            ? aviabilitiesGroup.sunday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.monday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedMonday}
                            onChange={(nextValue) => {
                              setCheckedMonday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.monday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <MondayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.monday
                            ? aviabilitiesGroup.monday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.tuesday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedTuesday}
                            onChange={(nextValue) => {
                              setCheckedTuesday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.tuesday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <TuesdayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.tuesday
                            ? aviabilitiesGroup.tuesday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.wednesday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedWednesday}
                            onChange={(nextValue) => {
                              setCheckedWednesday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.wednesday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <WednesdayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.wednesday
                            ? aviabilitiesGroup.wednesday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.thursday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedThursday}
                            onChange={(nextValue) => {
                              setCheckedThursday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.thursday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <ThursdayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.thursday
                            ? aviabilitiesGroup.thursday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.friday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedFriday}
                            onChange={(nextValue) => {
                              setCheckedFriday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.friday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <FridayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.friday
                            ? aviabilitiesGroup.friday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <VStack>
                      {!aviabilitiesGroup.saturday ? (
                        <HStack space={5} pt="6">
                          <Checkbox
                            colorScheme="yellow"
                            value={checkedSaturday}
                            onChange={(nextValue) => {
                              setCheckedSaturday(nextValue)
                            }}
                            accessibilityLabel="Check day"
                          />
                          <Text>{t('editAvailability.saturday')}</Text>
                        </HStack>
                      ) : (
                        <View></View>
                      )}
                      <SaturdayHours
                        onEdit={onSaveDay}
                        onAction={onSaveDay}
                        currency={currency}
                        daysHours={
                          aviabilitiesGroup.saturday
                            ? aviabilitiesGroup.saturday
                            : emptyDays
                        }
                      />
                    </VStack>
                  </Stack>
                  {props.edit === false ? (
                    <Stack
                      space={10}
                      direction={['column', 'column', 'column', 'row']}
                    >
                      <Button
                        isDisabled={days.length === 0 ? true : false}
                        isLoading={buttonEditLoading}
                        _loading={{
                          bg: 'amber.400:alpha.70',
                          _text: {
                            color: 'coolGray.700',
                          },
                        }}
                        _spinner={{
                          color: 'white',
                        }}
                        isLoadingText={t('submit_button.text')}
                        onPress={() => editAviabilitySignUp()}
                        colorScheme="yellow"
                      >
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('editAvailability.buttons.edit')}
                        </Text>
                      </Button>
                    </Stack>
                  ) : (
                    <Stack
                      space={10}
                      direction={['column', 'column', 'column', 'row']}
                    >
                      <Button
                        isDisabled={days.length === 0 ? true : false}
                        isLoading={buttonEditLoading}
                        _loading={{
                          bg: 'amber.400:alpha.70',
                          _text: {
                            color: 'coolGray.700',
                          },
                        }}
                        _spinner={{
                          color: 'white',
                        }}
                        isLoadingText={t('submit_button.text')}
                        onPress={() => editAviability()}
                        colorScheme="yellow"
                      >
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('editAvailability.buttons.edit')}
                        </Text>
                      </Button>
                    </Stack>
                  )}
                </VStack>
              </Center>
            </Box>
          </NativeBaseProvider>
        </View>
      ) : (
        <View
          pl={['10%', '10%', '10%', '5%']}
          pr={['10%', '10%', '10%', '5%']}
          pb="10%"
          pt="5%"
        >
          <NativeBaseProvider>
            {props.edit === false ? (
              <View>
                <Center>
                  <Box w={['90%', '90%', '500', '500']}>
                    <Progress
                      bg="yellow.100"
                      _filledTrack={{
                        bg: 'yellow.400',
                      }}
                      size="md"
                      value={33}
                    />
                  </Box>
                  <br />
                  <Heading size="xs">{t('heliportForm.step')} 2 / 3</Heading>
                </Center>
                <View style={{ alignItems: 'self-end' }}>
                  <Button
                    isDisabled={days.length === 0 ? true : false}
                    colorScheme="yellow"
                    rounded="2xl"
                    width="10"
                    leftIcon={<ArrowForwardIcon size="4" />}
                    onPress={() => history.push('/bankInformationManager')}
                  />
                </View>
              </View>
            ) : (
              <View></View>
            )}

            <p />
            <Box rounded="xl" bgColor="#f2f2f2" p="9" shadow="9">
              <Center>
                <VStack space={5}>
                  <Stack
                    space={[2, 2, 2, 40]}
                    p="3%"
                    pl="8%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Text bold>{t('editAvailability.day')}</Text>
                    <Text bold>{t('editAvailability.hour')}</Text>
                    <Text bold>{t('editAvailability.price')}</Text>
                    <Text bold>{t('editAvailability.approval')}</Text>
                  </Stack>
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedSunday}
                        onChange={(nextValue) => {
                          setCheckedSunday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.sunday')}</Text>
                    </HStack>
                    <SundayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedMonday}
                        onChange={(nextValue) => {
                          setCheckedMonday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.monday')}</Text>
                    </HStack>
                    <MondayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedTuesday}
                        onChange={(nextValue) => {
                          setCheckedTuesday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.tuesday')}</Text>
                    </HStack>
                    <TuesdayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedWednesday}
                        onChange={(nextValue) => {
                          setCheckedWednesday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.wednesday')}</Text>
                    </HStack>
                    <WednesdayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedThursday}
                        onChange={(nextValue) => {
                          setCheckedThursday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.thursday')}</Text>
                    </HStack>
                    <ThursdayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedFriday}
                        onChange={(nextValue) => {
                          setCheckedFriday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.friday')}</Text>
                    </HStack>
                    <FridayHours onAction={onSaveDay} />
                  </Stack>
                  <Divider />
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <HStack space={5} pt="6">
                      <Checkbox
                        colorScheme="yellow"
                        value={checkedSaturday}
                        onChange={(nextValue) => {
                          setCheckedSaturday(nextValue)
                        }}
                        accessibilityLabel="Check day"
                      />
                      <Text>{t('editAvailability.saturday')}</Text>
                    </HStack>
                    <SaturdayHours onAction={onSaveDay} />
                  </Stack>
                  <Stack
                    space={10}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Button
                      isDisabled={days.length === 0 ? true : false}
                      isLoading={buttonLoading}
                      _loading={{
                        bg: 'amber.400:alpha.70',
                        _text: {
                          color: 'coolGray.700',
                        },
                      }}
                      _spinner={{
                        color: 'white',
                      }}
                      isLoadingText={t('submit_button.text')}
                      onPress={() => createAviability()}
                      colorScheme="yellow"
                    >
                      <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                        {t('editAvailability.buttons.save')}
                      </Text>
                    </Button>
                  </Stack>
                </VStack>
              </Center>
            </Box>
            <Modal isOpen={showError}>
              <ModalError error={error} onClose={closeError} />
            </Modal>
          </NativeBaseProvider>
        </View>
      )}
    </View>
  )
}
export default HeliportDaysandHours
