import React from 'react'
import {
  Alert,
  Button,
  HStack,
  Box,
  Text,
  View,
  Heading,
  Input,
  Image,
  Center,
  VStack,
  Modal,
  IconButton,
  CloseIcon,
} from 'native-base'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import login from '../assets/login.png'
import imgSrc from '../assets/logo_yellow.png'
import { StyleSheet } from 'react-native'
import { auth } from '../utils/nhost'
import { RESET_URL } from '@env'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const [show, setShow] = React.useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      identifier: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      const { error } = await auth.resetPassword({
        email: data.identifier,
        options: {
          redirectTo: RESET_URL,
        },
      })
      if (error) {
        console.log(error)
      } else {
        setShow(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <View>
      <Center p={['15%', '15%', '10%', '2%']}>
        <Image
          source={{ uri: imgSrc }}
          alt="Alternate Text"
          style={styles.stretch}
        />
      </Center>
      <Center>
        <Box maxWidth="700" bgColor="white">
          <VStack space="2">
            <Heading size="lg">{t('forgotPassword.title')}</Heading>
            <Text pb="2" color="muted.600">
              {t('forgotPassword.enterEmailAddress')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  rounded="2xl"
                  placeholder={t('forgotPassword.emailPlaceHolder')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                />
              )}
              name="identifier"
            />
            {errors.identifier && (
              <Text p="2" color="red.600" fontSize="xs">
                {errors.identifier.message}
              </Text>
            )}
            <br />
            <Button
              rounded="2xl"
              size={'md'}
              onPress={handleSubmit(onSubmit)}
              colorScheme="yellow"
            >
              <HStack
                style={{ alignItems: 'center', justifyContent: 'center' }}
                space={3}
              >
                <Text fontSize="16" fontWeight="medium">
                  {t('forgotPassword.send')}
                </Text>
                <Image
                  source={{
                    uri: login,
                  }}
                  alt="Alternate Text"
                  style={{ width: 20, height: 20 }}
                />
              </HStack>
            </Button>
          </VStack>
        </Box>
      </Center>
      <Modal isOpen={show}>
        <Alert status="info">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  We heard that you lost your password. Sorry about that!
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => setShow(false)}
              />
            </HStack>
            <Box
              pl="6"
              _text={{
                color: 'coolGray.600',
              }}
            >
              But don't worry! We send an email with a link to reset your
              password
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default ForgotPassword

const styles = StyleSheet.create({
  stretch: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
  },
})
