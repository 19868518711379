import React from 'react'
import {
  View,
  Box,
  Alert,
  Heading,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import BookingDetailPaymentInvoice from '../components/BookingDetailPaymentInvoice'
import { useLocation } from '../routing'
import Loading from '../components/Loading'
import axiosInstance from '../AxiosInstance'
import { useTranslation } from 'react-i18next'

export const ManualReservationInvoice = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [amount, setAmount] = React.useState(null)
  const [booking, setBooking] = React.useState({})
  const [error, setError] = React.useState(false)
  const { t } = useTranslation()

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const booking = query.get('booking')

    const operator = query.get('operator')

    await getConfirmReservation(booking, operator)
    setLoading(false)
  }, [])

  const getConfirmReservation = async (booking, operator) => {
    try {
      const confirmReservation = await axiosInstance.post(
        `/bookings/confirmBookingManualReservation`,
        {
          id: booking,
          operator: operator,
        }
      )
      const amountInformation = confirmReservation.data.booking.meta.price
      setAmount(amountInformation)
      setBooking(confirmReservation.data.booking)
    } catch (error) {
      setError(true)
    }
  }

  return (
    <View>
      <AppBarHeader />
      {loading ? (
        <Loading />
      ) : (
        <View pr="28%" pl="28%">
          {error ? (
            <Alert w="100%" status="error">
              <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                <Alert.Icon size="md" />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentSummary.errorBooking.title')}
                </Text>

                <Box
                  _text={{
                    textAlign: 'center',
                  }}
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  {t('paymentSummary.errorBooking.message')}
                </Box>
              </VStack>
            </Alert>
          ) : (
            <Center>
              <VStack space={5}>
                <Alert w="100%" status="success">
                  <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                    <Alert.Icon size="md" />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('paymentSummary.bookingReceived.title')}
                    </Text>

                    <Box
                      _text={{
                        textAlign: 'center',
                      }}
                      _dark={{
                        _text: {
                          color: 'coolGray.600',
                        },
                      }}
                    >
                      {t('paymentSummary.bookingReceived.yourBooking')}
                    </Box>
                  </VStack>
                </Alert>
                <Box bgColor="#e0e0e0" rounded="xl" shadow="9">
                  <Heading p="2" size="md">
                    {t('paymentSummary.bookingReceived.paymentsDetails')}
                  </Heading>
                  <View p="2">
                    <Box
                      bgColor="#ffffff"
                      rounded="xl"
                      shadow="9"
                      width="35%"
                      p="2"
                    >
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.amount')}
                      </Text>
                      <Text bold fontSize="2xl" p="2">
                        {booking.meta.currency === 'brl' ? 'R$' : '$'}
                        {amount}
                      </Text>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <BookingDetailPaymentInvoice booking={booking} />
                    </Box>
                  </View>
                </Box>
                <View p="2">
                  <Button
                    colorScheme="yellow"
                    onPress={() => {
                      props.history.push('/manageReservationOperator')
                    }}
                  >
                    {t('paymentSummary.bookingReceived.viewReservations')}
                  </Button>
                </View>
              </VStack>
            </Center>
          )}
        </View>
      )}
    </View>
  )
}
export default ManualReservationInvoice
