import React from 'react'
import { Provider, connect } from 'react-redux'
import { store } from './redux/Store'
import Routes from './components/Routes'
import { AbilityContext } from './Can'
import ability from './Ability'
import './i18n'
import timekit from 'timekit-sdk'
import { HASURA_GRAPHQL, TIMEKIT_API_KEY } from '@env'
import Footer from './components/Footer'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SENTRY_DSN_APP } from '@env'
const location = window.location.hostname
const userLang = navigator.language || navigator.userLanguage
import { getToken } from './Token'

Sentry.init({
  dsn: `${SENTRY_DSN_APP}`,
  integrations: [new BrowserTracing()],
  environment: location === 'localhost' ? 'development' : 'production',
  tracesSampleRate: 1.0,
})
Sentry.setContext('details', {
  Language: userLang,
})
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth, storage } from './utils/nhost'
import { NativeBaseProvider } from 'native-base'
import Locale from './Locale'

const httpLink = createHttpLink({
  uri: HASURA_GRAPHQL,
})

const authLink = setContext((_, { headers }) => {
  const jwt = auth.getJWTToken()

  if (jwt) {
    return {
      headers: {
        ...headers,
        Authorization: jwt ? `Bearer ${jwt}` : '',
      },
    }
  }
})
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})

// Overwrites default config with supplied object, possible keys with default values below
timekit.configure({
  apiBaseUrl: 'https://api.timekit.io/', // API endpoint (do not change)
  apiVersion: 'v2', // version of API to call (do not change
  convertResponseToCamelcase: false, // should keys in JSON response automatically be converted from snake_case to camelCase?
  convertRequestToSnakecase: true, // should keys in JSON requests automatically be converted from camelCase to snake_case?
  autoFlattenResponse: true,
  appKey: TIMEKIT_API_KEY,
})

// Returns current config object
timekit.getConfig()

export const HybridApp = () => {
  const [token, setToken] = React.useState('')
  React.useEffect(async () => {
    auth.onAuthStateChanged(async () => {
      const userToken = auth.getJWTToken()
      setToken(userToken)
      const tokenInformation = getToken(userToken)
      if (
        tokenInformation &&
        tokenInformation['https://hasura.io/jwt/claims'] &&
        tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
      ) {
        const userId =
          tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
        Sentry.setUser({ id: userId })
      }
    })

    await Locale()
  }, [])
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AbilityContext.Provider value={ability(token)}>
          <NativeBaseProvider>
            <Routes />
            <Footer />
          </NativeBaseProvider>
        </AbilityContext.Provider>
      </Provider>
    </ApolloProvider>
  )
}

export default HybridApp
