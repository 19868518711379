import * as React from 'react'
import {
  Menu,
  HamburgerIcon,
  Box,
  Pressable,
  Avatar,
  HStack,
  Image,
  Text,
  NativeBaseProvider,
} from 'native-base'
import { StyleSheet, View } from 'react-native'
import { Link, useHistory } from '../routing'
import { AbilityContext } from '../Can'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import imgSrc from '../assets/logo_yellow.png'
import mexFlag from '../assets/206600.png'
import usaFlag from '../assets/330459.png'
import brazilFlag from '../assets/3022546.png'
import { auth } from '../utils/nhost'

const AppBarHeader = (props) => {
  const [urlFlag, setUrlFlag] = React.useState('')
  const ability = React.useContext(AbilityContext)
  const { t, i18n } = useTranslation()
  React.useEffect(() => {
    getLanguaje()
  }, [])
  async function getLanguaje() {
    const response = await AsyncStorage.getItem('flag')
    if (!response) {
      setUrlFlag('en')
    } else {
      setUrlFlag(response)
    }
  }
  let history = useHistory()

  const goToCalendarReservations = () => {
    history.push('/calendarView')
  }
  const goToReservationOperator = () => {
    history.push('/manageReservationOperator')
  }
  const goToCompanyProfile = () => {
    history.push('/companyProfile')
  }
  const goToManageReservation = () => {
    history.push('/')
  }

  const goToHeliport = () => {
    history.push('/manageHeliport')
  }

  const goToFleet = () => {
    history.push('/fleet')
  }
  const goToPilots = () => {
    history.push('/pilots')
  }
  const goToRegister = () => {
    history.push('/auth/register')
  }
  const goToLogin = () => {
    history.push('/auth/login')
  }

  const goToAccountManager = () => {
    history.push('/managerAccountMenu')
  }

  const goToAccountOperator = () => {
    history.push('/operatorAccountMenu')
  }

  const logoutOperator = async () => {
    await auth.signOut()
    history.push('/')
  }

  const logout = async () => {
    await auth.signOut()
    history.push('/')
  }

  const changeLanguageEnglish = async () => {
    await AsyncStorage.setItem('currency', 'usd')
    await AsyncStorage.setItem('flag', 'en')
    i18n.changeLanguage('en')
    window.location.reload(false)
  }
  const changeLanguageSpanish = async () => {
    await AsyncStorage.setItem('currency', 'mxn')
    await AsyncStorage.setItem('flag', 'es')
    i18n.changeLanguage('es')
    window.location.reload(false)
  }
  const changeLanguagePortuguese = async () => {
    await AsyncStorage.setItem('currency', 'brl')
    await AsyncStorage.setItem('flag', 'pt')
    i18n.changeLanguage('pt')
    window.location.reload(false)
  }
  return (
    <View bgColor="light.100" style={[styles.parent]}>
      <View style={styles.childFirst}>
        <Link to="/">
          <Image
            source={{ uri: imgSrc }}
            alt="Alternate Text"
            style={styles.stretch}
          />
        </Link>
      </View>
      <View>
        <HStack space={3} alignItems="center" pl={[1, 1, 100, 342]} pt={13}>
          {ability.can('view', 'OperatorMenu') && (
            <Box h="80%" w="90%" alignItems="flex-end">
              <Menu
                w={[150, 150, 170, 190]}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HamburgerIcon />
                    </Pressable>
                  )
                }}
              >
                <Menu.Item onPress={() => goToReservationOperator()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.operatorMenu.manageReservation')}
                  </Text>
                </Menu.Item>
                <Menu.Item onPress={() => goToFleet()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.operatorMenu.manageFleet')}
                  </Text>
                </Menu.Item>
                <Menu.Item onPress={() => goToPilots()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.operatorMenu.managePilots')}
                  </Text>
                </Menu.Item>
              </Menu>
            </Box>
          )}
          {ability.can('view', 'ManagerMenu') && (
            <Box h="80%" w="90%" alignItems="flex-end">
              <Menu
                w={[150, 150, 190, 190]}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HamburgerIcon />
                    </Pressable>
                  )
                }}
              >
                <Menu.Item onPress={() => goToManageReservation()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.managerMenu.manageReservation')}
                  </Text>
                </Menu.Item>
                <Menu.Item onPress={() => goToHeliport()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.managerMenu.manageHeliport')}
                  </Text>
                </Menu.Item>
                <Menu.Item onPress={() => goToCompanyProfile()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.managerMenu.manageCompany')}
                  </Text>
                </Menu.Item>
              </Menu>
            </Box>
          )}
          {ability.can('view', 'PublicMenu') && (
            <Box h="80%" w="90%" alignItems="flex-end">
              <Menu
                w={[100, 100, 190, 190]}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HamburgerIcon />
                    </Pressable>
                  )
                }}
              >
                <Menu.Item onPress={() => goToRegister()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.publicMenu.register')}
                  </Text>
                </Menu.Item>
                <Menu.Item onPress={() => goToLogin()}>
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                    {t('appBar.publicMenu.logIn')}
                  </Text>
                </Menu.Item>
              </Menu>
            </Box>
          )}
          {ability.can('view', 'OperatorMenu') && (
            <Menu
              w={[85, 85, 150, 190]}
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    accessibilityLabel="More options menu"
                    {...triggerProps}
                  >
                    <Box h="80%" w="90%" alignItems="flex-start" pt={1}>
                      <Avatar
                        bg="muted.50"
                        h={7}
                        w={7}
                        source={{
                          uri: 'https://cdn-icons-png.flaticon.com/128/64/64572.png',
                        }}
                      ></Avatar>
                    </Box>
                  </Pressable>
                )
              }}
            >
              <Menu.Item onPress={() => goToAccountOperator()}>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('appBar.operatorMenu.account')}
                </Text>
              </Menu.Item>
              <Menu.Item onPress={() => logoutOperator()}>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('appBar.operatorMenu.logOut')}
                </Text>
              </Menu.Item>
            </Menu>
          )}
          {ability.can('view', 'ManagerMenu') && (
            <Menu
              w={[85, 85, 150, 190]}
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    accessibilityLabel="More options menu"
                    {...triggerProps}
                  >
                    <Box h="80%" w="90%" alignItems="flex-start" pt={1}>
                      <Avatar
                        bg="muted.50"
                        h={7}
                        w={7}
                        source={{
                          uri: 'https://cdn-icons-png.flaticon.com/128/64/64572.png',
                        }}
                      ></Avatar>
                    </Box>
                  </Pressable>
                )
              }}
            >
              <Menu.Item onPress={() => goToAccountManager()}>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('appBar.managerMenu.account')}
                </Text>
              </Menu.Item>
              <Menu.Item onPress={() => logout()}>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('appBar.managerMenu.logOut')}
                </Text>
              </Menu.Item>
            </Menu>
          )}
          {ability.can('view', 'PublicMenu') && (
            <Link to="/auth/login">
              <Box h="80%" w="90%" alignItems="flex-start" pt={1}>
                <Avatar
                  bg="muted.50"
                  h={7}
                  w={7}
                  source={{
                    uri: 'https://cdn-icons-png.flaticon.com/128/64/64572.png',
                  }}
                ></Avatar>
              </Box>
            </Link>
          )}

          <Box h="80%" w="90%" alignItems="flex-start">
            <Menu
              w={[90, 90, 125, 190]}
              trigger={(triggerProps) => {
                if (urlFlag === 'es')
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HStack>
                        <Image
                          source={{ uri: mexFlag }}
                          alt="Alternate Text"
                          style={{ width: 30, height: 30 }}
                        />
                      </HStack>
                    </Pressable>
                  )
                else if (urlFlag === 'en')
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HStack>
                        <Image
                          source={{ uri: usaFlag }}
                          alt="Alternate Text"
                          style={{ width: 30, height: 30 }}
                        />
                      </HStack>
                    </Pressable>
                  )
                else if (urlFlag === 'pt')
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                    >
                      <HStack>
                        <Image
                          source={{ uri: brazilFlag }}
                          alt="Alternate Text"
                          style={{ width: 30, height: 30 }}
                        />
                      </HStack>
                    </Pressable>
                  )
              }}
            >
              <Menu.Item
                onPress={() => {
                  setUrlFlag('es')
                  changeLanguageSpanish()
                }}
              >
                <HStack>
                  <Image
                    source={{
                      uri: 'https://cdn-icons-png.flaticon.com/512/206/206600.png',
                    }}
                    alt="Alternate Text"
                    style={{ width: 30, height: 30 }}
                  />
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']} p="1">
                    {t('appBar.languageItems.mexico')}
                  </Text>
                </HStack>
              </Menu.Item>
              <Menu.Item
                onPress={() => {
                  setUrlFlag('pt')
                  changeLanguagePortuguese()
                }}
              >
                <HStack>
                  <Image
                    source={{
                      uri: 'https://cdn-icons-png.flaticon.com/512/3022/3022546.png',
                    }}
                    alt="Alternate Text"
                    style={{ width: 30, height: 30 }}
                  />
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']} p="1">
                    {t('appBar.languageItems.brazil')}
                  </Text>
                </HStack>
              </Menu.Item>
              <Menu.Item
                onPress={() => {
                  setUrlFlag('en')
                  changeLanguageEnglish()
                }}
              >
                <HStack>
                  <Image
                    source={{
                      uri: 'https://cdn-icons-png.flaticon.com/128/330/330459.png',
                    }}
                    alt="Alternate Text"
                    style={{ width: 30, height: 30 }}
                  />
                  <Text fontSize={['xs', 'sm', 'sm', 'sm']} p="1">
                    {t('appBar.languageItems.usa')}
                  </Text>
                </HStack>
              </Menu.Item>
            </Menu>
          </Box>
        </HStack>
      </View>
    </View>
  )
}

export default AppBarHeader
const styles = StyleSheet.create({
  stretch: {
    width: 450,
    height: 90,
    resizeMode: 'contain',
  },
  parent: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '10%',
    paddingRight: '10%',
    padding: 70,
  },
  childFirst: {
    width: '50%',
    height: '100%',
    paddingBottom: 50,
  },
})
