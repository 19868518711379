import AsyncStorage from '@react-native-async-storage/async-storage'

const phoneLanguage = async () => {
  const selectedLanguage = await AsyncStorage.getItem('flag')

  if (selectedLanguage) {
    if (selectedLanguage === 'es') {
      return 'mx'
    }
    if (selectedLanguage === 'en') {
      return 'us'
    }
    if (selectedLanguage === 'pt') {
      return 'br'
    }
  } else {
    return 'us'
  }
}

export default phoneLanguage
