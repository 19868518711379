import React from 'react'
import {
  View,
  Box,
  Heading,
  Text,
  HStack,
  Button,
  TextArea,
  VStack,
  Modal,
  Center,
  WarningTwoIcon,
} from 'native-base'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { useLazyQuery } from '@apollo/client'
import {
  GET_HELIPORT_MANAGER,
  GET_COMPANY_MANAGERS,
  GET_COMPANY_USERS,
} from '../../graphql/queries'
import { getToken } from '../../Token'
import '../../main.css'
import Loading from '../../components/Loading'
import { useHistory } from '../../routing'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../AxiosInstance'
import moment from 'moment-timezone'

const Actions = ({ bookingDetail, users }) => {
  const [visible, setIsVisible] = React.useState(false)
  const [bookingId, setBookingId] = React.useState('')
  const [reason, setReason] = React.useState('')
  const [error, setError] = React.useState('')
  const { t } = useTranslation()

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }

  const acepptBookings = async (id) => {
    try {
      await axiosInstance.post(`/bookings/accept`, {
        bookingId: id,
      })
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const reasons = (e) => {
    setReason(e.currentTarget.value)
  }

  const cancelBooking = async (id) => {
    try {
      await axiosInstance.post(`/bookings/cancel`, {
        bookingId: id,
      })
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBooking = async (id) => {
    try {
      await axiosInstance.post(`/bookings/cancel`, {
        bookingId: id,
      })
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const declineBooking = async () => {
    try {
      await axiosInstance.post(`/bookings/decline`, {
        booking_id: bookingId,
        reason: reason,
      })
      window.location.reload(false)
    } catch (error) {
      if (error.response.data.errors) {
        setError('Sorry, we can decline your reservation.')
      } else {
        setError(error.response.data.error.message)
      }
    }
  }

  if (
    bookingDetail.state === 'cancelled' ||
    bookingDetail.state === 'cancelled_by_customer' ||
    bookingDetail.state === 'declined' ||
    bookingDetail.state === 'completed'
  ) {
    return (
      <HStack space={3}>
        <Button
          size="xs"
          bgColor="danger.500"
          onPress={() => {
            deleteBooking(bookingDetail.id)
          }}
        >
          {t('calendarView.deleteButton')}
        </Button>
      </HStack>
    )
  } else if (
    bookingDetail.state === 'tentative' &&
    bookingDetail.meta.manual_reservation === true
  ) {
    return <View></View>
  } else if (bookingDetail.state === 'tentative') {
    return (
      <View>
        <HStack space={3}>
          <Button
            bgColor="yellow.300"
            size="xs"
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              acepptBookings(bookingDetail.id)
            }}
          >
            {t('calendarView.accept')}
          </Button>
          <Button
            bgColor="danger.500"
            size="xs"
            onPress={() => {
              setBookingId(bookingDetail.id), openModal()
            }}
          >
            {t('calendarView.reject')}
          </Button>
        </HStack>
        <Modal isOpen={visible} onClose={() => closeModal()} flex={1}>
          <Modal.Content maxWidth="350">
            <Modal.CloseButton />
            <Modal.Header>{t('calendarView.declineBooking')}</Modal.Header>
            <Modal.Body>
              <Text fontSize="md" fontWeight="bold">
                {t('calendarView.reason')}
              </Text>
              <TextArea value={reason} onChange={reasons} />
              <Text p="2" fontSize="xs" color="#eb0100">
                {error}
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    closeModal()
                  }}
                >
                  {t('calendarView.cancel')}
                </Button>
                <Button
                  colorScheme="yellow"
                  onPress={() => {
                    declineBooking()
                  }}
                >
                  {t('calendarView.save')}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    )
  } else {
    return (
      <HStack space={3}>
        <Button
          bgColor="danger.500"
          onPress={() => {
            cancelBooking(bookingDetail.id)
          }}
          size="xs"
        >
          {t('calendarView.cancelReservation')}
        </Button>
      </HStack>
    )
  }
}

export const CalendarView = (props) => {
  const [user, setUserId] = React.useState('')
  const [bookings, setBookings] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [visible, setIsVisible] = React.useState(false)
  const [bookingDetail, setBookingDetail] = React.useState({})
  const [usersManagers, setUsersManagers] = React.useState([])
  const [timezone, setTimezone] = React.useState('')
  const [error, setError] = React.useState('')
  let history = useHistory()
  const { t } = useTranslation()

  const [manager] = useLazyQuery(GET_HELIPORT_MANAGER, {
    variables: { userId: user },
  })
  const [managers] = useLazyQuery(GET_COMPANY_MANAGERS, {
    variables: { userId: user },
  })

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS)

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const getManagerProjectId = await manager()
    const timeKitProjectId =
      getManagerProjectId.data.heliports[0].heliport_profile.timekit_project_id
    await getBookingsManager(timeKitProjectId)
    const companyManagers = await managers()
    const getCompanyUsersEmails = await getUsersEmail(
      companyManagers.data.company[0].users_companies
    )
    const heliportTimeZone =
      companyManagers.data.company[0].heliports[0].heliport_profile.timezone
    setTimezone(heliportTimeZone)
    setUsersManagers(getCompanyUsersEmails)
    setIsLoading(false)
    const interval = setInterval(async () => {
      await getBookingsManager(timeKitProjectId, heliportTimeZone)
    }, 120000)
    return () => clearInterval(interval)
  }, [])

  const getBookingsManager = async (timeKitProjectId, heliportTimeZone) => {
    try {
      const getHeliportBookingsCancelandReject = await axiosInstance.post(
        `/bookings/getBookingsCancelandReject`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )
      const getHeliportBookingsCompleted = await axiosInstance.post(
        `/bookings/getBookingsCompleted`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )
      const getHeliportBookingsPending = await axiosInstance.post(
        `/bookings/getBookingsPending`,
        {
          timeKitProjectId: timeKitProjectId,
          timezone: heliportTimeZone,
        }
      )

      const getHeliportBookingsConfirmed = await axiosInstance.post(
        `/bookings/getBookingsConfirm`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )

      const getHeliportBookingsManualReservation = await axiosInstance.post(
        `/bookings/getBookingsManualRservation`,
        {
          timeKitProjectId: timeKitProjectId,
          timezone: heliportTimeZone,
        }
      )
      const getHeliportBookings = [
        ...getHeliportBookingsCancelandReject.data.bookings,
        ...getHeliportBookingsCompleted.data.bookings,
        ...getHeliportBookingsPending.data.bookings,
        ...getHeliportBookingsConfirmed.data.bookings,
        ...getHeliportBookingsManualReservation.data.bookings,
      ]
      const getProject = await axiosInstance.post(
        `/bookings/timeKitProjectId`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )

      const projectInformation = getProject.data.project

      const heliportBookings = getHeliportBookingsCalendar(getHeliportBookings)
      const blockPeriod = getBlockPeriodTime(
        projectInformation.availability_constraints
      )
      if (blockPeriod.length > 0) {
        const newArrayBlockPeriod = blockPeriod.map((block) => {
          const eventBlockPeriod = {
            id: 'block_period',
            title: 'Block period',
            borderColor: 'red',
            start: block.block_period.start,
            end: block.block_period.end,
            date: block.block_period.start,
            extendedProps: {
              state: 'block',
            },
          }
          return eventBlockPeriod
        })

        const newArrayEvents = [...heliportBookings, ...newArrayBlockPeriod]

        setBookings(newArrayEvents)
      } else {
        setBookings(heliportBookings)
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.bookings'))
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        setError(error.response.data.error.message)
      } else {
        setError(error.message)
      }
    }
  }

  const getUsersEmail = async (managersCompany) => {
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    return usersEmails
  }

  const getBlockPeriodTime = (availabilityConstraints) => {
    const blockPeriodObject = availabilityConstraints.filter((obj) => {
      return obj['block_period']
    })
    if (blockPeriodObject.length > 0) {
      return blockPeriodObject
    } else {
      return []
    }
  }

  const getHeliportBookingsCalendar = (heliportBookings) => {
    const heliportBookingsCalendar = heliportBookings.map((booking) => {
      const event = {
        id: booking.id,
        title: booking.attributes.customer.name,
        date: booking.attributes.event.start,
        borderColor: getColor(booking.state),
        start: booking.attributes.event.start,
        end: booking.attributes.event.end,
        extendedProps: {
          state: booking.state,
          tail_number: booking.attributes.customer.tail_number,
          meta: booking.meta,
          payment: booking.meta.paymentStatus
            ? getPaymentStatusStripe(booking)
            : getPaymentStatus(booking),
        },
      }
      return event
    })
    return heliportBookingsCalendar
  }

  const bookingStatus = {
    tentative: t('calendarView.tentative'),
    confirmed: t('calendarView.confirmed'),
    cancelled_by_customer: t('calendarView.cancelledCustomer'),
    cancelled: t('calendarView.cancelled'),
    declined: t('calendarView.declined'),
    completed: t('calendarView.completed'),
  }

  const getColor = (state) => {
    if (state === 'confirmed') {
      return 'green'
    } else if (state === 'tentative') {
      return 'yellow'
    } else if (state === 'completed') {
      return 'blue'
    } else {
      return 'red'
    }
  }

  const StateIcon = ({ state, meta }) => {
    if (state === 'confirmed') {
      return (
        <Box rounded="xl" bgColor="#3ebe6c">
          <Text p="2" fontSize="xs" color="white">
            {t('calendarView.approved')}
          </Text>
        </Box>
      )
    } else if (state === 'tentative' && meta.manual_reservation === true) {
      return (
        <Box rounded="xl" bgColor="#fea25f">
          <Text p="2" fontSize="xs" color="white">
            Manual Reservation
          </Text>
        </Box>
      )
    } else if (state === 'tentative') {
      return (
        <Box rounded="xl" bgColor="#deb104">
          <Text p="2" fontSize="xs" color="white">
            {t('calendarView.pendingApproval')}
          </Text>
        </Box>
      )
    } else if (state === 'completed') {
      return (
        <Box rounded="xl" bgColor="#696969">
          <Text p="2" fontSize="xs" color="white">
            {t('calendarView.completed')}
          </Text>
        </Box>
      )
    } else if (state === 'block') {
      return (
        <Box rounded="xl" bgColor="black">
          <Text p="2" fontSize="xs" color="white">
            {t('calendarView.blockPeriod')}
          </Text>
        </Box>
      )
    } else {
      return (
        <Box rounded="xl" bgColor="#f20100">
          <Text p="2" fontSize="xs" color="white">
            {t('calendarView.cancelled')}
          </Text>
        </Box>
      )
    }
  }

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <VStack space={2}>
          <Center>
            <Text fontSize="xs">{eventInfo.timeText}</Text>
          </Center>
          <Text fontSize="xs">{eventInfo.event.title}</Text>
          <Text fontSize="xs">{eventInfo.event.extendedProps.tail_number}</Text>
          <StateIcon
            state={eventInfo.event.extendedProps.state}
            meta={eventInfo.event.extendedProps.meta}
          />
        </VStack>
      </>
    )
  }
  const getDate = (bookingDate, timezone) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate, timezone) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate, timezone) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  const handleDateClick = (arg) => {
    const bookingDetail = {
      id: arg.event.id,
      operator: arg.event.title,
      date: getDate(arg.event.start, timezone),
      start: getStartBooking(arg.event.start, timezone),
      end: getEndBooking(arg.event.end, timezone),
      tail_number: arg.event.extendedProps.tail_number,
      state: arg.event.extendedProps.state,
      meta: arg.event.extendedProps.meta,
      payment: arg.event.extendedProps.payment,
    }
    setBookingDetail(bookingDetail)
    window.scrollTo(0, 0)
    openModal()
  }

  const goToBookingDetail = (id) => {
    document.body.style.overflow = 'scroll'
    history.push(`/bookingDetail/${id}`)
  }

  const l = useTranslation()
  const language = l.i18n.languages[0]
  const calendarLanguage = (language) => {
    if (language === 'es') {
      return 'es'
    }
    if (language === 'en') {
      return 'en'
    }
    if (language === 'pt') {
      return 'pt'
    }
  }
  const w = window.innerWidth
  if (error) return <Text>{error}</Text>
  return (
    <View>
      <HStack>
        <View
          width="100%"
          pl={['10%', '10%', '5%', '10%']}
          pr={['10%', '10%', '5%', '10%']}
        >
          {isLoading ? (
            <Loading />
          ) : bookings.length === 0 ? (
            <View>
              <Heading p="1%">{t('calendarView.booking')}</Heading>
              <Box p="12" rounded="xl" bgColor="gray.200">
                <Text>{t('calendarView.notBooking')}</Text>
              </Box>
            </View>
          ) : (
            <>
              <View p="4">
                <FullCalendar
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    timeGridPlugin,
                    listPlugin,
                  ]}
                  initialView={w <= 412 ? 'listWeek' : 'dayGridWeek'}
                  events={bookings}
                  eventContent={renderEventContent}
                  eventClick={handleDateClick}
                  headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,listDay,dayGridWeek',
                  }}
                  timeZone={timezone}
                  height="auto"
                  slotEventOverlap={false}
                  selectMirror={true}
                  selectable={true}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  }}
                  locale={calendarLanguage(language)}
                  views={{
                    dayGridMonth: { buttonText: t('calendarView.month') },
                    listDay: { buttonText: t('calendarView.day') },
                    dayGridWeek: { buttonText: t('calendarView.week') },
                  }}
                />
                <Modal
                  isOpen={visible}
                  onClose={() => closeModal()}
                  flex={1}
                  safeAreaTop={true}
                >
                  {bookingDetail.state === 'block' ? (
                    <Modal.Content maxWidth="500">
                      <Modal.CloseButton />
                      <Modal.Header>{t('calendarView.block')}</Modal.Header>
                      <Modal.Body>
                        <Center>
                          <HStack space={3}>
                            <WarningTwoIcon />
                            <Text fontSize="md" fontWeight="bold" p="2">
                              {t('calendarView.blockedTime')}
                            </Text>
                          </HStack>
                        </Center>
                      </Modal.Body>
                      <Modal.Footer></Modal.Footer>
                    </Modal.Content>
                  ) : (
                    <Modal.Content maxWidth="500">
                      <Modal.CloseButton />
                      <Modal.Header>
                        {t('calendarView.bookingModal')}
                      </Modal.Header>
                      <Modal.Body>
                        <HStack space={3}>
                          <Text fontSize="md" fontWeight="bold">
                            {bookingDetail.date}
                          </Text>
                          <Text fontSize="md" fontWeight="bold">
                            {bookingDetail.start}
                          </Text>
                          <Text fontSize="md" fontWeight="bold">
                            -
                          </Text>
                          <Text fontSize="md" fontWeight="bold">
                            {bookingDetail.end}
                          </Text>
                        </HStack>
                        <HStack space={2}>
                          <Text fontWeight="bold">
                            {t('calendarView.operator')}
                          </Text>
                          <Text>{bookingDetail.operator}</Text>
                        </HStack>
                        <HStack space={2}>
                          <Text fontWeight="bold">
                            {t('calendarView.tailNumber')}
                          </Text>
                          <Text>{bookingDetail.tail_number}</Text>
                        </HStack>
                        <HStack space={2}>
                          <Text fontWeight="bold">
                            {t('calendarView.status')}
                          </Text>
                          <Text>{bookingStatus[bookingDetail.state]}</Text>
                        </HStack>
                        <HStack space={2}>
                          <Text fontWeight="bold">
                            {t('calendarView.payment')}
                          </Text>
                          <Text>{bookingDetail.payment}</Text>
                        </HStack>
                        <Text
                          bold
                          underline
                          p="3"
                          onPress={() => {
                            goToBookingDetail(bookingDetail.id)
                          }}
                        >
                          {t('calendarView.viewDetails')}
                        </Text>
                      </Modal.Body>
                      <Modal.Footer>
                        <Actions
                          bookingDetail={bookingDetail}
                          users={usersManagers}
                        />
                      </Modal.Footer>
                    </Modal.Content>
                  )}
                </Modal>
              </View>
            </>
          )}
        </View>
      </HStack>
    </View>
  )
}
export default CalendarView
