import React from 'react'
import {
  View,
  Text,
  Box,
  Heading,
  Divider,
  HStack,
  VStack,
  Container,
  Stack,
  NativeBaseProvider,
} from 'native-base'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import { GET_OPERATOR } from '../../graphql/queries'
import { useHistory } from '../../routing'
import { useLazyQuery } from '@apollo/client'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import axiosInstance from '../../AxiosInstance'

const Bookings = ({ booking }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const goToBookingDetail = (id) => {
    history.push(`/bookingOperatorDetail/${id}`)
  }

  const getDate = (bookingDate, timezone) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate, timezone) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate, timezone) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const bookingStatus = {
    tentative: t('manageReservationOperator.bookingTentative'),
    confirmed: t('manageReservationOperator.bookingConfirmed'),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  return (
    <View>
      <NativeBaseProvider>
        <Box
          p={['4', '4', '10', '10']}
          rounded="xl"
          backgroundColor="light.100"
        >
          <Text p="1%" fontSize="md" fontWeight="bold">
            {t('manageReservationOperator.upcomingBookings.upcomingBookings')}
          </Text>
          <FlatList
            data={booking}
            keyExtractor={(item) => item.id.toString()}
            ItemSeparatorComponent={() => <br />}
            renderItem={({ item }) => (
              <Box
                p={['4', '4', '12', '12']}
                rounded="xl"
                backgroundColor="white"
                key={item.id.toString()}
              >
                <Stack
                  space={3}
                  direction={['column', 'column', 'column', 'row']}
                >
                  <Container width={['100%', '100%', '100%', '25%']}>
                    <View>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'manageReservationOperator.upcomingBookings.reservation.title'
                        )}
                      </Text>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.upcomingBookings.reservation.date'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {getDate(
                            item.attributes.event.start,
                            item.meta && item.meta.timezone
                              ? item.meta.timezone
                              : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.upcomingBookings.reservation.start'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {getStartBooking(
                            item.attributes.event.start,
                            item.meta && item.meta.timezone
                              ? item.meta.timezone
                              : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'manageReservationOperator.upcomingBookings.reservation.end'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {getEndBooking(
                            item.attributes.event.end,
                            item.meta && item.meta.timezone
                              ? item.meta.timezone
                              : undefined
                          )}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          Helipad:
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.attributes.event.what}
                        </Text>
                      </HStack>
                    </View>

                    <Divider bg="yellow.400" />
                  </Container>
                  <Container width={['100%', '100%', '100%', '25%']}>
                    <VStack space={3}>
                      <View>
                        <Text
                          fontSize={['xs', 'sm', 'sm', 'md']}
                          fontWeight="bold"
                        >
                          {t(
                            'manageReservationOperator.upcomingBookings.customers.title'
                          )}
                        </Text>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.upcomingBookings.customers.operator'
                            )}
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {item.meta && item.meta.name
                              ? item.meta.name
                              : item.attributes.customer.name}
                          </Text>
                        </HStack>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.upcomingBookings.customers.aircraftType'
                            )}
                          </Heading>
                          <Text></Text>
                        </HStack>
                        <HStack space={3}>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'manageReservationOperator.upcomingBookings.customers.tailNumbers'
                            )}
                          </Heading>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {item.meta && item.meta.tail_number
                              ? item.meta.tail_number
                              : item.attributes.customer
                              ? item.attributes.customer.tail_number
                              : ''}
                          </Text>
                        </HStack>
                      </View>
                    </VStack>
                    <Divider bg="yellow.400" />
                  </Container>
                  <Container width={['100%', '100%', '100%', '20%']}>
                    <Text fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
                      {t(
                        'manageReservationOperator.upcomingBookings.status.title'
                      )}
                    </Text>
                    <HStack space={2}>
                      <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                        {t(
                          'manageReservationOperator.upcomingBookings.status.booking'
                        )}
                      </Heading>
                      <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                        {bookingStatus[item.state]}
                      </Text>
                    </HStack>
                    <HStack space={2}>
                      <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                        {t(
                          'manageReservationOperator.upcomingBookings.status.payment'
                        )}
                      </Heading>
                      <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                        {item.meta.paymentStatus
                          ? getPaymentStatusStripe(item)
                          : getPaymentStatus(item)}
                      </Text>
                    </HStack>
                    <Divider bg="yellow.400" />
                  </Container>
                  <Container width="20%" height="100%">
                    <Text fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
                      {t(
                        'manageReservationOperator.upcomingBookings.actions.title'
                      )}
                    </Text>
                    <View>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'sm']}
                        bold
                        underline
                        onPress={() => {
                          goToBookingDetail(item.id)
                        }}
                      >
                        {t(
                          'manageReservationOperator.upcomingBookings.actions.viewDetails'
                        )}
                      </Text>
                    </View>
                  </Container>
                </Stack>
              </Box>
            )}
          />
        </Box>
      </NativeBaseProvider>
    </View>
  )
}

export const CurrentBookingsOperator = () => {
  const [user, setUserId] = React.useState('')
  const [bookings, setBookings] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState('')
  const { t } = useTranslation()

  const [operator] = useLazyQuery(GET_OPERATOR, {
    variables: { userId: user },
    fetchPolicy: 'network-only',
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const operatorInformation = await operator()
    await getBookingsOperator(operatorInformation)
    setIsLoading(false)
    const interval = setInterval(async () => {
      await getBookingsOperator(operatorInformation)
    }, 120000)
    return () => clearInterval(interval)
  }, [])

  const getBookingsOperator = async (operatorInformation) => {
    try {
      const getHeliportBookingsOperator = await axiosInstance.post(
        `/bookings/getBookingsOperator`,
        {
          operatorId: operatorInformation.data.operators[0].id,
        }
      )

      const heliportBookings = getHeliportCurrentBookings(
        getHeliportBookingsOperator.data.bookings
      )
      setBookings(heliportBookings)
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('errors.bookings'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('errors.bookings'))
      }
    }
  }

  const getHeliportCurrentBookings = (heliportBookings) => {
    const heliportBookingsPending = heliportBookings.filter((booking) => {
      const currentDate = moment().tz(booking.meta.timezone)
      const milliSecondCurrent = moment(currentDate).format('x')

      const dateStartBooking = booking.attributes.event.start
      const milliSecondStartBooking = moment(dateStartBooking).format('x')

      return (
        booking.state === 'tentative' &&
        milliSecondStartBooking > milliSecondCurrent
      )
    })
    const heliportBookingsConfirmed = heliportBookings.filter((booking) => {
      return booking.state === 'confirmed'
    })

    const bookingsOperator = [
      ...heliportBookingsPending,
      ...heliportBookingsConfirmed,
    ]

    return bookingsOperator
  }
  if (error) return <Text>{error}</Text>
  return (
    <View>
      {isLoading ? (
        <Loading />
      ) : bookings.length === 0 ? (
        <View>
          <Heading p="1%">{t('manageReservationOperator.title')}</Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
              {t('manageReservationOperator.noBooking')}
            </Text>
          </Box>
          <p />
        </View>
      ) : (
        <View>
          <Heading p="1%">{t('manageReservationOperator.title')}</Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <Bookings booking={bookings} />
          </Box>
          <p />
        </View>
      )}
    </View>
  )
}
export default CurrentBookingsOperator
