import React from 'react'

import { View } from 'native-base'
import AppBarHeader from '../components/AppBar'
import BankInformation from '../components/ManagerPayments/BankInformation'

export const BankInformationManager = () => {
  return (
    <>
      <View>
        <AppBarHeader />
      </View>
      <View>
        <BankInformation />
      </View>
    </>
  )
}
export default BankInformationManager
