import React from 'react'
import { Text, HStack, VStack, Alert, IconButton, CloseIcon } from 'native-base'

export const ModalError = ({ error, onClose }) => {
  const closeModal = () => {
    onClose()
  }
  return (
    <Alert status="error">
      <VStack space={1} flexShrink={1} w="100%">
        <HStack
          flexShrink={1}
          space={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack flexShrink={1} space={2} alignItems="center">
            <Alert.Icon />
            <Text
              fontSize="md"
              fontWeight="medium"
              _dark={{
                color: 'coolGray.800',
              }}
            >
              {error}
            </Text>
          </HStack>
          <IconButton
            variant="unstyled"
            icon={<CloseIcon size="3" color="coolGray.600" />}
            onPress={() => closeModal()}
          />
        </HStack>
      </VStack>
    </Alert>
  )
}

export default ModalError
