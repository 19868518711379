import React from 'react'
import { StyleSheet } from 'react-native'
import {
  View,
  Box,
  Button,
  Heading,
  Image,
  Text,
  VStack,
  Select,
  CheckIcon,
  Center,
  NativeBaseProvider,
  Stack,
  ZStack,
  ScrollView,
  Pressable,
  HStack,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useHistory } from '../routing'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_HELIPORTS_CITIES,
  GET_HELIPORT_ISHIGHLIGHTED,
} from '../graphql/queries'
import { FlatList } from 'react-native-gesture-handler'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import logoCircle from '../assets/logo1.png'
import logoHeliport from '../assets/logo2.png'
import logoCharge from '../assets/logo3.png'
import { auth, storage } from '../utils/nhost'
import locationIcon from '../assets/location_icon_yellow.png'

export const HomeMvp = () => {
  let [country, setCountry] = React.useState('')
  let [city, setCity] = React.useState('')
  let history = useHistory()
  const { t } = useTranslation()
  const goToSearch = () => {
    history.push(`/searchHeliports?country=${country}&city=${city}`)
  }
  const { loading, error, data } = useQuery(GET_HELIPORTS_CITIES, {
    variables: { country: country },
  })

  const HeliportList = () => {
    const [loading, setLoading] = React.useState(true)
    const [heliports, setHeliports] = React.useState([])
    let history = useHistory()
    const { t } = useTranslation()

    const goToHeliport = (id) => {
      history.push(`/${id}`)
    }
    const [getHeliports] = useLazyQuery(GET_HELIPORT_ISHIGHLIGHTED)

    React.useEffect(async () => {
      const heliportsHighlighted = await getHeliports({
        variables: { isHighlighted: true },
      })
      const heliportsWithImage = await getHeliportsWithImage(
        heliportsHighlighted.data.heliports
      )
      setHeliports(heliportsWithImage)

      setLoading(false)
    }, [])

    const getHeliportsWithImage = async (heliports) => {
      const newArrayHeliports = await Promise.all(
        heliports.map(async (heliport) => {
          if (heliport.heliport_profile.heliport_image) {
            const fileId = heliport.heliport_profile.heliport_image.file_id
            const getUrl = await storage.getPresignedUrl({ fileId })
            const heliportInformation = {
              id: heliport.id,
              name: heliport.heliport_profile.name,
              city: heliport.heliport_profile.city,
              urlImage: getUrl.presignedUrl.url,
              icao_code: heliport.heliport_profile.icao_code,
            }
            return heliportInformation
          } else {
            const heliportInformation = {
              id: heliport.id,
              name: heliport.heliport_profile.name,
              city: heliport.heliport_profile.city,
              icao_code: heliport.heliport_profile.icao_code,
            }
            return heliportInformation
          }
        })
      )
      return newArrayHeliports
    }

    const FeaturedHelipads = () => {
      return (
        <Stack
          direction={['column', 'column', 'row']}
          overflow="hidden"
          width="100%"
          _light={{
            backgroundColor: 'muted.100',
          }}
          _dark={{
            backgroundColor: 'muted.100',
          }}
        >
          <Box p="12" bgColor="#e0e0e0" width="100%">
            <Center>
              <Heading size="lg">{t('homepage.featuredHelipads')}</Heading>
            </Center>
            <>
              {heliports.length === 0 ? (
                <View
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Text p="5">{t('homepage.notHelipads')}</Text>
                </View>
              ) : (
                <View>
                  <ScrollView
                    horizontal={true}
                    contentContainerStyle={{
                      flexGrow: 1,
                      justifyContent: 'center',
                    }}
                  >
                    <Center>
                      <FlatList
                        horizontal={true}
                        data={heliports}
                        keyExtractor={(item) => item.id.toString()}
                        renderItem={({ item }) => (
                          <View key={item.id.toString()} p="5">
                            <Pressable
                              onPress={() => {
                                goToHeliport(item.icao_code)
                              }}
                            >
                              <Center>
                                <Heading size="md" pt="2" pr="2" pl="2">
                                  {item.name}
                                </Heading>
                                <Heading size="md" pr="2" pl="2" pb="4">
                                  {item.icao_code}
                                </Heading>
                              </Center>
                              <Center>
                                <View style={styles.photoHelipad}>
                                  <Image
                                    source={{
                                      uri: item.urlImage
                                        ? item.urlImage
                                        : 'https://cdn.pixabay.com/photo/2017/07/24/22/27/h-2536255_960_720.jpg',
                                    }}
                                    rounded="xl"
                                    alt="Alternate Text"
                                    height="300"
                                    width="222"
                                  />
                                  <Center
                                    backgroundColor="light.100"
                                    position="absolute"
                                    bottom="4%"
                                    px="3"
                                    py="1.5"
                                    left={
                                      item.city.length >= 17 ? '10%' : '15%'
                                    }
                                    right={
                                      item.city.length >= 17 ? '10%' : '15%'
                                    }
                                    rounded="2xl"
                                  >
                                    <HStack space={1}>
                                      <View style={styles.locationIcon}>
                                        <Image
                                          source={{
                                            uri: locationIcon,
                                          }}
                                          alt="Alternate Text"
                                          style={{ width: 20, height: 20 }}
                                        />
                                      </View>
                                      <Text fontSize="12" bold>
                                        {item.city}
                                      </Text>
                                    </HStack>
                                  </Center>
                                </View>
                              </Center>
                            </Pressable>
                          </View>
                        )}
                      />
                    </Center>
                  </ScrollView>
                </View>
              )}
            </>
          </Box>
        </Stack>
      )
    }

    if (loading) return <Loading />
    return (
      <View style={styles.staticCards}>
        <View>
          <NativeBaseProvider>
            <Center flex={1}>
              <FeaturedHelipads />
            </Center>
          </NativeBaseProvider>
        </View>
      </View>
    )
  }

  const HeliportSearchBar = () => {
    return (
      <Stack
        direction={['column', 'column', 'row']}
        rounded="xl"
        overflow="hidden"
        p="12"
        space={8}
        width={['80', '80', '6/6']}
        shadow="9"
        _light={{
          backgroundColor: 'muted.100',
        }}
        _dark={{
          backgroundColor: 'muted.100',
        }}
      >
        <Stack>
          <Text pb="2" fontWeight="bold">
            {t('homepage.country')}
          </Text>
          <VStack alignItems="center" space={4}>
            <Box rounded="xl" bgColor="#e0e0e0">
              <Select
                width="250"
                variant="unstyled"
                selectedValue={country}
                accessibilityLabel="Country"
                placeholder={t('homepage.countryPlaceholder')}
                InputLeftElement={
                  <View style={styles.locationIconSearch}>
                    <Image
                      source={{
                        uri: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
                      }}
                      alt="Alternate Text"
                      style={{ width: 15, height: 15 }}
                    />
                  </View>
                }
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                style={{ marginBottom: '5px' }}
                onValueChange={(itemValue) => setCountry(itemValue)}
              >
                <Select.Item
                  label={t('appBar.language.brazil')}
                  value="Brazil"
                />
                <Select.Item
                  label={t('appBar.language.mexico')}
                  value="Mexico"
                />
                <Select.Item label={t('appBar.language.usa')} value="USA" />
              </Select>
            </Box>
          </VStack>
        </Stack>
        <View>
          <Text pb="2" fontWeight="bold">
            {t('homepage.city')}
          </Text>
          <VStack alignItems="center" space={4}>
            <Box rounded="xl" bgColor="#e0e0e0">
              <Select
                width="250"
                selectedValue={city}
                accessibilityLabel="City"
                placeholder={t('homepage.cityPlaceholder')}
                variant="unstyled"
                InputLeftElement={
                  <View style={styles.locationIconSearch}>
                    <Image
                      source={{
                        uri: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
                      }}
                      alt="Alternate Text"
                      style={{ width: 15, height: 15 }}
                    />
                  </View>
                }
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                style={{ marginBottom: '5px' }}
                onValueChange={(itemValue) => setCity(itemValue)}
              >
                {data.heliports.map((m, index) => {
                  return (
                    <Select.Item
                      label={m.heliport_profile.city}
                      value={m.heliport_profile.city}
                      key={index}
                    />
                  )
                })}
              </Select>
            </Box>
          </VStack>
        </View>
        <View pt="7">
          <Button
            bgColor="yellow.300"
            size="lg"
            pb={3}
            pl={10}
            pr={10}
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              goToSearch()
            }}
          >
            {t('homepage.searchButton')}
          </Button>
        </View>
      </Stack>
    )
  }
  const l = useTranslation()
  const language = l.i18n.languages[0]

  const Testimonals = () => {
    return (
      <Stack
        direction={['column', 'column', 'row', 'row']}
        overflow="hidden"
        width="100%"
      >
        <Box
          pl={['5%', '5%', '3%', '9%']}
          pr={['5%', '5%', '5%', '10%']}
          width="100%"
        >
          <Stack
            direction={['column', 'column', 'row', 'row']}
            width="100%"
            space={5}
          >
            <Box
              p={['1', '1', '1', '8']}
              bgColor="#f2f2f2"
              rounded="xl"
              width={['100%', '100%', '50%', '50%']}
            >
              <VStack space={3}>
                <ZStack>
                  {language === 'pt' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['280', '280', '280', '395']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'es' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['290', '290', '285', '300']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'en' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['280', '280', '285', '410']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  <Text
                    bold
                    fontSize={['md', 'md', 'md', '2xl']}
                    pt="5"
                    pr="5"
                    pl="5"
                  >
                    {t('homepage.firstMessage.message')}
                  </Text>
                </ZStack>
                <p />
                <ZStack>
                  {language === 'pt' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['105', '105', '105', '140']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'es' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['105', '105', '105', '140']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'en' ? (
                    <Box
                      bg="#F9D342"
                      size="2"
                      height={['3', '3', '3', '5']}
                      width={['140', '140', '135', '190']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  <Text bold fontSize={['md', 'md', 'md', '2xl']} pl="5">
                    {t('homepage.firstMessage.use')}
                  </Text>
                </ZStack>
                <p />
                <Text
                  fontWeight="medium"
                  fontSize={['xs', 'xs', 'md', 'md']}
                  pb="5"
                  pr="5"
                  pl="5"
                >
                  Danielle, <br />
                  {t('homepage.firstMessage.hosting')} <br />
                  Hotel Vitória
                </Text>
              </VStack>
            </Box>
            <Box
              p={['1', '1', '1', '8']}
              bgColor="#f2f2f2"
              rounded="xl"
              width={['100%', '100%', '50%', '50%']}
            >
              <VStack space={3}>
                <ZStack>
                  {language === 'pt' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['240', '240', '240', '339']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'es' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['245', '245', '245', '348']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'en' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['235', '235', '235', '335']}
                      ml="23"
                      mt={['7', '7', '8', '9']}
                    />
                  ) : (
                    <View />
                  )}
                  <Text
                    bold
                    fontSize={['md', 'md', 'md', '2xl']}
                    pt="5"
                    pr="5"
                    pl="5"
                  >
                    {t('homepage.secondMessage.message')}
                  </Text>
                </ZStack>
                <p />
                <ZStack>
                  {language === 'pt' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['270', '270', '270', '370']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'es' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['265', '265', '265', '250']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  {language === 'en' ? (
                    <Box
                      bg="#F9D342"
                      size="3"
                      height={['3', '3', '3', '5']}
                      width={['270', '270', '270', '384']}
                      ml="23"
                      mt={['2', '2', '3', '4']}
                    />
                  ) : (
                    <View />
                  )}
                  <Text bold fontSize={['md', 'md', 'md', '2xl']} pl="5">
                    {t('homepage.secondMessage.coordination')}
                  </Text>
                </ZStack>
                <p />
                <Text
                  fontWeight="medium"
                  fontSize={['xs', 'xs', 'md', 'md']}
                  pb="5"
                  pr="5"
                  pl="5"
                >
                  Murilo, <br />
                  {t('homepage.secondMessage.operations')}
                </Text>
              </VStack>
            </Box>
          </Stack>

          <p />
          <Center>
            <Heading
              color="#000000"
              size={['md', 'md', 'lg', 'lg']}
              pb="4%"
              pt="5%"
            >
              {t('homepage.landingsMessage.title')}
            </Heading>
          </Center>
          <Center>
            <VStack>
              <Center
                pl={['0%', '0%', '0%', '0%']}
                pr={['0%', '0%', '0%', '0%']}
                width={['100%', '100%', '102%', '100%']}
              >
                <Box
                  pt="5"
                  pb="5"
                  pl={['3', '3', '8', '8']}
                  bgColor="#f2f2f2"
                  rounded="xl"
                  width="100%"
                >
                  <VStack space={4}>
                    <HStack space={1}>
                      <View
                        alignItems="center"
                        justifyContent="center"
                        pl={[0, 0, 3, 3]}
                        pr={[1, 1, 8, 8]}
                      >
                        <Image
                          source={{
                            uri: logoCircle,
                          }}
                          alt="Alternate Text"
                          width={[39, 39, 70, 70]}
                          height={[39, 39, 70, 70]}
                        />
                      </View>
                      <View>
                        {language === 'en' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.simplifiedMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['60', '60', '79', '79']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['23', '23', '88', '88']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.simplifiedMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['60', '60', '90', '90']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.simplifiedMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'es' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.simplifiedMessage.simplifiedMessage.management'
                              )}
                            </Text>
                            <View w="100%">
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['90', '90', '132', '142']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['50', '50', '88', '88']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.simplifiedMessage.simplifiedMessage.heliports'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'pt' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.simplifiedMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['75', '75', '106', '106']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['55', '55', '88', '88']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.simplifiedMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['73', '73', '105', '105']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.simplifiedMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.simplifiedMessage.minimizingManual'
                          )}
                        </Text>
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.simplifiedMessage.passengers'
                          )}
                        </Text>
                      </View>
                    </HStack>
                  </VStack>
                </Box>
                <br />
                <Box
                  pt="5"
                  pb="5"
                  pl={['3', '3', '8', '8']}
                  bgColor="#f2f2f2"
                  rounded="xl"
                  width="100%"
                >
                  <VStack space={4}>
                    <HStack space={1}>
                      <View
                        alignItems="center"
                        justifyContent="center"
                        pl={[0, 0, 3, 3]}
                        pr={[1, 1, 8, 8]}
                      >
                        <Image
                          source={{
                            uri: logoHeliport,
                          }}
                          alt="Alternate Text"
                          width={[39, 39, 70, 70]}
                          height={[39, 39, 70, 70]}
                        />
                      </View>
                      <View>
                        {language === 'en' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.centralizedMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['60', '60', '79', '79']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['55', '55', '83', '83']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.centralizedMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['58', '58', '82', '82']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.centralizedMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'es' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.centralizedMessage.centralizedMessage.booking'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['90', '90', '130', '130']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['60', '60', '83', '83']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.centralizedMessage.centralizedMessage.helipad'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <Text
                              color="#000000"
                              bold
                              pl={['60', '60', '125', '125']}
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.centralizedMessage.centralizedMessage.place'
                              )}
                            </Text>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'pt' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.centralizedMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['37', '37', '79', '79']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['60', '60', '83', '83']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.centralizedMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['58', '58', '82', '82']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.centralizedMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.centralizedMessage.offering'
                          )}
                        </Text>
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.centralizedMessage.helipad'
                          )}
                        </Text>
                      </View>
                    </HStack>
                  </VStack>
                </Box>
                <br />
                <Box
                  pt="5"
                  pb="5"
                  pl={['3', '3', '8', '8']}
                  bgColor="#f2f2f2"
                  rounded="xl"
                  width="100%"
                >
                  <VStack space={4}>
                    <HStack space={1}>
                      <View
                        alignItems="center"
                        justifyContent="center"
                        pl={[0, 0, 3, 3]}
                        pr={[1, 1, 8, 8]}
                      >
                        <Image
                          source={{
                            uri: logoCharge,
                          }}
                          alt="Alternate Text"
                          width={[39, 39, 73, 73]}
                          height={[39, 39, 70, 70]}
                        />
                      </View>
                      <View pr={20}>
                        {language === 'en' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.highVolumeMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['70', '70', '79', '79']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['60', '60', '97', '97']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.highVolumeMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['70', '70', '98', '98']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.highVolumeMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'es' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.highVolumeMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['85', '85', '125', '118']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['60', '60', '97', '97']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.highVolumeMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View pl={['85', '85', '127', '118']}>
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.highVolumeMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        {language === 'pt' ? (
                          <HStack space={1}>
                            <Text
                              color="#000000"
                              bold
                              fontSize={['md', 'md', '2xl', '2xl']}
                            >
                              {t(
                                'homepage.landingsMessage.highVolumeMessage.first'
                              )}
                            </Text>
                            <View>
                              <ZStack>
                                <Box
                                  bg="#F9D342"
                                  height="4"
                                  width={['70', '70', '97', '97']}
                                  mt={['2', '2', '4', '4']}
                                  pl={['55', '55', '97', '97']}
                                />
                                <Text
                                  color="#000000"
                                  bold
                                  fontSize={['md', 'md', '2xl', '2xl']}
                                >
                                  {t(
                                    'homepage.landingsMessage.highVolumeMessage.second'
                                  )}
                                </Text>
                              </ZStack>
                            </View>
                            <View
                              pl={['70', '70', '97', '97']}
                              pr={['5', '5', '0', '0']}
                            >
                              <Text
                                color="#000000"
                                bold
                                fontSize={['md', 'md', '2xl', '2xl']}
                              >
                                {t(
                                  'homepage.landingsMessage.highVolumeMessage.third'
                                )}
                              </Text>
                            </View>
                          </HStack>
                        ) : (
                          <View />
                        )}
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.highVolumeMessage.enabling'
                          )}
                        </Text>
                        <Text color="#000000" fontSize={[11, 11, 'xl', 'xl']}>
                          {t(
                            'homepage.landingsMessage.highVolumeMessage.operations'
                          )}
                        </Text>
                      </View>
                    </HStack>
                  </VStack>
                </Box>
              </Center>
            </VStack>
          </Center>
        </Box>
      </Stack>
    )
  }

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  return (
    <>
      <View>
        <AppBarHeader />
      </View>
      <View>
        <NativeBaseProvider>
          <Center flex={1}>
            <HeliportSearchBar />
          </Center>
        </NativeBaseProvider>
      </View>

      <View>
        <NativeBaseProvider>
          <HeliportList />
        </NativeBaseProvider>
      </View>
      <p />
      <Center>
        <Heading
          color="#000000"
          size={['md', 'md', 'lg', 'lg']}
          pb="4%"
          pt="5%"
        >
          {t('homepage.firstTitle')}
        </Heading>
      </Center>
      <View>
        <NativeBaseProvider>
          <Center flex={1}>
            <Testimonals />
          </Center>
        </NativeBaseProvider>
      </View>
    </>
  )
}
export default HomeMvp
const styles = StyleSheet.create({
  nameHelipad: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 18,
  },
  container: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  staticCards: {
    paddingTop: '10%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  locationIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  locationIconSearch: {
    paddingLeft: '10px',
    paddingBottom: '5px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  letter: {
    position: 'absolute',
    width: '463px',
    height: '28px',
    left: ' 175px',
    top: '1588px',
    backgroundColor: '#F9D342',
  },
  staticCards: {
    paddingTop: '10%',
  },
  photoHelipad: {
    justifyContent: 'center',
  },
  locationIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
