import React from 'react'
import { View } from 'react-native'
import {
  VStack,
  HStack,
  Box,
  Heading,
  Input,
  Text,
  Divider,
  Radio,
  Select,
  CheckIcon,
  TextArea,
  Button,
  Center,
  Stack,
  Checkbox,
  NativeBaseProvider,
  Modal,
} from 'native-base'
import PhoneInput from 'react-phone-input-2'
import TimezoneSelect from 'react-timezone-select'
import 'react-phone-input-2/lib/style.css'
import { getToken } from '../../Token'
const dms2dec = require('dms2dec')
import { Decimal2DMS } from 'dms-to-decimal'
import Autocomplete from 'react-google-autocomplete'
import { API_KEY_MAP } from '@env'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../AxiosInstance'
import ModalError from '../../components/ModalError'
import { auth } from '../../utils/nhost'
import { useForm, Controller } from 'react-hook-form'

export const EditHeliportForm = (props) => {
  const { t } = useTranslation()
  const coordenateLatitude = Decimal2DMS(
    props.heliportInformation.latitude,
    'latitude'
  )
  const coordenateLongitude = Decimal2DMS(
    props.heliportInformation.longitude,
    'longitude'
  )
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [heliportName, setHeliportName] = React.useState('')
  const [heliportCity, setHeliportCity] = React.useState('')
  const [heliportState, setHeliportState] = React.useState('')
  const [heliportIciaoCode, setHeliportIciaoCode] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [selectedCallingCode, setSelectedCallingCode] = React.useState(
    props.heliportInformation.phone
  )
  const [address, setAddress] = React.useState('')
  const [timezone, setTimezone] = React.useState(
    props.heliportInformation && props.heliportInformation.timezone
      ? props.heliportInformation.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [checkedFuel, setCheckedFuel] = React.useState(
    props.heliportInformation && props.heliportInformation.fuel_available
      ? props.heliportInformation.fuel_available
      : false
  )

  const [measureLimitWeigth, setMeasureLimitWeigth] = React.useState(
    props.heliportInformation.measure_limit_weigth
  )
  const [rotorDiameter, setRotorDiameter] = React.useState('')
  const [measureRotorDiameter, setMeasureRotorDiameter] = React.useState(
    props.heliportInformation.measure_rotor_diameter
  )
  const [currency, setCurrency] = React.useState(
    props.heliportInformation.heliport_currency
  )
  const [otherRestrictions, setOtherRestrictions] = React.useState(
    props.heliportInformation && props.heliportInformation.other_restrictions
      ? props.heliportInformation.other_restrictions
      : ''
  )
  const [cancellation, setCancellation] = React.useState('')

  const [heliportDescription, setHeliportDescription] = React.useState(
    props.heliportInformation && props.heliportInformation.heliport_description
      ? props.heliportInformation.heliport_description
      : ''
  )

  const [user, setUserId] = React.useState('')
  const [limitLateBookingTime, setLimitLateBookingTime] = React.useState(
    props.heliportInformation && props.heliportInformation.limit_late_booking
      ? props.heliportInformation.limit_late_booking.split(' ')[1].trim()
      : 'minutes'
  )
  const [cancellationTime, setCancellationTime] = React.useState(
    props.heliportInformation && props.heliportInformation.cancellation_policy
      ? props.heliportInformation.cancellation_policy.split(' ')[1].trim()
      : ''
  )
  const [time, setTime] = React.useState(
    props.heliportInformation && props.heliportInformation.landing_slot_length
      ? props.heliportInformation.landing_slot_length.split(' ')[1].trim()
      : ''
  )
  const [latitudeCoordinate, setLatitudeCoordinate] = React.useState(
    coordenateLatitude.slice(-1)
  )
  const [longitudeCoordinate, setLongitudeCoordinate] = React.useState(
    coordenateLongitude.slice(-1)
  )

  const [surfaceType, setSurfaceType] = React.useState('')
  const [checkedParking, setCheckedParking] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [error, setError] = React.useState('')
  const [dimension_one, setDimensionOne] = React.useState('')
  const [dimension_two, setDimensionTwo] = React.useState('')

  React.useEffect(() => {
    if (
      props.heliportInformation &&
      props.heliportInformation.dimensions_limit
    ) {
      const heliportDimensions = JSON.parse(
        props.heliportInformation.dimensions_limit
      )
      const dimension_one = heliportDimensions.dimension_one
        ? parseFloat(heliportDimensions.dimension_one)
        : ''
      setDimensionOne(dimension_one)
      const dimension_two = heliportDimensions.dimension_two
        ? parseFloat(heliportDimensions.dimension_two)
        : ''
      setDimensionTwo(dimension_two)
    }
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
  }, [])
  const restrictions = (e) => {
    setOtherRestrictions(e.currentTarget.value)
  }
  const description = (e) => {
    setHeliportDescription(e.currentTarget.value)
  }

  const coordinates = (heliport) => {
    const coordinatesDecimal = dms2dec(
      [
        parseFloat(heliport.latitudeGrades),
        parseFloat(heliport.latitudeMinutes),
        parseFloat(heliport.latitudeSeconds),
      ],
      latitudeCoordinate,
      [
        parseFloat(heliport.longitudeGrades),
        parseFloat(heliport.longitudeMinutes),
        parseFloat(heliport.longitudeSeconds),
      ],
      longitudeCoordinate
    )
    return coordinatesDecimal
  }

  //Latitude Coordinates
  const latitudeGradesData = coordenateLatitude.split('°')
  const latitudeMinutesData = latitudeGradesData[1].split("'")
  const latitudeSecondsData = latitudeMinutesData[1].split('"')
  //Longitude Coordinates
  const longitudeGradesData = coordenateLongitude.split('°')
  const longitudeMinutesData = longitudeGradesData[1].split("'")
  const longitudeSecondsData = longitudeMinutesData[1].split('"')

  const nameHeliport = props.heliportInformation.manager_name.split(' ')
  const firstNameHeliport = nameHeliport[0]
  const lastNameHeliport = nameHeliport[nameHeliport.length - 1]

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      latitudeGrades: latitudeGradesData[0] ? latitudeGradesData[0] : '',
      latitudeMinutes: latitudeMinutesData[0] ? latitudeMinutesData[0] : '',
      latitudeSeconds: latitudeSecondsData[0] ? latitudeSecondsData[0] : '',
      longitudeGrades: longitudeGradesData[0] ? longitudeGradesData[0] : '',
      longitudeMinutes: longitudeMinutesData[0] ? longitudeMinutesData[0] : '',
      longitudeSeconds: longitudeSecondsData[0] ? longitudeSecondsData[0] : '',
      landingSlotLength: props.heliportInformation.landing_slot_length
        ? props.heliportInformation.landing_slot_length.split(' ')[0].trim()
        : '',
      helipadsNumber: props.heliportInformation.helipads_number
        ? props.heliportInformation.helipads_number
        : '',
      sizeWeigthLimit: props.heliportInformation.size_limit_weigth
        ? props.heliportInformation.size_limit_weigth
        : '',
      limitLateBooking: props.heliportInformation.limit_late_booking
        ? props.heliportInformation.limit_late_booking.split(' ')[0].trim()
        : 0,
      hourlyRate: props.heliportInformation.hourly_rate
        ? props.heliportInformation.hourly_rate
        : 0,
      overnightRate: props.heliportInformation.overnight_rate
        ? props.heliportInformation.overnight_rate
        : 0,
    },
  })

  const addHeliport = async (heliport) => {
    setButtonLoading(true)
    const newHeliportCoordinates = coordinates(heliport)
    const latitude = newHeliportCoordinates[0]
    const longitude = newHeliportCoordinates[newHeliportCoordinates.length - 1]
    const token = auth.getJWTToken()
    let currentTimezone = ''
    const dimensions = {
      dimension_one: dimension_one ? dimension_one : '',
      dimension_two: dimension_two ? dimension_two : '',
    }
    try {
      if (timezone.value) {
        currentTimezone = timezone.value
      } else {
        currentTimezone = timezone
      }
      const updateHeliport = await axiosInstance.put(`/heliport/editHeliport`, {
        id: props.heliportInformation.id,
        token: token,
        heliport_description: heliportDescription,
        helipads_number: heliport.helipadsNumber
          ? heliport.helipadsNumber
          : props.heliportInformation.helipads_number,
        fuel_available: checkedFuel && checkedFuel === true ? true : false,
        address: address ? address : props.heliportInformation.address,
        cancellation_policy: cancellation
          ? `${cancellation} ${cancellationTime}`
          : props.heliportInformation.cancellation_policy,
        city: heliportCity ? heliportCity : props.heliportInformation.city,
        hourly_rate: heliport.hourlyRate
          ? heliport.hourlyRate
          : props.heliportInformation.hourly_rate,
        icao_code: heliportIciaoCode
          ? heliportIciaoCode
          : props.heliportInformation.icao_code,
        limit_late_booking: heliport.limitLateBooking
          ? `${heliport.limitLateBooking} ${limitLateBookingTime}`
          : props.heliportInformation.limit_late_booking,
        landing_slot_length: heliport.landingSlotLength
          ? `${heliport.landingSlotLength} ${time}`
          : props.heliportInformation.landing_slot_length,
        latitude:
          latitude || latitude === NaN
            ? latitude
            : props.heliportInformation.latitude,
        longitude:
          longitude || longitude === NaN
            ? longitude
            : props.heliportInformation.longitude,
        manager_name: getManagerName(),
        measure_rotor_diameter: measureRotorDiameter
          ? measureRotorDiameter
          : props.heliportInformation.measure_rotor_diameter,
        name: heliportName ? heliportName : props.heliportInformation.name,
        other_restrictions: otherRestrictions,
        overnight_rate: heliport.overnightRate
          ? heliport.overnightRate
          : props.heliportInformation.overnight_rate,
        phone: selectedCallingCode
          ? selectedCallingCode
          : props.heliportInformation.phone,
        email: props.heliportInformation.email,
        size_limit_weigth: heliport.sizeWeigthLimit
          ? heliport.sizeWeigthLimit
          : props.heliportInformation.size_limit_weigth,
        measure_limit_weigth: measureLimitWeigth
          ? measureLimitWeigth
          : props.heliportInformation.measure_limit_weigth,
        state: heliportState ? heliportState : props.heliportInformation.state,
        timezone: currentTimezone,
        country: props.heliportInformation.country,
        heliport_currency: currency
          ? currency
          : props.heliportInformation.heliport_currency,
        heliport_image_id: props.heliportInformation.heliport_image_id,
        surface_type: surfaceType
          ? surfaceType
          : props.heliportInformation.surface_type,
        parking_available: checkedParking
          ? 'true'
          : props.heliportInformation.parking_available,
        overnight_rate_currency: props.heliportInformation.heliport_currency,
        hourly_rate_currency: props.heliportInformation.heliport_currency,
        dimensions_limit: dimensions
          ? JSON.stringify(dimensions)
          : props.heliportInformation.dimensions_limit,
      })

      if (heliport.landingSlotLength) {
        await axiosInstance.put(`/reservations/updatelandingSlotLength`, {
          projectId: props.heliportInformation.timekit_project_id,
          landingSlotLength: heliport.landingSlotLength
            ? heliport.landingSlotLength
            : 1,
          time: time ? time : 'hour',
        })
      }
      if (heliport.limitLateBooking) {
        await axiosInstance.put(`/reservations/updateLimitLateTime`, {
          projectId: props.heliportInformation.timekit_project_id,
          limitLateBooking: heliport.limitLateBooking
            ? heliport.limitLateBooking
            : 1,
          limitLateBookingTime: limitLateBookingTime
            ? limitLateBookingTime
            : 'hour',
        })
      }
      setButtonLoading(false)
      props.onAction(updateHeliport.data.updatedHeliport)
    } catch (error) {
      setButtonLoading(false)
      if (error && error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('editHeliport.error'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('editHeliport.error'))
      }
      openError()
    }
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  //Google Autocomplete options
  const options = {
    fields: ['formatted_address', 'geometry.location', 'address_components'],
    strictBounds: false,
    types: ['geocode'],
  }
  const getManagerName = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else if (firstName) {
      return `${firstName} ${lastNameHeliport}`
    } else if (lastName) {
      return `${firstNameHeliport} ${lastName}`
    } else {
      return props.heliportInformation.manager_name
    }
  }

  const addressLanguage = (country) => {
    if (country === 'Brazil') {
      return 'pt'
    } else if (country === 'USA') {
      return 'en'
    } else {
      return 'es'
    }
  }
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }

  return (
    <View bgColor="white">
      <NativeBaseProvider>
        <Stack direction={['column', 'column', 'column', 'row']} space={1}>
          <Box p="5" width={['100%', '100%', '75%', '25%']}>
            <Heading size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.identifier')}
            </Heading>
            <Text>{t('editHeliport.name')}</Text>
            <Input
              placeholder={t('editHeliport.name')}
              size="md"
              bg="#FFFFFF"
              defaultValue={props.heliportInformation.name}
              onChangeText={(heliportName) => setHeliportName(heliportName)}
            />
            <Text>{t('editHeliport.code')}</Text>
            <Input
              placeholder='e.g., "SDJD"'
              maxLength={'4'}
              size="md"
              bg="#FFFFFF"
              defaultValue={props.heliportInformation.icao_code}
              onChangeText={(heliportIciaoCode) =>
                setHeliportIciaoCode(heliportIciaoCode)
              }
            />
            <Divider />
            <Heading size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.contactInformation')}
            </Heading>
            <Text>{t('editHeliport.managerName')}</Text>
            <VStack space={3}>
              <Input
                placeholder="First Name"
                size="md"
                bg="#FFFFFF"
                defaultValue={firstNameHeliport}
                onChangeText={(firstName) => setFirstName(firstName)}
              />
              <Input
                placeholder="Last Name"
                size="md"
                bg="#FFFFFF"
                defaultValue={lastNameHeliport}
                onChangeText={(lastName) => setLastName(lastName)}
              />
            </VStack>

            <Text>{t('editHeliport.phone')}</Text>
            <PhoneInput
              dropdownStyle={{ width: 250 }}
              inputStyle={{ width: '100%' }}
              country={'us'}
              masks={{ br: '.. .........' }}
              placeholder={placeholderPhone()}
              value={selectedCallingCode}
              onChange={(phone) => setSelectedCallingCode(phone)}
            />

            <Divider />
          </Box>
          <Box zIndex="-2" p="5" width={['100%', '100%', '75%', '25%']}>
            <Heading zIndex="-2" size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.location')}
            </Heading>
            <Text>{t('editHeliport.city')}</Text>
            <Input
              placeholder={t('editHeliport.city')}
              size="md"
              zIndex="-2"
              bg="#FFFFFF"
              defaultValue={props.heliportInformation.city}
              onChangeText={(heliportCity) => setHeliportCity(heliportCity)}
            />
            <Text>{t('editHeliport.state')}</Text>
            <Input
              maxLength={'2'}
              placeholder="e.g. SP"
              size="md"
              bg="#FFFFFF"
              defaultValue={props.heliportInformation.state}
              onChangeText={(heliportState) => setHeliportState(heliportState)}
            />
            <Text>{t('editHeliport.address')}</Text>
            <Autocomplete
              language={addressLanguage(props.heliportInformation.country)}
              options={options}
              apiKey={API_KEY_MAP}
              defaultValue={props.heliportInformation.address}
              style={{
                height: 35,
                paddingLeft: 9,
                fontSize: 14,
                border: '0.5px solid',
                borderRadius: 5,
                borderColor: 'silver',
              }}
              onPlaceSelected={(place) => {
                setAddress(place.formatted_address)
              }}
            />
            <Text>{t('editHeliport.latitude')}</Text>
            <VStack>
              <HStack space={1}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        h="10"
                        w="12"
                        placeholder="00"
                        textAlign="center"
                        bgColor="white"
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                      />
                    )}
                    name="latitudeGrades"
                  />
                  {errors.latitudeGrades && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.latitudeGrades.message
                        ? errors.latitudeGrades.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text>º</Text>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        h="10"
                        w="12"
                        placeholder="00"
                        textAlign="center"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        bgColor="white"
                        value={value}
                      />
                    )}
                    name="latitudeMinutes"
                  />
                  {errors.latitudeMinutes && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.latitudeMinutes.message
                        ? errors.latitudeMinutes.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text>’</Text>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        bgColor="white"
                        h="10"
                        w="12"
                        placeholder="00"
                        textAlign="center"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="latitudeSeconds"
                  />
                  {errors.latitudeSeconds && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.latitudeSeconds.message
                        ? errors.latitudeSeconds.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text>”</Text>
                <Select
                  pb="3"
                  selectedValue={latitudeCoordinate}
                  maxWidth="58"
                  placeholder="S / N"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />,
                  }}
                  onValueChange={(itemValue) =>
                    setLatitudeCoordinate(itemValue)
                  }
                >
                  <Select.Item label="S" value="S" />
                  <Select.Item label="N" value="N" />
                </Select>
              </HStack>
            </VStack>
            <br></br>
            <Text>{t('editHeliport.longitude')}</Text>
            <VStack>
              <HStack space={1}>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        h="10"
                        w="12"
                        bgColor="white"
                        placeholder="00"
                        textAlign="center"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="longitudeGrades"
                  />
                  {errors.longitudeGrades && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.longitudeGrades.message
                        ? errors.longitudeGrades.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text maxWidth="35">º</Text>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        h="10"
                        w="12"
                        bgColor="white"
                        placeholder="00"
                        textAlign="center"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="longitudeMinutes"
                  />
                  {errors.longitudeMinutes && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.longitudeMinutes.message
                        ? errors.longitudeMinutes.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text maxWidth="35">’</Text>
                <VStack>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t('heliportForm.numbers'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        h="10"
                        w="12"
                        bgColor="white"
                        textAlign="center"
                        placeholder="00"
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    name="longitudeSeconds"
                  />
                  {errors.longitudeSeconds && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.longitudeSeconds.message
                        ? errors.longitudeSeconds.message
                        : t('heliportForm.required')}
                    </Text>
                  )}
                </VStack>
                <Text>”</Text>
                <Select
                  pb="3"
                  selectedValue={longitudeCoordinate}
                  maxWidth="58"
                  placeholder={t('west') + ' / E'}
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />,
                  }}
                  onValueChange={(itemValue) =>
                    setLongitudeCoordinate(itemValue)
                  }
                >
                  <Select.Item label={t('west')} value="W" />
                  <Select.Item label="E" value="E" />
                </Select>
              </HStack>
            </VStack>
            <br></br>
            <Text>{t('editHeliport.timezone')}</Text>
            <TimezoneSelect value={timezone} onChange={setTimezone} />
            <Divider />
            <Heading size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.availability')}
            </Heading>
            <Text>{t('editHeliport.timeslot')}</Text>
            <HStack space={1}>
              <VStack>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: t('heliportForm.numbers'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      borderRadius="2xl"
                      placeholder="1"
                      textAlign="center"
                      w="12"
                      size="sm"
                      bg="#FFFFFF"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      variant="unstyled"
                      borderWidth={1}
                    />
                  )}
                  name="landingSlotLength"
                />
                {errors.landingSlotLength && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {errors.landingSlotLength.message
                      ? errors.landingSlotLength.message
                      : t('heliportForm.required')}
                  </Text>
                )}
              </VStack>
              <Select
                maxWidth="170"
                selectedValue={time}
                accessibilityLabel="time"
                placeholder={
                  t('editHeliport.hours') + '/' + t('editHeliport.minutes')
                }
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => setTime(itemValue)}
              >
                <Select.Item label={t('editHeliport.hours')} value="hours" />
                <Select.Item
                  label={t('editHeliport.minutes')}
                  value="minutes"
                />
              </Select>
            </HStack>
            <Text>{t('editHeliport.numberHelipads')}</Text>
            <Controller
              control={control}
              rules={{
                required: false,
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  message: t('heliportForm.numbers'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  size="md"
                  bg="#FFFFFF"
                  defaultValue={props.heliportInformation.helipads_number}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
              name="helipadsNumber"
            />
            {errors.helipadsNumber && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.helipadsNumber.message
                  ? errors.helipadsNumber.message
                  : t('heliportForm.required')}
              </Text>
            )}
            <Text>{t('editHeliport.fuel')}</Text>

            <Radio.Group
              name="myRadioGroup"
              value={checkedFuel}
              accessibilityLabel="select a role"
              onChange={(nextValue) => {
                setCheckedFuel(nextValue)
              }}
            >
              <Radio value={true} my={1} accessibilityLabel="fuel yes">
                {t('editHeliport.yes')}
              </Radio>

              <Radio value={false} my={1} accessibilityLabel="fuel no">
                {t('editHeliport.no')}
              </Radio>
            </Radio.Group>
          </Box>
          <Box zIndex="-2" p="5" width={['100%', '100%', '75%', '25%']}>
            <Heading size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.restrictions')}
            </Heading>
            <Text>{t('editHeliport.size')}</Text>
            <Text>{t('editHeliport.weight')}</Text>
            <HStack>
              <VStack>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: t('heliportForm.numbers'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      size="md"
                      width="107"
                      bg="#FFFFFF"
                      value={value}
                      onBlur={onBlur}
                      onChangeText={onChange}
                    />
                  )}
                  name="sizeWeigthLimit"
                />
                {errors.sizeWeigthLimit && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {errors.sizeWeigthLimit.message
                      ? errors.sizeWeigthLimit.message
                      : t('heliportForm.required')}
                  </Text>
                )}
              </VStack>
              <Select
                selectedValue={measureLimitWeigth}
                maxWidth="110"
                accessibilityLabel="kg/lbs"
                placeholder="kg/lbs"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => setMeasureLimitWeigth(itemValue)}
              >
                <Select.Item label="kg" value="kg" />
                <Select.Item label="lbs" value="lbs" />
                <Select.Item label="t" value="t" />
              </Select>
            </HStack>
            <Text>{t('editHeliport.maximumRotor')}</Text>
            <HStack>
              <Input
                textAlign="center"
                size="md"
                w="12"
                bg="white"
                defaultValue={dimension_one}
                onChangeText={(value) => setDimensionOne(value)}
                keyboardType="numeric"
              />
              <Center pr="1" pl="1">
                x
              </Center>
              <Input
                textAlign="center"
                size="md"
                w="12"
                bg="white"
                defaultValue={dimension_two}
                onChangeText={(value) => setDimensionTwo(value)}
              />
              <Select
                selectedValue={measureRotorDiameter}
                maxWidth="108"
                accessibilityLabel="m/ft"
                placeholder="m/ft"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) =>
                  setMeasureRotorDiameter(itemValue)
                }
              >
                <Select.Item label="m" value="m" />
                <Select.Item label="ft" value="ft" />
              </Select>
            </HStack>
            <Text>{t('editHeliport.surface')}</Text>
            <Input
              placeholder="e.g. Asphalt"
              size="md"
              bg="#FFFFFF"
              defaultValue={props.heliportInformation.surface_type}
              onChangeText={(surfaceType) => setSurfaceType(surfaceType)}
            />
            <Text>{t('editHeliport.otherRestrictions')}</Text>
            <TextArea
              bg="#FFFFFF"
              defaultValue={otherRestrictions}
              onChange={restrictions}
            />
            <Text>{t('editHeliport.limitLateBooking')}</Text>
            <HStack>
              <VStack>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: t('heliportForm.numbers'),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      width="107"
                      placeholder="1"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      bg="#FFFFFF"
                      size="sm"
                    />
                  )}
                  name="limitLateBooking"
                />
                {errors.limitLateBooking && (
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {errors.limitLateBooking.message
                      ? errors.limitLateBooking.message
                      : t('heliportForm.required')}
                  </Text>
                )}
              </VStack>

              <Select
                maxWidth="110"
                selectedValue={limitLateBookingTime}
                accessibilityLabel="time"
                placeholder="hours/days"
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) =>
                  setLimitLateBookingTime(itemValue)
                }
              >
                <Select.Item
                  label={t('editHeliport.minutes')}
                  value="minutes"
                />
                <Select.Item label={t('editHeliport.hours')} value="hours" />
              </Select>
            </HStack>
            <Text>{t('editHeliport.cancellation')}</Text>
            <HStack>
              <Input
                width="107"
                placeholder="1"
                onChangeText={(text) => {
                  setCancellation(text)
                }}
                bg="#FFFFFF"
                defaultValue={
                  props.heliportInformation.cancellation_policy
                    ? props.heliportInformation.cancellation_policy
                        .split(' ')[0]
                        .trim()
                    : ''
                }
                size="sm"
              />

              <Select
                maxWidth="110"
                selectedValue={cancellationTime}
                accessibilityLabel="time"
                placeholder="hours/days"
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => setCancellationTime(itemValue)}
              >
                <Select.Item
                  label={t('editHeliport.minutes')}
                  value="minutes"
                />
                <Select.Item label={t('editHeliport.hours')} value="hours" />
                <Select.Item label={t('editHeliport.days')} value="days" />
              </Select>
            </HStack>
          </Box>
          <Box p="5" width={['100%', '100%', '75%', '25%']}>
            <Heading size={['md', 'lg', 'md']} fontWeight="large">
              {t('editHeliport.fees')}
            </Heading>

            <HStack space={3}>
              <VStack width={['100%', '100%', '50%', '100%']}>
                <Text>{t('editHeliport.currency')}</Text>
                <Select
                  selectedValue={currency}
                  placeholder={t('editHeliport.currency')}
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />,
                  }}
                  onValueChange={(itemValue) => setCurrency(itemValue)}
                >
                  <Select.Item label={t('editHeliport.real')} value="R$" />
                  <Select.Item label={t('editHeliport.usd')} value="$" />
                  <Select.Item label={t('editHeliport.mexican')} value="MX$" />
                </Select>
              </VStack>
            </HStack>
            {props.heliportInformation.parking_available === 'false' ? (
              <View>
                <br />
                <HStack space={2}>
                  <Text>{t('editHeliport.parkingAvailable')}</Text>
                  <Checkbox
                    value={checkedParking}
                    onChange={(nextValue) => {
                      setCheckedParking(nextValue)
                    }}
                    accessibilityLabel="choose parking"
                    colorScheme="yellow"
                  />
                </HStack>

                {checkedParking ? (
                  <View>
                    <Text>{t('editHeliport.parkingHour')}</Text>
                    <VStack width="100%">
                      <Controller
                        control={control}
                        rules={{
                          required: false,
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: t('heliportForm.numbers'),
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            placeholder="$50/hour"
                            size="md"
                            bg="#FFFFFF"
                            value={value}
                            onBlur={onBlur}
                            onChangeText={onChange}
                          />
                        )}
                        name="hourlyRate"
                      />
                      {errors.hourlyRate && (
                        <Text p="2" fontSize="xs" color="#eb0100">
                          {errors.hourlyRate.message
                            ? errors.hourlyRate.message
                            : t('heliportForm.required')}
                        </Text>
                      )}
                    </VStack>
                    <Text>{t('editHeliport.parkingNight')}</Text>
                    <HStack space={3}>
                      <VStack width="100%">
                        <Controller
                          control={control}
                          rules={{
                            required: false,
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: t('heliportForm.numbers'),
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              placeholder="$500/night"
                              size="md"
                              bg="#FFFFFF"
                              value={value}
                              onChangeText={onChange}
                              onBlur={onBlur}
                            />
                          )}
                          name="overnightRate"
                        />
                        {errors.overnightRate && (
                          <Text p="2" fontSize="xs" color="#eb0100">
                            {errors.overnightRate.message
                              ? errors.overnightRate.message
                              : t('heliportForm.required')}
                          </Text>
                        )}
                      </VStack>
                    </HStack>
                  </View>
                ) : (
                  <View></View>
                )}
              </View>
            ) : (
              <View>
                <br />
                <Text>{t('editHeliport.editParking')}</Text>
                {props.heliportInformation.hourly_rate ? (
                  <View>
                    <Text>{t('editHeliport.parkingHour')}</Text>
                    <HStack space={3}>
                      <VStack width="100%">
                        <Controller
                          control={control}
                          rules={{
                            required: false,
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: t('heliportForm.numbers'),
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              placeholder="$50/hour"
                              size="md"
                              bg="#FFFFFF"
                              value={value}
                              onBlur={onBlur}
                              onChangeText={onChange}
                            />
                          )}
                          name="hourlyRate"
                        />
                        {errors.hourlyRate && (
                          <Text p="2" fontSize="xs" color="#eb0100">
                            {errors.hourlyRate.message
                              ? errors.hourlyRate.message
                              : t('heliportForm.required')}
                          </Text>
                        )}
                      </VStack>
                    </HStack>
                  </View>
                ) : (
                  <View />
                )}
                {props.heliportInformation.overnight_rate ? (
                  <View>
                    <Text>{t('editHeliport.parkingNight')}</Text>
                    <HStack space={3}>
                      <VStack width="100%">
                        <Controller
                          control={control}
                          rules={{
                            required: false,
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: t('heliportForm.numbers'),
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              placeholder="$500/night"
                              size="md"
                              bg="#FFFFFF"
                              value={value}
                              onChangeText={onChange}
                              onBlur={onBlur}
                            />
                          )}
                          name="overnightRate"
                        />
                        {errors.overnightRate && (
                          <Text p="2" fontSize="xs" color="#eb0100">
                            {errors.overnightRate.message
                              ? errors.overnightRate.message
                              : t('heliportForm.required')}
                          </Text>
                        )}
                      </VStack>
                    </HStack>
                  </View>
                ) : (
                  <View />
                )}
              </View>
            )}
            <br />
            <Text>{t('editHeliport.description')}</Text>
            <TextArea
              bg="#FFFFFF"
              defaultValue={heliportDescription}
              onChange={description}
            />
          </Box>
        </Stack>
        <Center>
          <View>
            <Button
              onPress={handleSubmit(addHeliport)}
              colorScheme="yellow"
              mb="5"
              rounded="2xl"
              isLoading={buttonLoading}
              _loading={{
                bg: 'amber.400:alpha.70',
                _text: {
                  color: 'coolGray.700',
                },
              }}
              _spinner={{
                color: 'white',
              }}
              isLoadingText={t('submit_button.text')}
            >
              {t('editHeliport.saveButton')}
            </Button>
          </View>
        </Center>
        <Modal isOpen={showError}>
          <ModalError error={error} onClose={closeError} />
        </Modal>
      </NativeBaseProvider>
    </View>
  )
}
export default EditHeliportForm
