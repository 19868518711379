import React from 'react'
import {
  View,
  HStack,
  Input,
  Stack,
  Select,
  CheckIcon,
  Pressable,
  Image,
  Box,
  Checkbox,
  Text,
} from 'native-base'
import button_plus from '../../assets/button_plus.png'
import minus_icon from '../../assets/minus_icon.png'
import TimePicker from 'rc-time-picker'
import '../../assets/index.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const FridayHours = ({ onAction, daysHours, currency }) => {
  const { t } = useTranslation()
  const [checkedFriday, setCheckedFriday] = React.useState('')

  const [days, setDays] = React.useState([])

  React.useEffect(async () => {
    setDays(daysHours)
  }, [])

  let addFormEditFields = () => {
    setDays([
      ...days,
      {
        startHour: moment('00:00', 'HH:mm'),
        endHour: moment('00:00', 'HH:mm'),
        price: '',
        currency: currency,
        booking_graph: '',
      },
    ])
  }

  let removeFormEditFields = (i) => {
    let newFormValues = [...days]
    newFormValues.splice(i, 1)
    setDays(newFormValues)
    handleSubmitEdit(newFormValues)
  }

  let handleChangeEditStartDay = (i, e) => {
    let newFormValues = [...days]
    newFormValues[i].startHour = e
    setDays(newFormValues)
    handleSubmitEdit()
  }
  let handleChangeEditEndDay = (i, e) => {
    let newFormValues = [...days]
    newFormValues[i].endHour = e
    setDays(newFormValues)
    handleSubmitEdit()
  }
  let handleChangeEditPrice = (i, e) => {
    let newFormValues = [...days]
    newFormValues[i].price = e.target.value
    setDays(newFormValues)
    handleSubmitEdit()
  }
  let handleChangeEditBookingGraph = (i, e) => {
    let newFormValues = [...days]
    newFormValues[i].booking_graph = e
    setDays(newFormValues)
    handleSubmitEdit()
  }

  let handleSubmitEdit = (deletedAvaibility) => {
    if (deletedAvaibility) {
      const newFormValues = deletedAvaibility.map((f) => {
        return {
          booking_graph: f.booking_graph,
          endHour:
            f.endHour.format('HH:mm') === '23:59' ||
            f.endHour.format('HH:mm') === '00:00'
              ? '24:00'
              : f.endHour.format('HH:mm'),
          startHour: f.startHour.format('HH:mm'),
          currency: f.currency,
          price: f.price,
        }
      })
      const friday = { friday: newFormValues }
      onAction(friday)
    } else {
      const newFormValues = days.map((f) => {
        return {
          booking_graph: f.booking_graph,
          endHour:
            f.endHour.format('HH:mm') === '23:59' ||
            f.endHour.format('HH:mm') === '00:00'
              ? '24:00'
              : f.endHour.format('HH:mm'),
          startHour: f.startHour.format('HH:mm'),
          currency: f.currency,
          price: f.price,
        }
      })
      const friday = { friday: newFormValues }
      onAction(friday)
    }
  }
  const sendDayObject = (nextValue) => {
    setCheckedFriday(nextValue)
    if (days.length > 0) {
      handleSubmitEdit()
    }
  }

  const [formValues, setFormValues] = React.useState([
    {
      startHour: moment('00:00', 'HH:mm'),
      endHour: moment('00:00', 'HH:mm'),
      price: '',
      currency: '',
      booking_graph: '',
    },
  ])

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        startHour: moment('00:00', 'HH:mm'),
        endHour: moment('00:00', 'HH:mm'),
        price: '',
        currency: '',
        booking_graph: '',
      },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues]
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
  }

  let handleChangeStartDay = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i].startHour = e
    setFormValues(newFormValues)
    handleSubmit()
  }
  let handleChangeEndDay = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i].endHour = e
    setFormValues(newFormValues)
    handleSubmit()
  }
  let handleChangePrice = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i].price = e.target.value
    setFormValues(newFormValues)
    handleSubmit()
  }

  let handleChangeBookingGraph = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i].booking_graph = e
    setFormValues(newFormValues)
    handleSubmit()
  }

  let handleSubmit = () => {
    const newFormValues = formValues.map((f) => {
      return {
        booking_graph: f.booking_graph,
        endHour:
          f.endHour.format('HH:mm') === '23:59' ||
          f.endHour.format('HH:mm') === '00:00'
            ? '24:00'
            : f.endHour.format('HH:mm'),
        startHour: f.startHour.format('HH:mm'),
        currency: f.currency,
        price: f.price,
      }
    })
    const friday = { friday: newFormValues }
    onAction(friday)
  }
  return (
    <View>
      {days && days.length > 0 ? (
        <View>
          <HStack space={5} pt="6">
            <Checkbox
              colorScheme="yellow"
              value={checkedFriday}
              onChange={(nextValue) => {
                sendDayObject(nextValue)
              }}
              accessibilityLabel="Check day"
            />
            <Text>{t('editAvailability.friday')}</Text>
          </HStack>
          {days.map((element, index) => (
            <Stack
              space={10}
              key={index}
              p="2%"
              direction={['column', 'column', 'column', 'row']}
            >
              <HStack space={3}>
                <TimePicker
                  showSecond={false}
                  onChange={(e) => handleChangeEditStartDay(index, e)}
                  value={element.startHour || ''}
                />
                <TimePicker
                  showSecond={false}
                  onChange={(e) => handleChangeEditEndDay(index, e)}
                  value={element.endHour || ''}
                />
              </HStack>
              <Box>
                <Input
                  bgColor="white"
                  placeholder={t('editAvailability.price')}
                  value={element.price || ''}
                  onChange={(e) => handleChangeEditPrice(index, e)}
                />
              </Box>
              <Select
                selectedValue={element.booking_graph || ''}
                maxWidth="127"
                placeholder={
                  t('editAvailability.automatic') +
                  '/' +
                  t('editAvailability.manual')
                }
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(e) => handleChangeEditBookingGraph(index, e)}
              >
                <Select.Item
                  label={t('editAvailability.automatic')}
                  value="instant"
                />
                <Select.Item
                  label={t('editAvailability.manual')}
                  value="confirm_decline"
                />
              </Select>
              {index ? (
                <Pressable
                  onPress={() => removeFormEditFields(index)}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    source={{
                      uri: minus_icon,
                    }}
                    alt="Alternate Text"
                    style={{ width: 20, height: 20 }}
                  />
                </Pressable>
              ) : null}

              <Pressable
                onPress={() => addFormEditFields()}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  source={{
                    uri: button_plus,
                  }}
                  alt="Alternate Text"
                  style={{ width: 20, height: 20 }}
                />
              </Pressable>
            </Stack>
          ))}
        </View>
      ) : (
        <View>
          {formValues.map((element, index) => (
            <Stack
              space={10}
              key={index}
              p="2%"
              direction={['column', 'column', 'column', 'row']}
            >
              <HStack space={3}>
                <TimePicker
                  showSecond={false}
                  onChange={(e) => handleChangeStartDay(index, e)}
                  value={element.startHour || ''}
                />

                <TimePicker
                  showSecond={false}
                  name="endDay"
                  onChange={(e) => handleChangeEndDay(index, e)}
                  value={element.endHour || ''}
                />
              </HStack>
              <Input
                bgColor="white"
                placeholder={t('editAvailability.price')}
                value={element.price || ''}
                onChange={(e) => handleChangePrice(index, e)}
              />
              <Select
                selectedValue={element.booking_graph || ''}
                maxWidth="127"
                placeholder={
                  t('editAvailability.automatic') +
                  '/' +
                  t('editAvailability.manual')
                }
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(e) => handleChangeBookingGraph(index, e)}
              >
                <Select.Item
                  label={t('editAvailability.automatic')}
                  value="instant"
                />
                <Select.Item
                  label={t('editAvailability.manual')}
                  value="confirm_decline"
                />
              </Select>
              {index ? (
                <Pressable
                  onPress={() => removeFormFields(index)}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    source={{
                      uri: minus_icon,
                    }}
                    alt="Alternate Text"
                    style={{ width: 20, height: 20 }}
                  />
                </Pressable>
              ) : null}
              <Pressable
                justifyContent="center"
                alignItems="center"
                onPress={() => addFormFields()}
              >
                <Image
                  source={{
                    uri: button_plus,
                  }}
                  alt="Alternate Text"
                  style={{ width: 20, height: 20 }}
                />
              </Pressable>
            </Stack>
          ))}
        </View>
      )}
    </View>
  )
}

export default FridayHours
