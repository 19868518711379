import React from 'react'
import {
  View,
  Box,
  Heading,
  Text,
  HStack,
  VStack,
  Button,
  Stack,
  Modal,
  NativeBaseProvider,
  CircleIcon,
  Image,
  Input,
  Select,
  CheckIcon,
  Pressable,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useParams, useHistory } from '../routing'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import button_plus from '../assets/button_plus.png'
import minus_icon from '../assets/minus_icon.png'
import axiosInstance from '../AxiosInstance'
import moment from 'moment-timezone'
import ModalError from '../components/ModalError'

const Actions = ({ bookingDetail, companyId, onAction }) => {
  let history = useHistory()
  const { t } = useTranslation()
  const [show, setShow] = React.useState(false)
  const [showCancel, setShowCancel] = React.useState(false)
  const [reason, setReason] = React.useState('')
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const rescheduleBookingsConfirmed = async (id, companyId) => {
    await history.push(
      `/scheduleHeliport?heliport=${
        bookingDetail.project.id
      }&company=${companyId}&booking=${id}&reschedule=${true}`
    )
  }
  const rescheduleBookings = async (id) => {
    await history.push(
      `/scheduleHeliport?heliport=${
        bookingDetail.project.id
      }&company=${companyId}&booking=${id}&reschedule=${true}`
    )
  }

  const openModalCancelBooking = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowCancel(true)
  }

  const closeModalCancelBooking = () => {
    document.body.style.overflow = 'scroll'
    setShowCancel(false)
  }

  const cancelBooking = async (id) => {
    try {
      const getBookingCanceled = await axiosInstance.post(`/bookings/cancel`, {
        bookingId: id,
      })
      onAction(getBookingCanceled.data.booking)
      closeModalCancelBooking()
    } catch (error) {
      closeModalCancelBooking()
      console.log(error)
    }
  }

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const declineBooking = async (id) => {
    setButtonLoading(true)
    try {
      const getBookingDeclined = await axiosInstance.post(`/bookings/decline`, {
        booking_id: id,
        reason: reason,
      })
      onAction(getBookingDeclined.data.booking)
      setButtonLoading(false)
      closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBooking = async (id) => {
    await axiosInstance.post(`/bookings/deleteBookingById`, {
      id: id,
    })
    history.push('/manageReservationOperator')
  }

  if (
    bookingDetail.state === 'cancelled' ||
    bookingDetail.state === 'cancelled_by_customer' ||
    bookingDetail.state === 'declined' ||
    bookingDetail.state === 'completed'
  ) {
    return (
      <HStack space={3}>
        <Button
          bgColor="danger.500"
          onPress={() => {
            deleteBooking(bookingDetail.id)
          }}
        >
          {t('manageReservationOperator.bookingOperatorDetail.deleteButton')}
        </Button>
      </HStack>
    )
  } else if (bookingDetail.state === 'tentative') {
    return (
      <View>
        <Stack space={3} direction={['column', 'column', 'column', 'row']}>
          <Button
            bgColor="yellow.300"
            size="md"
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              rescheduleBookings(bookingDetail.id)
            }}
          >
            {t(
              'manageReservationOperator.bookingOperatorDetail.rescheduleButton'
            )}
          </Button>
          <Button
            bgColor="danger.500"
            size="md"
            onPress={() => {
              openModal()
            }}
          >
            {t('manageReservationOperator.bookingOperatorDetail.cancelButton')}
          </Button>
        </Stack>
        <Modal isOpen={show} onClose={() => closeModal()} flex={1}>
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>
              {t(
                'manageReservationOperator.bookingOperatorDetail.cancelModalTitle'
              )}
            </Modal.Header>
            <Modal.Body>
              <Text bold>
                {t('manageReservationOperator.bookingOperatorDetail.reason')}
              </Text>
              <Input
                multiline
                height="100"
                onChangeText={(reason) => setReason(reason)}
              />
            </Modal.Body>
            <Modal.Footer>
              <HStack space={3}>
                <Button
                  bgColor="yellow.300"
                  size="md"
                  _text={{
                    color: 'coolgray.800',
                  }}
                  onPress={() => {
                    closeModal()
                  }}
                >
                  {t(
                    'manageReservationOperator.bookingOperatorDetail.returnReservation'
                  )}
                </Button>
                <Button
                  bgColor="coolGray.300"
                  size="md"
                  _text={{
                    color: 'coolgray.800',
                  }}
                  isLoading={buttonLoading}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                  onPress={() => {
                    declineBooking(bookingDetail.id)
                  }}
                >
                  {t(
                    'manageReservationOperator.bookingOperatorDetail.cancelButton'
                  )}
                </Button>
              </HStack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    )
  } else if (bookingDetail.state === 'confirmed') {
    return (
      <View>
        <Stack space={3} direction={['column', 'column', 'column', 'row']}>
          <Button
            bgColor="yellow.300"
            size="md"
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              rescheduleBookingsConfirmed(bookingDetail.id, companyId)
            }}
          >
            {t(
              'manageReservationOperator.bookingOperatorDetail.rescheduleButton'
            )}
          </Button>

          <Button
            bgColor="danger.500"
            size="md"
            onPress={() => {
              openModalCancelBooking()
            }}
          >
            {t('manageReservationOperator.bookingOperatorDetail.cancelButton')}
          </Button>
        </Stack>
        <Modal
          isOpen={showCancel}
          onClose={() => closeModalCancelBooking()}
          flex={1}
        >
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>
              {t(
                'manageReservationOperator.bookingOperatorDetail.cancelModalTitle'
              )}
            </Modal.Header>
            <Modal.Body>
              <Text p={8}>
                {t(
                  'manageReservationOperator.bookingOperatorDetail.sureCancel'
                )}
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <HStack space={3}>
                <Button
                  bgColor="yellow.300"
                  size="md"
                  _text={{
                    color: 'coolgray.800',
                  }}
                  onPress={() => {
                    closeModalCancelBooking()
                  }}
                >
                  {t(
                    'manageReservationOperator.bookingOperatorDetail.returnReservation'
                  )}
                </Button>
                <Button
                  bgColor="danger.500"
                  size="md"
                  _text={{
                    color: 'white',
                  }}
                  onPress={() => {
                    cancelBooking(bookingDetail.id)
                  }}
                >
                  {t(
                    'manageReservationOperator.bookingOperatorDetail.cancelButton'
                  )}
                </Button>
              </HStack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    )
  }
}

export const BookingsOperatorDetail = (props) => {
  const [bookingDate, setBookingDate] = React.useState('')
  const [loading, setIsLoading] = React.useState(true)
  const [bookingHourStart, setBookingHourStart] = React.useState('')
  const [bookingHourEnd, setBookingHourEnd] = React.useState('')
  const [bookingDetail, setBookingDetail] = React.useState({})
  const [bookingKeys, setBookingKeys] = React.useState([])
  const [companyId, setCompanyId] = React.useState('')
  const [inputnewDetail, setInputNewDetail] = React.useState('')
  const { t } = useTranslation()
  const [dictionary, setDictionary] = React.useState({
    additional_field: t(
      'manageReservationOperator.bookingOperatorDetail.additional_field'
    ),
    flight_number: t(
      'manageReservationOperator.bookingOperatorDetail.flightNumber'
    ),
    total_passenger: t(
      'manageReservationOperator.bookingOperatorDetail.totalPassenger'
    ),
    disembarking_passengers: t(
      'manageReservationOperator.bookingOperatorDetail.disembarking_passengers'
    ),
    email: t('manageReservationOperator.bookingOperatorDetail.email'),
    embarking_passengers: t(
      'manageReservationOperator.bookingOperatorDetail.embarking_passengers'
    ),
    next_destination: t(
      'manageReservationOperator.bookingOperatorDetail.next_destination'
    ),
    origin_flight: t(
      'manageReservationOperator.bookingOperatorDetail.origin_flight'
    ),
    passengers_ids: t(
      'manageReservationOperator.bookingOperatorDetail.passengers_ids'
    ),
    pilot_name: t('manageReservationOperator.bookingOperatorDetail.pilot_name'),
    copilot_name: 'Copilot Name',
    tail_number: t(
      'manageReservationOperator.bookingOperatorDetail.tail_number'
    ),
    timezone: t('manageReservationOperator.bookingOperatorDetail.timezone'),
    name: t('manageReservationOperator.bookingOperatorDetail.name'),
    model: t('manageReservationOperator.bookingOperatorDetail.model'),
    certificate_number: t(
      'manageReservationOperator.bookingOperatorDetail.certificate_number'
    ),
  })

  let history = useHistory()

  const [formValuesPassenger, setformValuesPassenger] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])

  const [isVisible, setIsVisible] = React.useState(false)

  const [bookingModalDetail, setBookingModalDetail] = React.useState('')

  const [buttonLoading, setButtonLoading] = React.useState(false)

  const [locale, setLocale] = React.useState('')

  const [errorEditDetail, setErrorEditDetail] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [error, setError] = React.useState('')
  let { id } = useParams()

  const bookingStatus = {
    tentative: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.tentative'
    ),
    confirmed: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.confirmed'
    ),
    cancelled_by_customer: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.cancelledCustomer'
    ),
    cancelled: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.cancelled'
    ),
    declined: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.declined'
    ),
    completed: t(
      'manageReservationOperator.bookingOperatorDetail.bookingStatus.completed'
    ),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  React.useEffect(async () => {
    await getBookingDetails(id)
    const currentLocale = await AsyncStorage.getItem('i18nextLng')
    setLocale(currentLocale)
    setIsLoading(false)
  }, [])

  const getBookingDetails = async (id) => {
    try {
      let booking = {}
      const getBooking = await axiosInstance.post(`/bookings/bookingById`, {
        id: id,
      })
      booking = getBooking.data.booking
      if (
        booking.meta &&
        booking.meta.canceled_id &&
        booking.meta.reschedule_id
      ) {
        history.push(`/bookingOperatorDetail/${booking.meta.reschedule_id}`)
        const getRedirectBooking = await axiosInstance.post(
          `/bookings/bookingById`,
          {
            id: booking.meta.reschedule_id,
          }
        )
        booking = getRedirectBooking.data.booking
      }
      const company =
        booking.meta && booking.meta.company_id ? booking.meta.company_id : ''
      setCompanyId(company)
      setBookingDetail(booking)

      const bookingOperatorDetails = {
        flight_number:
          booking.meta && booking.meta.flight_number
            ? booking.meta.flight_number
            : 'None',
        total_passenger:
          booking.meta && booking.meta.total_passenger
            ? booking.meta.total_passenger
            : '0',
        disembarking_passengers:
          booking.meta && booking.meta.disembarking_passengers
            ? booking.meta.disembarking_passengers
            : booking.attributes.customer.disembarking_passengers,
        embarking_passengers:
          booking.meta && booking.meta.embarking_passengers
            ? booking.meta.embarking_passengers
            : booking.attributes.customer.embarking_passengers,
        name:
          booking.meta && booking.meta.name
            ? booking.meta.name
            : booking.attributes.customer.name,
        email:
          booking.meta && booking.meta.email
            ? booking.meta.email
            : booking.attributes.customer.email,
        tail_number:
          booking.meta && booking.meta.tail_number
            ? booking.meta.tail_number
            : booking.attributes.customer.tail_number,
        pilot_name:
          booking.meta && booking.meta.pilot_name
            ? booking.meta.pilot_name
            : booking.attributes.customer.pilot_name,
        copilot_name:
          booking.meta && booking.meta.copilot_name
            ? booking.meta.copilot_name
            : 'None',
        next_destination:
          booking.meta && booking.meta.next_destination
            ? booking.meta.next_destination
            : booking.attributes.customer.next_destination,
        origin_flight:
          booking.meta && booking.meta.origin_flight
            ? booking.meta.origin_flight
            : booking.attributes.customer.origin_flight,
      }
      let keys = Object.keys(bookingOperatorDetails)

      setBookingKeys(keys)
      const date = moment(booking.attributes.event.start)
        .tz(booking.meta.timezone ? booking.meta.timezone : undefined)
        .format('LL')
      const bookingStart = moment(booking.attributes.event.start)
        .tz(booking.meta.timezone ? booking.meta.timezone : undefined)
        .format('HH:mm')
      const bookingEnd = moment(booking.attributes.event.end)
        .tz(booking.meta.timezone ? booking.meta.timezone : undefined)
        .format('HH:mm')
      setBookingDate(date)
      setBookingHourStart(bookingStart)
      setBookingHourEnd(bookingEnd)
    } catch (error) {
      console.log(error)
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('bookingOperatorDetail.error'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('bookingOperatorDetail.error'))
      }
      openError()
    }
  }

  const sendBookingDeclined = (booking) => {
    setBookingDetail(booking)
  }

  const openModal = (booking) => {
    setBookingModalDetail(booking)
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setInputNewDetail('')
    setIsVisible(false)
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  let addFormFields = () => {
    setformValuesPassenger([
      ...formValuesPassenger,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues.splice(i, 1)
    setformValuesPassenger(newFormValues)
  }

  let handleChangeFirstName = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].firstName = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeLastName = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].lastName = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeId = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].id = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeDocumentType = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].documentType = e
    setformValuesPassenger(newFormValues)
  }

  const getPassengers = (passengers) => {
    if (passengers[0].firstName) {
      const passengerNames = passengers.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const passengerToString = passengerNames.toString()

      return passengerToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const handleChange = (event) => setInputNewDetail(event.target.value)

  const editBookingDetail = async (detail) => {
    try {
      setButtonLoading(true)
      if (
        detail === 'disembarking_passengers' ||
        detail === 'embarking_passengers'
      ) {
        bookingDetail.meta[detail] = getPassengers(formValuesPassenger)
      } else {
        bookingDetail.meta[detail] = inputnewDetail
      }
      const newBooking = await axiosInstance.post(
        `/bookings/editBookingDetail`,
        {
          booking: bookingDetail,
        }
      )
      const bookingId = newBooking.data.booking.id
      setButtonLoading(false)
      closeModal()
      await getBookingDetails(bookingId)
    } catch (error) {
      setButtonLoading(false)
      if (error.response && error.response.data && error.response.data.errors) {
        setErrorEditDetail('Sorry, we can edit your reservation.')
      } else {
        setErrorEditDetail(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <View>
      <AppBarHeader />
      <View pl="10%" pr="10%">
        <Heading p="1%">
          {t('manageReservationOperator.bookingOperatorDetail.title')}
        </Heading>
        <Box shadow="9" rounded="2xl">
          <Box rounded="2xl" bgColor="white">
            <VStack space={3}>
              <View
                style={{
                  backgroundColor: '#FEFBEC',
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
                p="8"
              >
                <HStack space={3}>
                  <Text fontSize={['xs', 'xs', 'xl', '2xl']} fontWeight="bold">
                    {bookingDate}
                  </Text>
                  <CircleIcon size={['1', '1', '2', '2']} mt={[2, 2, 2, 4]} />
                  <Text fontSize={['xs', 'xs', 'xl', '2xl']} fontWeight="bold">
                    {bookingHourStart}
                  </Text>
                  <Text fontSize={['xs', 'xs', 'xl', '2xl']} fontWeight="bold">
                    -
                  </Text>
                  <Text fontSize={['xs', 'xs', 'xl', '2xl']} fontWeight="bold">
                    {bookingHourEnd}
                  </Text>
                </HStack>
              </View>

              <NativeBaseProvider>
                <Stack
                  space={3}
                  direction={['column', 'column', 'column', 'row']}
                >
                  <View pl="8" w={['100%', '100%', '100%', '33%']}>
                    <View>
                      <Text color="#B3B3B3" fontSize={['xs', 'xs', 'sm', 'sm']}>
                        {t('managerBookingDetail.operator')}
                      </Text>
                      <Text color="#4D4D4D" fontSize={['xs', 'xs', 'sm', 'md']}>
                        {bookingDetail.meta && bookingDetail.meta.name
                          ? bookingDetail.meta.name
                          : bookingDetail.attributes.customer.name}
                      </Text>
                    </View>
                    <br />
                    <Text color="#B3B3B3" fontSize={['xs', 'xs', 'sm', 'sm']}>
                      {t('managerBookingDetail.status')}
                    </Text>
                    <Text color="#4D4D4D" fontSize={['xs', 'xs', 'sm', 'md']}>
                      {bookingStatus[bookingDetail.state]}
                    </Text>
                    <br />
                    <Text color="#B3B3B3" fontSize={['xs', 'xs', 'sm', 'sm']}>
                      {t('managerBookingDetail.payment')}
                    </Text>
                    <Text color="#4D4D4D" fontSize={['xs', 'xs', 'sm', 'md']}>
                      {bookingDetail.meta.paymentStatus
                        ? getPaymentStatusStripe(bookingDetail)
                        : getPaymentStatus(bookingDetail)}
                    </Text>
                    <br />
                    {bookingDetail.meta.reason ? (
                      <View>
                        <Text
                          color="#B3B3B3"
                          fontSize={['xs', 'xs', 'sm', 'sm']}
                        >
                          {t('managerBookingDetail.reason')}
                        </Text>

                        <Text
                          color="#4D4D4D"
                          fontSize={['xs', 'xs', 'sm', 'md']}
                        >
                          {bookingDetail.meta.reason}
                        </Text>
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                  </View>

                  <View
                    pl={[8, 8, 8, 0]}
                    pr={[8, 8, 8, 0]}
                    w={['100%', '100%', '100%', '33%']}
                  >
                    {bookingKeys.map((booking) => (
                      <View key={booking}>
                        <VStack>
                          <View>
                            <Text
                              color="#B3B3B3"
                              fontSize={['xs', 'xs', 'sm', 'sm']}
                            >
                              {dictionary[booking]}
                            </Text>
                          </View>
                          <HStack>
                            <View width="90%">
                              {bookingDetail.meta &&
                              bookingDetail.meta[booking] ? (
                                <Text
                                  color="#4D4D4D"
                                  fontSize={['xs', 'xs', 'sm', 'md']}
                                >
                                  {bookingDetail.meta[booking]}
                                </Text>
                              ) : (
                                <Text
                                  color="#4D4D4D"
                                  fontSize={['xs', 'xs', 'sm', 'md']}
                                >
                                  {bookingDetail.attributes.customer[booking]}
                                </Text>
                              )}
                            </View>
                            <View width="10%">
                              {booking === 'disembarking_passengers' ||
                              booking === 'embarking_passengers' ||
                              booking === 'flight_number' ? (
                                <Button
                                  size={'sm'}
                                  colorScheme="yellow"
                                  onPress={() => {
                                    openModal(booking)
                                  }}
                                  isDisabled={
                                    bookingDetail.state === 'cancelled' ||
                                    bookingDetail.state === 'declined'
                                      ? true
                                      : false
                                  }
                                >
                                  <Image
                                    source={{
                                      uri: 'https://cdn-icons-png.flaticon.com/512/1159/1159633.png',
                                    }}
                                    alt="Alternate Text"
                                    style={{ width: 15, height: 15 }}
                                  />
                                </Button>
                              ) : (
                                <Text></Text>
                              )}
                            </View>
                          </HStack>
                          <br />
                        </VStack>
                      </View>
                    ))}
                  </View>
                  <View
                    pl="8"
                    pr={[8, 8, 8, 0]}
                    w={['100%', '100%', '100%', '20%']}
                  >
                    <Text
                      pb="2"
                      color="#B3B3B3"
                      fontSize={['xs', 'xs', 'sm', 'sm']}
                    >
                      {t('managerBookingDetail.actualArrival')}
                    </Text>
                    <View borderWidth="2" padding="1" borderRadius="10">
                      <Text color="#979797" fontSize={['xs', 'xs', 'sm', 'md']}>
                        {bookingDetail.meta.actual_arrival
                          ? bookingDetail.meta.actual_arrival
                          : '00:00'}
                      </Text>
                    </View>
                    <br />
                    <Text
                      pb="2"
                      color="#B3B3B3"
                      fontSize={['xs', 'xs', 'sm', 'sm']}
                    >
                      {t('managerBookingDetail.actualDeparture')}
                    </Text>
                    <View borderWidth="2" padding="1" borderRadius="10">
                      <Text color="#979797" fontSize={['xs', 'xs', 'sm', 'md']}>
                        {bookingDetail.meta.actual_departure
                          ? bookingDetail.meta.actual_departure
                          : '00:00'}
                      </Text>
                    </View>
                  </View>
                </Stack>
              </NativeBaseProvider>
              <View p="8" alignItems="end">
                <Actions
                  bookingDetail={bookingDetail}
                  companyId={companyId}
                  onAction={sendBookingDeclined}
                />
              </View>
            </VStack>
          </Box>
        </Box>
      </View>
      <p />
      <Modal isOpen={isVisible} onClose={() => closeModal()} flex={1}>
        <Modal.Content maxWidth="550px">
          <Modal.CloseButton />
          <Modal.Header>{t('managerBookingDetail.editBooking')}</Modal.Header>
          <Modal.Body>
            <Text fontSize="md" fontWeight="bold">
              {bookingModalDetail.replaceAll('_', ' ')}
            </Text>
            {bookingModalDetail === 'disembarking_passengers' ||
            bookingModalDetail === 'embarking_passengers' ? (
              <View>
                {formValuesPassenger.map((element, index) => (
                  <Stack
                    space={1}
                    key={index}
                    p="1%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="First Name"
                      value={element.firstName || ''}
                      onChange={(e) => handleChangeFirstName(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="Last Name"
                      value={element.lastName || ''}
                      onChange={(e) => handleChangeLastName(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    {locale === 'pt' ? (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder="Document"
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item label="Passporte" value="Passporte" />
                        <Select.Item label="CPF" value="CPF" />
                        <Select.Item label="RG" value="RG" />
                      </Select>
                    ) : (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder="Document"
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item label="Passport" value="Passport" />
                        <Select.Item
                          label="Driver's License"
                          value="Driver's License"
                        />
                      </Select>
                    )}

                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="ID"
                      value={element.id || ''}
                      onChange={(e) => handleChangeId(index, e)}
                      width={['100%', '100%', '100%', '40%']}
                    />

                    {index ? (
                      <Pressable
                        onPress={() => removeFormFields(index)}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Image
                          source={{
                            uri: minus_icon,
                          }}
                          alt="Alternate Text"
                          style={{ width: 20, height: 20 }}
                        />
                      </Pressable>
                    ) : null}

                    <Pressable
                      onPress={() => addFormFields()}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: button_plus,
                        }}
                        alt="Alternate Text"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </Stack>
                ))}
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errorEditDetail}
                </Text>
              </View>
            ) : (
              <Input
                value={inputnewDetail}
                onChange={handleChange}
                placeholder={bookingModalDetail}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal()
                }}
              >
                {t('managerBookingDetail.cancel')}
              </Button>
              <Button
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
                colorScheme="yellow"
                onPress={() => {
                  editBookingDetail(bookingModalDetail)
                }}
              >
                {t('managerBookingDetail.save')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showError}>
        <ModalError error={error} onClose={closeError} />
      </Modal>
    </View>
  )
}
export default BookingsOperatorDetail
