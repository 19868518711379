import React from 'react'
import {
  Text,
  Modal,
  HStack,
  VStack,
  View,
  Input,
  Avatar,
  Button,
  Box,
  Center,
  Image,
  Tooltip,
} from 'native-base'
import explanation from '../../assets/question_icon.png'
import { useMutation } from '@apollo/client'
import { GET_PILOTS } from '../../graphql/queries'
import { auth, storage } from '../../utils/nhost'
import {
  UPDATE_PILOT,
  DELETE_PILOT,
  CREATE_PILOT_IMAGE,
} from '../../graphql/mutations'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import ImageUploading from 'react-images-uploading'
import { useTranslation } from 'react-i18next'
import ModalError from '../../components/ModalError'
import { HASURA } from '@env'

const Actions = ({ pilot, user, onAction, onEdit, index }) => {
  const [show, setShow] = React.useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [images, setImages] = React.useState('')
  const maxNumber = 1
  const [createPilotImage] = useMutation(CREATE_PILOT_IMAGE)
  const [updatePilot] = useMutation(UPDATE_PILOT)
  const [deletePilot] = useMutation(DELETE_PILOT)
  const { t } = useTranslation()
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [buttonEditLoading, setButtonEditLoading] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificateNumber: pilot.certificate_number,
      firstName: pilot.first_name,
      lastName: pilot.last_name,
      email: pilot.email,
      selectedCallingCode: pilot.phone_number,
    },
  })

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    try {
      let res = await fetch(`${HASURA}/v1/storage/files/`, {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      let responseJson = await res.json()
      const imageId = responseJson.processedFiles[0].id
      return imageId
    } catch (error) {
      setError(t('pilots.errors.update'))
      openError()
    }
  }

  const onSubmit = async (pilotInformation) => {
    setButtonEditLoading(true)
    try {
      if (phoneValid === true) {
        if (images.length > 0) {
          const imageId = await uploadImage()
          const pilotImage = await createPilotImage({
            variables: {
              file_id: imageId,
            },
          })
          await updatePilot({
            variables: {
              id: pilot.id,
              certificate_number: pilotInformation.certificateNumber
                ? pilotInformation.certificateNumber
                : pilot.certificate_number,
              email: pilotInformation.email
                ? pilotInformation.email
                : pilot.email,
              first_name: pilotInformation.firstName
                ? pilotInformation.firstName
                : pilot.first_name,
              last_name: pilotInformation.lastName
                ? pilotInformation.lastName
                : pilot.last_name,
              phone_number: pilotInformation.selectedCallingCode
                ? pilotInformation.selectedCallingCode
                : pilot.phone_number,
              pilot_image_id: pilotImage
                ? pilotImage.data.insert_pilot_image_one.id
                : pilot.pilot_image_id,
            },
            refetchQueries: [
              {
                query: GET_PILOTS,
                variables: {
                  userId: user,
                },
              },
            ],
          })
          setImages([])
          setButtonEditLoading(false)
          closeModalEdit()
          window.location.reload()
        } else {
          const updatedPilot = await updatePilot({
            variables: {
              id: pilot.id,
              certificate_number: pilotInformation.certificateNumber
                ? pilotInformation.certificateNumber
                : pilot.certificate_number,
              email: pilotInformation.email
                ? pilotInformation.email
                : pilot.email,
              first_name: pilotInformation.firstName
                ? pilotInformation.firstName
                : pilot.first_name,
              last_name: pilotInformation.lastName
                ? pilotInformation.lastName
                : pilot.last_name,
              phone_number: pilotInformation.selectedCallingCode
                ? pilotInformation.selectedCallingCode
                : pilot.phone_number,
              pilot_image_id: pilot.pilot_image_id,
            },
            refetchQueries: [
              {
                query: GET_PILOTS,
                variables: {
                  userId: user,
                },
              },
            ],
          })
          onEdit(updatedPilot.data.update_pilots_by_pk, index)
          setButtonEditLoading(false)
          closeModalEdit()
        }
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonEditLoading(false)
      }
    } catch (error) {
      setButtonEditLoading(false)
      setError(t('pilots.errors.update'))
      openError()
    }
  }

  const deletePilotInformation = async () => {
    try {
      await deletePilot({
        variables: {
          id: pilot.id,
        },
        refetchQueries: [
          {
            query: GET_PILOTS,
            variables: {
              userId: user,
            },
          },
        ],
      })
      onAction(pilot.id)
    } catch (error) {
      setError(t('pilots.errors.delete'))
      openError()
    }
  }
  const openModalEdit = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModalEdit = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  return (
    <View>
      <HStack space={3}>
        <Button
          colorScheme="yellow"
          onPress={() => {
            openModalEdit()
          }}
        >
          {t('pilots.editButton')}
        </Button>
        <Button
          colorScheme="warning"
          onPress={() => {
            window.scrollTo(0, 0), setShowDelete(true)
          }}
        >
          {t('pilots.deleteButton')}
        </Button>
      </HStack>

      <Modal isOpen={show} onClose={() => closeModalEdit()} flex={1}>
        <Modal.Content backgroundColor="white">
          <Modal.CloseButton />
          <Modal.Header>{t('pilots.editTitle')}</Modal.Header>
          <Modal.Body>
            <View>
              <Text bold fontSize={['xs', 'sm', 'sm', 'md']} p="2">
                {t('pilots.currentPilotImage')}
              </Text>
              {images.length === 0 ? (
                <Center>
                  <Avatar
                    bg="yellow.400"
                    size="xl"
                    source={{
                      uri: pilot.urlImage,
                    }}
                  ></Avatar>
                </Center>
              ) : (
                <Text></Text>
              )}

              <br />
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <View className="upload__image-wrapper">
                    {imageList.map((image, index) => (
                      <Center key={index}>
                        <Image
                          source={{
                            uri: `${image['data_url']}`,
                          }}
                          alt="Alternate Text"
                          size={'xl'}
                          resizeMode="cover"
                          borderRadius={100}
                        />
                        <HStack space={3} p="4">
                          <Box shadow={2} maxWidth="40">
                            <Text pt="2" isTruncated="true">
                              {image.file.name}
                            </Text>
                          </Box>
                          <Button
                            colorScheme="yellow"
                            onPress={() => onImageUpdate(index)}
                          >
                            {t('pilots.updateButton')}
                          </Button>
                          <Button
                            colorScheme="red"
                            onPress={() => onImageRemove(index)}
                          >
                            {t('pilots.removeButton')}
                          </Button>
                        </HStack>
                      </Center>
                    ))}
                    <Center>
                      <Button
                        width="40%"
                        colorScheme="yellow"
                        size="md"
                        style={isDragging ? { color: 'red' } : undefined}
                        onPress={onImageUpload}
                        {...dragProps}
                      >
                        {t('pilots.uploadImage')}
                      </Button>
                    </Center>
                  </View>
                )}
              </ImageUploading>
            </View>

            <Text bold fontSize="md" p="2">
              {t('pilots.pilotInformation')}
            </Text>
            <Text bold fontSize="s" p="2">
              {t('pilots.certificateNumber')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.certificateNumber')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., BRA 2345"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="certificateNumber"
            />
            {errors.certificateNumber && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.firstName')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                  message: t('register.onlyLetters'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.firstName')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., John"
                    >
                      <Image
                        bg="transparent"
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="firstName"
            />
            {errors.firstName && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.firstName.message
                  ? errors.firstName.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.lastName')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                  message: t('register.onlyLetters'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.lastName')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., Smith"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="lastName"
            />
            {errors.lastName && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.lastName.message
                  ? errors.lastName.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.email')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder={t('pilots.email')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: 'white' }}
                  variant="unstyled"
                  borderWidth={1}
                  InputRightElement={
                    <Tooltip
                      bg="yellow.100"
                      _text={{
                        color: 'black',
                      }}
                      placement="left"
                      label="e.g., john@altaport.com"
                    >
                      <Image
                        source={{
                          uri: explanation,
                        }}
                        alt="Alternate Text"
                        mr={2}
                        style={{ width: 20, height: 20 }}
                      />
                    </Tooltip>
                  }
                />
              )}
              name="email"
            />
            {errors.email && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.email.message
                  ? errors.email.message
                  : t('pilots.required')}
              </Text>
            )}
            <Text bold fontSize="s" p="2">
              {t('pilots.phone')}
            </Text>
            <VStack space={1}>
              <HStack space={1}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      inputStyle={{
                        width: '100%',
                        backgroundColor: 'white',
                        borderColor: '#E3E3E3',
                      }}
                      buttonStyle={{ borderColor: '#D4D4D4' }}
                      masks={{ br: '.. .........' }}
                      placeholder={placeholderPhone()}
                      value={value}
                      onChange={onChange}
                      isValid={(value, country) => {
                        if (
                          value.length > 1 &&
                          country.name === 'United States' &&
                          value.length < 11
                        ) {
                          setPhoneValid(false)
                        } else if (
                          value.length > 2 &&
                          country.name === 'Brazil' &&
                          value.length < 12
                        ) {
                          setPhoneValid(false)
                        } else {
                          setPhoneValid(true)
                          return true
                        }
                      }}
                    />
                  )}
                  name="selectedCallingCode"
                />
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., +55 15 99677-1785"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    mt={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              </HStack>
              {errors.selectedCallingCode && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('pilots.required')}
                </Text>
              )}
              {phoneValid === true ? (
                <View />
              ) : (
                <View zIndex="-2">
                  <Text fontSize="xs" color="#eb0100">
                    {errorNumber}
                  </Text>
                </View>
              )}
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModalEdit()
                }}
              >
                {t('pilots.cancelButton')}
              </Button>
              <Button
                colorScheme="yellow"
                onPress={handleSubmit(onSubmit)}
                isLoading={buttonEditLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('pilots.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('pilots.deletePilot')}</Modal.Header>
          <Modal.Body>
            <Text fontSize={['xs', 'sm', 'sm', 'md']} p="2">
              {t('pilots.confirmMessage')}
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button colorScheme="yellow" onPress={deletePilotInformation}>
                {t('pilots.confirmButton')}
              </Button>
              <Button
                colorScheme="danger"
                onPress={() => {
                  setShowDelete(false)
                }}
              >
                {t('pilots.neverMindButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showError}>
        <ModalError error={error} onClose={closeError} />
      </Modal>
    </View>
  )
}
export default Actions
