import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Heading,
  Text,
  Divider,
  Center,
  Button,
  View,
  Input,
  VStack,
} from 'native-base'
import { useMutation } from '@apollo/client'
import {
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_CONTACT,
} from '../../graphql/mutations'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'

const Actions = ({ editOperator, onClose, onEdit }) => {
  const [updateOperator] = useMutation(UPDATE_OPERATOR)
  const [updateOperatorContact] = useMutation(UPDATE_OPERATOR_CONTACT)
  const { t } = useTranslation()
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [buttonEditLoading, setButtonEditLoading] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')

  const updateOperatorInformation = async (data) => {
    setButtonEditLoading(true)
    try {
      if (phoneValid === true) {
        await updateOperatorContact({
          variables: {
            id: editOperator.operators_contact.id,
            address: data.address
              ? data.address
              : editOperator.operators_contact.address,
            email: data.email
              ? data.email
              : editOperator.operators_contact.email,
            name: data.name ? data.name : editOperator.operator_name,
            phone: data.selectedCallingCode
              ? data.selectedCallingCode
              : editOperator.operators_contact.phone,
          },
        })
        const operator = await updateOperator({
          variables: {
            id: editOperator.id,
            operator_company_name: data.companyName
              ? data.companyName
              : editOperator.operator_company_name,
            operator_name: data.name ? data.name : editOperator.operator_name,
          },
        })

        const newOperator = {
          id: editOperator.id,
          operator_company_name: operator.data.update_operators_by_pk
            .operator_company_name
            ? operator.data.update_operators_by_pk.operator_company_name
            : editOperator.operator_company_name,
          operator_name: operator.data.update_operators_by_pk.operator_name
            ? operator.data.update_operators_by_pk.operator_name
            : editOperator.operator_name,
          operators_contact: {
            id: editOperator.operators_contact.id,
            email: operator.data.update_operators_by_pk.operators_contact.email
              ? operator.data.update_operators_by_pk.operators_contact.email
              : editOperator.operators_contact.email,
            phone: operator.data.update_operators_by_pk.operators_contact.phone
              ? operator.data.update_operators_by_pk.operators_contact.phone
              : editOperator.operators_contact.phone,
          },
        }
        onEdit(newOperator)
        onClose()
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonEditLoading(false)
      }
    } catch (error) {
      onClose()
      console.log(error)
    }
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: editOperator.operator_company_name,
      name: editOperator.operator_name,
      email: editOperator.operators_contact.email,
      selectedCallingCode: editOperator.operators_contact.phone.toString(),
    },
  })
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  return (
    <Center>
      <View
        backgroundColor="light.100"
        shadow="9"
        rounded="2xl"
        p="4"
        width="300"
      >
        <Heading size="lg">{t('operatorAccount.titleModal')}</Heading>
        <Text pb="2" color="muted.600">
          {t('operatorAccount.informationModal')}
        </Text>
        <Text bold color="muted.600">
          {t('operatorAccount.companyNameLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
              message: t('operatorAccount.lettersAndNumbers'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.companyNamePlaceholder')}
              bgColor="white"
            />
          )}
          name="companyName"
        />
        {errors.companyName && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.companyName.message
              ? errors.companyName.message
              : t('operatorAccount.required')}
          </Text>
        )}

        <Divider />
        <Text pb="2" color="muted.600">
          {t('operatorAccount.contactInformationModal')}
        </Text>
        <Text bold color="muted.600">
          {t('operatorAccount.nameModalLabel')}
        </Text>

        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
              message: t('operatorAccount.onlyLetters'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.nameModalLabel')}
              bgColor="white"
            />
          )}
          name="name"
        />
        {errors.name && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.name.message
              ? errors.name.message
              : t('operatorAccount.required')}
          </Text>
        )}
        <Text bold color="muted.600">
          {t('operatorAccount.emailModalLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.emailModalPlaceholder')}
              bgColor="white"
            />
          )}
          name="email"
        />
        {errors.email && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.email.message
              ? errors.email.message
              : t('operatorAccount.required')}
          </Text>
        )}
        <Text bold color="muted.600">
          {t('operatorAccount.phoneNumberModalLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhoneInput
              inputStyle={{
                width: '100%',
                backgroundColor: 'white',
                borderColor: '#E3E3E3',
              }}
              buttonStyle={{ borderColor: '#D4D4D4' }}
              masks={{ br: '.. .........' }}
              placeholder={placeholderPhone()}
              value={value}
              onChange={onChange}
              isValid={(value, country) => {
                if (
                  value.length > 1 &&
                  country.name === 'United States' &&
                  value.length < 11
                ) {
                  setPhoneValid(false)
                } else if (
                  value.length > 2 &&
                  country.name === 'Brazil' &&
                  value.length < 12
                ) {
                  setPhoneValid(false)
                } else {
                  setPhoneValid(true)
                  return true
                }
              }}
            />
          )}
          name="selectedCallingCode"
        />
        {errors.selectedCallingCode && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {t('operatorAccount.required')}
          </Text>
        )}
        {phoneValid === true ? (
          <View />
        ) : (
          <View zIndex="-2">
            <Text fontSize="xs" color="#eb0100">
              {errorNumber}
            </Text>
          </View>
        )}
        <br />
        <VStack space={1}>
          <Button
            rounded="2xl"
            colorScheme="yellow"
            isLoading={buttonEditLoading}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
            onPress={handleSubmit(updateOperatorInformation)}
          >
            {t('operatorAccount.saveModalButton')}
          </Button>
          <Button
            rounded="2xl"
            colorScheme="red"
            onPress={() => {
              onClose()
            }}
          >
            {t('operatorAccount.cancelModalButton')}
          </Button>
        </VStack>
      </View>
    </Center>
  )
}

export default Actions
