import React from 'react'
import {
  View,
  Center,
  Button,
  Box,
  HStack,
  VStack,
  Image,
  Text,
  ArrowBackIcon,
  Heading,
  Progress,
} from 'native-base'
import { auth } from '../../utils/nhost'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANY_MANAGERS } from '../../graphql/queries'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../routing'
import axiosInstance from '../../AxiosInstance'
import Loading from '../../components/Loading'

export const BankInformation = (props) => {
  const { t } = useTranslation()
  const [externalAccounts, setExternalAccounts] = React.useState([])
  const [companyId, setCompanyId] = React.useState('')
  const [userToken, setUserToken] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')
  let history = useHistory()
  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const token = auth.getJWTToken()
    setUserToken(token)
    const company = await getCompany({ variables: { userId: userId } })

    setCompanyId(company.data.company[0].id)

    const res = await axiosInstance.post(`/booking/account`, {
      token: token,
      company_id: company.data.company[0].id,
    })

    if (res.data && res.data.account) {
      const { account } = await res.data

      if (account.external_accounts) {
        setExternalAccounts(account.external_accounts.data)
      }
    }
    setLoading(false)
  }, [])

  const [getCompany] = useLazyQuery(GET_COMPANY_MANAGERS)
  const createAccount = async () => {
    try {
      const urlAccount = await axiosInstance.post(`/booking/connectAccount`, {
        token: userToken,
        company_id: companyId,
      })
      window.location.href = urlAccount.data.accountLink.url
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('errors.bookings'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('error.connectAccount'))
      }
    }
  }
  const managerAccountLocation = window.location.pathname

  if (loading) return <Loading />
  if (error) return <Text color="#eb0100">{error}</Text>
  return (
    <View pr={['0%', '0%', '30%', '30%']} pl={['0%', '0%', '30%', '30%']}>
      {externalAccounts.length > 0 ? (
        <View>
          <FlatList
            data={externalAccounts}
            keyExtractor={(item) => item.id.toString()}
            ItemSeparatorComponent={() => <Divider />}
            renderItem={({ item }) => (
              <Box
                p="12"
                rounded="xl"
                backgroundColor="white"
                key={item.id.toString()}
              >
                <HStack
                  space={20}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <View>
                    <HStack
                      space={3}
                      style={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Image
                        source={{
                          uri: 'https://cdn-icons-png.flaticon.com/512/522/522554.png',
                        }}
                        alt="Alternate Text"
                        style={{ width: 30, height: 30 }}
                      />
                      <VStack>
                        <HStack space={3}>
                          <Text>{item.bank_name}</Text>
                          <Text>xxxxxxxx{item.last4}</Text>
                        </HStack>
                        <HStack space={3}>
                          <Text>{t('managerAccount.routingNumber')}</Text>
                          <Text>{item.routing_number}</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </View>
                </HStack>
              </Box>
            )}
          />
        </View>
      ) : managerAccountLocation === '/managerAccount' ? (
        <View>
          <p />
          <Center>
            <Button
              onPress={() => {
                createAccount()
              }}
              colorScheme="yellow"
            >
              {t('managerAccount.createBank')}
            </Button>
          </Center>
        </View>
      ) : (
        <View>
          <View style={{ alignItems: 'self-end' }}>
            <Button
              colorScheme="yellow"
              rounded="2xl"
              width="10"
              leftIcon={<ArrowBackIcon size="4" />}
              onPress={() => history.push('/heliportDaysandHours')}
            />
          </View>
          <p />
          <Center>
            <HStack space={5}>
              <View>
                <Center>
                  <Box w={['90%', '90%', '500', '500']}>
                    <Progress
                      bg="yellow.100"
                      _filledTrack={{
                        bg: 'yellow.400',
                      }}
                      size="md"
                      value={66}
                    />
                  </Box>
                  <br />
                  <Heading size="xs">{t('heliportForm.step')} 3 / 3</Heading>
                </Center>
              </View>
            </HStack>
          </Center>
          <p />
          <View>
            <Center>
              <Button
                onPress={() => {
                  createAccount()
                }}
                colorScheme="yellow"
              >
                {t('managerAccount.createBank')}
              </Button>
            </Center>
          </View>
        </View>
      )}
    </View>
  )
}
export default BankInformation
