import React, { Component } from 'react'
import { Router, Switch, Route, Redirect } from '../routing'
import Login from '../auth/Login'
import Register from '../auth/Register'
import ChangePassword from '../auth/ChangePassword'
import ForgotPassword from '../auth/ForgotPassword'
import HomeMvp from '../views/HomeMvp'
import CompanyProfile from '../views/CompanyProfile'
import UserProfile from '../views/UserProfile'
import OperatorAccount from '../views/OperatorAccount'
import HeliportForm from '../views/HeliportForm'
import Heliport from '../views/Heliport'
import SearchHeliports from '../views/SearchHeliports'
import { auth } from '../utils/nhost'
import Fleet from '../views/Fleet'
import Pilot from '../views/Pilot'
import BookingDetail from '../views/BookingDetail'
import ScheduleHeliport from '../views/ScheduleHeliport'
import { AbilityContext } from '../Can'
import BookingsOperator from '../views/BookingsOperator'
import BookingsOperatorDetail from '../views/BookingsOperatorDetail'
import ManageHeliport from '../views/ManageHeliport'
import PaymentSummary from '../views/PaymentSummary'
import BookingForm from '../views/BookingForm'
import ErrorPayment from '../views/ErrorPayment'
import BookingInvoiceConfirmation from '../views/BookingInvoiceConfirmation'
import Reservations from '../views/Reservations'
import OperatorPayments from '../views/OperatorPayments'
import PaymentMethods from '../views/PaymentMethods'
import ManagerAccount from '../views/ManagerAccount'
import ManagerAccountMenu from '../views/ManagerAccountMenu'
import OperatorAccountMenu from '../views/OperatorAccountMenu'
import HeliportDaysandHours from '../views/HeliportDaysandHours'
import BankInformationManager from '../views/BankInformationManager'
import ManualReservationsForm from '../views/ManualReservationsForm'
import ManualReservationPayment from '../views/ManualReservationPayment'
import Loading from '../components/Loading'
import ManualReservationInvoice from '../views/ManualReservationInvoice'
import PaymentSummaryManualReservation from '../views/PaymentSummaryManualReservation'
import BookingFastCode from '../views/BookingFastCode'
import ManagerReports from '../views/ManagerReports'
import NotFound from '../views/NotFound'

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      exact
      strict
      render={(props) =>
        authed !== 'SIGNED_OUT' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/auth/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const Routes = (props) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const ability = React.useContext(AbilityContext)
  React.useLayoutEffect(() => {
    auth.onAuthStateChanged((loggedIn) => {
      setIsAuthenticated(loggedIn)
      setIsLoading(false)
    })
  }, [])
  if (isLoading === true) {
    return <Loading />
  }

  return (
    <Router>
      <Switch>
        <Route path="/auth/login" render={(props) => <Login {...props} />} />
        <Route
          path="/auth/register"
          render={(props) => <Register {...props} />}
        />
        <Route
          path="/auth/forgotPassword"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/auth/changePassword"
          render={(props) => <ChangePassword {...props} />}
        />

        <PrivateRoute
          authed={isAuthenticated}
          path="/companyProfile"
          component={CompanyProfile}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/userProfile"
          component={UserProfile}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/operatorAccount"
          component={OperatorAccount}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/fleet"
          component={Fleet}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/pilots"
          component={Pilot}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/heliportForm"
          component={HeliportForm}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/manageHeliport"
          component={ManageHeliport}
          exact
        />

        <PrivateRoute
          authed={isAuthenticated}
          path="/scheduleHeliport"
          component={ScheduleHeliport}
          exact
          strict
          sensitive
        />

        <PrivateRoute
          authed={isAuthenticated}
          path="/bookingDetail/:id"
          component={BookingDetail}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/manageReservationOperator"
          component={BookingsOperator}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/bookingOperatorDetail/:id"
          component={BookingsOperatorDetail}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/paymentSummary"
          component={PaymentSummary}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/bookingForm"
          component={BookingForm}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/errorPayment"
          component={ErrorPayment}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/operatorPayments"
          component={OperatorPayments}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/paymentMethods"
          component={PaymentMethods}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/invoiceBookingConfirmation"
          component={BookingInvoiceConfirmation}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/managerAccount"
          component={ManagerAccount}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/managerAccountMenu"
          component={ManagerAccountMenu}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/operatorAccountMenu"
          component={OperatorAccountMenu}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/heliportDaysandHours"
          component={HeliportDaysandHours}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/bankInformationManager"
          component={BankInformationManager}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/manualReservation"
          component={ManualReservationsForm}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/manualReservationPayment"
          component={ManualReservationPayment}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/manualReservationInvoiceConfirmation"
          component={ManualReservationInvoice}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/paymentSummaryManualReservation"
          component={PaymentSummaryManualReservation}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/bookingFastCode"
          component={BookingFastCode}
          exact
        />
        <PrivateRoute
          authed={isAuthenticated}
          path="/ManagerReports"
          component={ManagerReports}
          exact
        />
        {ability.can('view', 'ManageReservation') && (
          <PrivateRoute
            authed={isAuthenticated}
            path="/"
            component={Reservations}
            exact
          />
        )}
        {ability.can('view', 'searchHeliports') && (
          <Route
            authed={isAuthenticated}
            path="/searchHeliports"
            component={SearchHeliports}
            exact
            strict
          />
        )}
        {ability.can('view', 'searchHome') && (
          <Route exact path="/" render={(props) => <HomeMvp {...props} />} />
        )}
        {ability.can('view', 'heliport') && (
          <Route
            authed={isAuthenticated}
            path="/:id"
            component={Heliport}
            exact
            strict
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
