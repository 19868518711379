import React from 'react'
import {
  HStack,
  View,
  Image,
  Text,
  Center,
  CircleIcon,
  Link,
} from 'native-base'
import { useTranslation } from 'react-i18next'
import Tos from '../assets/Terms_of_Services_PT.pdf'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <View width="100%" pl={2} pr={2}>
      <Center pt="15%" pb="2%">
        <HStack space={3}>
          <View pt="1">
            <Image
              source={{
                uri: 'https://cdn-icons-png.flaticon.com/512/565/565487.png',
              }}
              alt="Alternate Text"
              width="3"
              height="3"
            />
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text fontSize="12">
              2022 Altaport, Inc. {''}
              <CircleIcon size="2" mb="0" /> {''}{' '}
              <Link
                _text={{ textDecoration: 'none' }}
                isExternal
                rel="Terms of Service"
                href={`https://www.altaport.com.br/privacy-policy`}
              >
                {t('homepage.privacy')}
              </Link>
              {''} {''}
              <CircleIcon size="2" mb="0" />
              {''}{' '}
              <Link
                _text={{ textDecoration: 'none' }}
                isExternal
                rel="Terms of Service"
                href={Tos}
              >
                {t('homepage.terms')}
              </Link>
            </Text>
          </View>
        </HStack>
      </Center>
    </View>
  )
}
export default Footer
