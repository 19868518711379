import React from 'react'
import {
  View,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  Modal,
  Popover,
  Box,
  Input,
  Pressable,
  NativeBaseProvider,
  Alert,
  IconButton,
  CloseIcon,
  Switch,
  Center,
} from 'native-base'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_TIMEZONE } from '../graphql/queries'
import { GET_HELIPORT_MANAGER, GET_COMPANY_MANAGERS } from '../graphql/queries'
import AppBarHeader from '../components/AppBar'
import CalendarView from '../components/Reservations/CalendarView'
import ListView from '../components/Reservations/ListView'
import { getToken } from '../Token'
import Loading from '../components/Loading'
import TimePicker from 'rc-time-picker'
import '../assets/index.css'
import moment from 'moment'
import { SERVER_SENGRID } from '@env'
import { auth } from '../utils/nhost'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'
import { useLocation, Link } from '../routing'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

export const Reservations = (props) => {
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [isEnabled, setIsEnabled] = React.useState(false)
  const [user, setUserId] = React.useState('')
  const [visibleEdit, setIsVisibleEdit] = React.useState(false)
  const [visibleErrorAccount, setIsVisibleErrorAccount] = React.useState(false)
  const [blockDateStart, setBlockDateStart] = React.useState('')
  const [blockDateEnd, setBlockDateEnd] = React.useState('')
  const [dateInput, setDateInput] = React.useState(moment().format('ddd, ll'))
  const [dateInputEnd, setDateInputEnd] = React.useState('')
  const [date, setDate] = React.useState(new Date())
  const [startHour, setStartHour] = React.useState(moment('00:00', 'HH:mm'))
  const [endHour, setEndHour] = React.useState(moment('00:00', 'HH:mm'))
  const [companyId, setCompanyId] = React.useState('')
  const [userToken, setUserToken] = React.useState('')
  const { t } = useTranslation()
  const l = useTranslation()
  const [stripeAccount, setStripeAccount] = React.useState('')
  const [modalAccount, setModalAccount] = React.useState(false)
  const [value] = React.useState(new Date())
  const [endValue] = React.useState(new Date())
  const language = l.i18n.languages[0]

  const toggleSwitch = async () => {
    if (isEnabled === false) {
      props.history.push('/?view=calendar')
    } else {
      props.history.push('/?view=listView')
    }
    setIsEnabled((previousState) => !previousState)
  }
  const [isVisibleBlockTimeError, setIsVisibleBlockTimeError] =
    React.useState(false)
  const [errorBlockTime, setErrorBlockTime] = React.useState('')
  const [emptyFile, setEmptyFile] = React.useState('')
  const [errorDates, setErrorDates] = React.useState('')

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  const [getCompany] = useLazyQuery(GET_COMPANY_MANAGERS)

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleEdit(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleEdit(false)
  }

  const openModalErrorBlockTime = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleBlockTimeError(true)
  }

  const closeModalErrorBlockTime = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleBlockTimeError(false)
  }

  const openModalError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleErrorAccount(true)
  }

  const closeModalError = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleErrorAccount(false)
  }

  const openModalAccount = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setModalAccount(true)
  }

  const closeModalAccount = () => {
    document.body.style.overflow = 'scroll'
    setModalAccount(false)
  }

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const token = auth.getJWTToken()
    setUserToken(token)
    const view = query.get('view')
    if (view === 'calendar') {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
    const company = await getCompany({ variables: { userId: userId } })
    setStripeAccount(company.data.company[0].account_stripe_id)
    setCompanyId(company.data.company[0].id)
    const res = await fetch(`${SERVER_SENGRID}/booking/account`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: token,
        company_id: company.data.company[0].id,
      }),
    })

    const { account } = await res.json()
    if (account.charges_enabled === false) {
      openModalError()
    }
  }, [])

  const { loading, error, data } = useQuery(GET_HELIPORT_MANAGER, {
    variables: { userId: user },
  })
  const onChangeDate = (newDate) => {
    const getDatePicker = newDate.toDateString()
    setBlockDateStart(getDatePicker)
    setDateInput(moment(getDatePicker).format('ddd, ll'))
  }

  const onChangeDateEnd = (newDate) => {
    const getDatePickerEnd = newDate.toDateString()
    setBlockDateEnd(getDatePickerEnd)
    setDateInputEnd(moment(getDatePickerEnd).format('ddd, ll'))
    setEmptyFile('')
  }
  const [getTimeZoneHeliport] = useLazyQuery(GET_HELIPORT_TIMEZONE)

  const editBlockTime = async () => {
    setErrorDates('')
    if (blockDateEnd) {
      setButtonLoading(true)
      const heliportTimeZone = await getTimeZoneHeliport({
        variables: { userId: user },
      })
      const initialDate = !blockDateStart ? date : blockDateStart
      const dateStartBooking = moment(initialDate).format('YYYY-MM-DD')
      const dateEndBooking = moment(blockDateEnd).format('YYYY-MM-DD')
      try {
        await axiosInstance.post(`/reservations/blockTime`, {
          timezone:
            heliportTimeZone.data.heliports[0].heliport_profile.timezone,
          dateStart: dateStartBooking,
          startHour: startHour.format('HH:mm'),
          dateEnd: dateEndBooking,
          projectId:
            heliportTimeZone.data.heliports[0].heliport_profile
              .timekit_project_id,
          endHour: endHour.format('HH:mm'),
        })
        setButtonLoading(false)
        closeModal()
      } catch (error) {
        setErrorBlockTime(error.response.data.error.message)
        setButtonLoading(false)
        closeModal()
        openModalErrorBlockTime()
      }
    } else {
      setEmptyFile('Please select a date')
    }
  }

  const fixErrorAccount = async () => {
    const res = await fetch(`${SERVER_SENGRID}/booking/errorAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: userToken,
        company_id: companyId,
      }),
    })
    const body = await res.json()
    window.location.href = body.accountLink.url
  }

  const changeSwitchTrue = async () => {
    props.history.push('/?view=calendar')
    setIsEnabled(true)
  }

  const changeSwitchFalse = async () => {
    props.history.push('/?view=listView')
    setIsEnabled(false)
  }

  const manualReservations = () => {
    if (stripeAccount) {
      props.history.push(
        `/scheduleHeliport?heliport=${data.heliports[0].heliport_profile.timekit_project_id}&company=${data.heliports[0].company.id}`
      )
    } else {
      openModalAccount()
    }
  }

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  return (
    <View>
      <AppBarHeader />
      {data.heliports.length > 0 && data.heliports[0].heliport_profile ? (
        <View>
          <NativeBaseProvider>
            <View pl="10%" pr="10%">
              <View>
                <Text bold fontSize="xl">
                  {t('reservations.title')}
                </Text>
                <p />
                <View alignItems="flex-end">
                  <VStack space={6}>
                    <Button
                      onPress={() => {
                        manualReservations()
                      }}
                      colorScheme="yellow"
                    >
                      {t('reservations.addReservationsManually')}
                    </Button>
                    <Button
                      width="100%"
                      onPress={() => {
                        openModal()
                      }}
                      colorScheme="yellow"
                    >
                      {t('reservations.blockTimes')}
                    </Button>
                    <View alignItems="center">
                      <HStack space={3}>
                        <Pressable
                          onPress={() => {
                            changeSwitchFalse()
                          }}
                        >
                          <VStack space={1} alignItems="center">
                            <Image
                              source={{
                                uri: 'https://cdn-icons-png.flaticon.com/512/839/839860.png',
                              }}
                              alt="Alternate Text"
                              style={{ width: 30, height: 30 }}
                            />
                            <Text>{t('reservations.listView')}</Text>
                          </VStack>
                        </Pressable>
                        <Switch
                          offTrackColor="light.500"
                          onTrackColor="orange.200"
                          onThumbColor="yellow.500"
                          offThumbColor="orange.50"
                          onToggle={toggleSwitch}
                          isChecked={isEnabled}
                        />
                        <Pressable
                          onPress={() => {
                            changeSwitchTrue()
                          }}
                        >
                          <VStack space={1} alignItems="center">
                            <Image
                              source={{
                                uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                              }}
                              alt="Alternate Text"
                              style={{ width: 30, height: 30 }}
                            />
                            <Text>{t('reservations.calendarView')}</Text>
                          </VStack>
                        </Pressable>
                      </HStack>
                    </View>
                  </VStack>
                </View>
              </View>
            </View>
          </NativeBaseProvider>
          <View>{isEnabled ? <CalendarView /> : <ListView />}</View>
        </View>
      ) : (
        <Center>
          <Alert status="error">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack
                flexShrink={1}
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{
                      color: 'coolGray.800',
                    }}
                  >
                    {t('errors.completeInformation')}
                  </Text>
                  <Link
                    style={{ color: '#4D4D4D' }}
                    rel="Heliport Form"
                    to={'/heliportForm'}
                  >
                    <Text>{t('errors.errorHeliportForm')}</Text>
                  </Link>
                </HStack>
              </HStack>
            </VStack>
          </Alert>
        </Center>
      )}

      <Modal
        isOpen={visibleEdit}
        onClose={() => closeModal(false)}
        flex={1}
        rounded="2xl"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('reservations.bookingBlockTimes')}</Modal.Header>
          <Modal.Body>
            <Text pb="2" fontWeight="bold">
              {t('reservations.startDateHour')}
            </Text>
            <HStack space={3}>
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Box widht="100%" borderWidth="1" borderRadius="4">
                      <HStack>
                        <Button {...triggerProps} colorScheme="yellow">
                          <Image
                            source={{
                              uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                            }}
                            alt="Alternate Text"
                            style={{ width: 12, height: 12 }}
                          />
                        </Button>
                        <View width="90%">
                          <Pressable {...triggerProps}>
                            <Input
                              value={dateInput}
                              borderColor="transparent"
                              width="90%"
                              isReadOnly={true}
                            />
                          </Pressable>
                        </View>
                      </HStack>
                    </Box>
                  )
                }}
              >
                <Popover.Content accessibilityLabel="Delete Customerd">
                  <Popover.Arrow />
                  <Popover.Body>
                    <Calendar
                      onChange={onChangeDate}
                      locale={language}
                      value={value}
                      maxDate={new Date(2150, 11, 31)}
                      minDate={new Date(2019, 11, 31)}
                    />
                  </Popover.Body>
                </Popover.Content>
              </Popover>
              <TimePicker
                showSecond={false}
                value={startHour}
                onChange={setStartHour}
              />
            </HStack>
            <Text pb="2" pt="2" fontWeight="bold">
              {t('reservations.endDateHour')}
            </Text>
            <HStack space={3}>
              <VStack>
                <Popover
                  trigger={(triggerProps) => {
                    return (
                      <Box widht="100%" borderWidth="1" borderRadius="4">
                        <HStack>
                          <Button {...triggerProps} colorScheme="yellow">
                            <Image
                              source={{
                                uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                              }}
                              alt="Alternate Text"
                              style={{ width: 12, height: 12 }}
                            />
                          </Button>
                          <View width="81%">
                            <Pressable {...triggerProps}>
                              <Input
                                value={dateInputEnd}
                                borderColor="transparent"
                                widht="90%"
                                isReadOnly={true}
                              />
                            </Pressable>
                          </View>
                        </HStack>
                      </Box>
                    )
                  }}
                >
                  <Popover.Content accessibilityLabel="Delete Customerd">
                    <Popover.Arrow />
                    <Popover.Body>
                      <Calendar
                        onChange={onChangeDateEnd}
                        locale={language}
                        value={endValue}
                        maxDate={new Date(2150, 11, 31)}
                        minDate={new Date(2019, 11, 31)}
                      />
                    </Popover.Body>
                  </Popover.Content>
                </Popover>
                <Text p="2" fontSize="xs" color="#eb0100">
                  {emptyFile}
                </Text>
              </VStack>

              <TimePicker
                showSecond={false}
                value={endHour}
                onChange={setEndHour}
              />
            </HStack>
            <Text p="2" fontSize="xs" color="#eb0100">
              {errorDates}
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal(false)
                }}
              >
                {t('reservations.cancelButton')}
              </Button>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
                onPress={() => {
                  editBlockTime()
                }}
              >
                {t('reservations.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <p />
      <Modal
        isOpen={visibleErrorAccount}
        onClose={() => closeModalError()}
        flex={1}
        rounded="2xl"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('reservations.errorAccount')}</Modal.Header>
          <Modal.Body>
            <Text>{t('reservations.completeBankInformation')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModalError()
                }}
              >
                {t('reservations.cancelButton')}
              </Button>
              <Button
                colorScheme="yellow"
                rounded="2xl"
                onPress={() => {
                  fixErrorAccount(), closeModalError()
                }}
              >
                {t('reservations.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={modalAccount}
        onClose={() => closeModalAccount()}
        flex={1}
        rounded="2xl"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('reservations.reminder')}</Modal.Header>
          <Modal.Body>
            <Text>{t('reservations.reminderMessage')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModalAccount()
                }}
              >
                {t('reservations.cancelButton')}
              </Button>
              <Button
                colorScheme="yellow"
                rounded="2xl"
                onPress={() => {
                  props.history.push('/managerAccount'), closeModalAccount()
                }}
              >
                {t('reservations.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={isVisibleBlockTimeError}>
        <Alert status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('try_again.text')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => closeModalErrorBlockTime()}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              <Text>{errorBlockTime}</Text>
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default Reservations
