import React from 'react'
import { View, Text, Box, HStack, VStack, Divider } from 'native-base'
import { auth } from '../../utils/nhost'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANY_MANAGERS } from '../../graphql/queries'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import axiosInstance from '../../AxiosInstance'

export const PayoutHistory = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [managerTransfers, setTransfers] = React.useState([])

  const [getCompany] = useLazyQuery(GET_COMPANY_MANAGERS)

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const token = auth.getJWTToken()

    const company = await getCompany({ variables: { userId: userId } })

    const res = await axiosInstance.post(`/booking/transfers`, {
      token: token,
      company_id: company.data.company[0].id,
    })
    if (res.data && res.data.transfers.length > 0) {
      const { transfers } = await res.data
      setTransfers(transfers)
    }

    setLoading(false)
  }, [])

  const getDate = (date) => {
    const paymentDate = new Date(date * 1000)
    const newDate = moment(paymentDate).format('LL')
    return newDate
  }

  const calculateAmount = (amount) => {
    const newAmount = parseFloat(amount) / 100
    return newAmount.toFixed(2)
  }

  if (loading) return <Loading />
  return (
    <View>
      {managerTransfers.length === 0 ? (
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Text bold p={5}>
            {t('managerAccount.noPayment')}
          </Text>
        </View>
      ) : (
        <View pr={['0%', '0%', '20%', '20%']} pl={['0%', '0%', '20%', '20%']}>
          <FlatList
            data={managerTransfers}
            keyExtractor={(item) => item.id.toString()}
            ItemSeparatorComponent={() => <Divider />}
            renderItem={({ item }) => (
              <Box
                p="12"
                rounded="xl"
                backgroundColor="white"
                key={item.id.toString()}
              >
                <HStack
                  space={[7, 7, 12, 12]}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Text bold>{getDate(item.created)}</Text>
                  <VStack space={3}>
                    <Text>{t('managerAccount.payout')}</Text>
                    <HStack space={3}>
                      {t('managerAccount.chaseChecking')}
                      {item.getTransferCharge.payment_method_details.card
                        ? item.getTransferCharge.payment_method_details.card
                            .last4
                        : ''}
                    </HStack>
                  </VStack>
                  <Text>${calculateAmount(item.amount)}</Text>
                </HStack>
              </Box>
            )}
          />
        </View>
      )}
    </View>
  )
}
export default PayoutHistory
