import * as React from 'react'
import { View } from 'native-base'
import PaymentsHistorial from '../components/Payments/PaymentsHistorial'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'

export const OperatorPayments = () => {
  const { t } = useTranslation()
  return (
    <View>
      <AppBarHeader />
      <PaymentsHistorial />
    </View>
  )
}

export default OperatorPayments
