import { gql } from '@apollo/client'
export const CREATE_COMPANY = gql`
  mutation insert_users_companies(
    $email: String
    $phone: numeric
    $company_name: String
  ) {
    insert_users_companies(
      objects: {
        company: {
          data: {
            company_profile: {
              data: {
                email: $email
                phone: $phone
                company_name: $company_name
              }
            }
          }
        }
      }
    ) {
      returning {
        company {
          company_profile {
            phone
            email
          }
        }
      }
    }
  }
`
export const CREATE_PROFILE = gql`
  mutation insert_profile(
    $first_name: String
    $last_name: String
    $phone_number: numeric
  ) {
    insert_profile(
      objects: {
        first_name: $first_name
        last_name: $last_name
        phone_number: $phone_number
      }
    ) {
      affected_rows
      returning {
        first_name
        last_name
        phone_number
        user_id
      }
    }
  }
`
export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $id: uuid!
    $phone_number: numeric
    $last_name: String
    $first_name: String
  ) {
    update_profile_by_pk(
      pk_columns: { id: $id }
      _set: {
        phone_number: $phone_number
        last_name: $last_name
        first_name: $first_name
      }
    ) {
      first_name
      last_name
      id
      phone_number
    }
  }
`
export const UPDATE_COMPANY_PROFILE = gql`
  mutation update_company_profile_by_pk(
    $id: uuid!
    $company_name: String
    $email: String
    $phone: numeric
  ) {
    update_company_profile_by_pk(
      pk_columns: { id: $id }
      _set: { company_name: $company_name, email: $email, phone: $phone }
    ) {
      company_name
      email
      id
      phone
    }
  }
`
export const CREATE_OPERATOR = gql`
  mutation insert_operator(
    $user_id: uuid
    $operator_company_name: String
    $operator_name: String
  ) {
    insert_operators_one(
      object: {
        user_id: $user_id
        operator_company_name: $operator_company_name
        operator_name: $operator_name
      }
    ) {
      id
      operator_company_name
      operator_name
    }
  }
`
export const UPDATE_OPERATOR = gql`
  mutation update_operator(
    $id: uuid!
    $operator_name: String
    $operator_company_name: String
  ) {
    update_operators_by_pk(
      pk_columns: { id: $id }
      _set: {
        operator_name: $operator_name
        operator_company_name: $operator_company_name
      }
    ) {
      operator_company_name
      operator_name
      id
      operators_contact {
        id
        name
        email
        phone
        address
      }
    }
  }
`

export const UPDATE_OPERATOR_CONTACT = gql`
  mutation update_operator_contact(
    $id: uuid!
    $phone: numeric
    $name: String
    $email: String
    $address: String
  ) {
    update_operators_contact_by_pk(
      pk_columns: { id: $id }
      _set: { address: $address, email: $email, name: $name, phone: $phone }
    ) {
      address
      email
      id
      phone
      name
    }
  }
`
export const CREATE_OPERATOR_CONTACT = gql`
  mutation insert_operator_contact(
    $email: String
    $name: String
    $phone: numeric
    $operator_id: uuid
  ) {
    insert_operators_contact_one(
      object: {
        email: $email
        name: $name
        phone: $phone
        operator_id: $operator_id
      }
    ) {
      email
      id
      name
    }
  }
`
export const CREATE_FLEET = gql`
  mutation insert_fleet_one($operator_id: uuid) {
    insert_fleet_one(object: { operator_id: $operator_id }) {
      id
      operator_id
    }
  }
`
export const CREATE_AIRCRAFT = gql`
  mutation insert_aircraft(
    $fleet_id: uuid
    $helicopter_makes_models: String
    $mtow: numeric
    $tail_numbers: String
    $weight_measure_type: String
    $rotor_length: numeric
    $rotor_measure_type: String
    $aircraft_image_id: uuid
  ) {
    insert_aircrafts_one(
      object: {
        fleet_id: $fleet_id
        helicopter_makes_models: $helicopter_makes_models
        mtow: $mtow
        tail_numbers: $tail_numbers
        weight_measure_type: $weight_measure_type
        rotor_length: $rotor_length
        rotor_measure_type: $rotor_measure_type
        aircraft_image_id: $aircraft_image_id
      }
    ) {
      tail_numbers
      weight_measure_type
      mtow
      helicopter_makes_models
      fleet_id
      id
      rotor_length
      aircraft_image_id
      aircraft_image {
        id
        file_id
      }
    }
  }
`

export const UPDATE_AIRCRAFT = gql`
  mutation updat_aircraft_by_id(
    $id: uuid!
    $helicopter_makes_models: String
    $mtow: numeric
    $rotor_length: numeric
    $rotor_measure_type: String
    $tail_numbers: String
    $weight_measure_type: String
    $aircraft_image_id: uuid
  ) {
    update_aircrafts_by_pk(
      pk_columns: { id: $id }
      _set: {
        helicopter_makes_models: $helicopter_makes_models
        mtow: $mtow
        rotor_length: $rotor_length
        rotor_measure_type: $rotor_measure_type
        tail_numbers: $tail_numbers
        weight_measure_type: $weight_measure_type
        aircraft_image_id: $aircraft_image_id
      }
    ) {
      id
      helicopter_makes_models
      mtow
      rotor_length
      rotor_measure_type
      tail_numbers
      weight_measure_type
      aircraft_image_id
    }
  }
`
export const DELETE_AIRCRAFT = gql`
  mutation delete_aircrafts_id($id: uuid!) {
    delete_aircrafts_by_pk(id: $id) {
      id
    }
  }
`

export const CREATE_AIRCRAFT_IMAGE = gql`
  mutation create_aircraft_image($file_id: uuid) {
    insert_aircraft_images_one(object: { file_id: $file_id }) {
      id
      file_id
    }
  }
`

export const CREATE_PILOT = gql`
  mutation insert_pilot(
    $certificate_number: String
    $email: String
    $name: String
    $operator_id: uuid
    $phone_number: String
    $user_id: uuid
    $first_name: String
    $last_name: String
    $pilot_image_id: uuid
  ) {
    insert_pilots_one(
      object: {
        certificate_number: $certificate_number
        email: $email
        phone_number: $phone_number
        operator_id: $operator_id
        first_name: $first_name
        last_name: $last_name
        pilot_image_id: $pilot_image_id
      }
    ) {
      first_name
      last_name
      certificate_number
      email
      id
      phone_number
      pilot_image_id
      pilot_image {
        id
        file_id
      }
    }
  }
`

export const CREATE_PILOT_IMAGE = gql`
  mutation create_pilot_image($file_id: uuid) {
    insert_pilot_image_one(object: { file_id: $file_id }) {
      id
      file_id
    }
  }
`

export const CREATE_HELIPORT = gql`
  mutation insert_heliport($company_id: uuid) {
    insert_heliports_one(object: { company_id: $company_id }) {
      company_id
      created_at
      id
    }
  }
`
export const CREATE_HELIPORT_PROFILE = gql`
  mutation insert_heliport_profile(
    $heliport_description: String
    $heliport_id: uuid
    $helipads_number: numeric
    $fuel_available: Boolean
    $address: String
    $cancellation_policy: String
    $city: String
    $email: String
    $hourly_rate: numeric
    $icao_code: String
    $landing_fee: numeric
    $landing_slot_length: String
    $latitude: numeric
    $longitude: numeric
    $manager_name: String
    $name: String
    $other_restrictions: String
    $overnight_rate: numeric
    $phone: String
    $reservations: String
    $size_limit_weigth: numeric
    $state: String
    $timezone: String
    $timekit_project_id: uuid
    $country: String
    $measure_limit_weigth: String
    $measure_rotor_diameter: String
    $heliport_image_id: uuid
    $heliport_currency: String
    $timezone_number: numeric
    $limit_late_booking: String
    $surface_type: String
    $parking_available: String
    $overnight_rate_currency: String
    $hourly_rate_currency: String
  ) {
    insert_heliport_profile_one(
      object: {
        heliport_id: $heliport_id
        heliport_description: $heliport_description
        helipads_number: $helipads_number
        fuel_available: $fuel_available
        email: $email
        city: $city
        cancellation_policy: $cancellation_policy
        address: $address
        hourly_rate: $hourly_rate
        icao_code: $icao_code
        landing_fee: $landing_fee
        landing_slot_length: $landing_slot_length
        latitude: $latitude
        longitude: $longitude
        manager_name: $manager_name
        name: $name
        other_restrictions: $other_restrictions
        overnight_rate: $overnight_rate
        phone: $phone
        reservations: $reservations
        size_limit_weigth: $size_limit_weigth
        state: $state
        timezone: $timezone
        timekit_project_id: $timekit_project_id
        country: $country
        measure_limit_weigth: $measure_limit_weigth
        measure_rotor_diameter: $measure_rotor_diameter
        heliport_image_id: $heliport_image_id
        heliport_currency: $heliport_currency
        timezone_number: $timezone_number
        limit_late_booking: $limit_late_booking
        surface_type: $surface_type
        parking_available: $parking_available
        overnight_rate_currency: $overnight_rate_currency
        hourly_rate_currency: $hourly_rate_currency
      }
    ) {
      address
      cancellation_policy
      city
      created_at
      email
      fuel_available
      helipads_number
      heliport_description
      heliport_id
      hourly_rate
      icao_code
      id
      landing_fee
      landing_slot_length
      latitude
      longitude
      manager_name
      name
      other_restrictions
      overnight_rate
      phone
      reservations
      size_limit_weigth
      state
      timezone
      heliport_currency
      surface_type
      limit_late_booking
      parking_available
      overnight_rate_currency
      hourly_rate_currency
    }
  }
`
export const CREATE_HELIPAD = gql`
  mutation insert_helipad(
    $name: String
    $heliport_id: uuid
    $timekit_resource_id: uuid
    $helipad_image_id: uuid
  ) {
    insert_helipads_one(
      object: {
        name: $name
        heliport_id: $heliport_id
        timekit_resource_id: $timekit_resource_id
        helipad_image_id: $helipad_image_id
      }
    ) {
      heliport_id
      id
      name
      timekit_resource_id
    }
  }
`
export const UPDATE_HELIPORT_PROFILE = gql`
  mutation update_heliport_profile(
    $id: uuid!
    $cancellation_policy: String
    $address: String
    $city: String
    $email: String
    $fuel_available: Boolean
    $helipads_number: numeric
    $heliport_description: String
    $hourly_rate: numeric
    $icao_code: String
    $landing_fee: numeric
    $landing_slot_length: String
    $latitude: numeric
    $longitude: numeric
    $manager_name: String
    $name: String
    $other_restrictions: String
    $overnight_rate: numeric
    $parking_fee: numeric
    $phone: String
    $reservations: String
    $size_limit_weigth: numeric
    $state: String
    $timezone: String
    $measure_limit_weigth: String
    $measure_rotor_diameter: String
    $heliport_currency: String
    $heliport_image_id: uuid
    $limit_late_booking: String
    $surface_type: String
    $parking_available: String
    $overnight_rate_currency: String
    $hourly_rate_currency: String
  ) {
    update_heliport_profile_by_pk(
      pk_columns: { id: $id }
      _set: {
        address: $address
        cancellation_policy: $cancellation_policy
        city: $city
        email: $email
        fuel_available: $fuel_available
        helipads_number: $helipads_number
        heliport_description: $heliport_description
        hourly_rate: $hourly_rate
        icao_code: $icao_code
        landing_fee: $landing_fee
        landing_slot_length: $landing_slot_length
        latitude: $latitude
        longitude: $longitude
        manager_name: $manager_name
        name: $name
        other_restrictions: $other_restrictions
        overnight_rate: $overnight_rate
        parking_fee: $parking_fee
        phone: $phone
        reservations: $reservations
        size_limit_weigth: $size_limit_weigth
        state: $state
        timezone: $timezone
        measure_limit_weigth: $measure_limit_weigth
        measure_rotor_diameter: $measure_rotor_diameter
        heliport_currency: $heliport_currency
        heliport_image_id: $heliport_image_id
        limit_late_booking: $limit_late_booking
        surface_type: $surface_type
        parking_available: $parking_available
        overnight_rate_currency: $overnight_rate_currency
        hourly_rate_currency: $hourly_rate_currency
      }
    ) {
      id
      created_at
      heliport_image_id
    }
  }
`
export const UPDATE_HELIPAD = gql`
  mutation update_helipad($id: uuid!, $name: String) {
    update_helipads_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`
export const DELETE_HELIPAD = gql`
  mutation delete_helipad($id: uuid!) {
    delete_helipads_by_pk(id: $id) {
      id
    }
  }
`
export const UPDATE_PILOT = gql`
  mutation update_pilots_by_pk(
    $id: uuid!
    $certificate_number: String
    $email: String
    $first_name: String
    $last_name: String
    $phone_number: String
    $pilot_image_id: uuid
  ) {
    update_pilots_by_pk(
      pk_columns: { id: $id }
      _set: {
        certificate_number: $certificate_number
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone_number: $phone_number
        pilot_image_id: $pilot_image_id
      }
    ) {
      first_name
      last_name
      id
      certificate_number
      email
      phone_number
      pilot_image_id
      pilot_image {
        id
        file_id
      }
    }
  }
`
export const DELETE_PILOT = gql`
  mutation delete_pilots_by_id($id: uuid!) {
    delete_pilots_by_pk(id: $id) {
      id
    }
  }
`
export const IMAGE_HELIPORT = gql`
  mutation insert_image($file_id: uuid) {
    insert_heliport_images_one(object: { file_id: $file_id }) {
      id
      file_id
    }
  }
`
export const CREATE_AVIABILITY = gql`
  mutation createAviability(
    $booking_graph: String
    $currency: String
    $day: String
    $end_hour: time
    $heliport_profile_id: uuid
    $price: numeric
    $start_hour: time
  ) {
    insert_heliport_aviability_one(
      object: {
        booking_graph: $booking_graph
        currency: $currency
        day: $day
        end_hour: $end_hour
        heliport_profile_id: $heliport_profile_id
        price: $price
        start_hour: $start_hour
      }
    ) {
      booking_graph
      currency
      day
      end_hour
      id
      price
      start_hour
    }
  }
`
export const CREATE_HELIPAD_IMAGE = gql`
  mutation create_helipad_image($file_id: uuid) {
    insert_helipad_images_one(object: { file_id: $file_id }) {
      file_id
      id
    }
  }
`
export const DELETE_HELIPORT_AVIABILITIES = gql`
  mutation deleteHeliportAviabilities($heliportProfileId: uuid) {
    delete_heliport_aviability(
      where: { heliport_profile_id: { _eq: $heliportProfileId } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
