import { defineAbility } from '@casl/ability'
import { getToken } from './Token'

export default function defineAbilityFor(token) {
  if (token) {
    const tokenInformation = getToken(token)

    const userRoles =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
    const roleOperator = userRoles.filter((role) => {
      if (role === 'operator') {
        return role
      }
    })
    const roleManager = userRoles.filter((role) => {
      if (role === 'manager') {
        return role
      }
    })

    if (roleOperator.length > 0 && roleOperator[0] === 'operator') {
      return defineAbility((can) => {
        can('view', 'OperatorMenu'), can('view', 'searchHome')
        can('view', 'searchHeliports'), can('view', 'heliport')
      })
    } else if (roleManager.length > 0 && roleManager[0] === 'manager') {
      return defineAbility((can) => {
        can('view', 'ManagerMenu'), can('view', 'ManageReservation')
      })
    }
  } else {
    return defineAbility((can) => {
      can('view', 'searchHome'),
        can('view', 'PublicMenu'),
        can('view', 'searchHeliports'),
        can('view', 'heliport')
    })
  }
}
