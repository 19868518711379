import React from 'react'
import {
  HStack,
  View,
  Box,
  Alert,
  Heading,
  Divider,
  Text,
  VStack,
  Center,
  Button,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLocation } from '../routing'
import BookingDetailPayment from '../components/BookingDetailPayment'
import { useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_BY_COMPANY } from '../graphql/queries'
import { SERVER_SENGRID } from '@env'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Loading from '../components/Loading'
import axiosInstance from '../AxiosInstance'
import { useTranslation } from 'react-i18next'

export const PaymentSummary = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [paymentIntentInformation, setPaymentIntentInformation] =
    React.useState({})
  const [amount, setAmount] = React.useState(null)
  const [booking, setBooking] = React.useState({})
  const [error, setError] = React.useState(false)
  const { t } = useTranslation()
  const [operatorPhone, setOperatorPhone] = React.useState('')
  const [getHeliport] = useLazyQuery(GET_HELIPORT_BY_COMPANY)
  const [heliportCurrency, setHeliportCurrency] = React.useState({})

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const getSessionId = query.get('session_id')

    const response = await fetch(`${SERVER_SENGRID}/booking/success`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        session_id: getSessionId,
      }),
    })
    const { session } = await response.json()

    setPaymentIntentInformation(session)

    const amount = calculateAmount(session.amount_total)

    const getBooking = await AsyncStorage.getItem('booking')

    const booking = JSON.parse(getBooking)

    const companyId = booking.meta.company_id

    const heliport = await getHeliport({
      variables: { id: companyId },
    })

    setHeliportCurrency(
      heliport.data.company_by_pk.heliports[0].heliport_profile
        .heliport_currency
    )

    setOperatorPhone(booking.meta.phone)

    booking.meta.payment_intent_id = session.payment_intent

    await getBookingCustomer(booking)

    setAmount(amount)

    setLoading(false)
  }, [])

  const calculateAmount = (amount) => {
    const newAmount = amount / 100
    return newAmount
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const getBookingCustomer = async (booking) => {
    try {
      if (booking.graph === 'confirm_decline' && booking.meta.is_created) {
        const getBookingById = await axiosInstance.post(
          `/bookings/bookingById`,
          {
            id: booking.id,
          }
        )
        setBooking(getBookingById.data.booking.attributes.customer)
      } else if (booking.graph === 'confirm_decline') {
        booking.meta = {
          ...booking.meta,
          stripe: 'paid-authorized',
        }
        const getBooking = await axiosInstance.post(
          `/bookings/confirmDeclineReservations`,
          {
            booking: booking,
          }
        )

        const bookingDetails = {
          id: getBooking.data.booking.id,
          graph: getBooking.data.booking.graph,
          customer: getBooking.data.booking.attributes.customer,
          resource_id: getBooking.data.booking.resource.id,
          project_id: getBooking.data.booking.project.id,
          meta: getBooking.data.booking.meta,
          what: getBooking.data.booking.what
            ? getBooking.data.booking.what
            : getBooking.data.booking.attributes.event.what,
          where: getBooking.data.booking.where
            ? getBooking.data.booking.where
            : getBooking.data.booking.attributes.event.where,
          description: getBooking.data.booking.description
            ? getBooking.data.booking.description
            : getBooking.data.booking.attributes.event.description,
          start: getBooking.data.booking.start
            ? getBooking.data.booking.start
            : getBooking.data.booking.attributes.event.start,
          end: getBooking.data.booking.end
            ? getBooking.data.booking.end
            : getBooking.data.booking.attributes.event.end,
          event_notifications:
            getBooking.data.booking.attributes.event_notifications,
        }

        const jsonValue = JSON.stringify(bookingDetails)
        await AsyncStorage.setItem('booking', jsonValue)
        setBooking(getBooking.data.booking.attributes.customer)
      } else {
        setBooking(booking.customer)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <View>
      <AppBarHeader />
      {loading ? (
        <Loading />
      ) : (
        <View pr="28%" pl="28%">
          {error ? (
            <Alert w="100%" status="error">
              <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                <Alert.Icon size="md" />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentSummary.errorBooking.title')}
                </Text>

                <Box
                  _text={{
                    textAlign: 'center',
                  }}
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  {t('paymentSummary.errorBooking.message')}
                </Box>
              </VStack>
            </Alert>
          ) : (
            <Center>
              <VStack space={5}>
                <Alert w="100%" status="success">
                  <VStack space={1} flexShrink={1} w="100%" alignItems="center">
                    <Alert.Icon size="md" />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('paymentSummary.bookingReceived.title')}
                    </Text>

                    <Box
                      _text={{
                        textAlign: 'center',
                      }}
                      _dark={{
                        _text: {
                          color: 'coolGray.600',
                        },
                      }}
                    >
                      {t('paymentSummary.bookingReceived.yourBooking')}
                    </Box>
                  </VStack>
                </Alert>
                <Box bgColor="#e0e0e0" rounded="xl" shadow="9">
                  <Heading p="2" size="md">
                    {t('paymentSummary.bookingReceived.paymentsDetails')}
                  </Heading>
                  <View p="2">
                    <Box
                      bgColor="#ffffff"
                      rounded="xl"
                      shadow="9"
                      width="35%"
                      p="2"
                    >
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.amount')}
                      </Text>
                      <Text bold fontSize="2xl" p="2">
                        {heliportCurrency === 'R$' ? 'R$' : '$'}
                        {amount}
                      </Text>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.billingDetails')}
                      </Text>
                      <HStack space={3}>
                        <Text bold fontSize="s" p="2">
                          {t('paymentSummary.bookingReceived.email')}
                        </Text>
                        <Text p="2">
                          {paymentIntentInformation.customer_details.email}
                        </Text>
                      </HStack>
                      <HStack space={3}>
                        <Text bold fontSize="s" p="2">
                          {t('paymentSummary.bookingReceived.phone')}
                        </Text>
                        <Text p="2">
                          {operatorPhone
                            ? formatPhoneNumber(operatorPhone.toString())
                            : ''}
                        </Text>
                      </HStack>
                      <Divider bg="yellow.400" />
                      <Text bold fontSize="s" p="2">
                        {t('paymentSummary.bookingReceived.paymentMethod')}
                      </Text>
                      <HStack space={3}>
                        <Text bold fontSize="s" p="2">
                          {t('paymentSummary.bookingReceived.type')}
                        </Text>
                        <Text p="2">
                          {paymentIntentInformation.payment_method_types[0]}
                        </Text>
                      </HStack>
                    </Box>
                  </View>
                  <View p="2">
                    <Box bgColor="#ffffff" rounded="xl" shadow="9" p="2">
                      <BookingDetailPayment booking={booking} />
                    </Box>
                  </View>
                </Box>
                <View p="2">
                  <Button
                    colorScheme="yellow"
                    onPress={() => {
                      props.history.push('/manageReservationOperator')
                    }}
                  >
                    {t('paymentSummary.bookingReceived.viewReservations')}
                  </Button>
                </View>
              </VStack>
            </Center>
          )}
        </View>
      )}
    </View>
  )
}
export default PaymentSummary
