import React from 'react'

import {
  View,
  Button,
  Center,
  HStack,
  NativeBaseProvider,
  Stack,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'

export const ManagerAccountMenu = (props) => {
  const { t } = useTranslation()
  const goToUserProfile = () => {
    props.history.push(`/userProfile`)
  }
  const goToManagerAccount = () => {
    props.history.push(`/managerAccount`)
  }
  const goToManagerReports = () => {
    props.history.push(`/ManagerReports`)
  }

  return (
    <View>
      <AppBarHeader />
      <NativeBaseProvider>
        <Center>
          <Stack space={3} direction={['column', 'column', 'column', 'row']}>
            <Button
              p="20"
              onPress={() => {
                goToUserProfile()
              }}
              bgColor="muted.200"
              _text={{
                color: '#1F2937',
                fontSize: 'md',
                bold: true,
              }}
              rounded="xl"
              shadow="8"
            >
              {t('managerAccount.menu.userProfile')}
            </Button>
            <Button
              p="20"
              onPress={() => {
                goToManagerAccount()
              }}
              bgColor="muted.200"
              _text={{
                color: '#1F2937',
                fontSize: 'md',
                bold: true,
              }}
              rounded="xl"
              shadow="8"
            >
              {t('managerAccount.menu.transactions')}
            </Button>
            <Button
              p="20"
              onPress={() => {
                goToManagerReports()
              }}
              bgColor="muted.200"
              _text={{
                color: '#1F2937',
                fontSize: 'md',
                bold: true,
              }}
              rounded="xl"
              shadow="8"
            >
              {t('managerReports.reports')}
            </Button>
          </Stack>
        </Center>
      </NativeBaseProvider>
    </View>
  )
}
export default ManagerAccountMenu
