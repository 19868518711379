import React from 'react'
import {
  Alert,
  Button,
  HStack,
  Box,
  Text,
  View,
  Heading,
  Input,
  Image,
  Center,
  VStack,
  Modal,
  IconButton,
  CloseIcon,
  SmallCloseIcon,
} from 'native-base'
import { useTranslation } from 'react-i18next'
import login from '../assets/login.png'
import imgSrc from '../assets/logo_yellow.png'
import { StyleSheet } from 'react-native'
import { auth } from '../utils/nhost'
import { useLocation } from '../routing'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PasswordChecklist from 'react-password-checklist'

export const ChangePassword = (props) => {
  const [show, setShow] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [errorPassword, setErrorPassword] = React.useState(false)
  const [passwordAgain, setPasswordAgain] = React.useState('')
  const [passwordValue, setPasswordValue] = React.useState('')
  const [passwordCheck, setPasswordCheck] = React.useState('')
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const { t } = useTranslation()

  function useParamQuery() {
    const { hash } = useLocation()
    const replaceUrl = hash.replace('#', '?')
    return React.useMemo(() => new URLSearchParams(replaceUrl), [replaceUrl])
  }

  let query = useParamQuery()

  const handleClick = () => setShowPassword(!showPassword)

  React.useEffect(async () => {
    const token = query.get('refreshToken')
    await AsyncStorage.setItem('nhostRefreshToken', token)
  }, [])

  const onSubmit = async () => {
    setButtonLoading(true)
    if (!passwordValue) {
      setPasswordAgain(t('register.required'))
      setButtonLoading(false)
    } else if (passwordValue && passwordCheck === true) {
      try {
        const { error } = await auth.changePassword({
          newPassword: passwordValue,
        })
        if (error) {
          setButtonLoading(false)
          setErrorPassword(error.message)
          setShowError(true)
        } else {
          setButtonLoading(false)
          setShow(true)
          props.history.push('/')
        }
      } catch (error) {
        setButtonLoading(false)
      }
    }
  }

  return (
    <View>
      <Center p={['15%', '15%', '10%', '2%']}>
        <Image
          source={{ uri: imgSrc }}
          alt="Alternate Text"
          style={styles.stretch}
        />
      </Center>
      <Center>
        <Box maxWidth="700" bgColor="white" p="5%">
          <VStack space="2">
            <Heading size="lg">{t('changePassword.title')}</Heading>
            <Text pb="2" color="muted.600">
              {t('changePassword.introduce')}
            </Text>
            <Input
              zIndex="-2"
              borderRadius="2xl"
              type={showPassword ? 'text' : 'password'}
              py="0"
              height={9}
              variant="unstyled"
              placeholder={t('register.passwordLabel')}
              onChangeText={(value) => setPasswordValue(value)}
              borderWidth={1}
              InputRightElement={
                <Button
                  size="xs"
                  variant="link"
                  rounded="none"
                  w="1/6"
                  h="full"
                  colorScheme="yellow"
                  onPress={handleClick}
                >
                  {showPassword ? t('register.hide') : t('register.show')}
                </Button>
              }
            />
            {passwordValue ? (
              <PasswordChecklist
                rules={[
                  'minLength',
                  'specialChar',
                  'number',
                  'capital',
                  'lowercase',
                ]}
                minLength={8}
                value={passwordValue}
                onChange={(valid) => setPasswordCheck(valid)}
                messages={{
                  number: t('errorsPassword.number'),
                  capital: t('errorsPassword.capital'),
                  lowercase: t('errorsPassword.lowercase'),
                  specialChar: t('errorsPassword.specialChar'),
                  minLength: t('errorsPassword.minLength'),
                }}
              />
            ) : (
              <View>
                {passwordAgain ? (
                  <View>
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {t('register.required')}
                    </Text>
                  </View>
                ) : (
                  <View />
                )}
              </View>
            )}
            <Button
              rounded="2xl"
              size={'md'}
              onPress={() => onSubmit()}
              colorScheme="yellow"
              isLoading={buttonLoading}
              _loading={{
                bg: 'amber.400:alpha.70',
                _text: {
                  color: 'coolGray.700',
                },
              }}
              _spinner={{
                color: 'white',
              }}
              isLoadingText={t('submit_button.text')}
            >
              <HStack space={3}>
                <Text>{t('changePassword.button')}</Text>
                <Image
                  source={{
                    uri: login,
                  }}
                  alt="Alternate Text"
                  style={{ width: 20, height: 20 }}
                />
              </HStack>
            </Button>
          </VStack>
        </Box>
      </Center>
      <Modal isOpen={show}>
        <Alert status="success">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  Successful password change!
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => setShow(false)}
              />
            </HStack>
          </VStack>
        </Alert>
      </Modal>
      <Modal isOpen={showError}>
        <Alert status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('try_again.text')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => setShowError(false)}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              {errorPassword}
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default ChangePassword

const styles = StyleSheet.create({
  stretch: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
  },
})
