import React from 'react'
import {
  View,
  Text,
  Box,
  HStack,
  VStack,
  Divider,
  Heading,
  Center,
} from 'native-base'
import { SERVER_SENGRID } from '@env'
import { FlatList } from 'react-native-gesture-handler'
import { GET_OPERATOR } from '../../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../../Token'
import { auth } from '../../utils/nhost'
import Loading from '../../components/Loading'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

export const PaymentsHistorial = () => {
  const [payments, setPayments] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [operator] = useLazyQuery(GET_OPERATOR)
  const { t } = useTranslation()

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const getOperator = await operator({
      variables: { userId: userId },
    })
    const token = auth.getJWTToken()

    const res = await fetch(`${SERVER_SENGRID}/booking/payments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operator_id: getOperator.data.operators[0].id,
        token: token,
      }),
    })
    const getPayments = await res.json()
    setPayments(getPayments.payments)
    setLoading(false)
  }, [])

  const getDate = (date) => {
    const paymentDate = new Date(date * 1000)
    const newDate = moment(paymentDate).format('LL')
    return newDate
  }

  if (loading) return <Loading />
  return (
    <View>
      {payments.length === 0 ? (
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Text bold p={5}>
            {t('paymentHistorial.emptyPayments')}
          </Text>
        </View>
      ) : (
        <View pr="30%" pl="30%">
          <Center>
            <Heading size="md">
              {t('paymentHistorial.paymentHistorialTitle')}
            </Heading>
          </Center>
          <FlatList
            data={payments}
            keyExtractor={(item) => item.id.toString()}
            ItemSeparatorComponent={() => <Divider />}
            renderItem={({ item }) => (
              <Box
                p="12"
                rounded="xl"
                backgroundColor="white"
                key={item.id.toString()}
              >
                <HStack space={12}>
                  <Text bold>{getDate(item.created)}</Text>
                  <VStack space={3}>
                    <Text>
                      {item.metadata.company_name
                        ? item.metadata.company_name
                        : 'Booking'}
                    </Text>
                    <HStack space={3}>
                      <Text>
                        {item.payment_method_details.card
                          ? item.payment_method_details.card.brand
                          : 'Monthly Invoice'}
                      </Text>
                      <Text>
                        ...{' '}
                        {item.payment_method_details.card
                          ? item.payment_method_details.card.last4
                          : ''}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
            )}
          />
        </View>
      )}
    </View>
  )
}
export default PaymentsHistorial
