import React from 'react'

import {
  View,
  Button,
  Center,
  HStack,
  NativeBaseProvider,
  VStack,
  Popover,
  Pressable,
  Box,
  Input,
  Image,
  Text,
  Stack,
  Heading,
  Container,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'
import moment from 'moment'
import { GET_HELIPORT_TIMEZONE } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../Token'
import Loading from '../components/Loading'
import { CSVLink } from 'react-csv'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const ManagerReports = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUserId] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [dateInput, setDateInput] = React.useState('')
  const [dateInputEnd, setDateInputEnd] = React.useState('')
  const [date, setDate] = React.useState(new Date())
  const [emptyFile, setEmptyFile] = React.useState('')
  const { t } = useTranslation()
  const [getTimeZoneHeliport] = useLazyQuery(GET_HELIPORT_TIMEZONE)
  const [data, setData] = React.useState([])
  const [show, setShow] = React.useState(false)
  const [startError, setStartError] = React.useState('')
  const [downloadLoading, setDownloadLoading] = React.useState('')
  const [heliportInformation, setHeliportInformation] = React.useState('')
  const [value] = React.useState(new Date())
  const [endValue] = React.useState(new Date())
  const [locale, setLocale] = React.useState('')

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const heliportData = await getTimeZoneHeliport({
      variables: { userId: userId },
    })
    setHeliportInformation(heliportData.data.heliports[0].heliport_profile)
    const currentLocale = await AsyncStorage.getItem('i18nextLng')
    setLocale(currentLocale)
    setLoading(false)
  }, [])

  const onChangeDate = (newDate) => {
    const getDatePicker = newDate.toDateString()
    setStartDate(getDatePicker)
    setDateInput(moment(getDatePicker).format('ddd, ll'))
  }

  const onChangeDateEnd = async (newDate) => {
    await downloadReport(newDate)
  }
  const headers = () => {
    if (heliportInformation.country === 'Brazil') {
      return [
        { label: 'Data do voo', key: 'date' },
        { label: 'Marca/Modelo de Helicóptero', key: 'model' },
        { label: 'Número da cauda do helicóptero', key: 'tail_number' },
        { label: 'Hora real de chegada', key: 'actual_arrival' },
        { label: 'Hora real de partida', key: 'actual_departure' },
        { label: 'Código ICAO de origem', key: 'origin_flight' },
        { label: 'Código ICAO de destino', key: 'next_destination' },
        { label: 'Número de passageiros', key: 'number_of_passengers' },
        { label: 'Nome do piloto', key: 'pilot_name' },
        { label: 'Código piloto ANAC', key: 'certificate_number' },
        { label: 'Nome do copiloto', key: 'copilot_name' },
        { label: 'Código ANAC do copiloto', key: 'certicate_number_copilot' },
      ]
    } else if (heliportInformation.country === 'USA') {
      return [
        { label: 'Date of flight', key: 'date' },
        { label: 'Helicopter Make/Model', key: 'model' },
        { label: 'Helicopter tail number', key: 'tail_number' },
        { label: 'Actual time of arrival', key: 'actual_arrival' },
        { label: 'Actual time of departure', key: 'actual_departure' },
        { label: 'Origin ICAO code', key: 'origin_flight' },
        { label: 'Destination ICAO code', key: 'next_destination' },
        { label: 'Number of passengers', key: 'number_of_passengers' },
        { label: 'Pilot name', key: 'pilot_name' },
        { label: 'Pilot ANAC code', key: 'certificate_number' },
        { label: 'Co-pilot name', key: 'copilot_name' },
        { label: 'Co-pilot ANAC code', key: 'certicate_number_copilot' },
      ]
    } else {
      return [
        { label: 'Fecha de vuelo', key: 'date' },
        { label: 'Marca/modelo de helicóptero', key: 'model' },
        { label: 'Número de cola del helicóptero', key: 'tail_number' },
        { label: 'Hora real de llegada', key: 'actual_arrival' },
        { label: 'Hora real de salida', key: 'actual_departure' },
        { label: 'Código OACI de origen', key: 'origin_flight' },
        { label: 'Código OACI de destino', key: 'next_destination' },
        { label: 'Número de pasajeros', key: 'number_of_passengers' },
        { label: 'Nombre del piloto', key: 'pilot_name' },
        { label: 'Código ANAC piloto', key: 'certificate_number' },
        { label: 'Nombre del copiloto', key: 'copilot_name' },
        { label: 'Código ANAC copiloto', key: 'certicate_number_copilot' },
      ]
    }
  }

  const numberPassengers = (passengers) => {
    if (passengers) {
      if (passengers === 'None') {
        return 0
      } else {
        return passengers.split('\n').length
      }
    } else {
      return 0
    }
  }

  const downloadReport = async (newDate) => {
    setDownloadLoading(t('managerReports.downloadLoading'))
    if (startDate) {
      setStartError('')
      const getDatePickerEnd = newDate.toDateString()
      setEndDate(getDatePickerEnd)
      setDateInputEnd(moment(getDatePickerEnd).format('ddd, ll'))
      setEmptyFile('')
      setShow(false)
      try {
        const getBookings = await axiosInstance.post(`/bookings/searchByDate`, {
          projectId: heliportInformation.timekit_project_id,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(newDate.toDateString()).format('YYYY-MM-DD'),
          startHour: '00:00',
          endHour: '23:59',
          timezone: heliportInformation.timezone,
        })
        const bookingReportData = await getBookingsData(
          getBookings.data.bookings
        )
        setData(bookingReportData)
        setDownloadLoading('')
        setShow(true)
      } catch (error) {
        console.log(error)
      }
    } else {
      setStartError(t('managerReports.startError'))
    }
  }
  const getBookingsData = async (bookings) => {
    const bookingsInformation = bookings.map((booking) => {
      return {
        date:
          booking && booking.event
            ? moment(booking.event.start).format('YYYY-MM-DD')
            : moment(booking.attributes.event.start).format('YYYY-MM-DD'),
        model:
          booking && booking.meta && booking.meta.model
            ? booking.meta.model
            : booking.attributes.meta.model,
        tail_number:
          booking && booking.meta && booking.meta.tail_number
            ? booking.meta.tail_number
            : booking.attributes.customer.tail_number,
        actual_arrival: booking.meta.actual_arrival
          ? booking.meta.actual_arrival
          : 'Not updated',
        actual_departure: booking.meta.actual_departure
          ? booking.meta.actual_departure
          : 'Not updated',
        origin_flight:
          booking && booking.meta && booking.meta.origin_flight
            ? booking.meta.origin_flight
            : booking.attributes.customer.origin_flight,
        next_destination:
          booking && booking.meta && booking.meta.next_destination
            ? booking.meta.next_destination
            : booking.attributes.customer.next_destination,
        number_of_passengers: numberPassengers(
          booking && booking.meta && booking.meta.embarking_passengers
            ? booking.meta.embarking_passengers
            : booking.attributes.customer.embarking_passengers
        ),
        pilot_name:
          booking && booking.meta && booking.meta.pilot_name
            ? booking.meta.pilot_name
            : booking.attributes.customer.pilot_name,
        certificate_number: booking.meta.certificate_number,
        copilot_name: booking.meta.copilot_name
          ? booking.meta.copilot_name
          : 'None',
        certicate_number_copilot: booking.meta.certicate_number_copilot
          ? booking.meta.certicate_number_copilot
          : 'None',
      }
    })
    return bookingsInformation
  }

  if (loading) return <Loading />
  return (
    <View>
      <AppBarHeader />
      <View height={['550', '550', '500', '500']}>
        <NativeBaseProvider>
          <Center>
            <Heading pb="8" size={['xs', 'xs', 'md', 'md']}>
              {t('managerReports.enterDate')}
            </Heading>
            <Box p="8" rounded="2xl" bgColor="light.100">
              <Stack space={3} direction={['column', 'column', 'row', 'row']}>
                <View>
                  <Center>
                    <Text pb="2" fontWeight="bold">
                      {t('managerReports.startDate')}
                    </Text>
                  </Center>
                  <HStack space={3}>
                    <Popover
                      trigger={(triggerProps) => {
                        return (
                          <Box widht="100%" borderWidth="1" borderRadius="4">
                            <HStack>
                              <Button {...triggerProps} colorScheme="yellow">
                                <Image
                                  source={{
                                    uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                                  }}
                                  alt="Alternate Text"
                                  style={{ width: 12, height: 12 }}
                                />
                              </Button>
                              <Container width="100%">
                                <Pressable {...triggerProps}>
                                  <Input
                                    style={{ backgroundColor: 'white' }}
                                    value={dateInput}
                                    borderColor="transparent"
                                    isReadOnly={true}
                                  />
                                </Pressable>
                              </Container>
                            </HStack>
                          </Box>
                        )
                      }}
                    >
                      <Popover.Content>
                        <Popover.Arrow />
                        <Popover.Body>
                          <Calendar
                            onChange={onChangeDate}
                            locale={locale}
                            value={value}
                            maxDate={new Date(2150, 11, 31)}
                            minDate={new Date(2019, 11, 31)}
                          />
                        </Popover.Body>
                      </Popover.Content>
                    </Popover>
                  </HStack>
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {startError}
                  </Text>
                </View>
                <View>
                  <Center>
                    <Text pb="2" fontWeight="bold">
                      {t('managerReports.endDate')}
                    </Text>
                  </Center>
                  <HStack space={3}>
                    <VStack>
                      <Popover
                        trigger={(triggerProps) => {
                          return (
                            <Box widht="100%" borderWidth="1" borderRadius="4">
                              <HStack>
                                <Button {...triggerProps} colorScheme="yellow">
                                  <Image
                                    source={{
                                      uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                                    }}
                                    alt="Alternate Text"
                                    style={{ width: 12, height: 12 }}
                                  />
                                </Button>
                                <Container width="100%">
                                  <Pressable {...triggerProps}>
                                    <Input
                                      style={{ backgroundColor: 'white' }}
                                      value={dateInputEnd}
                                      borderColor="transparent"
                                      isReadOnly={true}
                                    />
                                  </Pressable>
                                </Container>
                              </HStack>
                            </Box>
                          )
                        }}
                      >
                        <Popover.Content>
                          <Popover.Arrow />
                          <Popover.Body>
                            <Calendar
                              onChange={onChangeDateEnd}
                              locale={locale}
                              value={endValue}
                              maxDate={new Date(2150, 11, 31)}
                              minDate={new Date(2019, 11, 31)}
                            />
                          </Popover.Body>
                        </Popover.Content>
                      </Popover>
                      <Text p="2" fontSize="xs" color="#eb0100">
                        {emptyFile}
                      </Text>
                    </VStack>
                  </HStack>
                </View>
              </Stack>
              <br />
              <Center>
                <Text fontWeight="medium">{downloadLoading}</Text>
              </Center>
              <br />
              <Center>
                {endDate && show === true ? (
                  <View>
                    <CSVLink
                      style={{
                        textDecoration: 'none',
                        color: 'black',
                        padding: '9px',
                        backgroundColor: '#F9D342',
                        borderRadius: 5,
                        justifyContent: 'center',
                      }}
                      filename={'Report.csv'}
                      data={data}
                      headers={headers()}
                    >
                      <Text fontWeight="bold">
                        {t('managerReports.downloadReport')}
                      </Text>
                    </CSVLink>
                  </View>
                ) : (
                  <View>
                    <Button isDisabled maxWidth="100" colorScheme="yellow">
                      {t('managerReports.downloadReport')}
                    </Button>
                  </View>
                )}
              </Center>
            </Box>
          </Center>
        </NativeBaseProvider>
      </View>
    </View>
  )
}
export default ManagerReports
