import React from 'react'
import { View, Heading, Text, Center, Image } from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useTranslation } from 'react-i18next'
import { tw } from 'react-native-tailwindcss'
import errorImage from '../assets/error.png'

export const NotFound = (props) => {
  const { t } = useTranslation()
  return (
    <View>
      <AppBarHeader />
      <Center>
        <Image
          source={{ uri: errorImage }}
          alt="Alternate Text"
          style={{ width: 200, height: 200 }}
        />
        <Heading size="xs" style={[tw.fontMono]} mb="5">
          <Text> {t('errors.routeNotFound')}</Text>
        </Heading>
      </Center>
    </View>
  )
}
export default NotFound
