const English = {
  translation: {
    login: {
      title: 'Sign in',
      welcomeBack: 'Welcome back! Please enter your details',
      rememberMe: 'Remember me',
      notRegister: 'Not registered yet?',
      linkToRegister: 'Create an account!',
      username: 'Email',
      userNameorEmail: 'Enter your email',
      password: 'Password',
      passwordInput: '*Enter your password',
      show: 'Show',
      hide: 'Hide',
      signIn: 'Sign in',
      forgotPassword: 'Forgot password?',
      login: 'Login',
    },
    register: {
      title: 'Sign up',
      subtitle: 'Please enter your details.',
      userType: 'Select to your user type*',
      helpedManager: 'Helipad manager',
      helicopterOperator: 'Helicopter operator/pilot',
      companyNameLabel: 'Company name',
      companyNamePlaceHolder: 'Company name',
      firstNameLabel: 'First name',
      firstNamePlaceHolder: 'First name',
      lastNameLabel: 'Last name',
      lastNamePlaceHolder: 'Last name',
      phoneNumberLabel: 'Personal phone number',
      phonenumberCodeSelect: 'Please select your country code phone number',
      phoneNumberPlaceHolder: 'Phone number',
      userNameLabel: 'Email',
      userNamePlaceHolder: 'Email',
      passwordLabel: 'Password',
      passwordPlaceHolder: 'Password',
      acceptTerms: 'I agree to the following',
      tos: 'Terms of Service',
      show: 'Show',
      hide: 'Hide',
      member: 'Already a member?',
      signIn: 'Sign In!',
      registerButtonText: 'Register',
      tosMessage: 'Please accept Terms of Service',
      type: 'Please select your user type',
      required: 'This is required.',
      onlyLetters: 'Invalid, only letters can be used.',
      lettersAndNumbers: 'Invalid, only letters and numbers',
      invalidValue: 'Invalid value',
    },
    forgotPassword: {
      title: 'Forgot Password',
      enterEmailAddress: 'Enter your email address',
      emailPlaceHolder: 'Email',
      send: 'Send',
    },
    changePassword: {
      title: 'Create new password',
      enterEmailAddress: 'Enter your email address',
      emailLabel: 'Email',
      emailPlaceHolder: 'Email',
      createPasswordButton: 'Create password',
    },
    changePasswordOperator: {
      change: 'Change password',
      title: 'Please, create your new password',
      enterNewPassword: 'Please enter your new password',
      passwordInput: '*Password',
      show: 'Show',
      hide: 'Hide',
      cancel: 'Cancel',
      save: 'Save',
    },
    appBar: {
      pilotsAndOperator: 'PILOTS AND OPERATORS',
      operatorAccount: 'Operator account',
      fleet: 'Fleet',
      pilots: 'Pilots',
      profiles: 'Profiles',
      userProfile: 'User profile',
      companyProfile: 'Company profile',
    },
    operatorForm: {
      title: 'Operator ',
      information: 'Operator information',
      fillFields: 'Please, fill all fields',
      companyNameLabel: 'Company name',
      companyNamePlaceHolder: 'Company name',
      contactInformation: 'Contact information',
      nameLabel: 'Name',
      namePlaceHolder: 'Name',
      emailLabel: 'Email',
      emailPlaceHolder: 'Email',
      phoneNumberLabel: 'Public phone number',
      phoneNumberPlaceHolder: 'Phone number',
      addressLabel: 'Address',
      addressPlaceHolder: 'Address',
      saveButton: 'Save',
    },
    operatorAccount: {
      title: 'Operator',
      information: 'Operator information',
      nameLabel: 'Name',
      companyName: 'Company Name',
      contactInformation: 'Contact information',
      contactName: 'Name',
      contactEmail: 'Email',
      contactPhone: 'Phone number',
      contactAddress: 'Address',
      editButton: 'Edit',
      titleModal: 'Edit Operator',
      informationModal: 'Operator information',
      companyNameLabel: 'Company name',
      companyNamePlaceholder: 'Company name',
      contactInformationModal: 'Contact information',
      nameModalLabel: 'Name',
      nameModalPlaceholder: 'Name',
      emailModalLabel: 'Email',
      emailModalPlaceholder: 'Email',
      phoneNumberModalLabel: 'Phone number',
      phoneNumberPlaceHolder: 'Phone number',
      addressModalLabel: 'Address',
      addressModalPlaceholder: 'Address',
      changePassword: 'Change password',
      logOut: 'Log out',
      saveModalButton: 'Save',
      cancelModalButton: 'Cancel',
      logOut: 'Log out',
      saveModalButton: 'Save',
      cancelModalButton: 'Cancel',
      required: 'This is required.',
      onlyLetters: 'Only letters',
      numbers: 'Only numbers',
      lettersAndNumbers: 'Only letters and numbers',
      invalidValue: 'Invalid value',
    },
    operatorAccountMenu: {
      info: 'Operator info',
      loginSecurity: 'Login & security',
      billing: 'Billing',
    },
    operatorPayment: {
      paymentMethods: 'Payment methods',
      paymentHistory: 'Payment history',
    },
    manageReservationOperator: {
      bookingTentative: 'Requested',
      bookingConfirmed: 'Confirmed',
      noBooking: 'No Bookings',
      upcoming: 'Upcoming bookings',
      past: 'Past bookings',
      title: 'Bookings',
      upcomingBookings: {
        title: 'Bookings',
        upcomingBookings: 'Upcoming bookings',
        reservation: {
          title: 'Reservations',
          date: 'Date:',
          start: 'Start:',
          end: 'End:',
        },
        customers: {
          title: 'Customers',
          operator: 'Operator:',
          aircraftType: 'Aircraft type:',
          tailNumbers: 'Tail number:',
        },
        status: {
          title: 'Status',
          booking: 'Booking:',
          payment: 'Payment:',
        },
        actions: {
          title: 'Actions',
          viewDetails: 'View details',
        },
      },
      pastBookings: {
        title: 'Cancelled bookings',
        completed: 'Completed',
        completedTitle: 'Completed bookings',
        reservation: {
          title: 'Reservations',
          date: 'Date:',
          start: 'Start:',
          end: 'End:',
        },
        customers: {
          title: 'Customers',
          operator: 'Operator:',
          aircraftType: 'Aircraft type:',
          tailNumbers: 'Tail number:',
        },
        actions: {
          title: 'Actions',
          viewDetails: 'View details',
        },
        status: {
          title: 'Status',
          booking: 'Booking:',
          payment: 'Payment:',
          cancelledCustomer: 'Canceled',
          cancelled: 'Canceled',
          declined: 'Rejected',
        },
      },
      bookingOperatorDetail: {
        title: 'Booking detail',
        deleteButton: 'Delete',
        rescheduleButton: 'Reschedule reservation',
        cancelButton: 'Cancel reservation',
        cancelModalTitle: 'Cancel reservation',
        sureCancel: 'Are you sure you want to cancel?',
        returnReservation: 'Return to reservation',
        cancelModal: 'Cancel',
        declinedBooking: 'Declined booking',
        reason: 'Reason:',
        bookingStatus: {
          tentative: 'Requested',
          confirmed: 'Confirmed',
          cancelledCustomer: 'Canceled',
          cancelled: 'Canceled',
          declined: 'Rejected',
          completed: 'Completed',
        },
        operator: 'Operator:',
        status: 'Booking status',
        booking: 'Booking:',
        payment: 'Payment:',
        bookingDetail: 'Booking details:',
        additional_field: 'Additional field',
        disembarking_passengers: 'Disembarking passenger names',
        email: 'Email',
        embarking_passengers: 'Embarking passenger names',
        next_destination: 'Next destination',
        origin_flight: 'Origin flight',
        passengers_ids: 'Passenger IDs',
        pilot_name: 'Pilot name',
        tail_number: 'Aircraft tail number',
        timezone: 'Timezone',
        name: 'Name',
        model: 'Aircraft make/model',
        certificate_number: 'Pilot certificate number',
        reason: 'Reason:',
        error: "We can't find your booking",
        flightNumber: 'Flight Number',
        totalPassenger: 'Total number of passengers',
      },
    },
    methodsPayments: {
      notPayment: "You don't have any payment methods",
      addPaymentMethod: 'Add payment method',
      expiration: 'Expiration:',
      removePaymentMethod: 'Remove',
      setDefaultPaymentMethod: 'Set default',
      addPaymentMethodButton: 'Add payment method',
      createPaymentMethod: 'Create payment method',
      cardInformation: 'Card information',
      saveButton: 'Save',
    },
    userProfile: {
      title: 'User profile',
      firstNameLabel: 'First name',
      firstNamePlaceHolder: 'First name',
      lastNameLabel: 'Last name',
      lastNamePlaceHolder: 'Last name',
      phoneNumberLabel: 'Phone number',
      phoneNumberPlaceHolder: 'Phone number',
      editButton: 'Edit',
      modalTitle: 'Edit user profile',
      firstNameModalLabel: 'First name',
      firstNameModalPlaceHolder: 'First name',
      lastNameModalLabel: 'Last name',
      lastNameModalPlaceHolder: 'Last name',
      phoneNumberModalLabel: 'Phone number',
      phoneNumberModalPlaceHolder: 'Phone number',
      editButtonSave: 'Save',
      changePassword: 'Change password',
      logOut: 'Log out',
    },
    companyProfile: {
      title: 'Company profile',
      name: 'Company name',
      emailLabel: 'Email',
      phoneNumberLabel: 'Phone number',
      editButton: 'Edit',
      modalTitle: 'Edit company profile',
      companyNameModalLabel: 'Company name',
      emailModalLabel: 'Email name',
      phoneNumberModalLabel: 'Phone number',
      editButtonSave: 'Save',
    }, // // here we will place our translations...
    appBar: {
      operatorMenu: {
        manageReservation: 'Manage reservations',
        manageFleet: 'Manage fleet',
        managePilots: 'Manage pilots',
        account: 'Account',
        logOut: 'Log out',
      },
      managerMenu: {
        manageReservation: 'Manage reservations',
        manageHeliport: 'Manage my heliport',
        manageCompany: 'Manage company',
        account: 'Account',
        logOut: 'Log out',
      },
      publicMenu: {
        register: 'Register',
        logIn: 'Log in',
      },
      language: {
        mexico: 'Mexico',
        brazil: 'Brazil',
        usa: 'USA',
      },
      languageItems: {
        mexico: 'Spanish',
        brazil: 'Portuguese',
        usa: 'English',
      },
    },
    fleet: {
      required: 'This is required.',
      errorImage: 'Please upload a image',
      noAircraft: "You don't have aircrafts",
      addAircraft: 'Add aircraft',
      aircraftTitle: 'Aircraft',
      createAircraft: 'Create aircraft',
      aircraftInformation: 'Aircraft information',
      tailNumber: 'Tail number',
      model: 'Helicopter make/model',
      maxWeight: 'Max takeoff weight',
      rotorLength: 'Rotor length',
      updateButton: 'Update',
      removeButton: 'Remove',
      uploadButton: 'Upload Image',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      editButton: 'Edit',
      deleteButton: 'Delete',
      editAircraftTitle: 'Edit aircraft',
      currentAircraftImage: 'Current aircraft image',
      deleteFleet: 'Delete aircraft',
      confirmMessage: 'Do you want to delete this aircraft ?',
      confirmButton: 'Confirm',
      neverMindButton: 'Cancel',
      errors: {
        create: 'Sorry, we can not create your aircraft.',
        update: 'Sorry, we can not update your aircraft.',
        delete: 'Sorry, we can not delete your aircraft.',
      },
      ruleNumber: 'Invalid, only numbers',
    },
    loading: {
      loading: 'Loading',
    },
    homepage: {
      country: 'Country',
      countryPlaceholder: 'Country',
      city: 'City',
      cityPlaceholder: 'City',
      searchButton: 'Search',
      selectCity: 'Please select a city',
      reserve: 'Reserve',
      details: 'Details',
      duration: 'Duration:',
      fee: 'Fee:',
      featuredHelipads: 'Featured helipads',
      notHelipads: 'No existing heliports',
      firstTitle: "There's so much to love about Altaport.",
      secondTitle: 'Landings are better with Altaport.',
      privacy: 'Privacy',
      terms: 'Terms',
      siteMap: 'SiteMap',
      firstMessage: {
        message: '"I found the system super useful and',
        use: ' very easy to use."',
        hosting: 'Hosting supervisor,',
      },
      secondMessage: {
        message: '“A platform that optimizes and',
        coordination: 'streamlines the coordination work.”',
        operations: 'Flight Operations, Air Jet',
      },
      landingsMessage: {
        title: 'Landings are better with Altaport.',
        simplifiedMessage: {
          first: 'Simplified',
          second: 'heliport',
          third: 'management.',
          minimizingManual:
            'Minimizing manual actions required to schedule landings,',
          passengers: 'manage passengers, and process payments.',
        },
        centralizedMessage: {
          first: 'Centralized',
          second: 'helipad',
          third: 'booking.',
          offering:
            'Offering helicopter operators a streamlined experience for',
          helipad: 'helipad scheduling and payments.',
        },
        highVolumeMessage: {
          first: 'High-volume',
          second: 'vertiport',
          third: 'operations.',
          enabling: 'Enabling the future of high-volume autonomous drone and',
          operations: 'air taxi operations.',
        },
      },
    },
    pilots: {
      required: 'This is required.',
      editButton: 'Edit',
      deleteButton: 'Delete',
      editTitle: 'Edit Pilot',
      currentPilotImage: 'Current pilot image',
      updateButton: 'Update',
      removeButton: 'Remove',
      uploadImage: 'Upload image',
      pilotInformation: 'Pilot information',
      certificateNumber: 'Certificate number',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone number',
      email: 'Email',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      imageError: 'Please upload a image',
      noPilots: "You don't have pilots",
      addPilotButton: 'Add pilot',
      pilotsTitle: 'Pilots',
      createPilot: 'Create pilot',
      deletePilot: 'Delete pilot',
      confirmMessage: 'Do you want to delete this pilot ?',
      confirmButton: 'Confirm',
      neverMindButton: 'Never mind',
      errors: {
        create: 'Sorry,We can not create your pilot.',
        update: 'Sorry,We can not update your pilot.',
        delete: 'Sorry,We can not delete your pilot.',
      },
    },
    reservations: {
      title: 'Reservations',
      addReservationsManually: 'Add reservation manually',
      blockTimes: 'Block times',
      listView: 'List view',
      calendarView: 'Calendar view',
      bookingBlockTimes: 'Booking block times',
      startDateHour: 'Start date and hour',
      endDateHour: 'End date and hour',
      cancelButton: 'Cancel',
      saveButton: 'OK',
      errorAccount: 'Error account',
      completeBankInformation:
        'There was an error completing your bank information, please complete all bank information.',
      reminder: 'Reminder',
      reminderMessage:
        'Remember to complete your bank information to be able to make reservations, you can do it from the manager account in the Bank Information option',
    },
    calendarView: {
      deleteButton: 'Delete',
      accept: 'Accept',
      reject: 'Reject',
      declineBooking: 'Decline booking',
      reason: 'Reason:',
      cancel: 'Cancel',
      save: 'Save',
      cancelReservation: 'Cancel reservation',
      tentative: 'Requested',
      confirmed: 'Confirmed',
      cancelledCustomer: 'Canceled',
      cancelled: 'Canceled',
      declined: 'Rejected',
      completed: 'Completed',
      approved: 'Approved',
      pendingApproval: 'Pending approval',
      completed: 'Completed',
      blockPeriod: 'Block period',
      cancelled: 'Cancelled',
      unPaidInvoice: 'Unpaid - invoice',
      paidAuthorized: 'Paid - authorized',
      paidCharged: 'Paid - charged',
      unPaid: 'Unpaid',
      booking: 'Bookings',
      notBooking: 'No Bookings',
      month: 'Month',
      day: 'Day',
      week: 'Week',
      block: 'Block',
      blockedTime: 'Blocked time',
      bookingModal: 'Booking',
      operator: 'Operator:',
      tailNumber: 'Tail number:',
      status: 'Status:',
      payment: 'Payment:',
      viewDetails: 'View details',
      manualReservation: 'Manual reservation',
      locale: 'en',
    },
    heliportDetailsOperator: {
      latitude: 'Lat',
      longitude: 'Long',
      description: 'Description',
      helipad: 'Helipad',
      helipadDimensions: 'Helipad dimensions',
      location: 'Location',
      helipadDetails: 'Helipad details',
      availability: 'Availability',
      landing: 'landing slots',
      capacity: 'Capacity helipads:',
      fuel: 'Available',
      notFuel: 'Not available',
      yes: 'Yes',
      no: 'No',
      restrictions: 'Restrictions',
      cancellation: 'Cancellation policy:',
      otherRestrictions: 'Other restrictions:',
      fees: 'Fees',
      landingFee: 'Landing fee',
      parkingFee: 'Parking fee',
      hourlyRate: 'Hourly rate',
      overnightRate: 'Overnight rate',
      selectLanding: 'Select a landing time',
      reserveLanding: 'Reserve landing time',
      standar: 'Standard time',
      helipadManager: 'Helipad manager',
      makeReservation: 'Make reservation',
      map: 'Map',
      surfaceType: 'surface',
      helipads: 'Helipads',
      notParking: 'No parking available',
      maxWeight: 'Max takeoff weight:',
      helipadDimensions: 'Helipad dimensions:',
      fuelAvailability: 'Fuel availability: ',
      helipadsNumber: 'Helipads: ',
      typeSurface: 'Surface type: ',
      minutes: 'minute(s)',
      hours: 'hour(s)',
      otherRestrictions: 'Others Restrictions',
      none: 'None',
    },
    managerBooking: {
      upcomingBookings: 'Upcoming bookings',
      pastBookings: 'Past bookings',
      bookingPendingConfirm: {
        title: 'Bookings',
        noBooking: 'No booking',
        bookingStatus: {
          tentative: 'Requested',
          confirmed: 'Confirmed',
          cancelledCustomer: 'Canceled',
          cancelled: 'Canceled',
          declined: 'Rejected',
          completed: 'Completed',
        },
        confirmedRequests: {
          title: 'Confirmed requests',
          reservation: 'Reservation',
          date: 'Date:',
          start: 'Start:',
          end: 'End:',
          customer: 'Customer',
          operator: 'Operator:',
          aircraftType: 'Aircraft type:',
          tailNumber: 'Tail number:',
          operation: 'Operation:',
          embarking: 'Embarking',
          disembarking: 'Disembarking',
          passengerName: 'Passenger names:',
          status: 'Status:',
          booking: 'Booking:',
          payment: 'Payment:',
          actions: 'Actions',
          viewDetails: 'View details',
          cancelButton: 'Cancel',
        },
        pendingRequests: {
          title: 'Pending requests',
          reservation: 'Reservation',
          date: 'Date:',
          start: 'Start:',
          end: 'End:',
          customer: 'Customer',
          operator: 'Operator:',
          aircraftType: 'Aircraft type:',
          tailNumber: 'Tail number:',
          operation: 'Operation:',
          embarking: 'Embarking',
          disembarking: 'Disembarking',
          passengerName: 'Passenger names:',
          status: 'Status:',
          booking: 'Booking:',
          payment: 'Payment:',
          actions: 'Actions',
          viewDetails: 'View details',
          acceptButton: 'Accept',
          rejectButton: 'Reject',
          declineBooking: 'Decline booking',
          reason: 'Reason:',
          cancel: 'Cancel',
          save: 'Save',
        },
        canceledByTime:
          'Reservation canceled, due to exceeding the time of the request',
        errors: {
          decline: 'Sorry, we can not decline your reservation.',
          cancel: 'Error to cancel booking!',
        },
        manualReservation: 'Manual Reservations',
      },
      cancelCompletedRequests: {
        cancelled: 'Cancelled bookings',
        completed: 'Completed bookings',
        reservation: 'Reservation',
        date: 'Date:',
        start: 'Start:',
        end: 'End:',
        customer: 'Customer',
        operator: 'Operator:',
        aircraftType: 'Aircraft type:',
        tailNumber: 'Tail number:',
        status: 'Status:',
        booking: 'Booking:',
        payment: 'Payment:',
        actions: 'Actions',
        viewDetails: 'View details',
        deleteButton: 'Delete',
        acceptButton: 'Accept',
        rejectButton: 'Reject',
        declineBooking: 'Decline booking',
        reason: 'Reason:',
        cancel: 'Cancel',
        save: 'Save',
        bookingStatus: {
          tentative: 'Requested',
          confirmed: 'Confirmed',
          cancelledCustomer: 'Canceled',
          cancelled: 'Canceled',
          declined: 'Rejected',
          completed: 'Completed',
        },
      },
    },
    managerBookingDetail: {
      deleteButton: 'Delete',
      acceptButton: 'Accept',
      rejectButton: 'Reject',
      declineBooking: 'Decline booking',
      reason: 'Reason:',
      cancel: 'Cancel',
      save: 'Save',
      editDateTime: 'Edit date and time',
      cancelReservation: 'Cancel reservation',
      editBooking: 'Edit booking',
      landingDateTime: 'Landing date and time',
      start: 'Start:',
      end: 'End:',
      additionalfield: 'Additional field',
      disembarkingPassengers: 'Embarking passengers',
      email: 'Email:',
      embarkingPassengers: 'Disembarking passengers',
      operationEmbarking: 'Operation: embarking',
      operationDisembarking: 'Operation: disembarking',
      nextDestination: 'Next destination',
      originFlight: 'Origin flight',
      passengersIds: 'Passenger IDs:',
      pilotName: 'Pilot name',
      tailNumber: 'Aircraft tail number',
      timezone: 'Timezone:',
      name: 'Name:',
      model: 'Aircraft make/model:',
      certificateNumber: 'Pilot certificate number',
      copilotName: 'Co-pilot name',
      certificateNumberCopilot: 'Co-pilot certificate number',
      bookingDetail: 'Booking detail',
      operatorContact: 'Operator contact:',
      operator: 'Operator',
      phone: 'Phone',
      email: 'Email',
      status: 'Booking status',
      booking: 'Booking:',
      payment: 'Payment',
      bookingDetails: 'Booking details:',
      editBooking: 'Edit booking details',
      declinedBooking: 'Declined booking',
      reason: 'Reason',
      bookingStatus: {
        tentative: 'Requested',
        confirmed: 'Confirmed',
        cancelledCustomer: 'Canceled',
        cancelled: 'Canceled',
        declined: 'Rejected',
        completed: 'Completed',
      },
      actualArrival: 'Actual arrival',
      actualDeparture: 'Actual departure',
      enterActualArrival: 'Enter the actual arrival time',
      enterActualDeparture: 'Enter the actual departure time',
      flightNumber: 'Flight Number',
      totalPassenger: 'Total number of passengers',
    },
    editHeliport: {
      identifier: 'Heliport identifier',
      name: 'Heliport name',
      code: 'ICAO CODE',
      contactInformation: 'Contact information',
      managerName: 'Manager name',
      phone: 'Phone number',
      location: 'Location',
      city: 'City',
      state: 'State',
      address: 'Street address',
      latitude: 'Latitude',
      longitude: 'Longitude',
      timezone: 'Time Zone',
      availability: 'Avaibility',
      timeslot: 'Time slot length',
      hours: 'Hours',
      minutes: 'Minute',
      days: 'Days',
      numberHelipads: 'Number of helipads (capacity)',
      fuel: 'Fuel available?',
      yes: 'Yes',
      no: 'No',
      reservation: 'Reservation type',
      automatic: 'Automatic',
      manual: 'Manual approval',
      restrictions: 'Restrictions',
      size: 'Size limit',
      weight: 'Weight',
      maximumRotor: 'Helipad dimensions',
      surface: 'Surface Type',
      otherRestrictions: 'Other restrictions',
      cancellation: 'Cancellation window',
      fees: 'Fees',
      landingFee: 'Landing fee',
      currency: 'Heliport Currency',
      parkingCurrency: 'Currency',
      real: 'Real',
      usd: 'USD',
      mexican: 'Mexican peso',
      parkingAvailable: 'Parking available?',
      editParking: 'Parking',
      parkingHour: 'Hourly rate',
      parkingNight: 'Overnight rate',
      description: 'Heliport description',
      saveButton: 'SAVE',
      error: 'We can not update your heliport',
      fee: 'Fee',
      limitLateBooking: 'Limit Late Booking',
    },
    managerAccount: {
      menu: {
        userProfile: 'User profile',
        loginSecurity: 'Login & security',
        transactions: 'Transactions & payouts',
      },
      payoutHistory: 'Payout history',
      transactionHistory: 'Transaction history',
      bank: 'Bank information',
      noPayment: "You don't have any payments",
      payout: 'Payout',
      chaseChecking: 'Chase checking...',
      routingNumber: 'Routing number:',
      changePayout: 'Change payment method',
      verifyAccount: 'Verify account',
      createBank: 'Create Bank Information',
    },
    manageHeliport: {
      latitude: 'Latitude: ',
      longitude: 'Longitude: ',
      description: 'Description',
      helipad: 'Helipad',
      helipadDimensions: 'Helipad dimensions',
      manager: 'Manager',
      phone: 'Phone: ',
      name: 'Name: ',
      email: 'Email: ',
      address: 'Address',
      companyInformation: 'Company Information',
      companyName: 'Name: ',
      companyPhone: 'Phone: ',
      state: 'State: ',
      maxWeight: 'Max takeoff weight:',
      helipadDimensions: 'Helipad dimensions:',
      cancellation: 'Cancellation window:',
      editHeliport: 'Edit heliport',
      addReservationsManually: 'Add reservation manually',
      editImage: 'Edit image',
      currentImage: 'Current image',
      updateButton: 'Update',
      removeButton: 'Remove',
      uploadImage: 'Upload image',
      cancel: 'Cancel',
      save: 'Save',
      editAviabilities: 'Edit Heliport Availabilities',
      manageHelipad: {
        notHelipads: "You don't have helipads",
        addHelipad: 'ADD HELIPAD',
        helipadDetails: 'Helipad details',
        editHelipad: 'EDIT HELIPAD',
        delete: 'DELETE',
        createHelipadTitle: 'Create helipad',
        helipadInformation: 'Helipad information',
        name: 'Name',
        required: 'This is required.',
        timezone: 'Time Zone',
        editHelipadTitle: 'Edit helipad',
        createHelipad: 'CREATE HELIPAD',
        saveButton: 'Save',
        tryAgain: 'Please try again later!',
        errors: {
          create: 'Sorry, We can not create your helipad.',
          delete: 'Sorry, We can not delete your helipad.',
          edit: 'Sorry, We can not edit your helipad.',
          deleteErrorServer:
            ' Helipad can not be deleted because it has pending bookings',
        },
      },
      reminder: 'Reminder',
      reminderMessage:
        'Remember that you must complete the heliport information to make a reservation, you can do it from "edit heliport"',
      reminderAccept: 'Accept',
      country: {
        mexico: 'México',
        brazil: 'Brasil',
        usa: 'USA',
      },
    },
    submit_button: {
      text: 'Submitting',
    },
    try_again: {
      text: 'Please try again later!',
    },
    heliportForm: {
      required: 'This is required.',
      identifier: 'Heliport identifier',
      name: 'Heliport name',
      code: 'ICAO CODE',
      contactInformation: 'Contact information',
      managerName: 'Manager name',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Work number',
      location: 'Location',
      city: 'City',
      state: 'State',
      field: 'Field for only 2 characters (e.g. UT)',
      address: 'Street address',
      latitude: 'Latitude',
      numbers: 'Only numbers',
      longitude: 'Longitude',
      timezone: 'Time zone',
      country: 'Country',
      availability: 'Avaibility',
      timeslot: 'Time slot length',
      hours: 'Hours',
      hour: 'Hour',
      night: 'Night',
      minutes: 'Minutes',
      days: 'Days',
      numberHelipads: 'Number of helipads (capacity)',
      numberHelipadsLabel: 'Number of helipads',
      fuel: 'Fuel available?',
      yes: 'Yes',
      no: 'No',
      reservation: 'Reservation type',
      automatic: 'Automatic',
      manual: 'Manual approval',
      restrictions: 'Restrictions',
      size: 'Size limit',
      weight: 'Weight',
      maximumRotor: 'Helipad dimensions',
      maximumRotorLabel: 'Dimensions',
      surface: 'Surface Type',
      aspalthLabel: 'Asphalt',
      aspalth: 'e.g., Asphalt',
      otherRestrictions: 'Other restrictions',
      noAvailables: 'Not available on the weekend',
      limitLate: 'Limit late bookings',
      cancellation: 'Cancellation window',
      before: 'Before',
      flight: 'flight',
      fees: 'Fees',
      heliportCurrency: 'Heliport Currency',
      currency: 'Currency',
      real: 'Real',
      usd: 'USD',
      mexican: 'Mexican peso',
      parkingHour: 'Hourly rate',
      parkingNight: 'Overnight rate',
      description: 'Heliport description',
      descriptionLabel: 'A little description of the heliport',
      uploadImage: 'Upload image',
      update: 'Update',
      remove: 'Remove',
      requiedReservation: 'Required reservation details',
      disembarkingPassengers: 'Disembarking passenger names',
      embarkingPassengers: 'Embarking passenger names',
      originFlight: 'Origin of flight',
      nextDestination: 'Next destination',
      passengerIDs: 'Passenger ID #s (e.g. passport)',
      accept: 'I agree',
      servicesAgreement: 'Altaport Services Agreement',
      step: 'Step',
      createHeliport: 'CREATE HELIPORT',
      parkingAvailable: 'Parking available?',
      acceptTos: 'Please accept Terms of Service',
      addressField: 'The address field is required',
      imagesField: 'Image(s) required',
    },
    managerReports: {
      reports: 'Reports',
      enterDate: 'Please enter start date and end date',
      startDate: 'Start date',
      endDate: 'End Date',
      startError: 'Insert a startind date',
      downloadReport: 'Download',
      downloadLoading: 'Loading...',
    },
    bookingOperatorForm: {
      with: 'at',
      disembarkingPassengers: 'Disembarking passengers',
      embarkingPassengers: 'Embarking passengers',
      firstName: 'First name',
      lastName: 'Last name',
      document: 'Document',
      passport: 'Passport',
      driversLicense: "Driver's license",
      originFlight: 'Origin flight',
      nextDestination: 'Next destination',
      required: 'This is required.',
      selectPilot: 'Select pilot',
      selectCopilot: 'Select co-pilot',
      addPilot: 'Add pilot',
      selectAircraft: 'Select aircraft',
      addAircraft: 'Add aircraft',
      back: 'Back',
      confirmReservation: 'Confirm reservation',
      nextPayment: 'Next: payment details',
      repeatedPilot: "You can't select the same pilot",
      flightNumber: 'Flight Number',
      totalPassenger: 'Total number of passengers',
    },
    bookingMannualyForm: {
      with: 'at',
      name: 'Name',
      email: 'Email',
      phone: 'Phone number',
      disembarkingPassengers: 'Disembarking passengers',
      embarkingPassengers: 'Embarking passengers',
      firstName: 'First name',
      lastName: 'Last name',
      document: 'Document',
      passport: 'Passport',
      driversLicense: "Driver's license",
      originFlight: 'Origin flight',
      nextDestination: 'Next destination',
      required: 'This is required.',
      pilotName: 'Pilot',
      pilotCertificate: 'Certificate number',
      helicopterModel: 'Helicopter make/model',
      helicopterTail: 'Helicopter tail number',
      back: 'Back',
      confirmReservation: 'Confirm reservation',
      required: 'This is required.',
      onlyLetters: 'Invalid, only letters can be used.',
      lettersAndNumbers: 'Invalid, only letters and numbers',
      invalidValue: 'Invalid value',
      totalPassenger: 'Total number of passengers',
    },
    paymentMethods: {
      errorMonthlyInvoices:
        'Sorry, You can only use monthly invoices with heliports of your same currency.',
      errorReservation: 'Sorry, we can make your reservation',
      creditCard: 'Credit card',
      boleto: 'Boleto',
      monthlyInvoice: 'Monthly invoice',
      fastPass: 'Fast Pass',
      back: 'Back',
      selectOtherMethod: 'Please choose other payment method',
      passCode: {
        title: 'Fast Pass code',
        introduceCode: 'Please, introduce your code',
        code: 'Code:',
        required: 'This is required.',
        cancel: 'Cancel',
        send: 'Send',
      },
    },
    paymentSummary: {
      errorBooking: {
        title: 'Error booking!',
        message: 'Your booking has not been received, Please contact us',
      },
      bookingReceived: {
        title: 'Booking received!',
        yourBooking: 'Your booking has been received',
        paymentsDetails: 'Payment details',
        amount: 'Amount:',
        billingDetails: 'Billing details',
        email: 'Email:',
        phone: 'Phone:',
        paymentMethod: 'Payment method',
        type: 'Type:',
        viewReservations: 'View reservations',
        promotionalCode: 'Promotional code',
      },
      bookingDetailPayment: {
        bookingDetails: 'Booking details',
        additionalField: 'Additional field',
        disembarkingPassengers: 'Disembarking passenger names',
        email: 'Email:',
        embarkingPassengers: 'Embarking passenger names',
        nextDestination: 'Next destination',
        originFlight: 'Origin flight',
        passengersIds: 'Passenger IDs',
        pilotName: 'Pilot name',
        tailNumber: 'Aircraft tail number',
        timezone: 'Timezone',
        name: 'Name',
      },
    },
    editAvailability: {
      title: 'Edit Availability',
      day: 'Day',
      hour: 'Hours',
      price: 'Price',
      approval: 'Approval',
      sunday: 'Sunday',
      saturday: 'Saturday',
      friday: 'Friday',
      thursday: 'Thursday',
      wednesday: 'Wednesday',
      tuesday: 'Tuesday',
      monday: 'Monday',
      automatic: 'Automatic',
      manual: 'Manual',
      start: 'Start:',
      end: 'End:',
      save: 'Save',
      editButton: 'Edit',
      deleteButton: 'Delete',
      add: 'Add',
      noAvailability: 'no availability',
      none: 'None',
      actions: 'Actions',
      buttons: {
        edit: 'Save',
        save: 'Save ',
      },
      errorSave: 'We can not save days and hours',
      errorEdit: 'We can not edit days and hours',
    },
    errors: {
      bookings: "Sorry, we can't show your bookings.",
      completeInformation: 'Please, complete heliport form',
      errorHeliportForm: 'Heliport form',
      createHeliport: "Sorry,We can't create your heliport.",
      save: 'Sorry, we are unable to save helipad availabilities.',
      routeNotFound: "Sorry, we couldn't find what you're looking for.",
      heliportNotFound: "Sorry, we couldn't find the heliport.",
      acceptBooking: "Sorry, we can't accept your reservation.",
      cancelBooking: "Sorry, we can't cancel your reservation",
      declineBooking: "Sorry, we can't decline your reservation",
      editBooking: "Sorry, we can't edit your reservation",
      userProfile: "Sorry, we couldn't find profile you're looking",
      connectAccount: 'Error with connect account',
    },
    paymentHistorial: {
      emptyPayments: "You don't have Payments.",
      paymentHistorialTitle: 'Payments historial',
    },
    errorManager: {
      completeInformation: 'Please, complete heliport information.',
      message:
        'You should create Helipad from Manage my heliport or create heliport aviabilities.',
      button: 'Manage my heliport.',
      cancel: 'Cancel',
    },
    changePassword: {
      title: 'Create New Password',
      introduce: 'Please enter your new Password',
      button: 'Send',
    },
    paymentStatus: {
      unPaidInvoice: 'unpaid - invoice',
      paidAuthorized: 'requested',
      paidCharged: 'succeeded',
      unPaid: 'unpaid',
      paidCancel: 'canceled',
      manualReservation: 'unpaid - manual reservation',
      paidCode: 'promo code',
      succeeded: 'succeeded',
      canceled: 'canceled',
      requireCapture: 'require capture',
      payment: 'payment',
    },
    time: {
      days: 'day(s)',
      minutes: 'minute(s)',
      hours: 'hour(s)',
    },
    country: {
      brazil: 'Brazil',
      usa: 'USA',
      mexico: 'Mexico',
    },
    errorPhoneNumber: 'Invalid for the selected country code',
    errorsPassword: {
      minLength: 'At lest 8 characters.',
      specialChar: 'At lest a special character',
      number: 'At least a number.',
      capital: 'At lest an uppercase letter.',
      lowercase: 'At lest a lowercase letter.',
    },
    west: 'W',
  },
}
export default English
