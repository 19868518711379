import React from 'react'
import {
  View,
  Button,
  Text,
  Box,
  Heading,
  Divider,
  HStack,
  VStack,
  Container,
  Modal,
  NativeBaseProvider,
  Stack,
  Alert,
  IconButton,
  CloseIcon,
  Input,
} from 'native-base'
import { useLazyQuery } from '@apollo/client'
import {
  GET_HELIPORT_MANAGER,
  GET_COMPANY_MANAGERS,
  GET_COMPANY_USERS,
} from '../../graphql/queries'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import { useHistory } from '../../routing'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import axiosInstance from '../../AxiosInstance'

const BookingsIsPending = ({ booking, timezone, onRefresh }) => {
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [visible, setIsVisible] = React.useState(false)
  const [bookingId, setBookingId] = React.useState('')
  const [reason, setReason] = React.useState('')
  const [error, setError] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)
  let history = useHistory()
  const { t } = useTranslation()

  const bookingStatus = {
    tentative: t(
      'managerBooking.bookingPendingConfirm.bookingStatus.tentative'
    ),
  }

  const acepptBookings = async (item) => {
    openModalLoading()
    try {
      await axiosInstance.post(`/bookings/accept`, {
        bookingId: item.id,
      })
      onRefresh()
      closeModalLoading()
    } catch (error) {
      closeModalLoading()
      setShowError(true)
    }
  }

  const goToBookingDetail = (id) => {
    history.push(`/bookingDetail/${id}`)
  }

  const getDate = (bookingDate) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  const declineBooking = async () => {
    setButtonLoading(true)
    try {
      await axiosInstance.post(`/bookings/decline`, {
        booking_id: bookingId,
        reason: reason,
      })
      onRefresh()
      setButtonLoading(false)
      closeModal()
    } catch (error) {
      setButtonLoading(false)
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('managerBooking.bookingPendingConfirm.errors.decline'))
      } else {
        setError(
          error.response.data &&
            error.response.data.error &&
            error.response.data.message
            ? error.response.data.message
            : error.message
        )
      }
    }
  }

  const openModal = (id) => {
    setBookingId(id)
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }
  const openModalLoading = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowLoading(true)
  }

  const closeModalLoading = () => {
    document.body.style.overflow = 'scroll'
    setShowLoading(false)
  }
  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
              {t('managerBooking.bookingPendingConfirm.pendingRequests.title')}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.reservation'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.date'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getDate(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.start'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getStartBooking(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.end'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getEndBooking(item.attributes.event.end)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Helipad:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.attributes.event.what}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.customer'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.embarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.embarking_passengers
                                ? item.meta.embarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer.embarking_passengers
                                ? item.attributes.customer.embarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.pendingRequests.disembarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.disembarking_passengers
                                ? item.meta.disembarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer
                                    .disembarking_passengers
                                ? item.attributes.customer
                                    .disembarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '20%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.pendingRequests.status'
                        )}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.pendingRequests.booking'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.pendingRequests.payment'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '15%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.pendingRequests.actions'
                        )}
                      </Text>
                      <View>
                        <HStack space={3}>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'sm']}
                            bold
                            underline
                            p="3"
                            onPress={() => {
                              goToBookingDetail(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.viewDetails'
                            )}
                          </Text>

                          <Button
                            bgColor="yellow.300"
                            size="md"
                            _text={{
                              color: 'coolgray.800',
                              fontSize: ['xs', 'sm', 'sm', 'sm'],
                            }}
                            onPress={() => {
                              acepptBookings(item)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.acceptButton'
                            )}
                          </Button>
                          <Button
                            _text={{
                              fontSize: ['xs', 'sm', 'sm', 'sm'],
                            }}
                            bgColor="danger.500"
                            size="md"
                            onPress={() => {
                              openModal(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.pendingRequests.rejectButton'
                            )}
                          </Button>
                        </HStack>
                      </View>
                    </Container>
                  </Stack>
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {error}
                  </Text>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
        <Modal isOpen={visible} onClose={() => closeModal()} flex={1}>
          <Modal.Content maxWidth="350">
            <Modal.CloseButton />
            <Modal.Header>
              {t(
                'managerBooking.bookingPendingConfirm.pendingRequests.declineBooking'
              )}
            </Modal.Header>
            <Modal.Body>
              <Text fontSize="md" fontWeight="bold">
                {t(
                  'managerBooking.bookingPendingConfirm.pendingRequests.reason'
                )}
              </Text>
              <Input
                multiline
                height="100"
                onChangeText={(reason) => setReason(reason)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    closeModal()
                  }}
                >
                  {t(
                    'managerBooking.bookingPendingConfirm.pendingRequests.cancel'
                  )}
                </Button>
                <Button
                  colorScheme="yellow"
                  isLoading={buttonLoading}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                  onPress={() => {
                    declineBooking()
                  }}
                >
                  {t(
                    'managerBooking.bookingPendingConfirm.pendingRequests.save'
                  )}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        <Modal isOpen={showError}>
          <Alert status="error">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack
                flexShrink={1}
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{
                      color: 'coolGray.800',
                    }}
                  >
                    Error to confirm booking!
                  </Text>
                </HStack>
                <IconButton
                  variant="unstyled"
                  icon={<CloseIcon size="3" color="coolGray.600" />}
                  onPress={() => setShowError(false)}
                />
              </HStack>
            </VStack>
          </Alert>
        </Modal>
        <Modal isOpen={showLoading} _backdrop={{ bg: 'white' }}>
          <Loading />
        </Modal>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

const BookingsIsConfirmed = ({ booking, timezone, onRefresh }) => {
  const [showLoading, setShowLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  let history = useHistory()
  const { t } = useTranslation()

  const bookingStatus = {
    confirmed: t(
      'managerBooking.bookingPendingConfirm.bookingStatus.confirmed'
    ),
  }

  const cancelBooking = async (item) => {
    openModalLoading()
    try {
      await axiosInstance.post(`/bookings/cancel`, {
        bookingId: item.id,
      })
      onRefresh()
      closeModalLoading()
    } catch (error) {
      closeModalLoading()
      setShowError(true)
    }
  }

  const goToBookingDetail = (id) => {
    history.push(`/bookingDetail/${id}`)
  }

  const getDate = (bookingDate) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  const openModalLoading = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowLoading(true)
  }

  const closeModalLoading = () => {
    document.body.style.overflow = 'scroll'
    setShowLoading(false)
  }

  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
              {t(
                'managerBooking.bookingPendingConfirm.confirmedRequests.title'
              )}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.reservation'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.date'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getDate(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.start'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getStartBooking(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.end'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getEndBooking(item.attributes.event.end)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Helipad:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.attributes.event.what}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.customer'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.embarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.embarking_passengers
                                ? item.meta.embarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer.embarking_passengers
                                ? item.attributes.customer.embarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.disembarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.disembarking_passengers
                                ? item.meta.disembarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer
                                    .disembarking_passengers
                                ? item.attributes.customer
                                    .disembarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '20%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.confirmedRequests.status'
                        )}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.confirmedRequests.booking'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.confirmedRequests.payment'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width="40%" height="100%">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.confirmedRequests.actions'
                        )}
                      </Text>
                      <View>
                        <HStack space={3}>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'sm']}
                            bold
                            underline
                            p="3"
                            onPress={() => {
                              goToBookingDetail(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.viewDetails'
                            )}
                          </Text>
                          <Button
                            _text={{
                              fontSize: ['xs', 'sm', 'sm', 'sm'],
                            }}
                            bgColor="danger.500"
                            size="md"
                            onPress={() => {
                              cancelBooking(item)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.cancelButton'
                            )}
                          </Button>
                        </HStack>
                      </View>
                    </Container>
                  </Stack>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
        <Modal isOpen={showError}>
          <Alert status="error">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack
                flexShrink={1}
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{
                      color: 'coolGray.800',
                    }}
                  >
                    {t('managerBooking.bookingPendingConfirm.errors.cancel')}
                  </Text>
                </HStack>
                <IconButton
                  variant="unstyled"
                  icon={<CloseIcon size="3" color="coolGray.600" />}
                  onPress={() => setShowError(false)}
                />
              </HStack>
            </VStack>
          </Alert>
        </Modal>
        <Modal isOpen={showLoading} _backdrop={{ bg: 'white' }}>
          <Loading />
        </Modal>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

const BookingsManualReservation = ({ booking, timezone }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const bookingStatus = {
    tentative: t(
      'managerBooking.bookingPendingConfirm.bookingStatus.tentative'
    ),
  }

  const goToBookingDetail = (id) => {
    history.push(`/bookingDetail/${id}`)
  }
  const getDate = (bookingDate) => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      timeZone: timezone,
    }
    const start = new Date(bookingDate)
    const date = start.toLocaleDateString(undefined, options)
    return date
  }

  const getStartBooking = (bookingDate) => {
    const start = new Date(bookingDate)
    const bookingStart = start.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    })
    return bookingStart
  }

  const getEndBooking = (bookingDate) => {
    const end = new Date(bookingDate)
    const bookingEnd = end.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    })
    return bookingEnd
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
              {t('managerBooking.bookingPendingConfirm.manualReservation')}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.reservation'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.date'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getDate(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.start'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getStartBooking(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.end'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getEndBooking(item.attributes.event.end)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Helipad:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.attributes.event.what}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.customer'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.embarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.embarking_passengers
                                ? item.meta.embarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer.embarking_passengers
                                ? item.attributes.customer.embarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={1}>
                            <Heading
                              fontSize={['xs', 'sm', 'sm', 'sm']}
                              fontWeight="bold"
                            >
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.operation'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.bookingPendingConfirm.confirmedRequests.disembarking'
                              )}
                            </Text>
                          </HStack>
                          <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.passengerName'
                            )}
                          </Heading>
                          <HStack space={1}>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.disembarking_passengers
                                ? item.meta.disembarking_passengers
                                : item.attributes.customer &&
                                  item.attributes.customer
                                    .disembarking_passengers
                                ? item.attributes.customer
                                    .disembarking_passengers
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '20%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.confirmedRequests.status'
                        )}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.confirmedRequests.booking'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t(
                            'managerBooking.bookingPendingConfirm.confirmedRequests.payment'
                          )}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width="40%" height="100%">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t(
                          'managerBooking.bookingPendingConfirm.confirmedRequests.actions'
                        )}
                      </Text>
                      <View>
                        <HStack space={3}>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'sm']}
                            bold
                            underline
                            p="3"
                            onPress={() => {
                              goToBookingDetail(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.bookingPendingConfirm.confirmedRequests.viewDetails'
                            )}
                          </Text>
                        </HStack>
                      </View>
                    </Container>
                  </Stack>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

export const BookingsPendingConfirm = () => {
  const [user, setUserId] = React.useState('')
  const [bookingsPending, setBookingsPending] = React.useState([])
  const [bookingsConfirmed, setBookingsConfirmed] = React.useState([])
  const [bookingsManualReservation, setBookingsManualReservation] =
    React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [usersManagers, setUsersManagers] = React.useState([])
  const [timezone, setTimezone] = React.useState('')
  const [timekitProject, setTimekitProject] = React.useState('')
  const [error, setError] = React.useState('')
  const { t } = useTranslation()
  const l = useTranslation()

  const [manager] = useLazyQuery(GET_HELIPORT_MANAGER, {
    variables: { userId: user },
    fetchPolicy: 'network-only',
  })

  const [managers] = useLazyQuery(GET_COMPANY_MANAGERS, {
    variables: { userId: user },
    fetchPolicy: 'network-only',
  })

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const getManagerProjectId = await manager()
    const timeKitProjectId =
      getManagerProjectId.data.heliports[0].heliport_profile.timekit_project_id
    setTimekitProject(timeKitProjectId)
    const companyManagers = await managers()
    const getCompanyUsersEmails = await getUsersEmail(
      companyManagers.data.company[0].users_companies
    )
    setUsersManagers(getCompanyUsersEmails)

    const heliportTimeZone =
      companyManagers.data.company[0].heliports[0].heliport_profile.timezone
    setTimezone(heliportTimeZone)
    await getBookingsManager(timeKitProjectId, heliportTimeZone)
    setIsLoading(false)
    const interval = setInterval(async () => {
      await getBookingsManager(timeKitProjectId, heliportTimeZone)
    }, 120000)
    return () => clearInterval(interval)
  }, [])

  const getBookingsManager = async (timeKitProjectId, heliportTimeZone) => {
    try {
      const getHeliportBookingsPending = await axiosInstance.post(
        `/bookings/getBookingsPending`,
        {
          timeKitProjectId: timeKitProjectId,
          timezone: heliportTimeZone,
        }
      )

      setBookingsPending(getHeliportBookingsPending.data.bookings)

      const getHeliportBookingsConfirmed = await axiosInstance.post(
        `/bookings/getBookingsConfirm`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )
      setBookingsConfirmed(getHeliportBookingsConfirmed.data.bookings)
      const getHeliportBookingsManualReservation = await axiosInstance.post(
        `/bookings/getBookingsManualRservation`,
        {
          timeKitProjectId: timeKitProjectId,
          timezone: heliportTimeZone,
        }
      )
      setBookingsManualReservation(
        getHeliportBookingsManualReservation.data.bookings
      )
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('errors.bookings'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('errors.bookings'))
      }
    }
  }

  const getUsersEmail = async (managersCompany) => {
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    return usersEmails
  }

  const onBookingsRefresh = async () => {
    await getBookingsManager(timekitProject, timezone)
  }

  if (error) return <Text>{error}</Text>

  return (
    <View>
      {isLoading ? (
        <Loading />
      ) : bookingsPending.length === 0 &&
        bookingsConfirmed.length === 0 &&
        bookingsManualReservation.length === 0 ? (
        <View>
          <Heading p="1%">
            {t('managerBooking.bookingPendingConfirm.title')}
          </Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
              {t('managerBooking.bookingPendingConfirm.noBooking')}
            </Text>
          </Box>
        </View>
      ) : (
        <View>
          <Heading p="1%">
            {t('managerBooking.bookingPendingConfirm.title')}
          </Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <BookingsIsPending
              booking={bookingsPending}
              users={usersManagers}
              timezone={timezone}
              onRefresh={onBookingsRefresh}
            />
            <p />
            <BookingsIsConfirmed
              booking={bookingsConfirmed}
              users={usersManagers}
              timezone={timezone}
              onRefresh={onBookingsRefresh}
            />
            <p />
            <BookingsManualReservation
              booking={bookingsManualReservation}
              users={usersManagers}
              timezone={timezone}
              onRefresh={onBookingsRefresh}
            />
          </Box>
        </View>
      )}
    </View>
  )
}
export default BookingsPendingConfirm
