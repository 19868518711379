import React from 'react'
import {
  Alert,
  Button,
  VStack,
  HStack,
  IconButton,
  CloseIcon,
  Box,
  Text,
  Modal,
  View,
  Heading,
  Input,
  Checkbox,
  Image,
  Center,
} from 'native-base'
import { Link } from '../routing'
import { auth, storage } from '../utils/nhost'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import login from '../assets/login.png'
import imgSrc from '../assets/logo_yellow.png'
import { StyleSheet, ImageBackground } from 'react-native'
import { useHistory } from '../routing'

export const Login = (props) => {
  const [show, setShow] = React.useState(false)
  const [errorLogin, setErrorLogin] = React.useState('')
  const [checked, setChecked] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const { t } = useTranslation()

  const handleClick = () => setShowPassword(!showPassword)

  let history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      identifier: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      const { error } = await auth.signIn({
        email: data.identifier,
        password: data.password,
      })
      if (error) {
        setErrorLogin(error.message)
        setShow(true)
      } else {
        history.goBack()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <View bgColor="white">
      <Center p={['20%', '20%', '10%', '2%']}>
        <Image
          source={{ uri: imgSrc }}
          alt="Alternate Text"
          style={styles.stretch}
        />
      </Center>
      <View alignItems="center">
        <Box width={['90%', '90%', '50%', '30%']} bgColor="white">
          <Heading size="lg">
            <Text fontSize="36" color="#4D4D4D">
              {t('login.title')}
            </Text>
          </Heading>
          <Text fontSize="16" pb="2" color="#B3B3B3">
            {t('login.welcomeBack')}
          </Text>
          <p />
          <Text pl={2} bold color="#4D4D4D">
            {t('login.username')}
          </Text>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Invalid email address',
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                rounded="2xl"
                placeholder={t('login.userNameorEmail')}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            )}
            name="identifier"
          />
          {errors.identifier && (
            <Text p="2" color="red.600" fontSize="xs">
              {errors.identifier.message}
            </Text>
          )}
          <br />
          <Text pl={2} bold color="#4D4D4D">
            {t('login.password')}
          </Text>

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type={showPassword ? 'text' : 'password'}
                py="0"
                variant="unstyled"
                placeholder={t('login.password')}
                onBlur={onBlur}
                onChangeText={onChange}
                borderColor={errors.password ? '#eb0100' : 'light.200'}
                value={value}
                height={9}
                pl={2}
                borderWidth={1}
                borderRadius={30}
                InputRightElement={
                  <Button
                    size="xs"
                    variant="link"
                    rounded="none"
                    w="1/6"
                    h="full"
                    colorScheme="yellow"
                    onPress={handleClick}
                  >
                    {showPassword ? t('login.hide') : t('login.show')}
                  </Button>
                }
              />
            )}
            name="password"
          />
          {errors.password && (
            <Text p="2" color="red.600" fontSize="xs">
              This is required.
            </Text>
          )}
          <HStack w="100%" pt="2" pb="2">
            <View w="50%">
              <HStack pt="3" pr="3" pb="3" pl="2" space={2}>
                <Checkbox
                  value={checked}
                  accessibilityLabel="Remember me"
                  onChange={(nextValue) => {
                    setChecked(nextValue)
                  }}
                  colorScheme="yellow"
                />
                <Text fontSize="xs" color="muted.600">
                  {t('login.rememberMe')}
                </Text>
              </HStack>
            </View>
            <View textAlign={'right'} w="50%" pt="3">
              <Link
                style={{ textDecoration: 'none' }}
                to="/auth/forgotPassword"
              >
                <Text fontWeight="medium" fontSize="xs" color="#2166A6" p="2">
                  {t('login.forgotPassword')}
                </Text>
              </Link>
            </View>
          </HStack>
          <Button
            rounded="3xl"
            size={'md'}
            onPress={handleSubmit(onSubmit)}
            colorScheme="yellow"
          >
            <HStack
              style={{ alignItems: 'center', justifyContent: 'center' }}
              space={3}
            >
              <Text fontSize="16" fontWeight="medium">
                {t('login.signIn')}
              </Text>
              <Image
                source={{
                  uri: login,
                }}
                alt="Alternate Text"
                style={{ width: 20, height: 20 }}
              />
            </HStack>
          </Button>
          <Center>
            <HStack space={1} p="2">
              <Text fontSize="xs" color="muted.600">
                {t('login.notRegister')}
              </Text>
              <View>
                <Link style={{ textDecoration: 'none' }} to="/auth/register">
                  <Text fontWeight="medium" fontSize="xs" color="primary.700">
                    {t('login.linkToRegister')}
                  </Text>
                </Link>
              </View>
            </HStack>
          </Center>
        </Box>
      </View>
      <Modal isOpen={show}>
        <Alert status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('try_again.text')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => setShow(false)}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              {errorLogin}
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default Login
const styles = StyleSheet.create({
  stretch: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
  },
})
