import { NhostClient } from '@nhost/nhost-js'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { HASURA } from '@env'

const nhost = new NhostClient({
  backendUrl: HASURA,
  clientStorage: AsyncStorage,
  clientStorageType: 'react-native',
})

const { auth, storage } = nhost

export { auth, storage }
