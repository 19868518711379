import * as React from 'react'
import { Dimensions, StatusBar, Animated, Pressable } from 'react-native'
import { TabView, SceneMap } from 'react-native-tab-view'
import { Box, View } from 'native-base'
import BookingsPendingConfirm from './BookingsPendingConfirm'
import BookingsCancelandCompleted from './BookingsCancelandCompleted'
import { useTranslation } from 'react-i18next'

const FirstRoute = () => <BookingsPendingConfirm />

const SecondRoute = () => <BookingsCancelandCompleted />

const initialLayout = { width: Dimensions.get('window').width }

const renderScene = SceneMap({
  first: FirstRoute,
  second: SecondRoute,
})

export const ListView = () => {
  const { t } = useTranslation()
  const [index, setIndex] = React.useState(0)
  const [routes] = React.useState([
    {
      key: 'first',
      title: t('managerBooking.upcomingBookings'),
    },
    {
      key: 'second',
      title: t('managerBooking.pastBookings'),
    },
  ])

  const renderTabBar = (props) => {
    const inputRange = props.navigationState.routes.map((x, i) => i)
    return (
      <Box flexDirection="row">
        {props.navigationState.routes.map((route, i) => {
          const opacity = props.position.interpolate({
            inputRange,
            outputRange: inputRange.map((inputIndex) =>
              inputIndex === i ? 1 : 0.5
            ),
          })
          const color = index === i ? '#1f2937' : '#a1a1aa'
          const borderColor = index === i ? 'yellow.400' : 'coolGray.200'

          return (
            <Box
              borderBottomWidth="3"
              borderColor={borderColor}
              flex={1}
              alignItems="center"
              p="3"
              cursor="pointer"
              key={route.key}
            >
              <Pressable
                onPress={() => {
                  setIndex(i)
                }}
              >
                <Animated.Text style={{ color }}>{route.title}</Animated.Text>
              </Pressable>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <View>
      <View pl="10%" pr="10%">
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
          style={{ marginTop: StatusBar.currentHeight }}
        />
      </View>
    </View>
  )
}

export default ListView
