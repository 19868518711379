import React from 'react'
import { HStack, View, Spinner, Image, Center, Heading } from 'native-base'
import { StyleSheet } from 'react-native'
import imgSrc from '../assets/logo_yellow.png'
import { useTranslation } from 'react-i18next'

export const Loading = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.loading}>
      <Image alt="Loading" source={{ uri: imgSrc }} style={styles.stretch} />
      <HStack space={2} justifyContent="center">
        <Spinner color="#F9D342" accessibilityLabel="Loading posts" />
        <Heading fontSize="md">{t('loading.loading')}</Heading>
      </HStack>
    </View>
  )
}

export default Loading
const styles = StyleSheet.create({
  stretch: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
