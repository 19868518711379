import React from 'react'
import {
  Heading,
  Text,
  Modal,
  Divider,
  Center,
  Image,
  Box,
  View,
  HStack,
  Button,
  Input,
  Stack,
  NativeBaseProvider,
  VStack,
  Alert,
  IconButton,
  CloseIcon,
} from 'native-base'
import { FlatList } from 'react-native-gesture-handler'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_HELIPADS } from '../../graphql/queries'
import {
  CREATE_HELIPAD,
  UPDATE_HELIPAD,
  DELETE_HELIPAD,
  CREATE_HELIPAD_IMAGE,
} from '../../graphql/mutations'
import { getToken } from '../../Token'
import { useForm, Controller } from 'react-hook-form'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import ImageUploading from 'react-images-uploading'
import { auth, storage } from '../../utils/nhost'
import axiosInstance from '../../AxiosInstance'
import ModalError from '../../components/ModalError'
import { HASURA } from '@env'

export const Helipad = ({ heliportTimezone }) => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUserId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [helipad, setHelipad] = React.useState('')
  const [isVisible, setIsVisible] = React.useState(false)
  const [createHelipad] = useMutation(CREATE_HELIPAD)
  const [updateHelipad] = useMutation(UPDATE_HELIPAD)
  const [deleteHelipad] = useMutation(DELETE_HELIPAD)
  const [images, setImages] = React.useState('')
  const [editName, setEditName] = React.useState('')
  const maxNumber = 1
  const [createHelipadImage] = useMutation(CREATE_HELIPAD_IMAGE)
  const [getHelipads] = useLazyQuery(GET_HELIPADS)
  const [helipadsArray, setHelipadsArray] = React.useState([])
  const { t } = useTranslation()
  const [heliportId, setHeliportId] = React.useState('')
  const [timekitProjectId, setTimekitProjectId] = React.useState('')
  const [buttonCreateLoading, setButtonCreateLoading] = React.useState(false)
  const [heliportData, setHeliportData] = React.useState('')
  const [notReservation, setNotReservation] = React.useState(false)
  const [isVisibleWarning, setIsVisibleWarning] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showLoading, setShowLoading] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const helipads = await getHelipads({
      variables: { userId: userId },
    })
    const heliportFields = helipads.data.heliports[0]
    setHeliportData(heliportFields)
    const getHeliportId = helipads.data.heliports[0].id
    setHeliportId(getHeliportId)
    const getTimeKitProjectId =
      helipads.data.heliports[0].heliport_profile.timekit_project_id
    await getHelipadsArray(helipads.data.heliports[0].helipads)
    setTimekitProjectId(getTimeKitProjectId)
    setLoading(false)
  }, [])

  const getHelipadsArray = async (helipads) => {
    const helipadsInformation = await getHelipadsWithImage(helipads)
    setHelipadsArray(helipadsInformation)
  }

  // Validation of missing fields in register
  const validateReservation = () => {
    if (
      heliportData.heliport_profile.landing_slot_length &&
      heliportData.heliport_profile.helipads_number !== 0 &&
      heliportData.heliport_profile.size_limit_weigth !== 0 &&
      heliportData.heliport_profile.measure_limit_weigth &&
      heliportData.heliport_profile.measure_rotor_diameter &&
      heliportData.heliport_profile.cancellation_policy &&
      heliportData.heliport_profile.latitude !== 0 &&
      heliportData.heliport_profile.longitude !== 0 &&
      heliportData.heliport_profile.heliport_currency &&
      heliportData.heliport_profile.limit_late_booking &&
      heliportData.heliport_profile.surface_type
    ) {
      openModal()
    } else {
      setNotReservation(true)
    }
  }

  const getHelipadsWithImage = async (helipads) => {
    const newArrayHelipads = await Promise.all(
      helipads.map(async (helipad) => {
        if (helipad.helipad_image) {
          const fileId = helipad.helipad_image.file_id
          const getUrl = await storage.getPresignedUrl({ fileId })
          const helipadInformation = {
            ...helipad,
            urlImage: getUrl.presignedUrl.url,
          }
          return helipadInformation
        } else {
          const helipadInformation = {
            ...helipad,
            urlImage: null,
          }
          return helipadInformation
        }
      })
    )
    return newArrayHelipads
  }

  const showEditModal = (item) => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
    setHelipad(item)
  }

  const closeModalEdit = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  })

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    let res = await fetch(`${HASURA}/v1/storage/files/`, {
      method: 'post',
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let responseJson = await res.json()
    const imageId = responseJson.processedFiles[0].id
    return imageId
  }

  const openModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const onSubmit = async (helipadInformation) => {
    setButtonCreateLoading(true)

    try {
      const timeKitResource = await axiosInstance.post(
        `/resources/createResource`,
        {
          name: helipadInformation.name,
          timezone: heliportTimezone,
          projectId: timekitProjectId,
        }
      )
      if (images && timeKitResource.data.timekitResource.id) {
        const imageId = await uploadImage()
        const helipadImage = await createHelipadImage({
          variables: {
            file_id: imageId,
          },
        })
        const createdHelipad = await createHelipad({
          variables: {
            name: helipadInformation.name,
            heliport_id: heliportId,
            timekit_resource_id: timeKitResource.data.timekitResource.id,
            helipad_image_id: helipadImage.data.insert_helipad_images_one.id,
          },
          refetchQueries: [
            {
              query: GET_HELIPADS,
              variables: {
                userId: user,
              },
            },
          ],
        })
        const refetchHelipads = await getHelipads({
          variables: { userId: user },
          fetchPolicy: 'network-only',
        })
        const newHelipadsInformation = [
          ...refetchHelipads.data.heliports[0].helipads,
          createdHelipad.data.insert_helipads_one,
        ]
        const newHelipadsArray = await getHelipadsWithImage(
          newHelipadsInformation
        )
        setHelipadsArray(newHelipadsArray)
        reset()
        setImages([])
        setButtonCreateLoading(false)
        closeModal()
      } else if (timeKitResource.data.timekitResource.id) {
        const createdHelipad = await createHelipad({
          variables: {
            name: helipadInformation.name,
            heliport_id: heliportId,
            timekit_resource_id: timeKitResource.data.timekitResource.id,
          },
          refetchQueries: [
            {
              query: GET_HELIPADS,
              variables: {
                userId: user,
              },
            },
          ],
        })
        const refetchHelipads = await getHelipads({
          variables: { userId: user },
          fetchPolicy: 'network-only',
        })
        const newHelipadsInformation = [
          ...refetchHelipads.data.heliports[0].helipads,
          createdHelipad.data.insert_helipads_one,
        ]
        setHelipadsArray(newHelipadsInformation)
        reset()
        setButtonCreateLoading(false)
        closeModal()
      } else {
        setButtonCreateLoading(false)
        setError(t('manageHeliport.manageHelipad.errors.create'))
        openError()
      }
    } catch (error) {
      setButtonCreateLoading(false)
      if (error.response) {
        if (error.response.data.errors) {
          setError(t('manageHeliport.manageHelipad.errors.create'))
        } else {
          setError(error.response.data.error.message)
        }
      } else {
        setError(t('manageHeliport.manageHelipad.errors.create'))
      }
      openError()
    }
  }

  const editHelipad = async () => {
    setButtonLoading(true)
    try {
      await axiosInstance.post(`/resources/editResource`, {
        id: helipad.timekit_resource_id,
        first_name: editName ? editName : helipad.name,
        timezone: heliportTimezone,
      })

      await updateHelipad({
        variables: {
          id: helipad.id,
          name: editName ? editName : helipad.name,
        },
      })
      const refetchHelipads = await getHelipads({
        variables: { userId: user },
        fetchPolicy: 'network-only',
      })
      await getHelipadsArray(refetchHelipads.data.heliports[0].helipads)
      setButtonLoading(false)
      closeModalEdit()
    } catch (error) {
      setButtonLoading(false)
      if (error.response) {
        if (error.response.data.errors) {
          setError(t('manageHeliport.manageHelipad.errors.edit'))
        } else {
          setError(error.response.data.error.message)
        }
      } else {
        setError(t('manageHeliport.manageHelipad.errors.edit'))
      }
      openError()
    }
  }

  const deleteHelipadfromList = async (item) => {
    openModalLoading()
    try {
      const deletedBooking = await axiosInstance.post(
        `/resources/deleteResource`,
        {
          id: item.timekit_resource_id,
        }
      )
      if (deletedBooking.data.response === 'no_delete') {
        openWarningDelete()
      } else if (deletedBooking.data.response === 'resource_deleted') {
        const deletedHelipad = await deleteHelipad({
          variables: {
            id: item.id,
          },
        })
        const index = helipadsArray.findIndex((object) => {
          return object.id === deletedHelipad.data.delete_helipads_by_pk.id
        })
        helipadsArray.splice(index, 1)
        setHelipadsArray(helipadsArray)
      }
      closeModalLoading()
    } catch (error) {
      closeModalLoading()
      if (error.response) {
        if (error.response.data.errors) {
          setError(t('manageHeliport.manageHelipad.errors.delete'))
        } else {
          setError(error.response.data.error.message)
        }
      } else {
        setError(t('manageHeliport.manageHelipad.errors.delete'))
      }
      openError()
    }
  }

  const openWarningDelete = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleWarning(true)
  }

  const closeWarningDelete = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleWarning(false)
  }

  const openError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowError(true)
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }

  const openModalLoading = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowLoading(true)
  }

  const closeModalLoading = () => {
    document.body.style.overflow = 'scroll'
    setShowLoading(false)
  }

  if (loading) return <Loading />
  return (
    <View>
      <NativeBaseProvider>
        {helipadsArray.length === 0 ? (
          <View>
            <View
              style={{
                alignItems: 'center',
              }}
            >
              <Text p="5">{t('manageHeliport.manageHelipad.notHelipads')}</Text>
              <Button
                onPress={() => {
                  window.scrollTo(0, 0), validateReservation()
                }}
                colorScheme="yellow"
                rounded="2xl"
              >
                {t('manageHeliport.manageHelipad.addHelipad')}
              </Button>
            </View>
          </View>
        ) : (
          <View>
            <View>
              <FlatList
                data={helipadsArray}
                keyExtractor={(item) => item.id.toString()}
                ItemSeparatorComponent={() => <Divider />}
                renderItem={({ item }) => (
                  <Box key={item.id.toString()} p={2}>
                    <Stack
                      direction={['column', 'column', 'column', 'row']}
                      space={3}
                    >
                      <Center width={['100%', '100%', '100%', '20%']}>
                        <View>
                          <Image
                            source={{
                              uri: item.urlImage
                                ? item.urlImage
                                : 'https://cdn.pixabay.com/photo/2019/11/26/17/34/helicopter-4655049_1280.jpg',
                            }}
                            rounded="xl"
                            alt="Alternate Text"
                            size="xl"
                            height="170"
                            width="170"
                          />
                        </View>
                      </Center>
                      <View
                        width={['100%', '100%', '100%', '80%']}
                        height="170"
                      >
                        <Box
                          p="6"
                          rounded="xl"
                          backgroundColor="light.100"
                          key={item.id.toString()}
                        >
                          <Text bold mb="5" fontSize={['xs', 'sm', 'sm', 'md']}>
                            {item.name}
                          </Text>
                          <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                            {t('manageHeliport.manageHelipad.helipadDetails')}
                          </Text>
                          <br />
                          <Stack
                            direction={['column', 'column', 'row', 'row']}
                            space={1}
                          >
                            <Button
                              _text={{
                                fontSize: ['xs', 'sm', 'sm', 'sm'],
                              }}
                              onPress={() => showEditModal(item)}
                              colorScheme="yellow"
                              rounded="2xl"
                            >
                              {t('manageHeliport.manageHelipad.editHelipad')}
                            </Button>
                            <Button
                              _text={{
                                fontSize: ['xs', 'sm', 'sm', 'sm'],
                              }}
                              onPress={() => deleteHelipadfromList(item)}
                              colorScheme="red"
                              rounded="2xl"
                            >
                              {t('manageHeliport.manageHelipad.delete')}
                            </Button>
                          </Stack>
                        </Box>
                      </View>
                    </Stack>
                  </Box>
                )}
              />
              <p />
              <Button
                onPress={() => {
                  openModal()
                }}
                colorScheme="yellow"
                rounded="2xl"
              >
                {t('manageHeliport.manageHelipad.addHelipad')}
              </Button>
            </View>
          </View>
        )}
      </NativeBaseProvider>
      <Modal isOpen={show} onClose={() => closeModal()}>
        <Center>
          <View
            backgroundColor="light.100"
            shadow="9"
            rounded="2xl"
            p="6"
            width={['90%', '90%', '100%', '100%']}
          >
            <Heading size="lg">
              {t('manageHeliport.manageHelipad.createHelipadTitle')}
            </Heading>
            <Text fontSize="16" pb="2" color="#B3B3B3">
              {t('manageHeliport.manageHelipad.helipadInformation')}
            </Text>
            <Text bold color="muted.600">
              {t('manageHeliport.manageHelipad.name')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
                  message: t('register.lettersAndNumbers'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder="Name"
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  rounded="2xl"
                />
              )}
              name="name"
            />
            {errors.name && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.name.message
                  ? errors.name.message
                  : t('register.required')}
              </Text>
            )}

            <View p="4">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <View className="upload__image-wrapper">
                    {imageList.map((image, index) => (
                      <Center key={index}>
                        <Image
                          source={{
                            uri: `${image['data_url']}`,
                          }}
                          alt="Alternate Text"
                          size={'xl'}
                          resizeMode="cover"
                          rounded="xl"
                        />
                        <HStack space={3} p="4">
                          <Box shadow={2} width={['30%', '30%', '40%', '40%']}>
                            <Text pt="2" isTruncated="true">
                              {image.file.name}
                            </Text>
                          </Box>
                          <Button
                            colorScheme="yellow"
                            onPress={() => onImageUpdate(index)}
                          >
                            Update
                          </Button>
                          <Button
                            colorScheme="red"
                            onPress={() => onImageRemove(index)}
                          >
                            Remove
                          </Button>
                        </HStack>
                      </Center>
                    ))}
                    <Center>
                      <Button
                        rounded="2xl"
                        colorScheme="yellow"
                        size="md"
                        style={isDragging ? { color: 'red' } : undefined}
                        onPress={onImageUpload}
                        {...dragProps}
                      >
                        Upload Image
                      </Button>
                    </Center>
                  </View>
                )}
              </ImageUploading>
            </View>
            <View p="4">
              <Button
                onPress={handleSubmit(onSubmit)}
                colorScheme="yellow"
                rounded="2xl"
                isLoading={buttonCreateLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('manageHeliport.manageHelipad.createHelipad')}
              </Button>
            </View>
          </View>
        </Center>
      </Modal>
      <Modal isOpen={isVisible} onClose={() => closeModalEdit()}>
        <Center>
          <View
            backgroundColor="light.100"
            shadow="9"
            rounded="2xl"
            p="6"
            width="300"
          >
            <Heading size="lg">
              {t('manageHeliport.manageHelipad.editHelipadTitle')}
            </Heading>
            <Text fontSize="16" pb="2" color="#B3B3B3">
              {t('manageHeliport.manageHelipad.helipadInformation')}
            </Text>
            <Text bold color="muted.600">
              {t('manageHeliport.manageHelipad.name')}
            </Text>
            <Input
              onChangeText={(text) => setEditName(text)}
              defaultValue={helipad ? helipad.name : null}
              placeholder="Name"
              bgColor="white"
            ></Input>
            <br />

            <View p="4">
              <Button
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
                onPress={() => {
                  editHelipad()
                }}
                colorScheme="yellow"
                rounded="2xl"
              >
                {t('manageHeliport.manageHelipad.saveButton')}
              </Button>
            </View>
          </View>
        </Center>
      </Modal>
      <Modal
        isOpen={notReservation}
        onClose={() => setNotReservation(false)}
        flex={1}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('manageHeliport.reminder')}</Modal.Header>
          <Modal.Body>
            <Text p={3}>{t('manageHeliport.reminderMessage')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={3}>
              <Button
                bgColor="coolGray.300"
                size="md"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => setNotReservation(false)}
              >
                {t('manageHeliport.reminderAccept')}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showError}>
        <ModalError error={error} onClose={closeError} />
      </Modal>
      <Modal isOpen={isVisibleWarning}>
        <Alert status="warning">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('manageHeliport.manageHelipad.errors.deleteErrorServer')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => closeWarningDelete()}
              />
            </HStack>
          </VStack>
        </Alert>
      </Modal>
      <Modal isOpen={showLoading} _backdrop={{ bg: 'white' }}>
        <Loading />
      </Modal>
    </View>
  )
}

export default Helipad
